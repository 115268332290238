import { SEGMENTS as SET_SEGMENTS } from "./actions";
import React from "react";

let initialState = null;

export const reducer = (state = initialState, action) => {
	switch (action.type) {
	case SET_SEGMENTS:
		return action.segments;
		break;
	default:
		return state;
		break;
	}
};
