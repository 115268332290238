import {
	onboardingPrefix,
	trackSegmentEvent,
} from "assets/js/const/SegmentEvents";
import { listCampaignsService } from "../../services/campaignService";
import {
	PROTOCOLS,
	PUBLIC_DOMAINS,
} from "assets/js/scenes/account/scenes/domains/consts";
import { GOAL_NAME } from "assets/js/const/Goals";
import { AuthenticateDomainService } from "assets/js/services/DomainService";
import { listListsService } from "assets/js/services/listService";
import {
	isDomainPrivate,
	getDomainsListFromSenders,
} from "assets/js/scenes/account/scenes/senders/utils";
import { listSegmentService } from "assets/js/services/segmentService";
import { CAMPAIGN_STATUS } from "assets/js/const/Campaign";
import CakeStore from "../../store";
import { setUser } from "../../actions";
import { getAccountStatsService } from "assets/js/services/accountService";
import { listSendersService } from "assets/js/services/SenderService";

const updateAppCueData = (userId, updatedUserDataJson) => {
	if (window?.Appcues)
		window.Appcues.identify(userId, {
			...updatedUserDataJson,
		});
};
const newScript = (src) => {
	// create a promise for the newScript
	return new Promise(
		function (resolve, reject) {
			// create an html script element
			var script = document.createElement("script");
			// set the source of the script element
			script.src = src;
			// set a listener when the script element finishes loading the script
			script.addEventListener(
				"load",
				function () {
					// resolve if the script element loads
					resolve();
				}.bind(self)
			);
			// set a listener when the script element faces any errors while loading
			script.addEventListener(
				"error",
				function (e) {
					// reject if the script element has an error while loading the script
					reject(e);
				}.bind(self)
			);
			// append the script element to the body
			document.body.appendChild(script);
		}.bind(self)
	);
};
const loadingAppcuesCallback = (userDataJson, accountData) => {
	window.Appcues.identify(userDataJson.id, {
		...userDataJson,
	});
	window.Appcues.on("all", function (e, a) {
		const payload = { userId: userDataJson.id, ...a };
		trackSegmentEvent(`${onboardingPrefix}${e}`, payload);
		if (e === "checklist_skipped") {
			const node = document.getElementById("floating-container");
			if (node && node.parentNode) {
				node.parentNode.removeChild(node);
			}
		}
	});
	let options = {
		withCount: true,
		filter: `status==${CAMPAIGN_STATUS.DELIVERED},${CAMPAIGN_STATUS.SCHEDULED}`,
		perPage: "1",
	};
	let isUserHasGoal = false;

	// check if the user has campaigns or not , we need to check if he has scheduled or sent emails
	listCampaignsService(options, (data) => {
		if (data.pagination.count === 0) {
			isUserHasGoal = true;
			CakeStore.dispatch(
				setUser({
					...userDataJson,
					isUserHasGoal: isUserHasGoal,
				})
			);
			listCampaignsService(
				{
					...options,
					filter: `status==${CAMPAIGN_STATUS.INCOMPLETE}`,
				},
				(data) => {
					if (data.pagination.count && data.pagination.count > 0) {
						updateAppCueData(userDataJson.id, {
							...userDataJson,
							newCampaignIsCreated: true,
						});
					}
				}
			);
			// get contacts and segments
			let foundAtLeastOneSeg = false;
			listListsService({ withCount: true }, (data) => {
				getAccountStatsService({}, (data) => {
					if (data.data?.active_contacts > 0) {
						updateAppCueData(userDataJson.id, {
							...userDataJson,
							newContactIsAdded: true,
						});
					}
				});
				data.data.forEach((list) => {
					if (foundAtLeastOneSeg == false)
						listSegmentService(
							{
								withCount: true,
								id: list.id,
								perPage: 1,
							},
							(data) => {
								if (data.pagination.count && data.pagination.count > 0) {
									foundAtLeastOneSeg = true;
									updateAppCueData(userDataJson.id, {
										...userDataJson,
										newSegmentIsAdded: true,
									});
								}
							},
							() => {}
						);
				});
			});
			// need to get all other data required for this goal
			const checkIfSenderFromPublicDomain = (email) => {
				const atIndex = email.indexOf("@");
				const domain = email.substring(atIndex + 1);
				if (PUBLIC_DOMAINS.filter((d) => domain === d).length > 0) {
					return true;
				} else {
					return false;
				}
			};
			let foundAtLeastOneAuthenticatedSender = false;
			const authenticateADomain = (domain) => {
				let domainsAthenticatedValue = {};
				let doneCounter = 0;
				if (isDomainPrivate(domain)) {
					PROTOCOLS.forEach((protocol) => {
						AuthenticateDomainService(
							{
								domain: domain,
								protocol: protocol,
							},
							(data) => {
								domainsAthenticatedValue[protocol] = {
									verdict: data.verdict,
									record: data.record,
									suggested: data.suggested,
								};
								doneCounter = doneCounter + 1;
								if (doneCounter == 3) {
									let authenticated = true;

									if (domainsAthenticatedValue["dmarc"].verdict < 0) {
										authenticated = "blocked";
									} else if (domainsAthenticatedValue["spf"].suggested) {
										if (
											domainsAthenticatedValue["spf"].verdict > 0 &&
											domainsAthenticatedValue["dkim"].verdict > 0
										) {
											authenticated = "authenticated";
										} else authenticated = "unauthenticated";
									} else {
										if (domainsAthenticatedValue["dkim"].verdict > 0) {
											authenticated = "authenticated";
										} else authenticated = "unauthenticated";
									}
									if (authenticated === "authenticated") {
										foundAtLeastOneAuthenticatedSender = true;
										updateAppCueData(userDataJson.id, {
											...userDataJson,
											isAccountHasOneAuthenticatedDomain: true,
										});
									}
								}
							},
							() => {}
						);
					});
				}
			};
			listSendersService(
				{
					withCount: true,
					perPage: 200,
					page: 1,
				},
				(data) => {
					const sendersPublicDomains = [];
					if (data.data.length === 0) {
						sendersPublicDomains.push(
							checkIfSenderFromPublicDomain(userDataJson.email)
						);
						if (foundAtLeastOneAuthenticatedSender == false) {
							const atIndex = userDataJson.email.indexOf("@");
							const domain = userDataJson.email.substring(atIndex + 1);
							authenticateADomain(domain);
						}
					} else {
						data.data.forEach((sender) => {
							sendersPublicDomains.push(
								checkIfSenderFromPublicDomain(sender.email)
							);
						});
						getDomainsListFromSenders(data.data).forEach((domain) => {
							if (foundAtLeastOneAuthenticatedSender == false)
								authenticateADomain(domain);
						});
					}
					if (sendersPublicDomains.filter((r) => r == false).length > 0) {
						updateAppCueData(userDataJson.id, {
							hasGoal: GOAL_NAME.CAMPAIGN,
							isPublicDomain: false,
							newSenderIsCreated: true,
						});
					} else {
						updateAppCueData(userDataJson.id, {
							hasGoal: GOAL_NAME.CAMPAIGN,
							isPublicDomain: true,
							newSenderIsCreated: false,
						});
					}
				}
			);
		}
	});
};
const loadAppcues = (userDataJson, accountData, self, callback = () => {}) => {
	if (window.Appcues != undefined && window.Appcues != null) {
		loadingAppcuesCallback(userDataJson, accountData);
		callback();
	} else {
		// load the script for Appcues
		newScript("//fast.appcues.com/102943.js", self)
			.then(
				function () {
					loadingAppcuesCallback(userDataJson, accountData);
				}.bind(self)
			)
			.catch(function (error) {
				console.log("identifyUser: error on loading script");
			});
	}
};
export { updateAppCueData, loadAppcues, newScript };
