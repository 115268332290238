import { reducer as AccountSendersReducer } from "./scenes/senders/reducer";
import { reducer as UsersReducer } from "./scenes/users/reducer";
import _ from "lodash";

let initialState = () => {
	return {};
};

export const reducer = (state = initialState(), action) => {
	switch (action.type) {
	default:
		const rest = _.omit(state, Object.keys(initialState()));
		return {
			...state,
			usersReducer: UsersReducer(rest.usersReducer, action),
			sendersSort: AccountSendersReducer(rest.sendersSort, action),
			singleSender: AccountSendersReducer(rest.singleSender, action),
		};
		break;
	}
};
