import { PUBLIC_DOMAINS } from "../domains/consts";

export const getDomainsListFromSenders = (senders) => {
	const domainsList = [];
	senders.forEach((sender) => {
		const domain = getDomainFromEmail(sender.email);
		if (domainsList.filter((d) => d === domain).length == 0) {
			domainsList.push(domain);
		}
	});
	return domainsList;
};
export const getDomainFromEmail = (email) => {
	const atIndex = email.indexOf("@");
	const domain = email.substring(atIndex + 1);
	return domain;
};
export const isDomainPrivate = (domain) => {
	return PUBLIC_DOMAINS.filter((d) => d === domain).length === 0;
};
