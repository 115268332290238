/**
 * This file is responsible for handling the service calls for the contacts
 */
import store from "../store";
import {
	callAPI,
	chameau,
	DEFAULT_LIST_PARAMS,
	GATEWAY_PROXY,
	METHODS,
} from "utils/apiUtils";
import {
	SEGMENT_EVENTS,
	trackSegmentEvent,
} from "assets/js/const/SegmentEvents";
import { updateAppCueData } from "assets/js/scenes/OnBoardingFlow/utils";
import { deleteCacheKey, LOCAL_STORAGE_DATA } from "utils/localStorageUtil";

export function getContactsOfSegmentService(
	options = { segmentId: "", listId: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	const { segmentId, listId, ...restOptions } = options;
	callAPI(
		{
			url: GATEWAY_PROXY + `/lists/${listId}/segments/${segmentId}/contacts`,
			method: METHODS.get,
			query: {
				...chameau(restOptions),
				account_id: store.getState().global.account.id,
			},
		},
		successCallback,
		failureCallback
	);
}

export function getContactsOfListService(
	options = { listId: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	const { listId, ...restOptions } = options;
	callAPI(
		{
			url: GATEWAY_PROXY + `/lists/${listId}/contacts`,
			method: METHODS.get,
			query: {
				...chameau(restOptions),
				account_id: store.getState().global.account.id,
			},
		},
		successCallback,
		failureCallback
	);
}

export function getContactOfListService(
	options = { listId: "", contactId: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url:
				GATEWAY_PROXY +
				`/lists/${options.listId}/contacts/${options.contactId}`,
			method: METHODS.get,
			query: { account_id: store.getState().global.account.id },
		},
		successCallback,
		failureCallback
	);
}

export function deleteContactService(
	options = { listId: "", contactId: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url:
				GATEWAY_PROXY +
				`/lists/${options.listId}/contacts/${options.contactId}`,
			method: METHODS.delete,
			query: { account_id: store.getState().global.account.id },
		},
		(data) => {
			deleteCacheKey(LOCAL_STORAGE_DATA.LISTS_CONTACTS_COUNT, options.listId);
			trackSegmentEvent(SEGMENT_EVENTS.CONTACT_DELETED, data);
			successCallback(data);
		},
		failureCallback
	);
}

export function unsubscribeContactService(
	options = { listId: "", contactId: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url:
				GATEWAY_PROXY +
				`/lists/${options.listId}/contacts/${options.contactId}/unsubscribe`,
			method: METHODS.post,
			query: { account_id: store.getState().global.account.id },
		},
		(data) => {
			deleteCacheKey(LOCAL_STORAGE_DATA.LISTS_CONTACTS_COUNT, options.listId);
			trackSegmentEvent(SEGMENT_EVENTS.CONTACT_UNSUBSCRIBED, data);
			successCallback(data);
		},
		failureCallback
	);
}

export function addContactToSuppressionListService(
	options = { email: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + "/suppressed-emails",
			method: METHODS.post,
			body: { email: options.email },
			query: { account_id: store.getState().global.account.id },
		},
		(data) => {
			trackSegmentEvent(SEGMENT_EVENTS.CONTACT_SUPPRESSED, data);
			successCallback(data);
		},
		failureCallback
	);
}

export function removeContactFromSuppressionListService(
	options = { email: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	const { email, ...restOptions } = options;
	callAPI(
		{
			url: GATEWAY_PROXY + `/suppressed-emails/${email}`,
			method: METHODS.delete,
			query: {
				...chameau(restOptions),
				account_id: store.getState().global.account.id,
			},
		},
		(data) => {
			successCallback(data);
		},
		failureCallback
	);
}

export function getSuppressionListService(
	options = DEFAULT_LIST_PARAMS,
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + "/suppressed-emails",
			method: METHODS.get,
			query: {
				...chameau(options),
				account_id: store.getState().global.account.id,
			},
		},
		(data) => {
			trackSegmentEvent(SEGMENT_EVENTS.CONTACT_SUPPRESSED, data);
			successCallback(data);
		},
		failureCallback
	);
}

export function createContactService(
	options = { listId: "", contact: {} },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + `/lists/${options.listId}/contacts`,
			method: METHODS.post,
			body: options.contact,
			query: { account_id: store.getState().global.account.id },
		},
		(data) => {
			deleteCacheKey(LOCAL_STORAGE_DATA.LISTS_CONTACTS_COUNT, options.listId);
			updateAppCueData(store.getState().global.user.id, {
				newContactIsAdded: true,
			});
			trackSegmentEvent(SEGMENT_EVENTS.CONTACT_CREATED, data.data);
			successCallback(data);
		},
		failureCallback
	);
}

export function patchContactService(
	options = { listId: "", contactId: "", contact: {} },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url:
				GATEWAY_PROXY +
				`/lists/${options.listId}/contacts/${options.contactId}`,
			method: METHODS.patch,
			body: options.contact,
			query: { account_id: store.getState().global.account.id },
		},
		(data) => {
			trackSegmentEvent(SEGMENT_EVENTS.CONTACT_UPDATED, data.data);
			successCallback(data);
		},
		failureCallback
	);
}

export function importContactService(
	options = { listId: "", contacts: [] },
	successCallback = () => {},
	failureCallback = () => {}
) {
	const { listId, contacts, ...restOptions } = options;
	callAPI(
		{
			url: GATEWAY_PROXY + `/lists/${listId}/import-contacts`,
			method: METHODS.post,
			body: { contacts: contacts },
			query: { ...restOptions, account_id: store.getState().global.account.id },
		},
		successCallback,
		failureCallback
	);
}

export function getContactService(
	options,
	successCallback = () => {},
	failureCallback = () => {}
) {
	const { listId, contactId, ...restOptions } = options;
	callAPI(
		{
			url: GATEWAY_PROXY + `/lists/${listId}/contacts/${contactId}`,
			method: METHODS.get,
			query: { ...restOptions, account_id: store.getState().global.account.id },
		},
		successCallback,
		failureCallback
	);
}
