import { SET_USAGE_DATA } from "./actions";

let initialState = () => {
	return {
		contacts: null,
		emails: null,
		contactUsage: null,
		emailUsage: null,
		currentLists: null,
		currentUsers: null,
	};
};

export const reducer = (state = initialState(), action) => {
	switch (action.type) {
	case SET_USAGE_DATA:
		return {
			...state,
			contactUsage: action.contactUsage,
			contacts: action.contacts,
			emailUsage: action.emailUsage,
			emails: action.emails,
			currentLists: action.currentLists,
			currentUsers: action.currentUsers,
		};
	default:
		return state;
	}
};
