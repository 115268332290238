export const DELIVERIES = "SET_DELIVERIES";
export const OUTBOX_DELIVERIES = "SET_OUTBOX_DELIVERIES";
export const SENT_DELIVERIES = "SET_SENT_DELIVERIES";
export const LISTED_DELIVERIES_PER_PAGE = "SET_LISTED_DELIVERIES_PER_PAGE";
export const LISTED_DELIVERIES_PAGINATION = "SET_LISTED_DELIVERIES_PAGINATION";

export const setDeliveries = (deliveries) => {
	return {
		type: DELIVERIES,
		deliveries,
	};
};

export const setOutboxDeliveries = (outboxDeliveries) => {
	return {
		type: OUTBOX_DELIVERIES,
		outboxDeliveries,
	};
};

export const setSentDeliveries = (sentDeliveries) => {
	return {
		type: SENT_DELIVERIES,
		sentDeliveries,
	};
};

export const setListedDeliveriesPerPage = (perPage) => {
	return {
		type: LISTED_DELIVERIES_PER_PAGE,
		perPage,
	};
};

export const setListedDeliveriesPagination = (page) => {
	return {
		type: LISTED_DELIVERIES_PAGINATION,
		page,
	};
};
