import { callAPI, chameau, GATEWAY_PROXY, METHODS } from "utils/apiUtils";
import store from "../store";

export const getBrandService = (
	successCallback = () => {},
	failureCallback = () => {}
) => {
	callAPI(
		{
			url: GATEWAY_PROXY + "/brands/brand.json",
			method: METHODS.get,
		},
		successCallback,
		failureCallback
	);
};

export const updateBrandService = (
	options = { brand: "" },
	successCallback = () => {},
	failureCallback = () => {}
) => {
	callAPI(
		{
			url: GATEWAY_PROXY + "/brands/brand.json",
			method: METHODS.put,
			body: options,
		},
		successCallback,
		failureCallback
	);
};

export const uploadBrandLogoService = (
	options = { file: "" },
	successCallback = () => {},
	failureCallback = () => {}
) => {
	callAPI(
		{
			url: GATEWAY_PROXY + "/brands/default/logos/default",
			method: METHODS.put,
			body: { file: options.file },
			query: { account_id: store.getState().global.account.id },
		},
		successCallback,
		failureCallback
	);
};
