export const SET_USAGE_DATA = "SET_USAGE_DATA";

export const setUsageData = (usage) => {
	return {
		type: SET_USAGE_DATA,
		contacts: usage.contacts,
		contactUsage: usage.contactUsage,
		emails: usage.emails,
		emailUsage: usage.emailUsage,
		currentLists: usage.currentLists,
		currentUsers: usage.currentUsers,
	};
};
