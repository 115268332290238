import store from 'assets/js/store'
import { updateAccountMetaDataService } from 'assets/js/services/hiddenService'
import { ACCOUNT_META } from 'assets/js/const/MetaData'
import { setAccountMetadata } from 'assets/js/actions'

export function updateCampaignBlueprintMeta (campaignId = null,  blueprintId = null) {
	const metaName = ACCOUNT_META.CAMPAIGN_WITH_BLUEPRINT;
	const currentMeta = store.getState().global.accountMetaData[metaName] || {};

	if (!campaignId || (!currentMeta[campaignId] && blueprintId == null)){
		return;
	}
debugger
	if (blueprintId == null){
		delete currentMeta[campaignId];
	} else {
		currentMeta[campaignId] = blueprintId;
	}

	updateAccountMetaDataService({
		name : metaName,
		content : {data : currentMeta}
	},()=>{
		store.dispatch(setAccountMetadata(metaName,currentMeta));
	});
}

export function goThroughCampaignAndDeleteBlueprintMeta (campaigns = []) {
	if (campaigns?.length > 0) {
		campaigns.forEach((campaign)=>{
			if (campaign.status === "delivered"){
				updateCampaignBlueprintMeta(campaign.id);
			}
		});
	}
}