import { useEffect, useRef } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
	SEGMENT_EVENTS,
	segmentCondition,
	segmentPayloads,
} from "assets/js/const/SegmentEvents";

const GenericWrapper = (props) => {
	const previous = useRef(window.location.href);
	useEffect(() => {
		let listen = () => {};
		if (!props.segmentDisabledEvent?.includes(SEGMENT_EVENTS.PAGE)) {
			listen = props.history.listen((location) => {
				if (segmentCondition(SEGMENT_EVENTS.PAGE)) {
					// eslint-disable-next-line no-undef
					analytics.page(
						segmentPayloads(SEGMENT_EVENTS.PAGE, {
							path: location.pathname,
							url: window.location.href,
							search: window.location.search + location.search,
							referrer: previous.current,
						})
					);
				}

				previous.current = window.location.href;
			});
		}

		return () => {
			listen();
		};
	}, []);
	const previousLocation = useRef();
	useEffect(() => {
		const {
			location: { hash },
		} = props;

		if (hash !== previousLocation.current) {
			if (window?.Appcues) window.Appcues.page();
			previousLocation.current = hash;
		}
	});

	return props.children;
};

export default withRouter(
	connect(
		(state) => {
			return {
				segmentDisabledEvent:
					state.brand?.client_config?.segment_config?.disabled_events,
			};
		},
		(dispatch) => bindActionCreators({}, dispatch)
	)(GenericWrapper)
);
