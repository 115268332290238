import store from "assets/js/store";

const SEGMENT_EVENTS = {
	PAGE: "Page",
	IDENTIFY: "Identify",
	GROUP: "Group",

	SIGN_IN: "User.Loggedin",
	IMPERSONATE: "User.Impersonated",
	SIGN_OUT: "User.Loggedout",

	USER_CREATED: "User.Created",
	USER_UPDATED: "User.Updated",
	USER_DELETED: "User.Deleted",
	USER_CONFIRMED: "User.Confirmed",
	USER_CONFIRMATION_SENT: "User.Confirmation.Sent",
	USER_PASSWORD_RESET: "User.Password.Reset",
	USER_PASSWORD_CONFIRM: "User.Password.Confirm",

	ACCOUNT_CREATED: "Account.Created",
	ACCOUNT_CONFIRMED: "Account.Confirmed",
	ACCOUNT_UPDATED: "Account.Updated",
	ACCOUNT_SETUP: "Account.Setup",
	ACCOUNT_SUSPENDED: "Account.Suspended",

	PLAN_UPGRADED: "Plan.Upgraded",
	PLAN_DOWNGRADED: "Plan.Downgraded",

	CAMPAIGN_CREATED: "Campaign.Created",
	CAMPAIGN_UPDATED: "Campaign.Updated",
	CAMPAIGN_SCHEDULED: "Campaign.Scheduled",
	CAMPAIGN_UNSCHEDULED: "Campaign.Unscheduled",
	CAMPAIGN_DELETED: "Campaign.Deleted",
	CAMPAIGN_DUPLICATED: "Campaign.Duplicated",
	CAMPAIGN_PAUSED: "Campaign.Paused",
	CAMPAIGN_CANCELED: "Campaign.Canceled",
	CAMPAIGN_RESUMED: "Campaign.Resumed",
	CAMPAIGN_SUSPENDED: "Campaign.Suspended",
	TEST_EMAIL_SENT: "Campaign.Test.Sent",

	CONTACT_CREATED: "Contact.Created",
	CONTACT_UPDATED: "Contact.Updated",
	CONTACT_DELETED: "Contact.Deleted",
	CONTACT_UNSUBSCRIBED: "Contact.Unsubscribed",
	CONTACT_SUPPRESSED: "Contact.Suppressed",
	CONTACT_IMPORTED: "Contact.Imported",
	CONTACT_EXPORT: "Contact.Export",
	CONTACT_EXPORT_DOWNLOAD: "Contact.Export.Download",

	LIST_CREATED: "List.Created",
	LIST_UPDATED: "List.Updated",
	LIST_DELETED: "List.Deleted",

	SENDER_CREATED: "Sender.Created",
	SENDER_UPDATED: "Sender.Updated",
	SENDER_DELETED: "Sender.Deleted",
	SENDER_CONFIRMED: "Sender.Confirmed",
	SENDER_CONFIRMATION_SENT: "Sender.Confirmation.Sent",

	TEMPLATE_CREATED: "Template.Created",
	TEMPLATE_UPDATED: "Template.Updated",
	TEMPLATE_DELETED: "Template.Deleted",
	TEMPLATE_DUPLICATED: "Template.Duplicated",

	FORM_CREATED: "Form.Created",
	FORM_UPDATED: "Form.Updated",
	FORM_DELETED: "Form.Deleted",

	AUTOMATION_CREATED: "Automation.Created",
	AUTOMATION_UPDATED: "Automation.Updated",
	AUTOMATION_DELETED: "Automation.Deleted",
	AUTOMATION_ACTIVATED: "Automation.Activated",
	AUTOMATION_DEACTIVATED: "Automation.Deactivated",

	AUTOMATION_ACTION_CREATED: "Automation.Action.Created",
	AUTOMATION_ACTION_UPDATED: "Automation.Action.Updated",
	AUTOMATION_ACTION_DELETED: "Automation.Action.Deleted",

	SEGMENT_CREATED: "Segment.Created",
	SEGMENT_UPDATED: "Segment.Updated",
	SEGMENT_DELETED: "Segment.Deleted",

	ACTION_BLOCKED: "Action.Blocked",

	BRAND_UPDATED: "Brand.Updated",

	CAMPAIGN_LOG_EXPORT: "CampaignLog.Export",
	CAMPAIGN_LOG_DOWNLOAD: "CampaignLog.Export.Download",
};

const segmentCondition = (type) => {
	const segmentDisabledEvent =
		store.getState().brand?.client_config?.segment_config?.disabled_events;
	// eslint-disable-next-line no-undef
	if (
		typeof analytics !== "undefined" &&
		!segmentDisabledEvent.includes(type)
	) {
		return true;
	}
	return false;
};

const trackSegmentEvent = (type, data) => {
	if (segmentCondition(type)) {
		// eslint-disable-next-line no-undef
		analytics.track(type, segmentPayloads(type, data));
	}
};

const segmentPayloads = (event, data) => {
	let payload = {};

	switch (event) {
	case SEGMENT_EVENTS.PAGE:
		payload = {
			path: data?.path,
			url: data?.url,
			search: data?.search,
			referrer: data?.referrer,
		};
		break;
	case SEGMENT_EVENTS.IMPERSONATE:
	case SEGMENT_EVENTS.SIGN_IN:
	case SEGMENT_EVENTS.SIGN_OUT:
		payload = user(data);
		break;
	case SEGMENT_EVENTS.IDENTIFY:
	case SEGMENT_EVENTS.USER_CREATED:
	case SEGMENT_EVENTS.USER_UPDATED:
	case SEGMENT_EVENTS.USER_DELETED:
	case SEGMENT_EVENTS.USER_CONFIRMED:
	case SEGMENT_EVENTS.USER_CONFIRMATION_SENT:
	case SEGMENT_EVENTS.USER_PASSWORD_RESET:
		if (data?.limitAndUsage) {
			payload = {
				...user(data),
				accountId: data?.accountId,
				lineage: data?.lineage,
				plan: {
					type: data?.planType,
					limits: data?.limitAndUsage?.limits,
					usage: data?.limitAndUsage?.usage,
				},
				stats: data?.stats,
			};
		} else {
			payload = user(data);
		}
		break;
	case SEGMENT_EVENTS.ACCOUNT_SETUP:
		payload = account(data);
		break;
	case SEGMENT_EVENTS.GROUP:
		payload = { ...account(data), group_type: "Organization" };
		break;
	case SEGMENT_EVENTS.ACCOUNT_CREATED:
		payload = {
			tempId: data?.tempId,
			email: data?.email,
			language: data?.language,
			//Template onboarding only
			accountId: data?.accountId,
		};
		break;
	case SEGMENT_EVENTS.ACCOUNT_CONFIRMED:
		payload = {
			userId: data?.account_owner?.user_id,
			accountId: data?.id,
			lineage: data?.lineage,
			//Template onboarding only
			campaignId: data?.campaign_id,
		};
		break;
	case SEGMENT_EVENTS.CAMPAIGN_CREATED:
	case SEGMENT_EVENTS.CAMPAIGN_UPDATED:
	case SEGMENT_EVENTS.CAMPAIGN_SCHEDULED:
	case SEGMENT_EVENTS.CAMPAIGN_UNSCHEDULED:
	case SEGMENT_EVENTS.CAMPAIGN_SUSPENDED:
	case SEGMENT_EVENTS.CAMPAIGN_RESUMED:
	case SEGMENT_EVENTS.CAMPAIGN_CANCELED:
	case SEGMENT_EVENTS.CAMPAIGN_DELETED:
	case SEGMENT_EVENTS.CAMPAIGN_PAUSED:
		payload = campaign(data);
		break;
	case SEGMENT_EVENTS.CAMPAIGN_DUPLICATED:
		payload = {
			duplicatedFromId: data?.duplicatedFromId,
		};
		break;
	case SEGMENT_EVENTS.TEST_EMAIL_SENT:
		payload = {
			...campaign(data),
			emails: data?.emails,
		};
		break;
	case SEGMENT_EVENTS.CONTACT_DELETED:
	case SEGMENT_EVENTS.CONTACT_CREATED:
	case SEGMENT_EVENTS.CONTACT_UPDATED:
	case SEGMENT_EVENTS.CONTACT_SUPPRESSED:
	case SEGMENT_EVENTS.CONTACT_UNSUBSCRIBED:
		payload = contact(data);
		break;
	case SEGMENT_EVENTS.CONTACT_IMPORTED:
		payload = {
			listId: data?.listId,
			contactsTotal: data?.contactsTotal,
			contactImported: data?.contactImported,
			errorCount: data?.errorCount,
		};
		break;

	case SEGMENT_EVENTS.LIST_CREATED:
	case SEGMENT_EVENTS.LIST_DELETED:
	case SEGMENT_EVENTS.LIST_UPDATED:
		payload = list(data);
		break;

	case SEGMENT_EVENTS.SENDER_CREATED:
	case SEGMENT_EVENTS.SENDER_UPDATED:
	case SEGMENT_EVENTS.SENDER_DELETED:
	case SEGMENT_EVENTS.SENDER_CONFIRMED:
	case SEGMENT_EVENTS.SENDER_CONFIRMATION_SENT:
		payload = sender(data);
		break;

	case SEGMENT_EVENTS.ACCOUNT_UPDATED:
		payload = {};
		break;

	case SEGMENT_EVENTS.PLAN_UPGRADED:
	case SEGMENT_EVENTS.PLAN_DOWNGRADED:
		payload = {
			from: data?.from,
			to: data?.to,
		};
		break;

	case SEGMENT_EVENTS.TEMPLATE_CREATED:
	case SEGMENT_EVENTS.TEMPLATE_UPDATED:
	case SEGMENT_EVENTS.TEMPLATE_DELETED:
		payload = template(data);
		break;
	case SEGMENT_EVENTS.TEMPLATE_DUPLICATED:
		payload = {
			duplicatedFromId: data?.duplicatedFromId,
		};
		break;

	case SEGMENT_EVENTS.FORM_CREATED:
	case SEGMENT_EVENTS.FORM_DELETED:
	case SEGMENT_EVENTS.FORM_UPDATED:
		payload = form(data);
		break;

	case SEGMENT_EVENTS.AUTOMATION_CREATED:
	case SEGMENT_EVENTS.AUTOMATION_UPDATED:
	case SEGMENT_EVENTS.AUTOMATION_DELETED:
	case SEGMENT_EVENTS.AUTOMATION_ACTIVATED:
	case SEGMENT_EVENTS.AUTOMATION_DEACTIVATED:
		payload = workflow(data);
		break;

	case SEGMENT_EVENTS.AUTOMATION_ACTION_CREATED:
	case SEGMENT_EVENTS.AUTOMATION_ACTION_UPDATED:
	case SEGMENT_EVENTS.AUTOMATION_ACTION_DELETED:
		payload = action(data);
		break;

	case SEGMENT_EVENTS.SEGMENT_CREATED:
	case SEGMENT_EVENTS.SEGMENT_UPDATED:
	case SEGMENT_EVENTS.SEGMENT_DELETED:
		payload = segment(data);
		break;

	case SEGMENT_EVENTS.CONTACT_EXPORT:
	case SEGMENT_EVENTS.CONTACT_EXPORT_DOWNLOAD:
		payload = contactExport(data);
		break;
	default:
		payload = data;
		break;
	}

	payload.url = window.location.href;

	return payload;
};

/*
============
Some actions in routes wont return all the data, but just the id
Segment will clean the data and remove any UNDEFINED properties
============
 */

const segment = (data) => {
	return {
		id: data?.id,
		name: data?.name,
		campaigns_count: data?.campaigns_count,
		last_used: data?.last_used,
		created_on: data?.created_on,
	};
};

const action = (data) => {
	return {
		id: data?.id,
		name: data?.name,
		parent_id: data?.parent_id,
		condition: data?.condition,
		delay: data?.delay,
		type: data?.type,
		created_on: data?.created_on,
		updated_on: data?.updated_on,
	};
};

const workflow = (data) => {
	return {
		id: data?.id,
		name: data?.name,
		goal: data?.goal,
		description: data?.description,
		status: data?.status,
		trigger: data?.trigger,
		audience: data?.audience,
		created_on: data?.created_on,
		updated_on: data?.updated_on,
		activated_on: data?.activated_on,
	};
};

const form = (data) => {
	return {
		id: data?.id,
		name: data?.name,
		status: data?.status,
		language: data?.language,
		created_on: data?.created_on,
		last_updated_on: data?.last_updated_on,
		redirections: data?.redirections,
		list_id: data?.list_id,
		double_opt_in: data?.double_opt_in,
	};
};

const template = (data) => {
	return {
		id: data?.id,
		name: data?.name,
		created_on: data?.created_on,
		updated_on: data?.updated_on,
		tags: data?.tags,
	};
};

const account = (data) => {
	return {
		address: {
			city: data?.address.city,
			country: data?.address?.country,
			postalCode: data?.address?.postal_code,
			state: data?.address?.province,
			street: data?.address?.address1,
		},
		createdAt: data?.created_on,
		name: data?.name,
		phone: data?.phone,
		website: data?.website,
		lineage: data?.lineage,
	};
};

const user = (data) => {
	return {
		createdAt: data?.created_on,
		firstname: data?.first_name,
		lastname: data?.last_name,
		email: data?.email,
		id: data?.id,
		title: data?.title,
		phone: data?.mobile_phone,
		language: data?.language,
	};
};

const sender = (data) => {
	return {
		id: data?.id,
		name: data?.name,
		email: data?.email,
		confirmed: data?.confirmed,
		confirmedOn: data?.confirmed_on,
		language: data?.language,
		lastConfirmationSentOn: data?.last_confirmation_sent_on,
	};
};

const list = (data) => {
	return {
		id: data?.id,
		name: data?.name,
		language: data?.language,
		created_on: data?.created_on,
		default_sender: data?.default_sender,
		redirections: data?.redirections,
	};
};

const contact = (data) => {
	return {
		id: data?.id,
		email: data?.email,
		subscribedOn: data?.subscribed_on,
		lastBounceType: data?.last_bounce_type,
		bouncesCount: data?.bounces_count,
	};
};

const campaign = (data) => {
	return {
		campaignId: data?.id,
		audience: data?.audience,
		name: data?.name,
		subject: data?.content?.subject,
		scheduledFor: data?.scheduled_for,
		createdOn: data?.created_on,
		sender: data?.sender,
		contentType: data?.content?.type,
		templateId: data?.content?.bluePrint?.id,
		//Custom properties to pass
		contactsCount: data?.contactsCount,
	};
};

const contactExport = (data) => {
	return {
		listId: data?.listId,
		segmentId: data?.segment_id,
		description: data?.description,
	};
};

const onboardingPrefix = "Onboarding_";

export {
	SEGMENT_EVENTS,
	segmentPayloads,
	trackSegmentEvent,
	segmentCondition,
	onboardingPrefix,
};
