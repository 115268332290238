export const SUBSCRIPTIONS = "SET_SUBSCRIPTIONS";
export const SUBSCRIPTIONS_COUNT = "SET_SUBSCRIPTIONS_COUNT";
export const LISTED_SUBSCRIPTIONS_PER_PAGE =
	"SET_LISTED_SUBSCRIPTIONS_PER_PAGE";
export const LISTED_SUBSCRIPTIONS_PAGINATION =
	"SET_LISTED_SUBSCRIPTIONS_PAGINATION";

export const setSubscriptions = (subscriptions) => {
	return {
		type: SUBSCRIPTIONS,
		subscriptions,
	};
};

export const setSubscriptionsCount = (subscriptionsCount) => {
	return {
		type: SUBSCRIPTIONS_COUNT,
		subscriptionsCount,
	};
};

export const setListedSubscriptionsPerPage = (perPage) => {
	return {
		type: LISTED_SUBSCRIPTIONS_PER_PAGE,
		perPage,
	};
};

export const setListedSubscriptionsPagination = (page) => {
	return {
		type: LISTED_SUBSCRIPTIONS_PAGINATION,
		page,
	};
};
