/**
 * This file is to map 50/50 split condition , from and to BE structure, it should contain at least two functions, one that maps
 * from BE to FE structure, and the other one that maps from FE to BE structure
 */
import {
	getEdge,
	getEndCoachmarkNode,
	getNextTarget,
	getShadowsForStep,
} from "assets/js/scenes/automations/containers/editor/utils/utils";
import { ConditionEvents } from "assets/js/scenes/automations/containers/editor/EventsConfigs";
import { getNextOfBranch } from "../IfElse/utils/generalUtils";

const parseBESplit50ToFE = (automation, mainStep, subStep, targetId) => {
	const events = [];
	const percentageType = subStep.args.switch[0].condition.eq
		? "lessThan50"
		: subStep.args.switch[0].condition.or
			? "40"
			: subStep.args.switch[0].condition.and
				? "60"
				: "greaterThan50";
	let primraryPercentage = 0;
	switch (percentageType) {
	case "lessThan50":
		primraryPercentage = parseInt(
			100 /
					parseInt(
						getPercentageFromVariableEvaluator(
							subStep.args.switch[0].condition.eq.var
						)
					)
		);
		if (primraryPercentage == 33) primraryPercentage = 30;
		break;
	case "40":
		primraryPercentage = 40;
		break;
	case "60":
		primraryPercentage = 60;
		break;
	case "greaterThan50":
		primraryPercentage =
				100 -
				parseInt(
					100 /
						parseInt(
							getPercentageFromVariableEvaluator(
								subStep.args.switch[0].condition.not.eq.var
							)
						)
				);
		if (primraryPercentage == 67) primraryPercentage = 70;

		break;

	default:
		break;
	}
	//const primraryPercentage = parseInt(getPercentageFromVariableEvaluator(subStep.args.switch[0].condition.eq.var))*10;

	events.push({
		id: mainStep.name,
		position: {
			x: 0,
			y: 0,
		},
		data: {
			name: ConditionEvents()[2].name,
			desc: ConditionEvents()[2].desc,

			type: "condition",
			tileTitle: ConditionEvents()[2].tileTitle,
			id: mainStep.name,
			baseId: "split50",
			initialElement: false,
			// delayName: subStep.name,
			shadows: getShadowsForStep(automation, mainStep.name),
			percentage: {
				sec: 100 - primraryPercentage,
				primary: primraryPercentage,
			},
		},
		type: "editorNode",
	});
	const links = [];
	if (
		subStep.args.switch[0].next == "end" ||
		getNextOfBranch(subStep, automation) == "end"
	) {
		const endCoachmark = getEndCoachmarkNode(0, 0);
		events.push(endCoachmark);
		links.push(
			getEdge("branchingEdge", mainStep.name, endCoachmark.id, {
				branchLabel: "A",
			})
		);
	} else
		links.push(
			getEdge(
				"branchingEdge",
				mainStep.name,
				getNextOfBranch(subStep, automation),
				{
					branchLabel: "A",
				}
			)
		);
	if (targetId && targetId != "end") {
		links.push(
			getEdge("branchingEdge", mainStep.name, targetId, { branchLabel: "B" })
		);
	} else {
		const endCoachmark = getEndCoachmarkNode(0, 0);
		events.push(endCoachmark);
		links.push(
			getEdge("branchingEdge", mainStep.name, endCoachmark.id, {
				branchLabel: "B",
			})
		);
	}
	return {
		events: events,
		links: links,
	};
};
const parseFESplit50ToBE = (FEEvent, connectedEdges, events) => {
	let shadows = [];
	if (FEEvent.data.shadows) shadows = [...FEEvent.data.shadows];
	const inTos = events.filter(e=>e.data.baseId=="loop").filter(e=>e.data.goTo==FEEvent.id);
	if(inTos.length>0) shadows=[...shadows,...inTos.map(e=>`${e.id}_loop`)];
	let conditon = {};
	let percentageType =
		parseInt(FEEvent.data.percentage.primary) > 50
			? parseInt(FEEvent.data.percentage.primary) == 60
				? "60"
				: "greaterThan50"
			: parseInt(FEEvent.data.percentage.primary) == 40
				? "40"
				: "lessThan50";
	switch (percentageType) {
	case "greaterThan50":
		conditon = {
			not: {
				eq: {
					var: createPercentageVariableEvaluator(FEEvent),
					value: 0,
				},
			},
		};
		break;
	case "lessThan50":
		conditon = {
			eq: {
				var: createPercentageVariableEvaluator(FEEvent),
				value: 0,
			},
		};
		break;
	case "40":
		conditon = {
			or: [
				{
					eq: {
						var: createPercentageVariableEvaluator({
							data: { percentage: { primary: 30 } },
						}),
						value: 0,
					},
				},
				{
					eq: {
						var: createPercentageVariableEvaluator({
							data: { percentage: { primary: 10 } },
						}),
						value: 0,
					},
				},
			],
		};
		break;
	case "60":
		conditon = {
			and: [
				{
					not: {
						eq: {
							var: createPercentageVariableEvaluator({
								data: { percentage: { primary: 30 } },
							}),
							value: 0,
						},
					},
				},
				{
					not: {
						eq: {
							var: createPercentageVariableEvaluator({
								data: { percentage: { primary: 10 } },
							}),
							value: 0,
						},
					},
				},
			],
		};
		break;
	default:
		break;
	}

	return {
		main: [
			{
				steps: [
					{
						name: `${FEEvent.id}`,
						action: "SubAutomation.Call",
						args: {
							sub_automation: FEEvent.id,
						},
						next: "end",
						shadows: shadows,
					},
				],
			},
		],
		sub: [
			{
				name: FEEvent.id,
				params: [],
				type: "Split50",
				steps: [
					{
						action: "Condition.Switch",
						name: FEEvent.id,
						args: {
							switch: [
								{
									condition: conditon,
									next: getNextTarget(
										connectedEdges.filter(
											(edge) => edge.data.branchLabel == "A"
										),
										events
									),
								},
							],
						},
						next: getNextTarget(
							connectedEdges.filter(
								(edge) => edge.data.branchLabel == "B",
								events
							)
						),
					},
				],
			},
		],
	};
};
const createPercentageVariableEvaluator = (FEEvent) => {
	let lowerPercentage = parseInt(FEEvent.data.percentage.primary);
	if (parseInt(FEEvent.data.percentage.primary) > 50)
		lowerPercentage = parseInt(FEEvent.data.percentage.sec);
	return (
		"${int(input.data.contact.id) % " + `${parseInt(100 / lowerPercentage)} }`
	);
};
const getPercentageFromVariableEvaluator = (variableValue) => {
	return variableValue
		.replace("${int(input.data.contact.id) % ", "")
		.replace(" }", "");
};

export { parseBESplit50ToFE, parseFESplit50ToBE };
1;
