import { SET_HEADER_LAYOUT } from "./actions";

let initialState = () => {
	return {
		layoutHeader: null,
		layoutDescription: null,
		layoutTools: null,
	};
};

export const reducer = (state = initialState(), action) => {
	switch (action.type) {
	case SET_HEADER_LAYOUT:
		return {
			...state,
			layoutHeader: action.layoutHeader,
			layoutDescription: action.layoutDescription,
			layoutTools: action.layoutTools,
		};
	default:
		return state;
	}
};
