/**
 * This file is responsible for handling the service calls for the sender
 */
import {
	callAPI,
	chameau,
	DEFAULT_LIST_PARAMS,
	GATEWAY_PROXY,
	METHODS,
} from "utils/apiUtils";
import store from "../store";
import {
	SEGMENT_EVENTS,
	trackSegmentEvent,
} from "assets/js/const/SegmentEvents";
import { I18n } from "react-redux-i18n";
import { updateAppCueData } from "assets/js/scenes/OnBoardingFlow/utils";

export function listSendersService(
	options = DEFAULT_LIST_PARAMS,
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + "/brands/default/senders",
			method: METHODS.get,
			query: {
				...chameau(options),
				account_id: store.getState().global.account.id,
			},
		},
		(data) => {
			//If no sender exists when a bland fetch is made, create one
			if (!options?.filter && !options.page && data.data.length === 0) {
				const account = store.getState().global.account;
				const user = store.getState().global.user;
				const fn =
					user.first_name && user.first_name !== "-" ? user.first_name : null;
				const ln =
					user.last_name && user.last_name !== "-" ? user.last_name : null;

				createSenderService(
					{
						sender: {
							name: fn ? fn + ln : I18n.t("app-general-no-name"),
							email: user.email,
						},
					},
					(senderData) => {
						data.data.push(senderData.data);
						if (!isNaN(data.pagination.count)) {
							data.pagination.count++;
						}
						successCallback(data);
					},
					() => {
						successCallback(data);
					}
				);
			} else {
				successCallback(data);
			}
		},
		failureCallback
	);
}

export function resendSenderConfirmationService(
	options = { id: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url:
				GATEWAY_PROXY +
				`/brands/default/senders/${options.id}/resend-confirmation-email`,
			query: { account_id: store.getState().global.account.id },
		},
		(data) => {
			trackSegmentEvent(SEGMENT_EVENTS.SENDER_CONFIRMATION_SENT, data);
			successCallback(data);
		},
		failureCallback
	);
}

export function getSenderService(
	options = { id: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + `/brands/default/senders/${options.id}`,
			method: METHODS.get,
			query: { account_id: store.getState().global.account.id },
		},
		successCallback,
		failureCallback
	);
}

export function createSenderService(
	options = { sender: {} },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + "/brands/default/senders",
			body: options.sender,
			query: { account_id: store.getState().global.account.id },
		},
		(data) => {
			updateAppCueData(store.getState().global.user.id, {
				newSenderIsCreated: true,
			});
			if (successCallback) {
				trackSegmentEvent(SEGMENT_EVENTS.SENDER_CREATED, data.data);
				successCallback(data);
			}
		},
		failureCallback
	);
}

export function deleteSenderService(
	options = { id: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + `/brands/default/senders/${options.id}`,
			method: METHODS.delete,
			query: { account_id: store.getState().global.account.id },
		},
		(data) => {
			trackSegmentEvent(SEGMENT_EVENTS.SENDER_DELETED, data);
			successCallback(data);
		},
		failureCallback
	);
}

export function patchSenderService(
	options = { id: "", sender: {} },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + `/brands/default/senders/${options.id}`,
			method: METHODS.patch,
			body: options.sender,
			query: { account_id: store.getState().global.account.id },
		},
		(data) => {
			trackSegmentEvent(SEGMENT_EVENTS.SENDER_UPDATED, data.data);
			successCallback(data);
		},
		failureCallback
	);
}

export function confirmSenderService(
	options = { confirmation_id: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + "/brands/default/senders/confirm-email",
			body: options,
		},
		(data) => {
			trackSegmentEvent(SEGMENT_EVENTS.SENDER_CONFIRMED, data);
			successCallback(data);
		},
		failureCallback
	);
}
