export const SET_SINGLE_LIST = "SET_SINGLE_LIST";
export const SET_SINGLE_LIST_ATTRIBUTES = "SET_SINGLE_LIST_ATTRIBUTES";
export const RESET_SINGLE_LIST = "RESET_SINGLE_LIST";

export const setSingleList = (list) => {
	return {
		type: SET_SINGLE_LIST,
		list,
	};
};

export const setSingleListAttributes = (listAttributes) => {
	return {
		type: SET_SINGLE_LIST_ATTRIBUTES,
		listAttributes,
	};
};

export const resetSingleList = () => {
	return {
		type: RESET_SINGLE_LIST,
	};
};
