import {
	ACCOUNT as SET_ACCOUNT,
	USER as SET_USER,
	CRITICAL_ERROR as SET_CRITICAL_ERROR,
	LANGUAGE as SET_LANGUAGE,
	BRAND_PREVIEW,
	CUSTOMER,
	AUTHENTICATED,
	ACCOUNT_READY,
	ACCOUNT_META
} from "./actions";
import { reducer as TaskBarReducer } from "./data/taskbar/reducer";
import { reducer as usageReducer } from "./data/usage/reducer";

import _ from "lodash";

let initialState = () => {
	return {
		account: null,
		customer: null,
		user: null,
		previousPath: null,
		criticalError: null,
		language: null,
		previewingBrand: false,

		authenticated: null,
		accountReady: false,

		accountMetaData : {}
	};
};

export const reducer = (state = initialState(), action) => {
	switch (action.type) {
	case SET_ACCOUNT:
		return { ...state, account: action.account };
		break;
	case CUSTOMER:
		return { ...state, customer: action.customer };
		break;
	case SET_USER:
		return { ...state, user: action.user };
		break;
	case SET_CRITICAL_ERROR:
		return { ...state, criticalError: action.criticalError };
		break;
	case SET_LANGUAGE:
		return { ...state, language: action.language };
		break;
	case BRAND_PREVIEW:
		return { ...state, previewingBrand: action.preview };
		break;
	case AUTHENTICATED:
		return { ...state, authenticated: action.authenticated };
		break;
	case ACCOUNT_READY:
		return { ...state, accountReady: action.accountReady };
		break;
	case ACCOUNT_META:
		return { ...state, accountMetaData: {...state.accountMetaData, [action.name] : action.accountMetaData} };
		break;
	default:
		const rest = _.omit(state, Object.keys(initialState()));
		return {
			...state,
			taskBar: TaskBarReducer(rest.taskBar, action),
			usage: usageReducer(rest.usage, action),
		};
		break;
	}
};
