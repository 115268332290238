/**
 * This file is responsible for handling the service calls for the segments
 */
import store from "../store";
import {
	callAPI,
	chameau,
	DEFAULT_LIST_PARAMS,
	GATEWAY_PROXY,
	METHODS,
} from "utils/apiUtils";
import {
	SEGMENT_EVENTS,
	trackSegmentEvent,
} from "assets/js/const/SegmentEvents";
import { updateAppCueData } from "assets/js/scenes/OnBoardingFlow/utils";

export function listSegmentService(
	options = DEFAULT_LIST_PARAMS,
	successCallback = () => {},
	failureCallback = () => {}
) {
	const { id, ...restOptions } = options;
	if (!restOptions?.perPage) restOptions.perPage = 1000;
	if (!restOptions?.sort) restOptions.sort = "-created_on";

	restOptions.accountId = store.getState()?.global?.account?.id;

	callAPI(
		{
			url: GATEWAY_PROXY + `/lists/${id}/segments`,
			method: METHODS.get,
			query: chameau(restOptions),
		},
		successCallback,
		failureCallback
	);
}

export function getSegmentService(
	options = { listId: "", segmentId: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url:
				GATEWAY_PROXY +
				`/lists/${options.listId}/segments/${options.segmentId}`,
			method: METHODS.get,
			query: { account_id: store.getState().global.account.id },
		},
		successCallback,
		failureCallback
	);
}

export function updateSegmentService(
	options = { listId: "", segmentId: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url:
				GATEWAY_PROXY +
				`/lists/${options.listId}/segments/${options.segmentId}`,
			method: METHODS.patch,
			body: options.segment,
			query: { account_id: store.getState().global.account.id },
		},
		(data) => {
			trackSegmentEvent(SEGMENT_EVENTS.SEGMENT_UPDATED, data.data);
			successCallback(data);
		},
		failureCallback
	);
}

export function createSegmentService(
	options = { listId: "", segment: {} },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + `/lists/${options.listId}/segments`,
			body: options.segment,
			query: { account_id: store.getState().global.account.id },
		},
		(data) => {
			const user = store.getState().global.user;
			updateAppCueData(user.id, {
				newSegmentIsAdded: true,
			});
			trackSegmentEvent(SEGMENT_EVENTS.SEGMENT_CREATED, data.data);
			successCallback(data);
		},
		failureCallback
	);
}

export function deleteSegmentService(
	options = { listId: "", segmentId: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url:
				GATEWAY_PROXY +
				`/lists/${options.listId}/segments/${options.segmentId}`,
			method: METHODS.delete,
			query: { account_id: store.getState().global.account.id },
		},
		(data) => {
			trackSegmentEvent(SEGMENT_EVENTS.SEGMENT_DELETED, data);
			successCallback(data);
		},
		failureCallback
	);
}
