/**
 * This file is mainly to handle the mapping of data from events object to nodes object
 * Events: represents the actual automation events, holding data to from and to different backend endpoints
 * Nodes: represents the actual object that the diagram workflow uses, holding reference to event, and data
 *		  that represents how each node will be drawn , used by the reactflow lib
 */
import _ from "lodash";
import {
	parseBESendEmailToFE,
	parseFESendEmailToBE,
} from "assets/js/scenes/automations/scenes/AutomationEditor/containers/Actions/SendEmail/Mapper";
import {
	parseBEDelayToFE,
	parseFEDelayToBE,
} from "assets/js/scenes/automations/scenes/AutomationEditor/containers/Conditions/Delay/Mapper";
import {
	parseBEUpdateContactToFE,
	parseFEUpdateContactToBE,
} from "assets/js/scenes/automations/scenes/AutomationEditor/containers/Actions/UpdateContact/Mapper";
import {
	parseBEIfElseToFE,
	parseFEIfElseToBE,
} from "assets/js/scenes/automations/scenes/AutomationEditor/containers/Conditions/IfElse/Mapper";
import { addManualAddContactsTrigger } from "assets/js/scenes/automations/scenes/AutomationEditor/containers/Triggers/ManualTrigger/Mapper";
import { MAPPED } from "assets/js/scenes/automations/scenes/AutomationEditor/containers/Conditions/IfElse/SubAutomationsDefinitions";
import { EVENT_TYPES } from "assets/js/const/Automations";
import {
	convertTimeWithUnitToSeconds,
	convertSecondsToNearestUnit,
	getRepresentableStepFromSubAutomation,
	helperNode,
} from "assets/js/scenes/automations/containers/editor/utils/utils";
import {
	parseBETriggerToFE,
	parseFETriggerToBE,
} from "../../scenes/AutomationEditor/containers/Triggers/SubscribeTrigger/Mapper";
import {
	getEdge,
	getEventType,
	getEndCoachmarkNode,
	convertPlanToFlatArrayOfActions,
} from "assets/js/scenes/automations/containers/editor/utils/utils";
import { getConnectedEdges, isNode, isEdge } from "react-flow-renderer";
import { mapBEStepsToFEEvents as mapBEStepsToFEEventsV2 } from "../../automationsV2.0/Mappers/EditorMapper";
import {
	getValuesFromVar,
	getValudOfVariable,
} from "../../automationsV2.0/Mappers/ifElseUtils";
import store from "assets/js/store";
import {
	parseFESplit50ToBE,
	parseBESplit50ToFE,
} from "assets/js/scenes/automations/scenes/AutomationEditor/containers/Conditions/Split50/Mapper";
import {
	parseBEDeleteContactToFE,
	parseFEDeleteContactToBE,
} from "../../scenes/AutomationEditor/containers/Actions/DeleteContactFromList/Mapper";
import {
	parseBEAddContactToFE,
	parseFEAddContactToBE,
} from "../../scenes/AutomationEditor/containers/Actions/AddContactToList/Mapper";
import { parseBELoopingToFE, parseFELoopingToBE } from "../../scenes/AutomationEditor/containers/Actions/Looping/Mapper";

const mapBEStepToFEEvent = (automation, mainStep, subStep, target) => {
	switch (subStep.action) {
	case "CampaignEmail.Send":
		return parseBESendEmailToFE(automation, mainStep, subStep, target);
	case "Time.Sleep":
		return parseBEDelayToFE(automation, mainStep, subStep, target);
	case "Condition.Switch":
		return parseBEIfElseToFE(automation, mainStep, subStep, target);
	case "Condition.Split50":
		return parseBESplit50ToFE(automation, mainStep, subStep, target);
	case "Contact.SetCustomAttribute":
		return parseBEUpdateContactToFE(automation, mainStep, subStep, target);
	case "Event.Loop":
		return parseBELoopingToFE(automation, mainStep, subStep, target);
	case "Contact.Add":
		return parseBEAddContactToFE(automation, mainStep, subStep, target);
	case "Contact.Remove":
		return parseBEDeleteContactToFE(automation, mainStep, subStep, target);
	}
};
const mapFEEventToBEStep = (
	event,
	connectedEdges,
	events,
	nextIndex,
	selfIndex
) => {
	const eventType = getEventType(event.data.type, event.data.baseId);
	switch (eventType) {
	case EVENT_TYPES.TRIGGER_SUBSCRIBED:
		return parseFETriggerToBE(event, events);
	case EVENT_TYPES.ACTION_SENDEMAIL:
		return parseFESendEmailToBE(
			event,
			connectedEdges,
			events,
			nextIndex,
			selfIndex
		);
	case EVENT_TYPES.CONDITION_DELAY:
		return parseFEDelayToBE(event, connectedEdges, events);
	case EVENT_TYPES.CONDITION_IFELSE:
		return parseFEIfElseToBE(event, connectedEdges, events);
	case EVENT_TYPES.CONDITION_SPLIT50:
		return parseFESplit50ToBE(event, connectedEdges, events);
	case EVENT_TYPES.ACTION_UPDATECONTACT:
		return parseFEUpdateContactToBE(event, connectedEdges, events);
	case EVENT_TYPES.ACTION_LOOP:
		return parseFELoopingToBE(event, connectedEdges, events);
	case EVENT_TYPES.ACTION_ADDCONTACT:
		return parseFEAddContactToBE(event, connectedEdges, events);
	case EVENT_TYPES.ACTION_DELETECONTACT:
		return parseFEDeleteContactToBE(event, connectedEdges, events);
	}
};

const getSubAutomationForAMainStep = (automation, step) => {
	return automation.sub_automations.filter(
		(sub) => sub.name == step.args.sub_automation
	)[0];
};
const dummyFunctionToAddId = (automationMainSteps) => {
	automationMainSteps.forEach((mainStep, index) => {
		mainStep.id = mainStep.steps.filter(
			(s) => s.action == "SubAutomation.Call"
		)[0].args.sub_automation;
		mainStep.stepNumber = index;
	});
};
/**
 * The function returns the step that matches the condition in the emit step,so it is basiclly returning the next step,
 * @param {*} automationMainSteps
 * @param {*} emitStep
 */
const getMatchedConditionToEmitStep = (automationMainSteps, emitStep) => {
	// the condition could be one of the two, either a var= certainValue, or the emitStepId could be in an array that we compare to true
	return automationMainSteps.filter((s) => {
		if (s.condition.eq?.value == "${\"" + emitStep.args?.params?.step + "\"}") {
			return true;
		}
		let varValue;
		if (s.condition.or) {
			varValue = getValudOfVariable(
				s.condition.or[s.condition.or.length - 1].eq?.var
			).replace("input.data.step in ", "");
		}
		if (s.condition.eq?.var) {
			varValue = getValudOfVariable(s.condition.eq?.var).replace(
				"input.data.step in ",
				""
			);
		}

		//const varValue = getValudOfVariable(s.condition.eq?.var).replace("input.data.step in ", "");
		if (varValue[0] !== "[") return false;
		const varArray = JSON.parse(varValue);
		if (
			varArray.includes(emitStep.args?.params?.step) &&
			(s.condition.eq?.value == true || (s.condition?.or && s.condition?.or[s.condition.or.length - 1].eq?.value==true))
		) {
			return true;
		}
		return false;
	})[0];
};

const latestConversion = (automation) => {
	let events = [];
	let links = [];
	let nextStepToTrigger = null;
	let automationMainSteps = [];
	let nextStepCondition = null;
	// new automation
	if (
		automation.main.steps.length == 0 ||
		automation.main.steps[1]?.args?.switch?.length == 0
	) {
		nextStepToTrigger = helperNode();
		const coachmark = getEndCoachmarkNode(0, 0);
		const link = getEdge("custom", nextStepToTrigger.id, coachmark.id);
		events.push(coachmark);
		events.push(nextStepToTrigger);
		links.push(link);
		automationMainSteps = [];
	} else {
		automationMainSteps = convertPlanToFlatArrayOfActions(automation);
		dummyFunctionToAddId(automationMainSteps);

		nextStepToTrigger = {
			id: automationMainSteps[0].id,
		};
		nextStepCondition = automationMainSteps[0].condition;
	}
	const triggerObject = parseBETriggerToFE(
		automation.trigger.filter((trigger) => trigger.name == "Contact.Added")[0]
			.args.list_id,
		nextStepToTrigger,
		nextStepCondition
	);
	links = [...links, ...triggerObject.links];
	events = [...events, ...triggerObject.events];
	// by default we need to add a manual add contacts trigger
	const manualTriggerObject = addManualAddContactsTrigger(nextStepToTrigger);
	links = [...links, ...manualTriggerObject.links];
	events = [...events, ...manualTriggerObject.events];
	automationMainSteps.forEach((route) => {
		// get the actionable step
		const step = route.steps.filter((s) => s.action == "SubAutomation.Call")[0];
		const subAutomationSteps = getSubAutomationForAMainStep(
			automation,
			step
		).steps;
		const subAutomationType = getSubAutomationForAMainStep(
			automation,
			step
		).type;
		const emitStep = subAutomationSteps.filter(
			(s) =>
				s.action == "SubAutomation.Call" &&
				s.args.sub_automation == "emit_next_step"
		)[0];
		const actionableStep = getRepresentableStepFromSubAutomation(
			{
				steps: subAutomationSteps,
			},
			subAutomationType
		);
		const matchedConditionToEmitStep = emitStep
			? getMatchedConditionToEmitStep(automationMainSteps, emitStep)
			: null;
		const targetId = matchedConditionToEmitStep
			? matchedConditionToEmitStep.id
			: null;
		const eventObject = mapBEStepToFEEvent(
			automation,
			actionableStep,
			actionableStep,
			targetId
		);
		if (eventObject) {
			events = [...events, ...eventObject.events];
			links = [...links, ...eventObject.links];
		}
	});
	events = events.filter((element) => {
		return element !== undefined;
	});

	links = links.filter((element) => {
		return element !== undefined;
	});
	return [...events, ...links];
};
const mapBEStepsToFEEvents = (automation) => {
	// for backward compitability purporse, we need to do a acheck here to make sure if it is new version or not
	const editorVersion = automation.editor_version ? "latest" : "2.0";
	switch (editorVersion) {
	case "2.0":
		return mapBEStepsToFEEventsV2(automation);
		break;

	default:
		return latestConversion(automation);
		break;
	}
};
const adjustFirstEventAfterTrigger = (events) => {
	const firstElementAfterTriggerId = events.filter(
		(e) =>
			isEdge(e) &&
			events.filter((event) => event.data.type == "trigger")[0].id === e.source
	)[0].target;
	const index = events.findIndex(
		(ele) => ele.id === firstElementAfterTriggerId
	);
	const firstElementAfterTrigger = events[index];
	events.splice(index, 1);
	events.splice(0, 0, firstElementAfterTrigger);
};
const mapFEEventsToBEEvents = (events, activeAutomation) => {
	const automation = {
		name: activeAutomation.name,
		list_id: "",
		trigger: [
			{
				name: "Custom.Event",
				args: {
					customevent_id: activeAutomation.customevent_id,
				},
			},
		],
		main: {
			steps: [
				{
					name: "init",
					action: "Condition.Switch",
					args: {
						switch: [
							{
								steps: [
									{
										name: "addOutput",
										action: "Variable.Set",
										args: {
											"input.data.step_trigger": {},
											"input.data.outputs": [],
											"input.data.loops":{}
										},
									},
								],
								condition: {
									eq: {
										value: true,
										var: "${not(\"step_trigger\" in input.data)}",
									},
								},
							},
						],
					},
				},
				{
					name: "router",
					pausable: "true",
					action: "Condition.Switch",
					args: {
						switch: [],
					},
				},
			],
		},
		sub_automations: [],
	};
	adjustFirstEventAfterTrigger(events);
	const nodes = events.filter((event) => isNode(event));
	const edges = events.filter((event) => isEdge(event));

	//First step: convert each event to main and subautomation, if it's subautomation is not required
	nodes.forEach((event, index) => {
		if (event.data.baseId == "manual") return;
		const connectedEdges = getConnectedEdges(nodes, edges).filter(
			(edge) => edge.source == event.id
		);
		if (event.data.type == "trigger") {
			automation.list_id = event.data.audience;
			automation.trigger.push(mapFEEventToBEStep(event, null, events));
		} else {
			const mappedObject = mapFEEventToBEStep(
				event,
				connectedEdges,
				events,
				index + 1,
				index
			);
			if (mappedObject?.main) {
				mappedObject.main.forEach((newStep) => {
					automation.main.steps[1].args.switch = [
						...automation.main.steps[1].args.switch,
						newStep,
					];
				});
			}

			if (mappedObject?.sub)
				automation.sub_automations = [
					...automation.sub_automations,
					...mappedObject.sub,
				];
			// this is required to add the reusable subautomations
			if (mappedObject?.reusableSubAutomations) {
				mappedObject?.reusableSubAutomations.forEach((sub) => {
					if (
						automation.sub_automations.filter(
							(subAutomation) => subAutomation.name == sub
						).length == 0
					) {
						automation.sub_automations.push(MAPPED[sub]());
					}
				});
			}
		}
	});
	// having this layer above the automation structure to handle the next and the jump/ emit steps
	adjustNextAndJumpsStepNumbers(automation);
	// having this layer above the automation structure to change it to sequence of switch cases
	splitSwitchToSeveralSwitchCases(automation);
	return automation;
};
const splitSwitchToSeveralSwitchCases = (automation) => {
	const indexOFFirstRouterStep = 1;
	if (automation.main.steps[indexOFFirstRouterStep].args.switch.length <= 10) {
		return;
	} else {
		let currentRouterStepIndex = indexOFFirstRouterStep;
		let currentStepSteps = [];
		automation.main.steps[indexOFFirstRouterStep].args.switch.forEach(
			(step) => {
				if (
					automation.main.steps[currentRouterStepIndex].args.switch.length == 10
				) {
					automation.main.steps.splice(currentRouterStepIndex + 1, 0, {
						name: `router_${currentRouterStepIndex + 1}`,
						pausable: "true",
						action: "Condition.Switch",
						args: {
							switch: [],
						},
					});
					currentRouterStepIndex = currentRouterStepIndex + 1;
					currentStepSteps = [];
				}
				currentStepSteps.push(step);
				automation.main.steps[currentRouterStepIndex].args.switch = [
					...currentStepSteps,
				];
			}
		);
	}
};

const adjustNextAndJumpsStepNumbers = (automation) => {
	const routerSteps = automation.main.steps[1].args.switch;
	const stepPreviousMap = {};
	routerSteps.forEach((step, index) => {
		const currentStepName = step.steps[0].name;
		const subAutomationSteps = automation.sub_automations.filter(
			(sub) => sub.name == step.steps[0].args.sub_automation
		)[0].steps;
		subAutomationSteps.forEach((subStep) => {
			const actionType =
				subStep.action == "Condition.Switch"
					? "switch"
					: subStep.description == "delay"
						? "delay"
						: "default";
			switch (actionType) {
			case "switch":
				{
					// check for the yes branch
					if (subStep.args.switch[0].next) {
						if (subStep.args.switch[0].next == "end") {
							delete subStep.args.switch[0].next;
						} else {
							// nextYesCondition is the yes step name
							const nextYesCondition = `${currentStepName}_yes`;
							stepPreviousMap[
								subStep.args.switch[0].next
							] = `${currentStepName}_yes`;
							// create the emit caller step
							subStep.args.switch[0].steps = [
								{
									name: "loopback",
									action: "SubAutomation.Call",
									args: {
										sub_automation: "emit_next_step",
										params: {
											step: nextYesCondition,
											delay: 0,
										},
									},
									next: "end",
								},
							];
							delete subStep.args.switch[0].next;
						}
					}
					// check for the no branch
					if (subStep.next)
						if (subStep.next == "end") {
							delete subStep.next;
						} else {
							const nextNoCondition = `${currentStepName}_no`;
							stepPreviousMap[subStep.next] = nextNoCondition;
							if (nextNoCondition)
								subAutomationSteps.push({
									name: "loopback_1",
									action: "SubAutomation.Call",
									args: {
										sub_automation: "emit_next_step",
										params: {
											step: nextNoCondition,
											delay: 0,
										},
									},
								});
							delete subStep.next;
						}
				}

				break;
			case "delay":
				{
					if (subStep.next) {
						if (subStep.next == "end") {
							delete subStep.next;
							return;
						} else {
							subAutomationSteps.splice(0, 1, {
								name: subAutomationSteps[0].name,
								action: "SubAutomation.Call",
								args: {
									sub_automation: "emit_next_step",
									params: {
										step: currentStepName,
										//findIndexOfStep(subStep.next,automation),
										delay: subAutomationSteps[0].args.params.delay,
									},
								},
							});

							stepPreviousMap[subStep.next] = currentStepName;
							delete subStep.next;
						}
					}
				}
				break;

			default: {
				if (subStep.next) {
					if (subStep.next == "end") {
						delete subStep.next;
						return;
					} else {
						subAutomationSteps.push({
							name: "loopback",
							action: "SubAutomation.Call",
							args: {
								sub_automation: "emit_next_step",
								params: {
									step: currentStepName,
									delay: 0,
								},
							},
						});
						stepPreviousMap[subStep.next] = currentStepName;
						delete subStep.next;
					}
				}
			}
			}
		});
		// handle the route condition
		if (index !== 0) {
			let stepWithShadows = [stepPreviousMap[step.steps[0].name]];
			if(step.steps[0].inTos && step.steps[0].inTos.length>0) stepWithShadows = [...stepWithShadows, ...step.steps[0].inTos];
			if (
				routerSteps
					.map((r) => r.steps[0])
					.filter((sub) => sub.name == stepWithShadows[0])[0]?.shadows
			) {
				stepWithShadows = [
					...stepWithShadows,
					...routerSteps
						.map((r) => r.steps[0])
						.filter((sub) => sub.name == stepWithShadows[0])[0]?.shadows,
				];
			}
		
			step.condition = {
				eq: {
					var: "${input.data.step in " + JSON.stringify(stepWithShadows) + "}",
					value: true,
				},
			};
		} else {
			let triggerShadows = [];
			 triggerShadows = store.getState().automations.automationEvents
				? store
					.getState()
					.automations.automationEvents.filter(
						(e) => e.data.baseId == "subscribed"
					)[0].data.shadows
				: store
					.getState()
					.automationsV1.automationEvents.filter(
						(e) => e.data.baseId == "subscribed"
					)[0].data.shadows;
			
			triggerShadows = triggerShadows ? [...triggerShadows] : [];
			if(step.steps[0].inTos && step.steps[0].inTos.length>0) triggerShadows = [...triggerShadows, ...step.steps[0].inTos];
			step.condition =
				triggerShadows && triggerShadows.length > 0
					? {
						or: [
							{
								eq: {
									var: "${not(\"step\" in input.data)}",
									value: true,
								},
							},
							{
								eq: {
									var:
											"${input.data.step in " +
											JSON.stringify(triggerShadows) +
											"}",
									value: true,
								},
							},
						],
					  }
					: {
						eq: {
							var: "${not(\"step\" in input.data)}",
							value: true,
						},
					  };
		}
	});
	automation.main.steps[1].args.switch.forEach((step) => {
		if (step.steps) delete step.steps[0].shadows;
		delete step.steps[0].inTos;
	});
	if (automation.main.steps[1].args.switch.length > 0) {
		automation.main.steps[1].args.switch[0].steps.unshift({
			name: "log_visitor",
			action: "Execution.AddVisitor",
			args: {
				contactId: "${input.data.contact.id}",
			},
		});
	}
};

export {
	convertSecondsToNearestUnit,
	convertTimeWithUnitToSeconds,
	mapBEStepsToFEEvents,
	mapFEEventsToBEEvents,
	getMatchedConditionToEmitStep,
};
