const BRAND = {
	CURRENT_BRAND: "current_app_brand",
	MODIFIED_BRAND: "modified_app_brand",
	UNPUBLISHED_CHANGES: "current_app_brand_unpublished_changes",
	FAV_SIZE_BIG: 512,
	FAV_SIZE_MED: 180,
	FAV_SIZE_SMALL: 32,
	FAV_SIZE_XSMALL: 16,
	LOGO_MAX_WIDTH: 400,
	LOGO_MAX_HEIGHT: 90,
};

export default BRAND;
