import React from "react";
import { setLocale, Translate } from "react-redux-i18n";
import store from "assets/js/store";
import moment from "moment";
import Cookies from "universal-cookie";
import { setLanguage } from "assets/js/actions";
import originalBrand from "assets/js/scenes/brand/data/originalBrand";
import { registerLocale, setDefaultLocale } from "react-datepicker";

const getLanguagesOptions = (selected) => {
	return languages().map((language, index) => {
		return {
			label: language.label,
			value: language.value,
			selected: language.value === selected,
		};
	});
};
/**
 *
 * @param {locale value to get label for } locale
 * this function is used to get the labels displayed in the user settings, we need to add a try and catch block here
 * to handle the case if user's data in the database has a locale value that is not listed in the above
 * the case happened after removing PT
 */
const getLanguageLabel = (locale) => {
	try {
		return languages().find((language, index) => {
			return language.value == locale;
		}).label;
	} catch (error) {
		return languages().find(
			(language, index) =>
				language.value == adjustLocaleToMatchBE(fallBackLanguage)
		).label;
	}
};

/**
 * @return array
 */
function languages() {
	const defaultLocals = ["en_us", "fr_ca"];
	let resellerLocales = store?.getState()?.brand?.client_config?.languages;

	let localesToUse = [];

	if (resellerLocales && resellerLocales.length > 0) {
		resellerLocales = resellerLocales.map((locale) => locale.toLowerCase());
		localesToUse = supportedLocalesApi.filter((locale) => {
			return resellerLocales.includes(locale.value.toLowerCase());
		});
	} else {
		localesToUse = supportedLocalesApi.filter((locale) => {
			return defaultLocals.includes(locale.value.toLowerCase());
		});
	}

	return localesToUse;
}

const getUrlsBasedOnLanguage = (urls, name, language = "en") => {
	let returnedUrl = "";

	if (urls) {
		if (urls[language]) returnedUrl = urls[language];
		if (urls.en) returnedUrl = urls.en;
		if (urls[Object.keys(urls)[0]]) returnedUrl = urls[Object.keys(urls)[0]];
	}

	if (returnedUrl === "default" && originalBrand?.url?.[name]) {
		return getUrlsBasedOnLanguage(originalBrand?.url?.[name], language);
	}

	return returnedUrl;
};

const fallBackLanguage = "en-US";
/** for some reason, need to check with BE guys, the backend has locales with - instead of _,
 *  for example : en_US instead of en-US
 *  while the standard is -, to solve the problem we replace all the - with -
 */
function adjustLocaleToMatchBE(locale) {
	return locale.replace("-", "_");
}
/**
 * the functions main purpose is handle how our fallback works in the system, priority is to user selection or cookie saved locale
 * if exists in our set of supported languages then navigator's language if supported, then en-US
 * @param userSelectedLocale locale
 */
function getLocaleWithFallback(locale) {
	const lookupLanguages = languages().map((lng) => lng.value);
	if (locale && lookupLanguages.includes(adjustLocaleToMatchBE(locale))) {
		return adjustLocaleToMatchBE(locale);
	}
	let browserFallBackLang = navigator.language || navigator.userLanguage;
	browserFallBackLang = adjustLocaleToMatchBE(browserFallBackLang);

	if (browserFallBackLang && lookupLanguages.includes(browserFallBackLang)) {
		return browserFallBackLang;
	} else {
		return adjustLocaleToMatchBE(fallBackLanguage);
	}
}

const supportedLocalesApi = [
	{
		label: <Translate value="app-general-languages_en-US" />,
		value: "en_US",
		supportLink: "en-us",
	},
	{
		label: <Translate value="app-general-languages_fr-CA" />,
		value: "fr_CA",
		supportLink: "fr",
	},
	{
		label: <Translate value="app-general-languages_nl-NL" />,
		value: "nl_NL",
		supportLink: "nl-nl",
	},
	{
		label: <Translate value="app-general-languages_es-ES" />,
		value: "es_ES",
		supportLink: "es-es",
	},
	{
		label: <Translate value="app-general-languages_pt-BR" />,
		value: "pt_BR",
		supportLink: "pt-br",
	},
	{
		label: <Translate value="app-general-languages_es-US" />,
		value: "es_US",
		supportLink: "es-us",
	},
];

function setLocaleWithFallback(desiredLocale) {
	// for the language we have two points of truth, the cookies, and the language state in the store
	// we also need to adjust moment with the proper locale
	const finalLang = getLocaleWithFallback(desiredLocale);
	moment.locale(finalLang.replace("_", "-"));
	new Cookies().set("locale", finalLang, { path: "/" });
	store.dispatch(setLanguage(finalLang));
	window.document.querySelector("html").setAttribute("lang", finalLang);
	return (dispatch) => dispatch(setLocale(finalLang));
}

function setDatePickerLocale(language = "en_US") {
	try {
		// eslint-disable-next-line no-undef
		let curLocale = require(`date-fns/locale/${language.replace(
			"_",
			"-"
		)}/index.js`);
		registerLocale(curLocale.code, curLocale);
		setDefaultLocale(curLocale.code);
	} catch (error) {
		try {
			// eslint-disable-next-line no-undef
			let curLocale = require(`date-fns/locale/${language
				.replace("_", "-")
				.substring(0, 2)}/index.js`);
			registerLocale(curLocale.code, curLocale);
			setDefaultLocale(curLocale.code);
		} catch (e) {
			// eslint-disable-next-line no-undef
			let curLocale = require("date-fns/locale/en-US/index.js");
			registerLocale("en-US", curLocale);
			setDefaultLocale("en-US");
		}
	}
}

export {
	getLanguagesOptions,
	getLanguageLabel,
	adjustLocaleToMatchBE,
	getLocaleWithFallback,
	languages,
	fallBackLanguage,
	supportedLocalesApi,
	getUrlsBasedOnLanguage,
	setLocaleWithFallback,
	setDatePickerLocale,
};
