import { GET_BRAND_SUCCESS } from "./types";

const INITIAL_STATE = null;

export const reducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
	case GET_BRAND_SUCCESS:
		return {
			...state,
			...action.brand,
		};
	default:
		return state;
	}
};
