function uuidv4() {
	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
		(
			c ^
			(crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
		).toString(16)
	);
}
const getRowJson = () => {
	return {
		columns: [
			{
				"grid-columns": 12,
				modules: [],
				style: {
					"background-color": "transparent",
					"border-bottom": "0px solid transparent",
					"border-left": "0px solid transparent",
					"border-right": "0px solid transparent",
					"border-top": "0px solid transparent",
					"padding-bottom": "60px",
					"padding-left": "30px",
					"padding-right": "30px",
					"padding-top": "60px",
				},
				uuid: uuidv4(),
			},
		],
		container: {
			style: {
				"background-color": "transparent",
				"background-image": "none",
				"background-position": "top left",
				"background-repeat": "no-repeat",
			},
		},
		content: {
			computedStyle: {
				hideContentOnDesktop: false,
				hideContentOnMobile: false,
				rowColStackOnMobile: true,
			},
			style: {
				"background-color": "transparent",
				"background-image": "none",
				"background-position": "top left",
				"background-repeat": "no-repeat",
				color: "#333",
				width: "600px",
			},
		},
		locked: false,
		type: "one-column-empty",
		uuid: uuidv4(),
	};
};

const getModuleJson = () => {
	return {
		descriptor: {
			computedStyle: {
				hideContentOnDesktop: false,
				hideContentOnMobile: false,
			},
			style: {
				"padding-bottom": "10px",
				"padding-left": "10px",
				"padding-right": "10px",
				"padding-top": "10px",
				"background-color": "transparent",
			},
			text: {
				computedStyle: {
					linkColor: "#9b9b9b",
				},
				html: "<div class=\"txtTinyMce-wrapper\" style=\"font-family: Arial, &apos;Helvetica Neue&apos;, Helvetica, sans-serif; font-size: 12px; line-height: 21px;\" data-mce-style=\"font-family: Arial, &apos;Helvetica Neue&apos;, Helvetica, sans-serif; font-size: 12px; line-height: 21px;\"><p style=\"font-size: 14px; line-height: 25px; text-align: center; word-break: break-word; font-family: Arial, &apos;Helvetica Neue&apos;, Helvetica, sans-serif;\" data-mce-style=\"font-size: 14px; line-height: 25px; text-align: center; word-break: break-word;\"><span style=\"font-size: 12px; line-height: 21px;\" data-mce-style=\"font-size: 12px; line-height: 21px;\"><a style=\"text-decoration: underline;\" title=\"View in browser\" href=\"[SHOWEMAIL]\" target=\"_blank\" rel=\"noopener\">View in browser</a>    <a style=\"text-decoration: underline;\" title=\"View in browser\" href=\"[UNSUBSCRIBE]\" target=\"_blank\" rel=\"noopener\">Unsubscribe</a> </span></p></div>",
				style: {
					color: "#9b9b9b",
					"font-family": "Arial, 'Helvetica Neue', Helvetica, sans-serif",
					"line-height": "180%",
				},
			},
		},
		locked: false,
		type: "mailup-bee-newsletter-modules-text",
		uuid: uuidv4(),
	};
};

const StyledHtml =
	"<div class=\"txtTinyMce-wrapper\" style=\"font-family: Arial, &apos;Helvetica Neue&apos;, Helvetica, sans-serif; font-size: 12px; line-height: 18px;\" data-mce-style=\"font-family: Arial, &apos;Helvetica Neue&apos;, Helvetica, sans-serif; font-size: 12px; line-height: 18px;\">##INSERT_CONTENT_HERE##</div>";
const StyledUnSubscribe =
	"<p style=\"font-size: 18px; line-height: 27px; text-align: center; word-break: break-word; font-family: Arial, &apos;Helvetica Neue&apos;, Helvetica, sans-serif;\" data-mce-style=\"font-size: 18px; line-height: 27px; text-align: center; word-break: break-word;\"><span style=\"font-size: 12px; line-height: 21px;\" data-mce-style=\"font-size: 12px; line-height: 21px;\">    <a style=\"text-decoration: underline;\" title=\"View in browser\" href=\"[UNSUBSCRIBE]\" target=\"_blank\" rel=\"noopener\">Unsubscribe</a></span></p>";
// const StyledGlobalUnsubscribe ="<p style=\"font-size: 18px; line-height: 27px; text-align: center; word-break: break-word; font-family: Arial, &apos;Helvetica Neue&apos;, Helvetica, sans-serif;\" data-mce-style=\"font-size: 18px; line-height: 27px; text-align: center; word-break: break-word;\"><span style=\"font-size: 12px; line-height: 21px;\" data-mce-style=\"font-size: 12px; line-height: 21px;\">    <a style=\"text-decoration: underline;\" title=\"View in browser\" href=\"[GLOBAL_UNSUBSCRIBE]\" target=\"_blank\" rel=\"noopener\">Global Unsubscribe</a></span></p>";
const StyledAddress =
	"<p style=\"font-size: 18px; line-height: 27px; text-align: center; word-break: break-word; font-family: Arial, &apos;Helvetica Neue&apos;, Helvetica, sans-serif;\" data-mce-style=\"font-size: 18px; line-height: 27px; text-align: center; word-break: break-word;\"><span style=\"font-size: 12px; line-height: 18px;\" data-mce-style=\"font-size: 12px; line-height: 18px;\">[CLIENTS.ADDRESS]</span></p>";

export {
	getRowJson,
	getModuleJson,
	StyledHtml,
	StyledUnSubscribe,
	//  StyledGlobalUnsubscribe ,
	StyledAddress,
};
