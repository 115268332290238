/**
 * This file is responsible for handling the service calls for the account
 */
import { callAPI, chameau, GATEWAY_PROXY, METHODS } from "utils/apiUtils";
import {
	SEGMENT_EVENTS,
	segmentCondition,
	segmentPayloads,
	trackSegmentEvent,
} from "assets/js/const/SegmentEvents";
import store from "../store";

export function whoami(
	options = {},
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + "/whoami",
			method: METHODS.get,
			body: options,
		},
		successCallback,
		failureCallback
	);
}

export function confirmAccountService(
	options = { accountId: "", confirmation: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + `/accounts/${options.accountId}/confirm`,
			method: METHODS.post,
			body: { confirmation: options.confirmation },
		},
		(data) => {
			trackSegmentEvent(SEGMENT_EVENTS.ACCOUNT_CONFIRMED, data.data);
			successCallback(data);
		},
		failureCallback
	);
}

export function createAccountService(
	options = { account: {} },
	successCallback = () => {},
	failureCallback = () => {}
) {
	const { account, ...rest } = options;
	callAPI(
		{
			url: GATEWAY_PROXY + "/accounts",
			method: METHODS.post,
			body: account,
			query: { ...chameau(rest) },
		},
		successCallback,
		failureCallback
	);
}

export function updateAccountService(
	options = { accountId: null, account: {} },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + `/accounts/${options.accountId || "self"}`,
			method: METHODS.patch,
			body: options.account,
		},
		(data) => {
			if (segmentCondition(SEGMENT_EVENTS.GROUP)) {
				// eslint-disable-next-line no-undef
				analytics.group(
					data.data.id,
					segmentPayloads(SEGMENT_EVENTS.GROUP, data.data)
				);
			}
			successCallback(data);
		},
		failureCallback
	);
}

export function updateSelfAccountService(
	options = { account: {} },
	successCallback = () => {},
	failureCallback = () => {}
) {
	updateAccountService(
		{ ...options, accountId: "self" },
		successCallback,
		failureCallback
	);
}

export function getAccountStatsService(
	options = { startTime: null, endTime: null },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + "/reports/accounts/self",
			method: METHODS.get,
			query: chameau(options),
		},
		successCallback,
		failureCallback
	);
}

export function createAccountAndCampaignService(
	options = {},
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + "/accountAndCampaign",
			method: METHODS.post,
			body: options,
		},
		(data) => {
			trackSegmentEvent(SEGMENT_EVENTS.ACCOUNT_CREATED, data);
			successCallback(data);
		},
		failureCallback
	);
}

export function verifyAccountAndCampaignService(
	options = { accountId: "", waiterId: "", pinCode: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	const { accountId, waiterId, pinCode, ...restOptions } = options;
	callAPI(
		{
			url:
				GATEWAY_PROXY +
				`/accountAndCampaign/${accountId}/waiter/${waiterId}/confirm-pin/${pinCode}`,
			method: METHODS.get,
			query: chameau(restOptions),
		},
		(data) => {
			trackSegmentEvent(SEGMENT_EVENTS.ACCOUNT_CONFIRMED, data);
			successCallback(data);
		},
		failureCallback
	);
}

export function resendConfirmationEmailAccountAndCampaignService(
	options = { accountId: "", waiterId: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	const { accountId, waiterId, ...restOptions } = options;
	callAPI(
		{
			url:
				GATEWAY_PROXY +
				`/accountAndCampaign/${accountId}/waiter/${waiterId}/resend`,
			query: chameau(restOptions),
		},
		successCallback,
		failureCallback
	);
}

export function resendConfirmationEmailAccountService(
	options = { email: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	const { email, ...rest } = options;
	callAPI(
		{
			url: GATEWAY_PROXY + "/accounts/resend-verification-email",
			method: METHODS.post,
			body: { email: email },
			query: {
				...chameau(rest),
				account_id: store.getState().global.account.id,
			},
		},
		(data) => {
			successCallback(data);
		},
		failureCallback
	);
}
