/**
 * This file is to map trigger, from and to BE structure, it should contain at least two functions, one that maps
 * from BE to FE structure, and the other one that maps from FE to BE structure
 */
import { TriggerEvents } from "assets/js/scenes/automations/containers/editor/EventsConfigs";
import {
	getEdge,
	getValudOfVariable,
} from "../../../../../containers/editor/utils/utils";

const getShadows = (condition) => {
	let shadows = [];
	let varValue;
	if (condition.or) {
		varValue = getValudOfVariable(
			condition.or[condition.or.length - 1].eq?.var
		).replace("input.data.step in ", "");

		if (varValue[0] !== "[") return false;
		shadows = JSON.parse(varValue).filter(v =>!v.includes("_loop"));
	}
	return shadows;
};

/**
 *
 * @param {*} audience
 */
const parseBETriggerToFE = (audience, nextSubAutomation, nextStepCondition) => {
	let shadows = [];
	if (nextStepCondition) {
		shadows = getShadows(nextStepCondition);
	}
	const id = `event_${+new Date()}`;
	const triggerEvent = {
		id: id,
		type: "editorNode",
		data: {
			audience: audience,
			baseId: "subscribed",
			id: id,
			...TriggerEvents()[0],
			initialElement: true,
			shadows: shadows,
		},
		position: {
			x: -252,
			y: 0,
		},
	};
	const firstTargetId = nextSubAutomation.id;
	const links = getEdge("branchingEdge", triggerEvent.id, firstTargetId);

	return {
		events: [triggerEvent],
		links: [links],
	};
};
const parseFETriggerToBE = (FEEvent, events) => {
	return {
		args: {
			list_id: FEEvent.data.audience,
		},
		name: "Contact.Added",
	};
};
export { parseBETriggerToFE, parseFETriggerToBE };
