/**
 * This component holds the whole app ui routing system, lazy loads private routes
 */
import React, { Suspense, lazy } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import ErrorThrower from "./ErrorThrower";
import fetchRoute from "utils/routes";
import GenericWrapper from "assets/js/components/GenericWrapper";
import OnboardingFlow from "assets/js/scenes/OnBoardingFlow/OnBoardingFlow";

const UI = lazy(() => import("assets/js/UI"));

const UIMain = (props) => {
	return (
		<BrowserRouter>
			<GenericWrapper>
				<ErrorThrower>
					<Switch>
						<Route
							path={fetchRoute("public")}
							render={(props) => (
								<OnboardingFlow
									authenticated={false}
									fetchAccount={() => {}}
									{...props}
								/>
							)}
						/>

						<Suspense fallback={<div></div>}>
							<UI cakemailApiClient={props.cakemailApiClient} />
						</Suspense>
					</Switch>
				</ErrorThrower>
			</GenericWrapper>
		</BrowserRouter>
	);
};
export default UIMain;
