import React from "react";
import { Translate } from "react-redux-i18n";
import {
	getRowJson,
	getModuleJson,
	StyledHtml,
	StyledUnSubscribe,
	StyledAddress,
} from "./IsValidEmailDate";
import deepcopy from "deepcopy";
import store from "assets/js/store";

/**
 * Filter rows of a BEE document by desktop/mobile visibility
 *
 * @param {*} rows
 * @returns
 */
function filterRows(rows, desktop = false, mobile = false) {
	const filteredRows = [];
	for (const row of rows) {
		const filteredRow = filterRow(row, desktop, mobile);
		if (filteredRow) {
			filteredRows.push(filteredRow);
		}
	}
	return filteredRows;
}

/**
 * Filter a row by desktop and/or mobile visibility
 *
 * @param {any} row
 * @returns
 */
function filterRow(row, desktop = false, mobile = false) {
	const computedStyle = row.content?.computedStyle || {};
	const updatedRow = { ...row };
	if (
		(!computedStyle.hideContentOnDesktop && desktop) ||
		(!computedStyle.hideContentOnMobile && mobile)
	) {
		const filteredColumns = [];

		for (const col of row.columns) {
			const filteredColumn = filterColumn(col, desktop, mobile);
			if (filteredColumn) {
				filteredColumns.push(filteredColumn);
			}
		}
		updatedRow.columns = filteredColumns;
		return updatedRow;
	}
	return false;
}

/**
 * Filter a column by desktop and/or mobile visibility
 *
 * @param {*} col
 * @returns
 */
function filterColumn(col, desktop = false, mobile = false) {
	const updatedCol = { ...col };
	const computedStyle = col.descriptor?.computedStyle || {};
	if (
		(!computedStyle.hideContentOnDesktop && desktop) ||
		(!computedStyle.hideContentOnMobile && mobile)
	) {
		const filteredModules = [];

		for (const module of col.modules) {
			const filteredModule = filterModule(module, desktop, mobile);
			if (filteredModule) {
				filteredModules.push(filteredModule);
			}
		}
		updatedCol.modules = filteredModules;
		return updatedCol;
	}
	return false;
}

/**
 * Filter a module by desktop and/or mobile visibility
 *
 * @param {*} col
 * @returns
 */
function filterModule(module, desktop = false, mobile = false) {
	const computedStyle = module?.descriptor?.computedStyle || {};
	if (
		(!computedStyle.hideContentOnDesktop && desktop) ||
		(!computedStyle.hideContentOnMobile && mobile)
	) {
		return module;
	}
	return false;
}

const checkDesignerValidity = (data, returnUpdatedFooter) => {
	// gather filtered views
	const mobile = JSON.stringify(filterRows(data.page.rows, false, true));
	const desktop = JSON.stringify(filterRows(data.page.rows, true, false));
	const desktopMissingContent = [];
	const mobileMissingContent = [];
	let missingContentInBoth = [];

	let accountOverrides = store.getState().global.account.overrides;

	const unsubRegex = /alt=['|"]\[UNSUBSCRIBE\]['|"]/i;
	if (
		accountOverrides.inject_address &&
		!desktop.includes("[CLIENTS.ADDRESS]")
	) {
		const desktopAddress =
			desktop.includes("[CLIENTS.ADDRESS1]") &&
			desktop.includes("[CLIENTS.COUNTRY]") &&
			desktop.includes("[CLIENTS.CITY]") &&
			desktop.includes("[CLIENTS.POSTAL_CODE]") &&
			desktop.includes("[CLIENTS.PROVINCE]");
		if (!desktopAddress) {
			desktopMissingContent.push(StyledAddress);
		}
	}
	if (
		accountOverrides.inject_address &&
		!mobile.includes("[CLIENTS.ADDRESS]")
	) {
		const mobileAddress =
			mobile.includes("[CLIENTS.ADDRESS1]") &&
			mobile.includes("[CLIENTS.COUNTRY]") &&
			mobile.includes("[CLIENTS.CITY]") &&
			mobile.includes("[CLIENTS.POSTAL_CODE]") &&
			mobile.includes("[CLIENTS.PROVINCE]");
		if (!mobileAddress) {
			mobileMissingContent.push(StyledAddress);
		}
	}
	if (
		accountOverrides.inject_unsubscribe_link &&
		!desktop.includes("[UNSUBSCRIBE]")
	) {
		if (!desktop.includes("[GLOBAL_UNSUBSCRIBE]"))
			desktopMissingContent.push(StyledUnSubscribe);
	}
	if (
		accountOverrides.inject_unsubscribe_link &&
		!mobile.includes("[UNSUBSCRIBE]")
	) {
		if (!mobile.includes("[GLOBAL_UNSUBSCRIBE]"))
			mobileMissingContent.push(StyledUnSubscribe);
	}

	missingContentInBoth = mobileMissingContent.filter((content) =>
		desktopMissingContent.includes(content)
	);
	const missingContentInMobileOnly = mobileMissingContent.filter(
		(content) => !desktopMissingContent.includes(content)
	);
	const missingContentInDesktopOnly = desktopMissingContent.filter(
		(content) => !mobileMissingContent.includes(content)
	);

	if (returnUpdatedFooter)
		return {
			missingInBoth: missingContentInBoth,
			missingInMobile: missingContentInMobileOnly,
			missingInDesktop: missingContentInDesktopOnly,
		};
	if (
		missingContentInMobileOnly.length > 0 ||
		missingContentInDesktopOnly.length > 0 ||
		missingContentInBoth.length > 0
	) {
		return false;
	}
	return true;
};

const hasCustomMergeTag = (htmlContent, mergeTagArray = []) => {
	let content = htmlContent;

	if (typeof htmlContent === "object") content = JSON.stringify(htmlContent);

	return mergeTagArray.find(function (tag) {
		return content.includes("[" + tag.name + "]");
	});
};

const checkHtmlValidity = (htmlContent, returnMissingContentKeys) => {
	const missingContentKeys = [];
	let accountOverrides = store.getState().global.account.overrides;
	if (
		accountOverrides.inject_address &&
		!htmlContent.includes("[CLIENTS.ADDRESS]")
	) {
		if (
			htmlContent.includes("[CLIENTS.ADDRESS1]") &&
			htmlContent.includes("[CLIENTS.COUNTRY]") &&
			htmlContent.includes("[CLIENTS.CITY]") &&
			htmlContent.includes("[CLIENTS.POSTAL_CODE]") &&
			htmlContent.includes("[CLIENTS.PROVINCE]")
		) {
			//return true;
		} else missingContentKeys.push(StyledAddress);
	}
	const unsubUrlPattern = /alt=['|"]\[UNSUBSCRIBE\]['|"]/i;
	if (
		accountOverrides.inject_unsubscribe_link &&
		(htmlContent.match(unsubUrlPattern) ||
			!htmlContent.includes("[UNSUBSCRIBE]"))
	) {
		if (!htmlContent.includes("[GLOBAL_UNSUBSCRIBE]"))
			missingContentKeys.push(StyledUnSubscribe);
	}

	if (returnMissingContentKeys)
		return {
			valid: missingContentKeys.length === 0,
			missingContentKeys: missingContentKeys,
		};
	else return missingContentKeys.length === 0;
};
/**
 * function that is responsible for validating email content
 * @param {object that has either json or html keys} emailContent
 * return true if email has unsbubscribelink and address information else return false
 */
const isEmailContentValid = (emailContent, returnUpdatedFooter = false) => {
	let content = "";
	if (emailContent.json) {
		content = emailContent.json;
		return checkDesignerValidity(content, returnUpdatedFooter);
	} else {
		content = emailContent.html;
		return checkHtmlValidity(content, returnUpdatedFooter);
	}
};
const updateEmailWithFooter = (missingBoth, desktopMissing, mobileMissing) => {
	let updatedFooter = [];
	if (
		missingBoth.length > 0 ||
		desktopMissing.length > 0 ||
		mobileMissing.length > 0
	) {
		if (mobileMissing.length > 0) {
			const copyOfFooterJson = deepcopy(getRowJson());
			updatedFooter.push(copyOfFooterJson);
			const copyOfModuleJson = deepcopy(getModuleJson());
			copyOfFooterJson.content.computedStyle.hideContentOnDesktop = true;
			copyOfFooterJson.content.computedStyle.hideContentOnMobile = false;
			copyOfModuleJson.descriptor.text.html = StyledHtml.replace(
				"##INSERT_CONTENT_HERE##",
				mobileMissing.join(" ")
			);
			copyOfFooterJson.columns[0].modules.push(copyOfModuleJson);
		}
		if (desktopMissing.length > 0) {
			const copyOfFooterJson = deepcopy(getRowJson());
			updatedFooter.push(copyOfFooterJson);
			const copyOfModuleJson = deepcopy(getModuleJson());
			copyOfFooterJson.content.computedStyle.hideContentOnDesktop = false;
			copyOfFooterJson.content.computedStyle.hideContentOnMobile = true;
			copyOfModuleJson.descriptor.text.html = StyledHtml.replace(
				"##INSERT_CONTENT_HERE##",
				desktopMissing.join(" ")
			);
			copyOfFooterJson.columns[0].modules.push(copyOfModuleJson);
		}
		if (missingBoth.length > 0) {
			const copyOfFooterJson = deepcopy(getRowJson());
			updatedFooter.push(copyOfFooterJson);
			const copyOfModuleJson = deepcopy(getModuleJson());
			copyOfFooterJson.content.computedStyle.hideContentOnDesktop = false;
			copyOfFooterJson.content.computedStyle.hideContentOnMobile = false;
			copyOfModuleJson.descriptor.text.html = StyledHtml.replace(
				"##INSERT_CONTENT_HERE##",
				missingBoth.join(" ")
			);
			copyOfFooterJson.columns[0].modules.push(copyOfModuleJson);
		}
	}
	return updatedFooter;
};
const getUpdatedEmailContentWithMissingRequirements = (
	emailContent,
	hideAddedRows = false
) => {
	if (emailContent.json) {
		const missingRequirements = isEmailContentValid(emailContent, true);
		const viewWithMissingContent = [];
		if (missingRequirements) {
			const updatedFooter = updateEmailWithFooter(
				missingRequirements.missingInBoth,
				missingRequirements.missingInDesktop,
				missingRequirements.missingInMobile
			);
			if (updatedFooter.length > 0) {
				if (hideAddedRows) {
					updatedFooter.forEach((row) => {
						row.content.style.display = "none !important";
						row.content.style.width = "0 !important";
						row.content.style.maxWidth = "0 !important";
						row.container.style.maxWidth = "0 !important";
						row.container.style.width = "0 !important";
						row.container.style.display = "none !important";
						row.columns[0].modules[0].descriptor.text.style.display =
							"none !important";
						row.columns[0].modules[0].descriptor.text.style.padding =
							"0px !important";
					});
				}

				emailContent.json.page.rows.push(...updatedFooter);
			}
			if (missingRequirements.missingInBoth.length > 0) {
				viewWithMissingContent.filter((view) => view === "desktop").length ==
					0 && viewWithMissingContent.push("desktop");
				viewWithMissingContent.filter((view) => view === "mobile").length ==
					0 && viewWithMissingContent.push("mobile");
			}
			missingRequirements.missingInDesktop.length > 0 &&
				viewWithMissingContent.filter((view) => view === "desktop").length ==
					0 &&
				viewWithMissingContent.push("desktop");
			missingRequirements.missingInMobile.length > 0 &&
				viewWithMissingContent.filter((view) => view === "mobile").length ==
					0 &&
				viewWithMissingContent.push("mobile");
		}
		return {
			emailContent: emailContent,
			viewWithMissingContent: viewWithMissingContent,
		};
	} else {
		if (!hideAddedRows) {
			const missingKeys = isEmailContentValid(emailContent, true);
			if (!missingKeys.valid) {
				emailContent.html = emailContent.html.replace(
					"</body>",
					`<div>${missingKeys.missingContentKeys.join("</br>")}</div></body>`
				);
			}
			return { emailContent: emailContent };
		}
		return emailContent;
	}
};

/**
 * the purpose of the function is to know if the email content contains at least one merge tag or not, return true if it contains at least one merge
 * tag, and false otherwise
 * @param {} emailContent
 */
const isEmailHasAtLeastOneMergeTag = (emailContent) => {
	let content = "";

	if (emailContent) {
		content = JSON.stringify(emailContent.json);
	} else {
		content = emailContent.html;
	}
	const unsubUrlPattern = /alt=['|"]\[UNSUBSCRIBE\]['|"]/i;
	return (
		content.match(unsubUrlPattern) ||
		content.includes("[UNSUBSCRIBE]") ||
		content.includes("[CLIENTS.ADDRESS]") ||
		content.includes("[CLIENTS.ADDRESS1]") ||
		content.includes("[CLIENTS.COUNTRY]") ||
		content.includes("[CLIENTS.CITY]") ||
		content.includes("[CLIENTS.POSTAL_CODE]") ||
		content.includes("[CLIENTS.PROVINCE]")
	);
};

const getInvalidFieldsInEmail = (emailContent) => {
	let issueCount = 0;
	let missingContent = [];
	let missingUnsubscribeContent = [];
	let content = "";

	if (emailContent.json) {
		content = JSON.stringify(emailContent.json);
	} else {
		content = emailContent.html;
	}

	if (!content.includes("[CLIENTS.ADDRESS]")) {
		let missingAddressElement = 0;
		let missingAddressContent = [];

		if (!content.includes("[CLIENTS.ADDRESS1]")) {
			missingAddressElement++;
			missingAddressContent.push("[CLIENTS.ADDRESS1]");
		}
		if (!content.includes("[CLIENTS.COUNTRY]")) {
			missingAddressElement++;
			missingAddressContent.push("[CLIENTS.COUNTRY]");
		}
		if (!content.includes("[CLIENTS.CITY]")) {
			missingAddressElement++;
			missingAddressContent.push("[CLIENTS.CITY]");
		}
		if (!content.includes("[CLIENTS.POSTAL_CODE]")) {
			missingAddressElement++;
			missingAddressContent.push("[CLIENTS.POSTAL_CODE]");
		}
		if (!content.includes("[CLIENTS.PROVINCE]")) {
			missingAddressElement++;
			missingAddressContent.push("[CLIENTS.PROVINCE]");
		}

		if (missingAddressElement === 5) {
			issueCount++;
			missingContent.push({
				tags: "[CLIENTS.ADDRESS]",
				string: <Translate value="app-campaigns-flow-validate-addres_msg" />,
				//url: props.addressKbUrl,
			});
		} else if (missingAddressElement !== 0) {
			issueCount += missingAddressElement;
			missingContent.push({
				tags: "".concat(missingAddressContent),
				string: <Translate value="app-campaigns-flow-validate-addres_msg" />,
				//url: props.addressKbUrl,
			});
		}
	}

	if (!content.includes("[UNSUBSCRIBE]")) {
		issueCount++;

		missingUnsubscribeContent.push({
			tags: "[UNSUBSCRIBE]",
			string: <Translate value="app-campaigns-flow-validate-unsub_msg" />,
			//url: props.unsubscribeKbUrl,
		});
	}
	return [...missingContent, ...missingUnsubscribeContent];
};

export {
	isEmailContentValid,
	getInvalidFieldsInEmail,
	isEmailHasAtLeastOneMergeTag,
	getUpdatedEmailContentWithMissingRequirements,
	hasCustomMergeTag,
};
