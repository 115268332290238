import { LISTED_FORMS as SET_LISTED_FORMS } from "./actions";
import React from "react";

let initialState = null;

export const reducer = (state = initialState, action) => {
	switch (action.type) {
	case SET_LISTED_FORMS:
		return action.listedForms;
		break;
	default:
		return state;
		break;
	}
};
