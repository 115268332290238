import {
	SINGLE_DELIVERY,
	SINGLE_DELIVERY_ENGAGEMENT_STATS,
	SINGLE_DELIVERY_SENT_ACTIVITY_STATS,
	SINGLE_DELIVERY_BOUNCED_ACTIVITY_STATS,
	SINGLE_DELIVERY_SPAM_ACTIVITY_STATS,
	SINGLE_DELIVERY_UNSUB_ACTIVITY_STATS,
	SINGLE_DELIVERY_CLICKED_ACTIVITY_STATS,
	SINGLE_DELIVERY_NOOPEN_ACTIVITY_STATS,
	SINGLE_DELIVERY_OPEN_ACTIVITY_STATS,
	SINGLE_DELIVERY_SUMMARY_ACTIVITY_STATS,
	RESET_ACTIVITY_STATS,
	SINGLE_CAMPAIGN_LIST,
	SINGLE_CAMPAIGN_SEGMENT,
	SINGLE_CAMPAIGN_REPORT,
	SINGLE_CAMPAIGN_CLICK_REPORT,
	LAST_FEW_CAMPAIGNS_REPORT,
} from "./actions";

let initialState = () => {
	return {
		delivery: null,
		deliveryEngagementStats: null,
		lastFewCampaignsReport: null,
		openActivity: null,
		sentToActivity: null,
		noOpenActivity: null,
		clickedActivity: null,
		unsubActivity: null,
		spamActivity: null,
		bouncedActivity: null,
		summaryActivity: null,
		campaignListReport: null,
		campaignSegment: null,
		campaignReport: null,
		clickReport: null,
	};
};

export const reducer = (state = initialState(), action) => {
	switch (action.type) {
	case SINGLE_DELIVERY:
		return { ...state, delivery: action.delivery };
	case SINGLE_DELIVERY_ENGAGEMENT_STATS:
		return {
			...state,
			deliveryEngagementStats: action.deliveryEngagementStats,
		};
	case SINGLE_DELIVERY_SENT_ACTIVITY_STATS:
		return { ...state, sentToActivity: action.sentToActivity };
	case SINGLE_DELIVERY_OPEN_ACTIVITY_STATS:
		return { ...state, openActivity: action.openActivity };
	case SINGLE_DELIVERY_BOUNCED_ACTIVITY_STATS:
		return { ...state, bouncedActivity: action.bouncedActivity };
	case SINGLE_DELIVERY_SPAM_ACTIVITY_STATS:
		return { ...state, spamActivity: action.spamActivity };
	case SINGLE_DELIVERY_UNSUB_ACTIVITY_STATS:
		return { ...state, unsubActivity: action.unsubActivity };
	case SINGLE_DELIVERY_CLICKED_ACTIVITY_STATS:
		return { ...state, clickedActivity: action.clickedActivity };
	case SINGLE_DELIVERY_NOOPEN_ACTIVITY_STATS:
		return { ...state, noOpenActivity: action.noOpenActivity };
	case SINGLE_DELIVERY_SUMMARY_ACTIVITY_STATS:
		return { ...state, summaryActivity: action.summaryActivity };
	case SINGLE_CAMPAIGN_LIST:
		return { ...state, campaignListReport: action.campaignListReport };
	case SINGLE_CAMPAIGN_SEGMENT:
		return { ...state, campaignSegment: action.campaignSegment };
	case SINGLE_CAMPAIGN_REPORT:
		return { ...state, campaignReport: action.campaignReport };
	case SINGLE_CAMPAIGN_CLICK_REPORT:
		return { ...state, clickReport: action.clickReport };
	case LAST_FEW_CAMPAIGNS_REPORT:
		return {
			...state,
			lastFewCampaignsReport: action.lastFewCampaignsReport,
		};
	case RESET_ACTIVITY_STATS:
		return {
			...state,
			openActivity: null,
			sentToActivity: null,
			noOpenActivity: null,
			clickedActivity: null,
			unsubActivity: null,
			spamActivity: null,
			bouncedActivity: null,
			summaryActivity: null,
		};
	default:
		return state;
	}
};
