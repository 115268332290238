const originalBrand = {
	client_config: {
		support: {
			email: "",
			phone: "",
		},
		segment_config: {
			disabled_event: [],
			enabled: false,
			id: "",
			server: "",
		},
		zendesk_config: {
			enabled: false,
			return_to: "",
		},
	},
	page_customization: {
		sign_in: {
			sign_up_link_disabled: false,
			sign_up_link_redirection: "",
		},
		sign_up: {
			disabled: false,
			sign_in_link_disabled: false,
			sign_in_link_redirection: "",
		},
	},
	images: {
		"ill-empty-automations":
			"https://app-brand-assets.s3.amazonaws.com/cakemail/illustrations/ill-empty-automations.png",
		favicon: "https://app-brand-assets.s3.amazonaws.com/default/favicon.svg",
		"ill-empty-forms":
			"https://app-brand-assets.s3.amazonaws.com/cakemail/illustrations/ill-empty-forms.png",
		"ill-empty-campaigns-drafts":
			"https://app-brand-assets.s3.amazonaws.com/cakemail/illustrations/ill-empty-campaigns-drafts.png",
		"favicon-mstile":
			"https://app-brand-assets.s3.amazonaws.com/default/favicon.svg",
		"ill-add-contacts":
			"https://app-brand-assets.s3.amazonaws.com/cakemail/illustrations/ill-add-contacts.png",
		"favicon-32":
			"https://app-brand-assets.s3.amazonaws.com/default/favicon.svg",
		"favicon-android-chrome-512":
			"https://app-brand-assets.s3.amazonaws.com/default/favicon.svg",
		"ill-create-email":
			"https://app-brand-assets.s3.amazonaws.com/cakemail/illustrations/ill-create-email.png",
		"ill-empty-campaigns-outbox":
			"https://app-brand-assets.s3.amazonaws.com/cakemail/illustrations/ill-empty-campaigns-outbox.png",
		"ill-empty-contacts":
			"https://app-brand-assets.s3.amazonaws.com/cakemail/illustrations/ill-empty-contacts.png",
		"favicon-16":
			"https://app-brand-assets.s3.amazonaws.com/default/favicon.svg",
		logo: "https://app-brand-assets.s3.amazonaws.com/default/default-logo.svg",
		"favicon-android-chrome-192":
			"https://app-brand-assets.s3.amazonaws.com/default/favicon.svg",
		"ill-empty-campaigns-sent":
			"https://app-brand-assets.s3.amazonaws.com/cakemail/illustrations/ill-empty-campaigns-sent.png",
		"favicon-apple-touch":
			"https://app-brand-assets.s3.amazonaws.com/cakemail/favicon/apple-touch-icon.png",
		"ill-empty-campaigns-templates":
			"https://app-brand-assets.s3.amazonaws.com/cakemail/illustrations/ill-empty-campaigns-templates.png",
		"ill-empty-listing":
			"https://app-brand-assets.s3.amazonaws.com/cakemail/illustrations/ill-empty-listing.png",
		"ill-workflow":
			"https://app-brand-assets.s3.amazonaws.com/cakemail/illustrations/ill-workflow.png",
	},
	theme: {
		base: {
			"background-color-2": "#F8F8F8",
			"body-font-color": "#444444",
			"error-brand-color": "#FF1E61",
			"primary-brand-color-darken": "#444444",
			"error-brand-color-darken": "#FD2E61",
			"heading-font":
				"aller, Lucida Grande,Lucida Sans Unicode,Lucida Sans,Geneva,Verdana,sans-serif",
			"primary-brand-color": "#000000",
			"secondary-brand-color-darken": "#1866CC",
			"text-font":
				"proxima-nova, Lucida Grande,Lucida Sans Unicode,Lucida Sans,Geneva,Verdana,sans-serif",
			"body-font-color-2": "#888888",
			"warning-brand-color": "#FF971E",
			"body-font-color-3": "var(--primary-brand-color)",
			"success-brand-color": "#09D266",
			"secondary-brand-color": "#1E7FFF",
			"background-color-1": "#FFFFFF",
		},
		advance: {
			Spinner: {
				"spinner-stroke-outer": "#1E7FFF",
			},
		},
	},
	url: {
		privacy_url: {
			en: "",
		},
		email_designer_help: {
			en: "",
		},
		schedule_help_url: {
			en: "",
		},
		anti_spam_url: {
			en: "",
		},
		protect_account_kb_url: {
			en: "",
		},
		unusubcribe_mergetag_kb_url: {
			en: "",
		},
		kb_boost_1: {
			en: "",
		},
		kb_boost_segment: {
			en: "",
		},
		kb_choose_sender_email: {
			en: "",
		},
		terms_url: {
			en: "",
		},
		address_mergetag_kb_url: {
			en: "",
		},
		help_url: {
			en: "",
		},
		/* Not used
		contact_us_url: {
			en : ""
		}
		*/
	},
};

export default originalBrand;
