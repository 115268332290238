import { I18n } from "react-redux-i18n";

const STEP_STATUS = {
	INACTIVE: "INACTIVE",
	ACTIVE: "ACTIVE",
	COMPLETED: "COMPLETED",
};
const PUBLIC_DOMAINS = [
	"gmail.com",
	"yahoo.com",
	"hotmail.com",
	"outlook.com",
	"aol.com",
	"msn.com",
	"apple.com",
	"me.com",
];

const SECURITY_PROTOCOLS = (domainName, resellerName) => {
	return [
		{
			title: "SPF",
			text: I18n._translate("app-account-domains-athenticate-spf-desc", {
				resellerName: resellerName,
			}),
			record: "",
			recordType: "TXT",
			recordName: domainName,
		},
		{
			title: "DKIM",
			text: I18n._translate("app-account-domains-athenticate-dkim-desc"),
			record: I18n._translate("app-account-domains-athenticate-dkim-record-1"),
			recordType: "CNAME",
			recordName: I18n._translate(
				"app-account-domains-athenticate-dkim-record-name-1",
				{ domainName: domainName }
			),
		},
		{
			title: "DMARC",
			text: I18n._translate("app-account-domains-athenticate-dmarc-desc"),
			record: I18n._translate(
				"app-account-domains-athenticate-dmarc-record-1",
				{ domainName: domainName }
			),
			recordType: "TXT",
			recordName: I18n._translate(
				"app-account-domains-athenticate-dmarc-record-name-1",
				{ domainName: domainName }
			),
		},
	];
};
const PROTOCOLS = ["dmarc", "dkim", "spf"];

export { STEP_STATUS, SECURITY_PROTOCOLS, PROTOCOLS, PUBLIC_DOMAINS };
