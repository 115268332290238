import {
	NEW_CONTACT_ATTRIBUTE as SETTINGS_NEW_CONTACT_ATTRIBUTE,
	PERMISSION_DATE as SETTINGS_PERMISSION_DATE,
	PREVIEW_DATA as SETTINGS_PREVIEW_DATA,
	SELECTED_EMAIL_TYPES as SETTINGS_SELECTED_EMAIL_TYPES,
	TICKET_ID as SETTINGS_TICKET_ID,
} from "./actions";

export let initialState = () => {
	return {
		permissionDate: null,
		previewData: [],
		selectedEmailTypes: [],
		newContactAttributes: [],
		ticketId: null,
	};
};

export const reducer = (state = initialState(), action) => {
	switch (action.type) {
	case SETTINGS_PERMISSION_DATE:
		return { ...state, permissionDate: action.date };
		break;
	case SETTINGS_SELECTED_EMAIL_TYPES:
		return { ...state, selectedEmailTypes: action.selectedEmailTypes };
		break;
	case SETTINGS_PREVIEW_DATA:
		return {
			...state,
			previewData: action.toClean
				? cleanEmptyData(action.previewData)
				: action.previewData,
		};
		break;
	case SETTINGS_NEW_CONTACT_ATTRIBUTE:
		return { ...state, newContactAttributes: action.newContactAttributes };
		break;
	case SETTINGS_TICKET_ID:
		return { ...state, ticketId: action.id };
		break;
	default:
		return state;
		break;
	}
};
