/**
 * This file is to map send email action , from and to BE structure, it should contain at least two functions, one that maps
 * from BE to FE structure, and the other one that maps from FE to BE structure
 */
import { ActionEvents } from "assets/js/scenes/automations/containers/editor/EventsConfigs";
import store from "assets/js/store";
import {
	getEdge,
	getEndCoachmarkNode,
	getNextTarget,
	getShadowsForStep,
} from "assets/js/scenes/automations/containers/editor/utils/utils";

const parseBESendEmailToFE = (automation, mainStep, subStep, targetId) => {
	const events = [];
	events.push({
		id: mainStep.name,
		type: "editorNode",
		position: {
			x: 0,
			y: 0,
		},
		data: {
			name: ActionEvents()[0].name,
			desc: ActionEvents()[0].desc,
			type: "action",
			tileTitle: "app-automation-editor_events_action-1_tileTitle",
			id: mainStep.name,
			actionName: mainStep.description,
			baseId: "sendEmail",
			shadows: getShadowsForStep(automation, mainStep.name),
			email: {
				content: {
					id: subStep.args.content.id,
					subject: subStep.args.subject,
					html: null,
					text: null,
					json: {},
					type: "bee",
				},
				sender: {
					id: subStep.args.sender?.id,
				},
				thumbnailUrl: subStep.args.thumbnail,
				initialElement: false,
			},
		},
	});
	const links = [];
	if (targetId) {
		links.push(getEdge("custom", mainStep.name, targetId));
	} else {
		const endCoachmark = getEndCoachmarkNode(0, 0);
		events.push(endCoachmark);
		links.push(getEdge("custom", mainStep.name, endCoachmark.id));
	}
	return {
		events: events,
		links: links,
	};
};
const parseFESendEmailToBE = (
	FEEvent,
	connectedEdges,
	events,
	nextIndex,
	selfIndex
) => {
	let shadows = [];
	let inTos =[];
	if (FEEvent.data.shadows) shadows = [...FEEvent.data.shadows];
	inTos = events.filter(e=>e.data.baseId=="loop").filter(e=>e.data.goTo==FEEvent.id);
	if(inTos.length>0) inTos=inTos.map(e=>`${e.id}_loop`);

	return {
		main: [
			{
				steps: [
					{
						name: `${FEEvent.id}`,
						action: "SubAutomation.Call",
						args: {
							sub_automation: FEEvent.id,
						},
						next: "end",
						shadows: shadows,
						inTos:inTos
					},
				],
			},
		],
		sub: [
			{
				name: FEEvent.id,
				params: [],
				steps: [
					{
						name: `${FEEvent.id}`,
						description: FEEvent.data.actionName,
						action: "CampaignEmail.Send",
						args: {
							content: {
								id: FEEvent.data.email.content.id,
							},
							sender: {
								id: FEEvent.data.email.sender.id,
							},
							subject: FEEvent.data.email.content.subject,
						},
						next: getNextTarget(connectedEdges, events),
					},
					{
						action: "Variable.Set",
						name: "set_output",
						args: {
							"input.data.outputs":
								"${list.concat(input.data.outputs," + FEEvent.id + "_output)}",
							[`input.data.step_trigger.${FEEvent.id}`]:
								"${" + FEEvent.id + "_output}",
						},
					},
				],
			},
		],
		reusableSubAutomations: ["emit_next_step"],
	};
};
export { parseBESendEmailToFE, parseFESendEmailToBE };
