import { omit } from "lodash";
import { reducer as SingleListReducer } from "./scenes/singleList/reducer";

import { SET_LISTS, RESET_LISTS } from "./actions";

let initialState = () => {
	return {
		lists: null,
	};
};

export const reducer = (state = initialState(), action) => {
	switch (action.type) {
	case SET_LISTS:
		return { ...state, lists: action.lists };
	case RESET_LISTS:
		return initialState();
	default:
		const rest = omit(state, Object.keys(initialState()));
		return {
			...state,
			singleList: SingleListReducer(rest.singleList, action),
		};
	}
};
