/**
 * This file is to map delay condition , from and to BE structure, it should contain at least two functions, one that maps
 * from BE to FE structure, and the other one that maps from FE to BE structure
 */
import { ConditionEvents } from "assets/js/scenes/automations/containers/editor/EventsConfigs";

import {
	getEdge,
	getEndCoachmarkNode,
	getNextTarget,
	convertSecondsToNearestUnit,
	convertTimeWithUnitToSeconds,
} from "./utils";

const parseBEDelayToFE = (mainStep, subStep, targetId) => {
	const events = [];
	events.push({
		id: mainStep.id,
		position: {
			x: 0,
			y: 0,
		},
		data: {
			name: ConditionEvents()[0].name,
			desc: ConditionEvents()[0].desc,
			type: "condition",
			tileTitle: "app-automation-editor_events_condition-1_tileTitle",
			id: mainStep.id,
			baseId: "delay",
			directValue: convertSecondsToNearestUnit(mainStep.args.params.delay),
			initialElement: false,
			delayName: subStep.name,
		},
		type: "editorNode",
	});
	const links = [];
	if (targetId) {
		links.push(getEdge("custom", mainStep.id, targetId));
	} else {
		const endCoachmark = getEndCoachmarkNode(0, 0);
		events.push(endCoachmark);
		links.push(getEdge("custom", mainStep.id, endCoachmark.id));
	}
	return {
		events: events,
		links: links,
	};
};
const parseFEDelayToBE = (FEEvent, connectedEdges, events) => {
	return {
		main: [
			{
				name: FEEvent.id,
				action: "SubAutomation.Call",
				pausable: true,
				args: {
					sub_automation: "sub_delay",
					params: {
						delay: convertTimeWithUnitToSeconds(FEEvent.data.directValue),
					},
				},
				next: getNextTarget(connectedEdges, events),
			},
		],
	};
};
const getSubDelay = () => {
	return {
		name: "sub_delay",
		params: ["delay"],
		steps: [
			{
				name: "delay_a_few_seconds",
				action: "Time.Sleep",
				pausable: true,
				args: {
					seconds: "${int(delay)}",
				},
			},
		],
	};
};
export { parseBEDelayToFE, parseFEDelayToBE, getSubDelay };
