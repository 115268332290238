export const ADD = "ADD_TASKBAR";
export const REPLACE = "REPLACE_TASKBAR";
export const DELETE = "REMOVE_TASKBAR";

export const addTaskBar = (taskBar, taskBarId) => {
	return {
		type: ADD,
		taskBar,
		taskBarId,
	};
};

export const replaceTaskBar = (index, taskBar) => {
	return {
		type: REPLACE,
		index,
		taskBar,
	};
};

export const deleteTaskBar = (index) => {
	return {
		type: DELETE,
		index,
	};
};
