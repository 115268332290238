import {
	COL_ORDER as TABLE_GRID_COL_ORDER,
	LISTED_SELECTED_CONTACTS as SET_SELECTED_CONTACTS,
	LISTED_UNSELECTED_CONTACTS as SET_UNSELECTED_CONTACTS,
	SORT_CHANGED as SET_SORT_CHANGED,
	RESET_TABLE_GRID_COL_ORDER,
	SELECT_ALL_CHANGED as SET_SELECT_ALL,
} from "./actions";
import React, { Component } from "react";
import { I18n, Translate } from "react-redux-i18n";

let initialState = () => {
	return {
		sortChanged: false,
		selectedContacts: [],
		selectAllChecked: false,
		tableColOrder: [
			{
				name: "email",
				isAttribute: false,
				i18n: <Translate value="app-general-email" />,
				type: "text",
			},
			{
				name: "subscribed_on",
				isAttribute: false,
				i18n: (
					<Translate value="app-contacts-segments-creation-DL-created_date" />
				),
				type: "timestamp",
			},
			{
				name: "status",
				isAttribute: false,
				i18n: <Translate value="app-contact-status" />,
				type: "text",
			},
		],
	};
};

export const reducer = (state = initialState(), action) => {
	switch (action.type) {
	case SET_SELECTED_CONTACTS:
		return { ...state, selectedContacts: action.selectedContacts };
		break;
	case SET_UNSELECTED_CONTACTS:
		return { ...state, unSelectedContacts: action.unSelectedContacts };
		break;
	case TABLE_GRID_COL_ORDER:
		return { ...state, tableColOrder: action.tableColOrder };
		break;
	case RESET_TABLE_GRID_COL_ORDER:
		return { ...state, tableColOrder: initialState().tableColOrder };
		break;
	case SET_SORT_CHANGED:
		return { ...state, sortChanged: action.sortChange };
		break;
	case SET_SELECT_ALL:
		return { ...state, selectAllChecked: action.selectAllChecked };
		break;
	default:
		return state;
		break;
	}
};
