import {
	DialogHandler,
	FullPageLoaderHandler,
	NotificationHandler,
	OverlayHandler,
	TaskBarHandler,
} from "cakemail-ui-components/lib/react";
import { clearBackToFlows } from "utils/urlUtils";

export function fakeBtnLoad(ref) {}

export function clearGlobalItems() {
	DialogHandler.clearQueue();
	DialogHandler.hide();
	NotificationHandler.clearQueue();
	NotificationHandler.hide();
	FullPageLoaderHandler.hide();
	OverlayHandler.hide();
	TaskBarHandler.hide();
	clearBackToFlows();
}
