import { listContactsOfList } from "assets/js/scenes/contacts/actions";
import { listListsService } from "assets/js/services/listService";

const SET_LISTS = "SET_LISTS";
const LIST_LISTS_FAILURE = "LIST_LISTS_FAILURE";
const RESET_LISTS = "RESET_LISTS";

export { SET_LISTS, LIST_LISTS_FAILURE, RESET_LISTS };

function setLists(lists) {
	return {
		type: SET_LISTS,
		lists,
	};
}

function resetLists() {
	return {
		type: RESET_LISTS,
	};
}

function listListsFailure(data) {
	return {
		type: LIST_LISTS_FAILURE,
	};
}

function listLists(
	accountId,
	successCallback,
	failureCallback,
	withContactsCount = false
) {
	return function (dispatch) {
		listListsService(
			{},
			(data) => {
				const lists = data;
				if (withContactsCount) {
					const promises = [];
					data.data.forEach((list) => {
						promises.push(
							dispatch(
								listContactsOfList(
									accountId,
									list.id,
									() => {},
									() => {}
								)
							)
						);
					});
					if (promises.length > 0) {
						return Promise.all(promises)
							.then((values) => {
								dispatch(setLists(data));
								if (successCallback) successCallback(data);
							})
							.catch(failureCallback);
					} else {
						dispatch(setLists(data));
						if (successCallback) successCallback(data);
					}
				} else {
					dispatch(setLists(data));
					if (successCallback) successCallback(data);
				}
			},
			(msg, response) => {
				dispatch(listListsFailure());
				if (failureCallback) failureCallback(msg, response);
			}
		);
	};
}

export { setLists, listLists, resetLists, listListsFailure };
