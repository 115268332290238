import { CAMPAIGNS } from "./actions";

let initialState = () => {
	return {
		campaigns: null,
	};
};

export const reducer = (state = initialState(), action) => {
	switch (action.type) {
	case CAMPAIGNS:
		return {
			...state,
			campaigns: action.campaigns,
		};
	default:
		return state;
	}
};
