const GOAL_ACTION = {
	CREATE: "CREATE",
	DELETE: "DELETE",
};

const GOAL_NAME = {
	CAMPAIGN: "goalCampaign",
};

export { GOAL_ACTION, GOAL_NAME };
