import {
	SET_SINGLE_FORM,
	SET_LOADING_FORM_GROUP,
	SET_FORM_ATTRIBUTES,
} from "./actions";

let initialState = () => {
	return {
		singleForm: null,
		loading: true,
		formAttributes: null,
	};
};

export const reducer = (state = initialState(), action) => {
	switch (action.type) {
	case SET_SINGLE_FORM:
		return { ...state, singleForm: action.singleForm };
	case SET_FORM_ATTRIBUTES:
		return { ...state, formAttributes: action.formAttributes };
	case SET_LOADING_FORM_GROUP:
		return { ...state, loading: action.loading };

	default:
		return state;
	}
};
