export const DELIVERABLES = "SET_DELIVERABLES";
export const DRAFT_DELIVERABLES = "SET_DRAFT_DELIVERABLES";
export const LISTED_DELIVERABLES_PER_PAGE = "SET_LISTED_DELIVERABLES_PER_PAGE";
export const LISTED_DELIVERABLES_PAGINATION =
	"SET_LISTED_DELIVERABLES_PAGINATION";

export const setDeliverables = (deliverables) => {
	return {
		type: DELIVERABLES,
		deliverables,
	};
};

export const setDraftDeliverables = (draftDeliverables) => {
	return {
		type: DRAFT_DELIVERABLES,
		draftDeliverables,
	};
};

export const setListedDeliverablesPerPage = (perPage) => {
	return {
		type: LISTED_DELIVERABLES_PER_PAGE,
		perPage,
	};
};

export const setListedDeliverablesPagination = (page) => {
	return {
		type: LISTED_DELIVERABLES_PAGINATION,
		page,
	};
};
