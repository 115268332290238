export const actionTypes = {
	CHANGE_OVERVIEW_DATA_RANGE: "CHANGE_OVERVIEW_DATA_RANGE",
	CHANGE_OVERVIEW_LOCATION: "CHANGE_OVERVIEW_LOCATION",
	SET_SUBSCRIBERS: "SET_SUBSCRIBERS",
	SET_CONFIRMED_SUBSCRIBERS: "SET_CONFIRMED_SUBSCRIBERS",
	SET_SUBSCRIBER_SOURCES: "SET_SUBSCRIBER_SOURCES",
	SET_SUBSCRIBER_INTERESTS: "SET_SUBSCRIBER_INTERESTS",
	SET_SUBSCRIBER_LANGUAGES: "SET_SUBSCRIBER_LANGUAGES",
	SET_SUBSCRIBER_MAP: "SET_SUBSCRIBER_MAP",
	SET_SUBSCRIBERS_LOADING: "SET_SUBSCRIBERS_LOADING",
	SET_CONFIRMED_SUBSCRIBERS_LOADING: "SET_CONFIRMED_SUBSCRIBERS_LOADING",
	SET_SUBSCRIBER_SOURCES_LOADING: "SET_SUBSCRIBER_SOURCES_LOADING",
	SET_SUBSCRIBER_INTERESTS_LOADING: "SET_SUBSCRIBER_INTERESTS_LOADING",
	SET_SUBSCRIBER_LANGUAGES_LOADING: "SET_SUBSCRIBER_LANGUAGES_LOADING",
	SET_SUBSCRIBER_MAP_LOADING: "SET_SUBSCRIBER_MAP_LOADING",
};

export const changeOverviewDateRange = (currentDateRange) => {
	return {
		type: actionTypes.CHANGE_OVERVIEW_DATA_RANGE,
		currentDateRange,
	};
};

export const changeOverviewLocation = (currentLocation) => {
	return {
		type: actionTypes.CHANGE_OVERVIEW_LOCATION,
		currentLocation,
	};
};

export const setSubscribers = ({ data, language, dateRange }) => {
	return {
		type: actionTypes.SET_SUBSCRIBERS,
		data,
		language,
		dateRange,
	};
};

export const setConfirmedSubscribers = ({ data, language, dateRange }) => {
	return {
		type: actionTypes.SET_CONFIRMED_SUBSCRIBERS,
		data,
		language,
		dateRange,
	};
};

export const setSubscriberSources = ({ data, language, dateRange }) => {
	return {
		type: actionTypes.SET_SUBSCRIBER_SOURCES,
		data,
		language,
		dateRange,
	};
};

export const setSubscriberInterests = ({ data, language, dateRange }) => {
	return {
		type: actionTypes.SET_SUBSCRIBER_INTERESTS,
		data,
		language,
		dateRange,
	};
};

export const setSubscriberLanguages = ({ data, language, dateRange }) => {
	return {
		type: actionTypes.SET_SUBSCRIBER_LANGUAGES,
		data,
		language,
		dateRange,
	};
};

export const setSubscriberMap = ({ data, language, dateRange, location }) => {
	return {
		type: actionTypes.SET_SUBSCRIBER_MAP,
		data,
		language,
		dateRange,
		location,
	};
};

export const setSubscribersLoading = (loading) => {
	return {
		type: actionTypes.SET_SUBSCRIBERS_LOADING,
		loading,
	};
};

export const setConfirmedSubscribersLoading = (loading) => {
	return {
		type: actionTypes.SET_CONFIRMED_SUBSCRIBERS_LOADING,
		loading,
	};
};

export const setSubscriberSourcesLoading = (loading) => {
	return {
		type: actionTypes.SET_SUBSCRIBER_SOURCES_LOADING,
		loading,
	};
};

export const setSubscriberInterestsLoading = (loading) => {
	return {
		type: actionTypes.SET_SUBSCRIBER_INTERESTS_LOADING,
		loading,
	};
};

export const setSubscriberLanguagesLoading = (loading) => {
	return {
		type: actionTypes.SET_SUBSCRIBER_LANGUAGES_LOADING,
		loading,
	};
};

export const setSubscriberMapLoading = (loading) => {
	return {
		type: actionTypes.SET_SUBSCRIBER_MAP_LOADING,
		loading,
	};
};
