/**
 * This file is responsible for handling the service calls for the hidden calls
 */
import store from "../store";
import React from "react";
import { callAPI, METHODS, GATEWAY_PROXY } from "utils/apiUtils";
import { DialogHandler } from "cakemail-ui-components/lib/react";
import { Translate } from "react-redux-i18n";
import { getUrlsBasedOnLanguage } from "utils/languages";

/*
=========== ZENDESK SERVICES ===========
 */

export function sendZendeskRequestService(
	options = { content: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + "/requests",
			body: options.content,
			query: { account_id: store.getState().global.account.id },
		},
		successCallback,
		failureCallback
	);
}

export function connectToZendeskSupport(
	options = { isSupport: true },
	successCallback = () => {},
	failureCallback = () => {}
) {
	if (options.isSupport) {
		const state = store.getState();
		const zendeskReturnTo =
			state?.brand?.client_config?.zendesk_config?.return_to;
		const helpUrl = getUrlsBasedOnLanguage(
			state.brand?.url?.help_url,
			"help_url",
			state.global.language
		);

		callAPI(
			{
				url: GATEWAY_PROXY + "/zendesk",
				query: { account_id: store.getState().global?.account?.id || null },
			},
			(data) => {
				let url = data?.body?.url;
				if (zendeskReturnTo) {
					url += "&return_to=" + encodeURIComponent(zendeskReturnTo);
				}
				window.open(url, "_blank");
			},
			(msg, error) => {
				if (helpUrl) {
					window.open(helpUrl, "_blank");
				} else {
					DialogHandler.show({
						type: "error",
						title: <Translate value="app-auth-support-error" />,
					});
				}
				failureCallback(msg, error);
			}
		);
	} else {
		callAPI(
			{
				url: GATEWAY_PROXY + "/zendesk",
				query: { account_id: store.getState().global.account.id },
			},
			successCallback,
			failureCallback
		);
	}
}

/*
=========== STRIPE BILLING PORTAL ===========
 */

/**
 * deprecated Used only in UI.js for compatibility
 */

export function openStripeBillingPortal(
	options = { returnToUrl: "", parentAccountId: "", currency: "", price: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	//TODO Remove if when Courrielleur plans are ready to be switched to new system
	let url = `${GATEWAY_PROXY}/billing/portal/v2/${options.price}/url`;
	if (options.parentAccountId === 205)
		url = GATEWAY_PROXY + "/billing/portal";

	callAPI(
		{
			url: url,
			method: METHODS.get,
			query: options,
		},
		successCallback,
		failureCallback
	);
}

export function openStripeBillingCheckout(
	options = { returnToUrl: "", price: "", parentAccountId: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + "/billing/checkout",
			method: METHODS.get,
			query: options,
		},
		successCallback,
		failureCallback
	);
}

export function getBillingSelfService(
	options = { portal_return_url: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + "/billing/self",
			method: METHODS.get,
			query: options,
		},
		successCallback,
		failureCallback
	);
}

export function getBillingCustomer(
	options = { parentAccountId: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + "/billing/customer",
			method: METHODS.get,
			query: options,
		},
		successCallback,
		failureCallback
	);
}

export function getBillingProduct(
	options = { parentAccountId: null, id: null },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + "/billing/products/" + options.id,
			method: METHODS.get,
			query: {
				parentAccountId:
					options.parentAccountId ||
					store.getState().brand?.config?.partner_account_id,
			},
		},
		successCallback,
		failureCallback
	);
}

/*
=========== METADATA SERVICE ===========
 */

export function createAccountMetaDataService(
	options = {
		id: "",
		name: "",
		content: {},
	},
	successCallback = () => {},
	failureCallback = () => {}
) {
	const accountId = store.getState().global.account.id;
	callAPI(
		{
			url: GATEWAY_PROXY + `/accounts/${options.id || accountId}/metadata/${options.name}`,
			body: {data : options.content},
			query: { account_id: accountId },
		},
		successCallback,
		failureCallback
	);
}

export function updateAccountMetaDataService(
	options = {
		id: "",
		name: "",
		content: {},
	},
	successCallback = () => {},
	failureCallback = () => {}
) {
	const accountId = store.getState().global.account.id;
	callAPI(
		{
			url: GATEWAY_PROXY + `/accounts/${options.id || accountId}/metadata/${options.name}`,
			body: options.content,
			method : METHODS.put,
			query: { account_id: accountId },
		},
		successCallback,
		failureCallback
	);
}

export function getAccountMetaDataService(
	options = { name: null, id: null },
	successCallback = () => {},
	failureCallback = () => {}
) {
	const accountId = store.getState().global.account.id;
	callAPI(
		{
			url: GATEWAY_PROXY + `/accounts/${options.id || accountId}/metadata/${options.name}`,
			method: METHODS.get,
			query: { account_id: accountId },
		},
		successCallback,
		failureCallback
	);
}

export function createUserMetaDataService(
	options = {
		id: "",
		name: "",
		content: {},
	},
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + `/users/${options.id}/metadata/${options.name}`,
			body: {data : options.content},
			query: { account_id: store.getState().global.account.id },
		},
		successCallback,
		failureCallback
	);
}

export function getUserMetaDataService(
	options = { name: null, id: null },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + `/users/${options.id}/metadata/${options.name}`,
			method: METHODS.get,
		},
		successCallback,
		failureCallback
	);
}

export function deleteUserMetaDataService(
	options = { name: null, id: null },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + `/users/${options.id}/metadata/${options.name}`,
			method: METHODS.delete,
		},
		successCallback,
		failureCallback
	);
}

/*
=========== TOKEN SERVICE ===========
 */
export function getBeeTokenService(
	options = { editorType: "email_designer" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	const { editorType } = options;
	callAPI(
		{
			url: GATEWAY_PROXY + "/bee/token",
			method: METHODS.post,
			body: {
				type: "email_designer",
			},
		},
		successCallback,
		failureCallback
	);
}
