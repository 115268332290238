import {
	IMPORTATION_CONFIG as SET_IMPORTATION_CONFIG,
	IMPORTATION_FILE,
	IMPORTATION_DELIMITER,
} from "./actions";

export let initialState = () => {
	return {
		file: null,
		delimiter: null,
		importationConfig: {
			configuration: {
				emptyAsValue: false,
				section: null,
				properties: {},
				excluded: {
					names: [],
					indexes: [],
				},
			},
			source: {
				header: true,
				separator: "comma",
				type: "CSVImportSource",
			},
		},
	};
};

export const reducer = (state = initialState(), action) => {
	switch (action.type) {
	case SET_IMPORTATION_CONFIG:
		return { ...state, importationConfig: action.importationConfig };
		break;
	case IMPORTATION_FILE:
		return { ...state, file: action.file };
		break;
	case IMPORTATION_DELIMITER:
		return { ...state, delimiter: action.delimiter };
		break;
	default:
		return state;
		break;
	}
};
