/**
 * This file is to map delay condition , from and to BE structure, it should contain at least two functions, one that maps
 * from BE to FE structure, and the other one that maps from FE to BE structure
 */
import { ActionEvents } from "assets/js/scenes/automations/containers/editor/EventsConfigs";
import store from "assets/js/store";
import {
	convertSecondsToNearestUnit,
	convertTimeWithUnitToSeconds,
} from "assets/js/scenes/automations/containers/editor/utils/utils";
import {
	getEdge,
	getEndCoachmarkNode,
	getNextTarget,
	getShadowsForStep,
} from "assets/js/scenes/automations/containers/editor/utils/utils";

const parseBEUpdateContactToFE = (automation, mainStep, subStep, targetId) => {
	const events = [];
	events.push({
		id: mainStep.name,
		position: {
			x: 0,
			y: 0,
		},
		data: {
			name: ActionEvents()[1].name,
			desc: ActionEvents()[1].desc,
			type: "action",
			tileTitle: "update contact",
			id: mainStep.name,
			baseId: "updateContact",
			customAttributes: mainStep.args.custom_attributes,
			initialElement: false,

			shadows: getShadowsForStep(automation, mainStep.name),
		},
		type: "editorNode",
	});
	const links = [];
	if (targetId) {
		links.push(getEdge("custom", mainStep.name, targetId));
	} else {
		const endCoachmark = getEndCoachmarkNode(0, 0);
		events.push(endCoachmark);
		links.push(getEdge("custom", mainStep.name, endCoachmark.id));
	}
	return {
		events: events,
		links: links,
	};
};
const parseFEUpdateContactToBE = (FEEvent, connectedEdges, events) => {
	let shadows = [];
	let inTos =[];
	if (FEEvent.data.shadows) shadows = [...FEEvent.data.shadows];
	inTos = events.filter(e=>e.data.baseId=="loop").filter(e=>e.data.goTo==FEEvent.id);
	if(inTos.length>0) inTos=inTos.map(e=>`${e.id}_loop`);
	return {
		main: [
			{
				steps: [
					{
						name: `${FEEvent.id}`,
						action: "SubAutomation.Call",
						args: {
							sub_automation: FEEvent.id,
						},
						next: "end",
						shadows: shadows,
						inTos:inTos
					},
				],
			},
		],
		sub: [
			{
				name: FEEvent.id,
				params: [],
				steps: [
					{
						name: FEEvent.id,

						action: "Contact.SetCustomAttribute",
						args: {
							custom_attributes: FEEvent.data.customAttributes,
						},
						next: getNextTarget(connectedEdges, events),
					},
				],
			},
		],
	};
};

export { parseBEUpdateContactToFE, parseFEUpdateContactToBE };
