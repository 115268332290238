import {
	IMPORT_ID as SET_IMPORT_ID,
	IMPORTING as IS_IMPORTING,
	RESET as RESET_IMPORT,
	RESET_CONFIG as RESET_IMPORT_CONFIG,
} from "./actions";
import {
	initialState as configInitialState,
	reducer as ConfigurationReducer,
} from "./data/configuration/reducer";
import {
	initialState as headerInitialState,
	reducer as HeaderReducer,
} from "./data/heading/reducer";
import {
	initialState as settingsInitialState,
	reducer as SettingsReducer,
} from "./data/settings/reducer";

import _ from "lodash";

let initialState = () => {
	return {
		importId: null,
		isImporting: false,
	};
};

export const reducer = (state = initialState(), action) => {
	switch (action.type) {
	case SET_IMPORT_ID:
		return { ...state, importId: action.id };
		break;
	case IS_IMPORTING:
		return { ...state, isImporting: action.isImporting };
		break;
	case RESET_IMPORT:
		return {
			importId: null,
			isImporting: false,
			importationConfig: configInitialState(),
			heading: headerInitialState(),
			settings: settingsInitialState(),
		};
		break;
	case RESET_IMPORT_CONFIG:
		return {
			importId: null,
			isImporting: false,
			importationConfig: configInitialState(),
			heading: headerInitialState(),
		};
		break;
	default:
		const rest = _.omit(state, Object.keys(initialState()));
		return {
			...state,
			importationConfig: ConfigurationReducer(rest.importationConfig, action),
			heading: HeaderReducer(rest.heading, action),
			settings: SettingsReducer(rest.settings, action),
		};
		break;
	}
};
