/**
 * This file is responsible for handling the service calls for the reports
 */
import store from "../store";
import { callAPI, chameau, GATEWAY_PROXY, METHODS } from "utils/apiUtils";
import { SEGMENT_EVENTS, trackSegmentEvent } from "../const/SegmentEvents";

export function getCampaignStatsService(
	options = { id: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + `/reports/campaigns/${options.id}`,
			method: METHODS.get,
			query: { account_id: store.getState().global.account.id },
		},
		successCallback,
		failureCallback
	);
}

export function getActionStatsService(
	options = { actionId: "", automationId: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url:
				GATEWAY_PROXY +
				`/reports/workflows/${options.workflowId}/actions/${options.actionId}`,
			method: METHODS.get,
			query: { account_id: store.getState().global.account.id },
		},
		successCallback,
		failureCallback
	);
}

export function getCampaignLinksReportService(
	options = { campaignId: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	const { campaignId, ...restOptions } = options;
	callAPI(
		{
			url: GATEWAY_PROXY + `/reports/campaigns/${campaignId}/links`,
			method: METHODS.get,
			query: {
				...chameau(restOptions),
				account_id: store.getState().global.account.id,
			},
		},
		successCallback,
		failureCallback
	);
}

export function getListStatsService(
	options = { id: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	const { id, ...rest } = options;
	callAPI(
		{
			url: GATEWAY_PROXY + `/reports/lists/${id}`,
			method: METHODS.get,
			query: { account_id: store.getState().global.account.id },
		},
		(data) => {
			trackSegmentEvent(SEGMENT_EVENTS.LIST_DELETED, data);
			successCallback(data);
		},
		failureCallback
	);
}
