import {
	DELIVERIES as SET_DELIVERIES,
	OUTBOX_DELIVERIES as SET_OUTBOX_DELIVERIES,
	SENT_DELIVERIES as SET_SENT_DELIVERIES,
	LISTED_DELIVERIES_PER_PAGE as SET_LISTED_DELIVERIES_PER_PAGE,
	LISTED_DELIVERIES_PAGINATION as SET_LISTED_DELIVERIES_PAGINATION,
} from "./actions";

let initialState = () => {
	return {
		deliveries: null,
		outboxDeliveries: null,
		sentDeliveries: null,
		perPage: 25,
		page: 1,
	};
};

export const reducer = (state = initialState(), action) => {
	switch (action.type) {
	case SET_DELIVERIES:
		return { ...state, deliveries: action.deliveries };
		break;
	case SET_OUTBOX_DELIVERIES:
		return { ...state, outboxDeliveries: action.outboxDeliveries };
		break;
	case SET_SENT_DELIVERIES:
		return { ...state, sentDeliveries: action.sentDeliveries };
		break;
	case SET_LISTED_DELIVERIES_PER_PAGE:
		return { ...state, perPage: action.perPage, page: initialState().page };
		break;
	case SET_LISTED_DELIVERIES_PAGINATION:
		return { ...state, page: action.page };
	default:
		return state;
		break;
	}
};
