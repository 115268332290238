import { SINGLE_USER as SET_SINGLE_USERS, USERS as SET_USERS } from "./actions";

let initialState = () => {
	return {
		users: null,
		singleUser: null,
	};
};

export const reducer = (state = initialState(), action) => {
	switch (action.type) {
	case SET_USERS:
		return { ...state, users: action.users };
	case SET_SINGLE_USERS:
		return { ...state, singleUser: action.singleUser };
	default:
		return state;
	}
};
