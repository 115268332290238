/**
 * This file contains all the events configurations required by the editor including : default data for any new event, event name and desc..etc
 */
import { Translate, I18n } from "react-redux-i18n";
import React from "react";
import { beeTemplate } from "assets/js/scenes/campaigns/scenes/campaignFlow/data/baseTemplates";
import { EDITORS } from "assets/js/const/Campaign";

const startingFromScratchInitialElements = [
	{
		id: "1",
		type: "editorNode",
		data: {
			text: "1",
			initialElement: true,
			type: "helper_trigger",
			selected: true,
		},
		draggable: false,
		position: { x: 350, y: 0 },
	},
];
const TriggerEvents = () => [
	{
		name: I18n._translate("app-automation-editor_events_trigger-1_name"),
		desc: I18n._translate("app-automation-editor_events_trigger-1_desc"),
		type: "trigger",
		tileTitle: "app-automation-editor_events_trigger-1_tileTitle",
		id: "subscribed",
		// defaultValues:{
		// 	listId: store.getState().lists.lists?.data[0]?.id,
		// }
	},
];
const ActionEvents = (senderId) => [
	{
		name: I18n._translate("app-automation-editor_events_action-1_name"),
		desc: I18n._translate("app-automation-editor_events_action-1_desc"),
		type: "action",
		tileTitle: "app-automation-editor_events_action-1_tileTitle",
		id: "sendEmail",
		defaultValues: {
			isEmailEdited: false,
			thumbnailUrl:
				"https://screenshots2.wbsrvcx.com/3cb941e1b3b84fcfe7a8d5978cc1c9d4/400/480/https%3A%2F%2Fapi.cakemail.dev%2Fworkflows%2FFBmzHVrr1kGydjhnq89n%2Factions%2FcktenTUClmJMBG6OUSUS%2Frender%3Ftoken%3D02c1ce2a5a0cd5043913a91535767bc8%26account_id%3D870702?bust=1651102578",
			email: {
				name: I18n._translate("app-campaigns-flow-template_essentials"),
				content: {
					type: EDITORS.BEE,
					subject: I18n._translate(
						"app-automations-action-default_subjectLine"
					),
					id: "scratchBee1",
					json: beeTemplate(),
				},
			},
			senderId: senderId,
			actionName: I18n._translate("app-automations-action-default_name"),
		},
	},
	{
		name: I18n._translate("app-automation-editor_events_action-2_name") ,
		desc: I18n._translate("app-automation-editor_events_action-2_desc"),
		type: "action",
		tileTitle: "app-automation-editor_events_action-2_tileTitle" ,
		id: "loop",
		defaultValues: {
			waitFor:0,
			loopFor:1,
		},
	},
// 	{
// 		name: "Update contact",
// 		desc: "update contact",
// 		type: "action",
// 		tileTitle: "Update contact",
// 		id: "updateContact",
// 		defaultValues: {},
// 	},
// 	{
// 		name: "Delete contact from a list",
// 		desc: "Delete contact from a list",
// 		type: "action",
// 		tileTitle: "Delete contact from a list",
// 		id: "deleteContact",
// 		defaultValues: {},
// 	},
// 	{
// 		name: "Add contact to a list",
// 		desc: "Add contact to a list",
// 		type: "action",
// 		tileTitle: "Add contact to a list",
// 		id: "addContact",
// 		defaultValues: {},
// 	},
 ];
const ConditionEvents = () => [
	{
		name: I18n._translate("app-automation-editor_events_condition-1_name"),
		desc: I18n._translate("app-automation-editor_events_condition-1_desc"),
		type: "condition",
		tileTitle: "app-automation-editor_events_condition-1_tileTitle",
		id: "delay",
		defaultValues: {
			delay: "1 Seconds",
		},
	},
	{
		name: I18n._translate("app-automation-editor_events_condition-2_name"),
		desc: I18n._translate("app-automation-editor_events_condition-2_desc"),
		type: "condition",
		tileTitle: "app-automation-editor_events_condition-2_tileTitle",
		id: "ifElse",
		defaultValues: {
			elseCondition: "and",
		},
		conditions: { operator: "and", conditions: [] },
		configs: {
			noOfLinks: 2,
			label1: "Yes",
			label2: "No",
		},
	},
	{
		name: I18n._translate("app-automation-editor_events_condition-3_name"),
		desc: I18n._translate("app-automation-editor_events_condition-3_desc"),
		type: "condition",
		tileTitle: "50/50 split, randomly split your audience",
		percentage: {
			primary: 50,
			sec: 50,
		},
		id: "split50",
		configs: {
			noOfLinks: 2,
			label1: "A",
			label2: "B",
		},
	},
];
const triggerNewNode = {
	id: "1",
	type: "editorNode",
	data: {
		text: "1",
		initialElement: true,
		type: "trigger",
		selected: true,
	},
	draggable: false,
	position: { x: 350, y: 0 },
};
export {
	startingFromScratchInitialElements,
	TriggerEvents,
	triggerNewNode,
	ConditionEvents,
	ActionEvents,
};
