import store from "../../../../../../../store";

const GET_STATS = () => {
	return {
		name: "sub_get_stats",
		params: ["trigger_id"],
		steps: [
			{
				name: "check_if_exists",
				action: "Condition.Switch",
				args: {
					switch: [
						{
							condition: {
								eq: {
									var: "${trigger_id in input.data.outputs}",
									value: true,
								},
							},
							steps: [
								{
									name: "clog",
									action: "CampaignEmail.CountLogs",
									args: {
										type: "clickthru",
										trigger_id: "${trigger_id}",
									},
								},
								{
									name: "olog",
									action: "CampaignEmail.CountLogs",
									args: {
										type: "opened",
										trigger_id: "${trigger_id}",
									},
								},
								{
									name: "slog",
									action: "CampaignEmail.CountLogs",
									args: {
										type: "sent",
										trigger_id: "${trigger_id}",
									},
								},
								{
									name: "return",
									action: "Return",
									return: {
										c: "${int(clog_output.body.data.count.unique)}",
										o: "${int(olog_output.body.data.count.unique)}",
										s: "${int(slog_output.body.data.count.unique)}",
									},
								},
							],
						},
					],
				},
			},
			{
				name: "done",
				action: "Return",
				return: {
					c: 0,
					o: 0,
					s: 0,
				},
			},
		],
	};
};
const EVALUATE_ALL_CONDITION_ON_EMAILS = () => {
	return {
		name: "all_action_emails",
		params: ["trigger_ids", "type"],
		steps: [
			{
				name: "for_loop",
				action: "For.Loop",
				args: {
					value: "trigger_id",
					in: "${trigger_ids}",
				},
				steps: [
					{
						name: "get_logs",
						action: "CampaignEmail.CountLogs",
						args: {
							type: "${type}",
							trigger_id: "${trigger_id}",
						},
					},
					{
						name: "isDone",
						action: "Condition.Switch",
						args: {
							switch: [
								{
									condition: {
										lt: {
											var: "${int(get_logs_output.body.data.count.unique)}",
											value: 1,
										},
									},
									steps: [
										{
											name: "not_done",
											action: "Return",
											return: false,
										},
									],
								},
							],
						},
					},
				],
			},
			{
				name: "done",
				action: "Return",
				return: true,
			},
		],
	};
};
const EVALUATE_ALL_NOT_CONDITION_ON_EMAILS = () => {
	return {
		name: "all_not_action_emails",
		params: ["trigger_ids", "type"],
		steps: [
			{
				name: "for_loop",
				action: "For.Loop",
				args: {
					value: "trigger_id",
					in: "${trigger_ids}",
				},
				steps: [
					{
						name: "get_logs",
						action: "CampaignEmail.CountLogs",
						args: {
							type: "${type}",
							trigger_id: "${trigger_id}",
						},
					},
					{
						name: "isDone",
						action: "Condition.Switch",
						args: {
							switch: [
								{
									condition: {
										ge: {
											var: "${int(get_logs_output.body.data.count.unique)}",
											value: 1,
										},
									},
									steps: [
										{
											name: "not_done",
											action: "Return",
											return: false,
										},
									],
								},
							],
						},
					},
				],
			},
			{
				name: "done",
				action: "Return",
				return: true,
			},
		],
	};
};
const EVALUATE_ALL_CONDITION_ON_CONTACT_TEXT = () => {
	return {
		name: "evaluate_all_condition_on_text",
		params: ["values", "attrVal"],
		steps: [
			{
				name: "for_loop",
				action: "For.Loop",
				args: {
					value: "val",
					in: "${values}",
				},
				steps: [
					{
						name: "isDone",
						action: "Condition.Switch",
						args: {
							switch: [
								{
									condition: {
										eq: {
											var: "${text.match_regex(attrVal,val)}",
											value: false,
										},
									},
									steps: [
										{
											name: "not_done",
											action: "Return",
											return: false,
										},
									],
								},
							],
						},
					},
				],
			},
			{
				name: "done",
				action: "Return",
				return: true,
			},
		],
	};
};
const EVALUATE_ALL_NOT_CONDITION_ON_CONTACT_TEXT = () => {
	return {
		name: "evaluate_all_not_condition_on_text",
		params: ["values", "attrVal"],
		steps: [
			{
				name: "for_loop",
				action: "For.Loop",
				args: {
					value: "val",
					in: "${values}",
				},
				steps: [
					{
						name: "isDone",
						action: "Condition.Switch",
						args: {
							switch: [
								{
									condition: {
										eq: {
											var: "${text.match_regex(attrVal,val)}",
											value: true,
										},
									},
									steps: [
										{
											name: "not_done",
											action: "Return",
											return: false,
										},
									],
								},
							],
						},
					},
				],
			},
			{
				name: "done",
				action: "Return",
				return: true,
			},
		],
	};
};
const EMIT_EVETS = () => {
	return {
		name: "emit_next_step",
		params: ["step", "delay"],
		steps: [
			{
				name: "self_loop_emit",
				action: "CustomEvent.Emit",
				pausable: true,
				args: {
					delay: "${int(delay)}",
					customevent_id:
						store.getState().automations.activeAutomation?.customevent_id,
					payload: {
						step: "${step}",
						contact: "${input.data.contact}",
						outputs: "${input.data.outputs}",
						step_trigger: "${input.data.step_trigger}",
						loops:"${input.data.loops}",
					},
				},
			},
		],
	};
};
const GET_LAST_5_OF_LIST = () => {
	return {
		params: [],
		name: "get_last_5_of_list",
		steps: [
			{
				name: "get_list_len",
				action: "Variable.Set",
				args: {
					triggers_len: "${len(input.data.outputs)}",
				},
			},
			{
				action: "Condition.Switch",
				name: "slice_list",
				args: {
					switch: [
						{
							condition: {
								le: {
									value: 5,
									var: "${triggers_len}",
								},
							},
							steps: [
								{
									return: "${input.data.outputs}",
									name: "return_all",
									action: "Return",
								},
							],
						},
						{
							condition: {
								ge: {
									value: 5,
									var: "${triggers_len}",
								},
							},
							name: "return",
							steps: [
								{
									action: "Return",
									return: [
										"${input.data.outputs[triggers_len - 5]}",
										"${input.data.outputs[triggers_len - 4]}",
										"${input.data.outputs[triggers_len - 3]}",
										"${input.data.outputs[triggers_len - 2]}",
										"${input.data.outputs[triggers_len - 1]}",
									],
									name: "return",
								},
							],
						},
					],
				},
			},
		],
	};
};
const GET_MAP_LIST_VALUE = () => {
	return {
		name: "get_value_list_key",
		params: ["listItems", "key"],
		steps: [
			{
				name: "for_loop",
				action: "For.Loop",
				args: {
					value: "item",
					in: "${listItems}",
				},
				steps: [
					{
						name: "isDone",
						action: "Condition.Switch",
						args: {
							switch: [
								{
									condition: {
										eq: {
											var: "${item.name}",
											value: "${key}",
										},
									},
									steps: [
										{
											name: "done",
											action: "Return",
											return: "${item.value}",
										},
									],
								},
							],
						},
					},
				],
			},
			{
				name: "not_done",
				action: "Return",
				return: "",
			},
		],
	};
};
const EVALUATE_ALL_GT_CONDITION_ON_CONTACT_NUMBER = () => {
	return {
		name: "evaluate_all_gt_condition_number",
		params: ["values", "attrVal"],
		steps: [
			{
				name: "for_loop",
				action: "For.Loop",
				args: {
					value: "val",
					in: "${values}",
				},
				steps: [
					{
						name: "isDone",
						action: "Condition.Switch",
						args: {
							switch: [
								{
									condition: {
										lt: {
											var: " ${attrVal}",
											value: "${val}",
										},
									},
									steps: [
										{
											name: "not_done",
											action: "Return",
											return: false,
										},
									],
								},
							],
						},
					},
				],
			},
			{
				name: "done",
				action: "Return",
				return: true,
			},
		],
	};
};
const EVALUATE_ALL_NOT_GT_CONDITION_ON_CONTACT_NUMBER = () => {
	return {
		name: "evaluate_all_not_gt_condition_number",
		params: ["values", "attrVal"],
		steps: [
			{
				name: "for_loop",
				action: "For.Loop",
				args: {
					value: "val",
					in: "${values}",
				},
				steps: [
					{
						name: "isDone",
						action: "Condition.Switch",
						args: {
							switch: [
								{
									condition: {
										gt: {
											var: " ${attrVal}",
											value: "${val}",
										},
									},
									steps: [
										{
											name: "not_done",
											action: "Return",
											return: false,
										},
									],
								},
							],
						},
					},
				],
			},
			{
				name: "done",
				action: "Return",
				return: true,
			},
		],
	};
};
const EVALUATE_ALL_LT_CONDITION_ON_CONTACT_NUMBER = () => {
	return {
		name: "evaluate_all_lt_condition_number",
		params: ["values", "attrVal"],
		steps: [
			{
				name: "for_loop",
				action: "For.Loop",
				args: {
					value: "val",
					in: "${values}",
				},
				steps: [
					{
						name: "isDone",
						action: "Condition.Switch",
						args: {
							switch: [
								{
									condition: {
										gt: {
											var: " ${int(attrVal)}",
											value: "${int(val)}",
										},
									},
									steps: [
										{
											name: "not_done",
											action: "Return",
											return: false,
										},
									],
								},
							],
						},
					},
				],
			},
			{
				name: "done",
				action: "Return",
				return: true,
			},
		],
	};
};
const EVALUATE_ALL_NOT_LT_CONDITION_ON_CONTACT_NUMBER = () => {
	return {
		name: "evaluate_all_not_lt_condition_number",
		params: ["values", "attrVal"],
		steps: [
			{
				name: "for_loop",
				action: "For.Loop",
				args: {
					value: "val",
					in: "${values}",
				},
				steps: [
					{
						name: "isDone",
						action: "Condition.Switch",
						args: {
							switch: [
								{
									condition: {
										lt: {
											var: " ${int(attrVal)}",
											value: "${int(val)}",
										},
									},
									steps: [
										{
											name: "not_done",
											action: "Return",
											return: false,
										},
									],
								},
							],
						},
					},
				],
			},
			{
				name: "done",
				action: "Return",
				return: true,
			},
		],
	};
};
export {
	GET_STATS,
	EVALUATE_ALL_CONDITION_ON_EMAILS,
	EVALUATE_ALL_NOT_CONDITION_ON_EMAILS,
	EMIT_EVETS,
	GET_LAST_5_OF_LIST,
	EVALUATE_ALL_CONDITION_ON_CONTACT_TEXT,
	GET_MAP_LIST_VALUE,
	EVALUATE_ALL_NOT_CONDITION_ON_CONTACT_TEXT,
	EVALUATE_ALL_GT_CONDITION_ON_CONTACT_NUMBER,
	EVALUATE_ALL_NOT_GT_CONDITION_ON_CONTACT_NUMBER,
	EVALUATE_ALL_LT_CONDITION_ON_CONTACT_NUMBER,
	EVALUATE_ALL_NOT_LT_CONDITION_ON_CONTACT_NUMBER,
};
const MAPPED = {
	sub_get_stats: GET_STATS,
	all_action_emails: EVALUATE_ALL_CONDITION_ON_EMAILS,
	all_not_action_emails: EVALUATE_ALL_NOT_CONDITION_ON_EMAILS,
	emit_next_step: EMIT_EVETS,
	get_last_5_of_list: GET_LAST_5_OF_LIST,
	evaluate_all_condition_on_text: EVALUATE_ALL_CONDITION_ON_CONTACT_TEXT,
	evaluate_all_not_condition_on_text:
		EVALUATE_ALL_NOT_CONDITION_ON_CONTACT_TEXT,
	evaluate_all_gt_condition_number: EVALUATE_ALL_GT_CONDITION_ON_CONTACT_NUMBER,
	evaluate_all_not_gt_condition_number:
		EVALUATE_ALL_NOT_GT_CONDITION_ON_CONTACT_NUMBER,
	evaluate_all_lt_condition_number: EVALUATE_ALL_LT_CONDITION_ON_CONTACT_NUMBER,
	evaluate_all_not_lt_condition_number:
		EVALUATE_ALL_NOT_LT_CONDITION_ON_CONTACT_NUMBER,
	get_map_list_value: GET_MAP_LIST_VALUE,
};
export { MAPPED };
