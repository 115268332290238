import {
	DialogHandler,
	NotificationHandler,
	Button,
} from "cakemail-ui-components/lib/react";
import { Translate, I18n } from "react-redux-i18n";
import React from "react";
import moment from "moment";
import store from "../store";

import { openBillingPortal, validateResponse } from "utils/apiUtils";
import { getCalendarFormat } from "utils/dateTimeUtils";
import { listCampaignsService } from "../services/campaignService";

const CONTACT_LIMITS = {
	SOFT: 90,
	HARD: 100,
};

const SENT_LIMITS = {
	SOFT: 75,
};

export function checkLimitNotification(oldUsage, curUsage, isUpdate = true) {
	const storeGlobal = store.getState().global;
	const usage = curUsage ? curUsage : storeGlobal.usage;

	if (!storeGlobal.account) {
		return;
	}

	const limits = {
		contactLimit: storeGlobal.account.usage_limits.maximum_contacts,
		emailsMonthlyLimits: storeGlobal.account.usage_limits.per_month,
	};

	let notification = {
		text: (
			<Translate
				value="app-billing-limit-contacts"
				limit={Math.floor(usage.contactUsage)}
			/>
		),
		buttons: (
			<>
				<Button
					size="xsmall"
					text={<Translate value="app-menu-account-usage-upgrade_your_plan" />}
					onClick={() => {
						openBillingPortal();
					}}
				/>
				<Button
					size="xsmall"
					text={<Translate value="app-menu-account-usage-detail-btn" />}
					onClick={() => {
						openLimitDialog(usage, limits);
					}}
					shapeStyle="stroke"
				/>
			</>
		),
		colorTheme: "yellow",
	};

	if (isUpdate) {
		if (
			oldUsage.contactUsage <= CONTACT_LIMITS.HARD &&
			usage.contactUsage > CONTACT_LIMITS.HARD
		) {
			notification.text = (
				<Translate
					value="app-billing-limit-contacts"
					limit={Math.floor(usage.emailUsage)}
				/>
			);
			NotificationHandler.show(notification);
		} else if (
			oldUsage.contactUsage < CONTACT_LIMITS.HARD &&
			usage.contactUsage === CONTACT_LIMITS.HARD
		) {
			notification.text = (
				<Translate
					value="app-billing-limit-contacts"
					limit={CONTACT_LIMITS.HARD}
				/>
			);
			NotificationHandler.show(notification);
		} else if (
			oldUsage.contactUsage < CONTACT_LIMITS.SOFT &&
			usage.contactUsage >= CONTACT_LIMITS.SOFT
		) {
			notification.text = (
				<Translate
					value="app-billing-limit-contacts"
					limit={CONTACT_LIMITS.SOFT}
				/>
			);
			NotificationHandler.show(notification);
		} else if (
			oldUsage.emailUsage < SENT_LIMITS.SOFT &&
			usage.emailUsage >= SENT_LIMITS.SOFT
		) {
			notification.text = (
				<Translate
					value="app-billing-limit-sent-monthly"
					limit={SENT_LIMITS.SOFT}
				/>
			);
			NotificationHandler.show(notification);
		}
	} else {
		if (usage.contactUsage > CONTACT_LIMITS.SOFT) {
			NotificationHandler.show(notification);
		} else if (usage.emailUsage > SENT_LIMITS.SOFT) {
			notification.text = (
				<Translate
					value="app-billing-limit-sent-monthly"
					limit={Math.floor(usage.emailUsage)}
				/>
			);
			NotificationHandler.show(notification);
		}
	}
}

export function openLimitDialog(
	usage,
	limits,
	descOverride = null,
	infoOverride = null,
	titleOverride = null
) {
	let title, fullContent;
	const storeGlobal = store.getState().global;
	//Content "Componentified"
	let content = (props) => {
		return (
			<>
				<p>{props.desc}</p>
				<div className="info">
					{props.info.map((element, index) => {
						return (
							<p key={index}>
								{element.desc} <span>{element.info}</span>
							</p>
						);
					})}
				</div>
			</>
		);
	};

	const show = (title, fullContent) => {
		DialogHandler.show({
			title: title,
			content: fullContent,
			primaryBtnText: (
				<Translate value="app-menu-account-usage-upgrade_your_plan" />
			),
			primaryBtnOnClick: () => {
				openBillingPortal();
			},
			secondaryBtnText: <Translate value="app-general-close" />,
			className: "limitDialog",
			type: "warning",
		});
	};

	if (usage.contactUsage >= CONTACT_LIMITS.SOFT) {
		title = (
			<Translate
				value="app-billing-limit-contacts-usage-title"
				usage={Math.floor(usage.contactUsage)}
			/>
		);

		let props = {
			desc: (
				<Translate
					value="app-billing-limit-contacts-usage-desc"
					resellerName={store.getState().brand.name}
				/>
			),
			info: [
				{
					desc: <Translate value="app-billing-limit-contacts-usage-contacts" />,
					info: usage.contacts,
				},
				{
					desc: <Translate value="app-billing-limit-contacts-usage-limit" />,
					info: limits.contactLimit,
				},
			],
		};

		if (usage.contactUsage > CONTACT_LIMITS.HARD) {
			props.desc = (
				<Translate
					value="app-billing-limit-contacts-usage-desc-busted"
					dangerousHTML
					resellerName={store.getState().brand.name}
				/>
			);
		}
		if (descOverride) {
			props.desc = descOverride;
		}
		if (infoOverride) {
			props.info = infoOverride;
		}
		if (titleOverride) {
			title = titleOverride;
		}

		fullContent = content(props);
		show(title, fullContent);
	} else {
		const endOfMonthDate = moment().endOf("month");
		let remaining = limits.emailsMonthlyLimits - usage.emails;

		if (remaining < 0) {
			remaining = 0;
		}

		title = (
			<Translate
				value="app-billing-limit-sent-usage-title"
				usage={Math.floor(usage.emailUsage)}
			/>
		);
		let props = {
			desc: (
				<Translate value="app-billing-limit-sent-usage-desc" dangerousHTML />
			),
			info: [
				{
					desc: <Translate value="app-billing-limit-sent-usage-limit" />,
					info: limits.emailsMonthlyLimits,
				},
				{
					desc: <Translate value="app-billing-limit-sent-usage-sent" />,
					info: usage.emails,
				},
				{
					desc: <Translate value="app-billing-limit-sent-usage-remaining" />,
					info: remaining,
				},
				{
					desc: <Translate value="app-billing-limit-sent-usage-reset" />,
					info: `${getCalendarFormat(endOfMonthDate.unix(), false)} (${moment(
						endOfMonthDate
					).diff(moment(), "days")} ${I18n.t("app-general-days")})`,
				},
			],
		};

		if (descOverride) {
			props.desc = descOverride;
		}
		if (infoOverride) {
			props.info = infoOverride;
		}
		if (titleOverride) {
			title = titleOverride;
		}

		listCampaignsService(
			{
				withCount: true,
				perPage: 1,
				filter: "status==scheduled",
			},
			(data) => {
				props.info.splice(1, 0, {
					desc: <Translate value="app-billing-limit-sent-usage-scheduled" />,
					info: data.pagination.count,
				});
				fullContent = content(props);
				show(title, fullContent);
			},
			() => {}
		);
	}
}
