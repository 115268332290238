export const CUSTOM = "SET_CUSTOM_HEADING";
export const VISIBLE = "SET_HEADING_VISIBLE";

export const setCustomHeader = (header, subHeader, text, isError) => {
	return {
		type: CUSTOM,
		header,
		subHeader,
		text,
		isError,
	};
};

export const setHeaderVisibility = (isVisible) => {
	return {
		type: VISIBLE,
		isVisible,
	};
};
