export const ACCOUNT = "SET_ACCOUNT";
export const CUSTOMER = "SET_CUSTOMER";
export const USER = "SET_USER";
export const API = "SET_API_CLIENT";
export const CRITICAL_ERROR = "SET_CRITICAL_ERROR";
export const LANGUAGE = "SET_LANGUAGE";
export const BRAND_PREVIEW = "SET_BRAND_PREVIEW";
export const AUTHENTICATED = "SET_AUTHENTICATED";
export const ACCOUNT_READY = "SET_ACCOUNT_READY";
export const ACCOUNT_META = "SET_ACCOUNT_META";

export const setAccount = (account) => {
	return {
		type: ACCOUNT,
		account,
	};
};

export const setCustomer = (customer) => {
	return {
		type: CUSTOMER,
		customer,
	};
};

export const setUser = (user) => {
	return {
		type: USER,
		user,
	};
};

export const setCriticalError = (criticalError) => {
	return {
		type: CRITICAL_ERROR,
		criticalError,
	};
};

export const setLanguage = (language) => {
	return {
		type: LANGUAGE,
		language,
	};
};

export const setBrandPreview = (preview = false) => {
	return {
		type: BRAND_PREVIEW,
		preview,
	};
};

export const setAuthenticated = (authenticated = null) => {
	return {
		type: AUTHENTICATED,
		authenticated,
	};
};

export const setAccountReady = (accountReady = false) => {
	return {
		type: ACCOUNT_READY,
		accountReady,
	};
};

export const setAccountMetadata = (name, accountMetaData = false) => {
	return {
		type: ACCOUNT_META,
		name,
		accountMetaData,
	};
};
