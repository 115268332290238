import _ from "lodash";
import { EVENT_TYPES } from "assets/js/const/Automations";
import { I18n } from "react-redux-i18n";
import { RepresentableSteps } from "../../containers/editor/MapperUtils";
import store from "assets/js/store";
import { getConnectedEdges, isEdge, isNode } from "react-flow-renderer";
import { getConditionsValues } from "./ifElseUtils";
import { flextree } from "d3-flextree";

const uniqueId = (length = 4) => {
	return parseInt(
		Math.ceil(Math.random() * Date.now())
			.toPrecision(length)
			.toString()
			.replace(".", "")
	);
};
const getEventId = () => `event_${+uniqueId()}`;
const getNodeId = () => `n${+uniqueId()}`;
const getCoahmkarNodeId = () => `coachmark_${+uniqueId()}`;

const getEdgeId = () => `edge_${+uniqueId()}`;

const isNewEvent = (node) => {
	return node.id.includes("event_");
};

const getEndCoachmarkNode = (x, y, level) => {
	return {
		id: getCoahmkarNodeId(),
		type: "editorNode",
		data: {
			initialElement: false,
			type: "coachmark",
			baseId: "coachmark",
			selected: false,
			//level: level,
		},
		draggable: false,
		position: { x: x, y: y },
		className: "coachmarkNode",
	};
};
const helperNode = () => {
	return {
		id: getNodeId(),
		draggable: false,
		type: "editorNode",
		data: {
			type: "helper",
			baseId: "helper",
		},
		position: {
			x: 0,
			y: 0,
		},
	};
};
const changeHelperNode = (event, nodes = [], selectedNodeId) => {
	let helperNode = null;
	let addNewLink = null;
	const updatedNodes = nodes?.map((node, index) => {
		if (node.id == selectedNodeId) {
			if (node.data.type.includes("helper")) {
				helperNode = node;
				addNewLink = event.baseId === "ifElse";
			}
			return {
				created: node.data.type.includes("helper") && true,
				...node,
				data: {
					...event,
					eventId: event.id,
					selected: true,
				},
			};
		}
		if (helperNode) {
			if (node.source === helperNode.id || node.target == helperNode.id) {
				if (addNewLink && node.source === helperNode.id) {
					return {
						...node,
						type: "branchingEdge",
						data: {
							...node.data,
							hideAddBtn: false,
							branchLabel: "Yes",
						},
					};
				} else
					return {
						...node,
						data: {
							...node.data,
							hideAddBtn: false,
						},
					};
			}
		}
		return { ...node };
	});
	if (addNewLink) {
		const newCoachmark = getEndCoachmarkNode(0, 0);
		const newLink = {
			id: getEdgeId(),
			source: helperNode.id,
			target: newCoachmark.id,
			type: "branchingEdge",
			data: {
				hideAddBtn: false,
				branchLabel: "No",
			},
		};
		updatedNodes.push(newCoachmark);
		updatedNodes.push(newLink);
	}
	return updatedNodes;
};

// const changeHelperNode = (event, nodes = []) => {
// 	let helperNode = null;
// 	return nodes?.map((node) => {
// 		if (node.data.selected) {
// 			if (node.data.type.includes("helper")) {
// 				helperNode = node;
// 			}
// 			return {
// 				created: node.data.type.includes("helper") && true,
// 				...node,
// 				data: {
// 					...event,
// 					eventId: event.id,
// 					selected: true,
// 				},
// 			};
// 		}
// 		if (helperNode) {
// 			if (node.source === helperNode.id || node.target == helperNode.id) {
// 				return {
// 					...node,
// 					data: {
// 						...node.data,
// 						hideAddBtn: false,
// 					},
// 				};
// 			}
// 		}

// 		return { ...node };
// 	});
// };
const constructEventObject = (event, id = null, params) => {
	const options = {};
	//prepare any default values
	if (getEventType(event.type, event.id) === EVENT_TYPES.TRIGGER_SUBSCRIBED) {
		options.audience = {
			list_id: params.listId,
		};
	}
	if (getEventType(event.type, event.id) === EVENT_TYPES.CONDITION_DELAY) {
		options.directValue = params.delay;
	}
	if (getEventType(event.type, event.id) === EVENT_TYPES.ACTION_SENDEMAIL) {
		options.email = params.email
			? { ...params.email, sender: { id: params.senderId } }
			: null;
		options.condition = params.condition;
		options.thumbnailUrl = params.thumbnailUrl;
		options.isEmailEdited = params.isEmailEdited;
	}
	return {
		...event,
		actionName: params.actionName,
		baseId: event.id,
		id: id || getEventId(),
		...options,
	};
};
const getNodeYValue = (events, nodeIndex) => {
	let nodeHeights = events.map((e, index) => {
		if (nodeIndex > index) {
			if (e.data.baseId === "sendEmail") return 180;
			else return 110;
		} else {
			return 0;
		}
	});
	nodeHeights = nodeHeights.reduce((x, y) => x + y);
	nodeHeights = nodeHeights + nodeIndex * 100;
	return nodeHeights;
};
const getEventType = (type, id) => {
	return `${type.toUpperCase()}_${id?.toUpperCase()}`;
};
const getStatsStepName = (conditionValue, value) => {
	return `${conditionValue}${value}`;
};

const getNextTarget = (connectedEdges, allEvents) => {
	const events = allEvents
		? allEvents
		: store.getState().automations.automationEvents;
	const conntectedEvent = events.filter(
		(e) => e.id == connectedEdges[0].target
	)[0];
	if (connectedEdges[0].target.includes("coachmark_")) {
		return "cleanup_and_exit";
	}
	// TODO : Need to improve how the valid stats for this to work properly
	else if (
		conntectedEvent.data.baseId == "ifElse" &&
		conntectedEvent.data.conditions.conditions.length > 0
	) {
		const value = getConditionsValues(
			conntectedEvent.data.conditions.conditions[0],
			conntectedEvent.id
		)[0];
		if (value) return getStatsStepName(conntectedEvent.id, "0");
		else {
			return connectedEdges[0].target;
		}
	} else {
		return connectedEdges[0].target;
	}
};
const convertSecondsToNearestUnit = (timeInSecs) => {
	if (timeInSecs % (60 * 60 * 24 * 7) == 0) {
		return `${timeInSecs / (60 * 60 * 24 * 7)} Weeks`;
	}
	if (timeInSecs % (60 * 60 * 24) == 0) {
		return `${timeInSecs / (60 * 60 * 24)} Days`;
	}
	if (timeInSecs % (60 * 60) == 0) {
		return `${timeInSecs / (60 * 60)} Hours`;
	}
	if (timeInSecs % 60 == 0) {
		return `${timeInSecs / 60} Minutes`;
	}
	return `${timeInSecs} Seconds`;
};
const convertTimeWithUnitToSeconds = (timeWithUnit) => {
	if (typeof timeWithUnit === "string" || timeWithUnit instanceof String) {
		const unit = timeWithUnit.split(" ")[1];
		const value = timeWithUnit.split(" ")[0];
		if (unit === "Weeks") {
			return value * 60 * 60 * 24 * 7;
		}
		if (unit === "Days") {
			return value * 60 * 60 * 24;
		}
		if (unit === "Hours") {
			return value * 60 * 60;
		}
		if (unit === "Minutes") {
			return value * 60;
		}
		return value;
	}
	return timeWithUnit;
};
const getDataEventsOnly = (uiEvents) => {
	return uiEvents.filter(
		(event) =>
			event.type === "editorNode" &&
			event.data.type !== "coachmark" &&
			event.data.type !== "trigger" &&
			event.data.type !== "helper"
	);
};
const getActionName = (name) => {
	return name ? name : I18n._translate("app-automations-action-default_name");
};
const getEdge = (type, sourceId, targetId, data) => {
	return {
		id: getEdgeId(),
		source: sourceId,
		target: targetId,
		type: type,
		data: {
			hideAddBtn: false,
			...data,
		},
	};
};
const getRepresentableStepFromSubAutomation = (subAutomation) => {
	const representableStep = subAutomation.steps.filter((s) =>
		RepresentableSteps.includes(s.action)
	)[0];
	return representableStep;
};
const checkIfIndexNotLastElement = (index, automation) => {
	return index !== automation.main.steps.length - 1;
};
const getSubAutomationForAMainStep = (automation, step) => {
	return automation.sub_automations.filter(
		(sub) => sub.name == step.args.sub_automation
	)[0];
};
const getTargetStepId = (automation, events, valueOfNextStep) => {
	let targetRepresentableStepId = 0;
	const indexOfNextStep = automation.main.steps.indexOf(
		automation.main.steps.filter((s) => s.id == valueOfNextStep)[0]
	);
	const nextStep = automation.main.steps[indexOfNextStep];
	if (indexOfNextStep && indexOfNextStep != -1) {
		if (nextStep.action == "SubAutomation.Call")
			targetRepresentableStepId = getRepresentableStepFromSubAutomation(
				getSubAutomationForAMainStep(automation, nextStep)
			).id;
		else targetRepresentableStepId = nextStep.id;
	} else {
		// if the element is the last element, we will need to create an end coachmark and specify the new end coachmark as the target step
		const y = getNodeYValue(events, events.length);
		const endCoachmark = getEndCoachmarkNode(0, y);
		events.push(endCoachmark);
		targetRepresentableStepId = endCoachmark.id;
	}
	return targetRepresentableStepId;
};
const getNextRepresentableElement = (el, automation) => {
	if (
		el &&
		el.next &&
		automation.main.steps.filter((s) => s.id === el.next).length > 0
	) {
		const nextStep = automation.main.steps.filter((s) => s.id === el.next)[0];
		if (nextStep.action == "SubAutomation.Call") {
			if (
				getRepresentableStepFromSubAutomation(
					getSubAutomationForAMainStep(automation, nextStep)
				)
			) {
				return nextStep.id;
			} else
				return getNextRepresentableElement(
					automation.main.steps.filter((e) => e.id == el.next)[0],
					automation
				);
		} else {
			if (RepresentableSteps.includes(nextStep.action)) {
				return nextStep.id;
			} else
				return getNextRepresentableElement(
					automation.main.steps.filter((e) => e.id == el.next)[0],
					automation
				);
		}
	} else {
		if (!el?.next) return null;
		else
			return getNextRepresentableElement(
				automation.main.steps.filter((e) => e.id == el.next)[0],
				automation
			);
	}
};
import deepcopy from "deepcopy";

const orderEvents = (events) => {
	const orderedEvents = [];
	const triggerNode = events.filter((e) => e.baseId == "subscribe")[0];
	orderedEvents.push(triggerNode);
	const orderConnectedNodes = (event) => {
		getOutgoers(event, events)[0]((e) => {
			const connectedEdge = getConnectedEdges(
				events.filter((node) => isNode(node)),
				events.filter((node) => isEdge(node))
			).filter((edge) => edge.source == event.id);
			if (connectedEdge[0].type == "custom") {
				orderedEvents.push(e);
			} else {
			}
		});
	};
};

const layoutedEvents = (events) => {
	var data = {
		nodes: events.filter((e) => isNode(e)),
		links: events.filter((e) => isEdge(e)),
	};

	let tree = (function (object) {
		var o = {},
			children = {};

		object.nodes.forEach(function (a, i) {
			o[a.id] = {
				id: a.id,
				name: a.id,
				size: [
					480,
					a.data.baseId == "sendEmail"
						? 366
						: a.data.baseId == "coachmark"
							? 30
							: 289,
				],
			};
		});

		object.links.forEach(function (a) {
			o[a.target].parent = o[a.source].id;
			o[a.source].children = o[a.source].children || [];
			o[a.source].children.push(o[a.target]);
			children[a.target] = true;
		});

		return Object.keys(o)
			.filter(function (k) {
				return !children[k];
			})
			.map(function (k) {
				return o[k];
			});
	})(data);
	const updatedEvents = deepcopy(events);
	let orderedEvents = [];
	var traverse = function (current) {
		//process current node here
		if (!current.children) return;
		current.children.forEach((child, index) => {
			const element = updatedEvents.filter((e) => e.id == child.data.id)[0];
			element.position.x = child.x;
			element.position.y =
				element.data.baseId == "coachmark" ? child.y - 8 : child.y;
			orderedEvents.push(element);
			traverse(child);
		});
	};
	const treemap = flextree();
	treemap.spacing((nodeA, nodeB) => {
		return nodeA.path(nodeB).length + 210;
	});

	let nodes = treemap.hierarchy(tree[0], (d) => d.children);
	nodes = treemap(nodes);
	traverse(nodes);
	orderedEvents = [
		events.filter((e) => e.data.initialElement == true)[0],
		...orderedEvents,
		...updatedEvents.filter((e) => isEdge(e)),
	];

	return orderedEvents;
};

const getAllPreviousSendActions = (eventId) => {
	const events = store.getState().automations.automationEvents;
	const sendEmailEvents = [];
	const nodes = events.filter((event) => isNode(event));
	const edges = events.filter((event) => isEdge(event));
	const getPreviousEmails = (id) => {
		if (events.filter((e) => e.id == id)[0].data.baseId == "trigger") return;
		getConnectedEdges(nodes, edges)
			.filter((edge) => edge.target == id)
			.forEach((e) => {
				const connectedEvent = events.filter(
					(event) => event.id == e.source
				)[0];
				if (connectedEvent.data.baseId == "sendEmail") {
					sendEmailEvents.push(connectedEvent);
				}
				getPreviousEmails(connectedEvent.id);
			});
	};
	getPreviousEmails(eventId);
	return sendEmailEvents;
};
export {
	constructEventObject,
	getEventType,
	getEndCoachmarkNode,
	getNodeId,
	getEdgeId,
	isNewEvent,
	getNodeYValue,
	changeHelperNode,
	convertTimeWithUnitToSeconds,
	convertSecondsToNearestUnit,
	getDataEventsOnly,
	getActionName,
	getEdge,
	getRepresentableStepFromSubAutomation,
	getTargetStepId,
	getNextRepresentableElement,
	helperNode,
	getStatsStepName,
	getNextTarget,
	layoutedEvents,
	getAllPreviousSendActions,
	getConditionsValues,
};
