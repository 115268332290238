/**
 * This file is to map delay condition , from and to BE structure, it should contain at least two functions, one that maps
 * from BE to FE structure, and the other one that maps from FE to BE structure
 */
import { ActionEvents } from "assets/js/scenes/automations/containers/editor/EventsConfigs";
import store from "assets/js/store";
import {
	convertSecondsToNearestUnit,
	convertTimeWithUnitToSeconds,
} from "assets/js/scenes/automations/containers/editor/utils/utils";
import {
	getEdge,
	getEndCoachmarkNode,
	getNextTarget,
	getShadowsForStep,
} from "assets/js/scenes/automations/containers/editor/utils/utils";
import { getMatchedConditionToEmitStep } from "assets/js/scenes/automations/containers/editor/Mapper";
import { convertPlanToFlatArrayOfActions } from "assets/js/scenes/automations/containers/editor/utils/utils";

const parseBELoopingToFE = (automation, mainStep, subStep, targetId) => {
	const events = [];
	events.push({
		id: mainStep.name,
		position: {
			x: 0,
			y: 0,
		},
		data: {
			name: ActionEvents()[1].name,
			desc: ActionEvents()[1].desc,
			type: "action",
			tileTitle: ActionEvents()[1].tileTitle,
			id: mainStep.name,
			baseId: "loop",
			//customAttributes: mainStep.args.custom_attributes,
			initialElement: false,
			shadows: getShadowsForStep(automation, mainStep.name),
			goTo: getMatchedConditionToEmitStep(convertPlanToFlatArrayOfActions(automation),subStep.args.switch[0].steps[1])?.id,
			loopFor:subStep.args.switch[0].condition.lt.value
		},
		type: "editorNode",
	});
	const links = [];
	if (targetId) {
		links.push(getEdge("custom", mainStep.name, targetId));
	} else {
		const endCoachmark = getEndCoachmarkNode(0, 0);
		events.push(endCoachmark);
		links.push(getEdge("custom", mainStep.name, endCoachmark.id));
	}
	return {
		events: events,
		links: links,
	};
};
const parseFELoopingToBE = (FEEvent, connectedEdges, events) => {
	let shadows = [];
	let inTos =[];
	if (FEEvent.data.shadows) shadows = [...FEEvent.data.shadows];
	inTos = events.filter(e=>e.data.baseId=="loop").filter(e=>e.data.goTo==FEEvent.id);
	if(inTos.length>0) inTos=inTos.map(e=>`${e.id}_loop`);
	return {
		main: [
			{
				steps: [
					{
						name: `${FEEvent.id}`,
						action: "SubAutomation.Call",
						args: {
							sub_automation: FEEvent.id,
						},
						next: "end",
						shadows: shadows,
						inTos:inTos
					},
				],
			},
		],
		sub: [
			{
				name: FEEvent.id,
				params: [],
				type:"Loop",
				steps: [
					{
						name: "loopinit",
						action: "Condition.Switch",
						args: {
							switch: [
								{
									steps: [
										{
											name: "addcurrentLoop",
											action: "Variable.Set",
											args: {
												[`input.data.loops.${FEEvent.id}`]: 0,
											},
										},
									],
									condition: {
										eq: {
											value: true,
											var: `\${not("${FEEvent.id}" in input.data.loops)}`,
										},
									},
								},
							],
						},
					},
					{
						name: FEEvent.id,
						action: "Condition.Switch",
						args: {
							switch: [
								{
									steps: [
										{
											name: "increment",
											action: "Variable.Set",
											args: {
												[`input.data.loops.${FEEvent.id}`]: `\${input.data.loops.${FEEvent.id} + 1}`,
											},
										},
										{
											name: "loopback",
											action: "SubAutomation.Call",
											args: {
												sub_automation: "emit_next_step",
												params: {
													step: `${FEEvent.id}_loop`,
													delay: 0
													//FEEvent.data.waitFor,
												},
											},
										},
										{
											"name": "done",
											"action": "Return",
											"return":true
                                            
										} 
									],
									condition: {
										lt: {
											value: parseInt(FEEvent.data.loopFor),
											var: `\${input.data.loops.${FEEvent.id}}`,
										},
									},
								},
							],
						},
						next: getNextTarget(connectedEdges, events),
					},
					{
						"name": "reset",
						"action": "Variable.Set",
						"args": {
							[`input.data.loops.${FEEvent.id}`]: 0
						}
					}
				],
			}
		],
	};
};

export { parseBELoopingToFE, parseFELoopingToBE };
