import {
	ADD as ADD_TASKBAR,
	REPLACE as REPLACE_TASKBAR,
	DELETE as REMOVE_TASKBAR,
} from "./actions";
import React from "react";
import uuidv4 from "uuid/v4";

let initialState = () => {
	return {
		list: {},
	};
};

export const reducer = (state = initialState(), action) => {
	let copy = {};
	switch (action.type) {
	case ADD_TASKBAR:
		for (let taskBarKey in state.list) {
			if (state.list.hasOwnProperty(taskBarKey)) {
				copy[taskBarKey] = state.list[taskBarKey];
			}
		}

		let taskBarId =
				action.taskBarId === undefined ? uuidv4() : action.taskBarId;
		let TaskBar = action.taskBar;
		if (!copy.hasOwnProperty(taskBarId)) {
			copy[taskBarId] = (
				<TaskBar.type {...TaskBar.props} taskBarId={taskBarId}>
					{TaskBar.children}
				</TaskBar.type>
			);
		}

		return { ...state, list: copy };
		break;
	case REPLACE_TASKBAR:
		return {
			...state,
			list: replaceTaskBar(state.list, action.index, action.taskBar),
		};
		break;
	case REMOVE_TASKBAR:
		for (let taskBarKey in state.list) {
			if (state.list.hasOwnProperty(taskBarKey)) {
				copy[taskBarKey] = state.list[taskBarKey];
			}
		}
		delete copy[action.index];
		return { ...state, list: copy };
		break;
	default:
		return state;
		break;
	}
};
