import { omit } from "lodash";
import { reducer as ListingReducer } from "./scenes/listing/reducer";
import { reducer as OverviewReducer } from "./scenes/overview/reducer";
import { reducer as FormGroupReducer } from "./data/formGroup/reducer";
import { actionTypes } from "./actions";

let initialState = () => {
	return {
		currentLanguage: "all",
		languageList: null,
	};
};

export const reducer = (state = initialState(), action) => {
	switch (action.type) {
	case actionTypes.CHANGE_CURRENT_LANGUAGE:
		return { ...state, currentLanguage: action.current };

	case actionTypes.SET_LANGUAGES:
		const languageList = [...action.languages];
		return { ...state, languageList };

	default:
		const rest = omit(state, Object.keys(initialState()));
		return {
			...state,
			list: ListingReducer(rest.list, action),
			overview: OverviewReducer(rest.overview, action),
			formGroup: FormGroupReducer(rest.formGroup, action),
		};
	}
};
