export const SINGLE_DELIVERY = "SET_SINGLE_DELIVERY";
export const SINGLE_DELIVERY_ENGAGEMENT_STATS =
	"SET_SINGLE_DELIVERY_ENGAGEMENT_STATS";
export const SINGLE_DELIVERY_SENT_ACTIVITY_STATS =
	"SET_SINGLE_DELIVERY_SENT_ACTIVITY_STATS";
export const SINGLE_DELIVERY_NOOPEN_ACTIVITY_STATS =
	"SET_SINGLE_DELIVERY_NOOPEN_ACTIVITY_STATS";
export const SINGLE_DELIVERY_CLICKED_ACTIVITY_STATS =
	"SET_SINGLE_DELIVERY_CLICKED_ACTIVITY_STATS";
export const SINGLE_DELIVERY_UNSUB_ACTIVITY_STATS =
	"SET_SINGLE_DELIVERY_UNSUB_ACTIVITY_STATS";
export const SINGLE_DELIVERY_SPAM_ACTIVITY_STATS =
	"SET_SINGLE_DELIVERY_SPAM_ACTIVITY_STATS";
export const SINGLE_DELIVERY_BOUNCED_ACTIVITY_STATS =
	"SET_SINGLE_DELIVERY_BOUNCED_ACTIVITY_STATS";
export const SINGLE_DELIVERY_OPEN_ACTIVITY_STATS =
	"SET_SINGLE_DELIVERY_OPEN_ACTIVITY_STATS";
export const SINGLE_DELIVERY_SUMMARY_ACTIVITY_STATS =
	"SET_SINGLE_DELIVERY_SUMMARY_ACTIVITY_STATS";
export const RESET_ACTIVITY_STATS = "RESET_ACTIVITY_STATS";
export const SINGLE_CAMPAIGN_LIST = "SET_SINGLE_CAMPAIGN_LIST";
export const SINGLE_CAMPAIGN_SEGMENT = "SET_SINGLE_CAMPAIGN_SEGMENT";
export const SINGLE_CAMPAIGN_REPORT = "SET_SINGLE_CAMPAIGN_REPORT";
export const SINGLE_CAMPAIGN_CLICK_REPORT = "SET_SINGLE_CAMPAIGN_CLICK_REPORT";
export const LAST_FEW_CAMPAIGNS_REPORT = "SET_LAST_FEW_CAMPAIGNS_REPORT";

export const setSingleDelivery = (delivery) => {
	return {
		type: SINGLE_DELIVERY,
		delivery,
	};
};

export const setSingleDeliveryEngagementStats = (deliveryEngagementStats) => {
	return {
		type: SINGLE_DELIVERY_ENGAGEMENT_STATS,
		deliveryEngagementStats,
	};
};

export const setCampaignReport = (campaignReport) => {
	return {
		type: SINGLE_CAMPAIGN_REPORT,
		campaignReport,
	};
};

export const setcampaignListReport = (campaignListReport) => {
	return {
		type: SINGLE_CAMPAIGN_LIST,
		campaignListReport,
	};
};

export const setCampaignSegment = (campaignSegment) => {
	return {
		type: SINGLE_CAMPAIGN_SEGMENT,
		campaignSegment,
	};
};

export const setCampaignClickReport = (clickReport) => {
	return {
		type: SINGLE_CAMPAIGN_CLICK_REPORT,
		clickReport,
	};
};

export const setSingleDeliveryOpenActivityStats = (openActivity) => {
	return {
		type: SINGLE_DELIVERY_OPEN_ACTIVITY_STATS,
		openActivity,
	};
};

export const setSingleDeliverySentActivityStats = (sentToActivity) => {
	return {
		type: SINGLE_DELIVERY_SENT_ACTIVITY_STATS,
		sentToActivity,
	};
};

export const setSingleDeliveryNoOpenActivityStats = (noOpenActivity) => {
	return {
		type: SINGLE_DELIVERY_NOOPEN_ACTIVITY_STATS,
		noOpenActivity,
	};
};

export const setSingleDeliveryClickedActivityStats = (clickedActivity) => {
	return {
		type: SINGLE_DELIVERY_CLICKED_ACTIVITY_STATS,
		clickedActivity,
	};
};

export const setSingleDeliveryUnsubActivityStats = (unsubActivity) => {
	return {
		type: SINGLE_DELIVERY_UNSUB_ACTIVITY_STATS,
		unsubActivity,
	};
};

export const setSingleDeliverySpamActivityStats = (spamActivity) => {
	return {
		type: SINGLE_DELIVERY_SPAM_ACTIVITY_STATS,
		spamActivity,
	};
};

export const setSingleDeliveryBouncedActivityStats = (bouncedActivity) => {
	return {
		type: SINGLE_DELIVERY_BOUNCED_ACTIVITY_STATS,
		bouncedActivity,
	};
};

export const setSingleDeliverySummaryActivityStats = (summaryActivity) => {
	return {
		type: SINGLE_DELIVERY_SUMMARY_ACTIVITY_STATS,
		summaryActivity,
	};
};

export const setLastFewCampaignsReport = (lastFewCampaignsReport) => {
	return {
		type: LAST_FEW_CAMPAIGNS_REPORT,
		lastFewCampaignsReport,
	};
};

export const resetActivityStats = () => {
	return {
		type: RESET_ACTIVITY_STATS,
	};
};
