import { LOCAL_BRAND, UNPUBLISHED_BRAND, UPDATE_LOCAL_BRAND } from "./actions";
import { findDataInBrand } from "assets/js/scenes/brand/utils";

let initialState = () => {
	return {
		data: null,
		unpublishedData: {},
	};
};

export const reducer = (state = initialState(), action) => {
	switch (action.type) {
	case LOCAL_BRAND:
		if (!action.path) {
			return { ...state, data: action.data };
		}
		return {
			...state,
			data: updateData(action.path, action.data, state.data),
		};
		break;
	case UNPUBLISHED_BRAND:
		return { ...state, unpublishedData: action.data };
	case UPDATE_LOCAL_BRAND:
		//path, localData, unpublishedData, toDelete = false
		// eslint-disable-next-line no-case-declarations
		let newData = null;
		// eslint-disable-next-line no-unused-vars
		let newUnpubData = null;

		if (!action.path) {
			newData = { ...action.localData };
		} else {
			newData = updateData(action.path, action.localData, state.data);
		}

		if (!action.unpublishedData) {
			newUnpubData = {};
		} else {
			newUnpubData = updateUnpublishedData(
				action.path,
				action.unpublishedData,
				action.toDelete,
				state.unpublishedData
			);
		}

		return { data: newData, unpublishedData: newUnpubData };
	default:
		return {
			...state,
		};
	}
};

function updateUnpublishedData(path, data, toDelete, unPubState) {
	let updatedUnpubState = { ...unPubState };
	if (toDelete) {
		delete updatedUnpubState[path];
	} else {
		updatedUnpubState[path] = data;
	}
	return updatedUnpubState;
}

function updateData(path, data, state) {
	let fullPath = path.split(".");

	let modData = findDataInBrand(path, state, true);
	modData[fullPath[fullPath.length - 1]] = data;

	return { ...state };
}
