/**
 * This file is responsible for handling the service calls for the account
 */
import store from "../store";
import { updateAppCueData } from "assets/js/scenes/OnBoardingFlow/utils";
import {
	callAPI,
	chameau,
	DEFAULT_LIST_PARAMS,
	GATEWAY_PROXY,
	METHODS,
} from "utils/apiUtils";
import {
	SEGMENT_EVENTS,
	trackSegmentEvent,
} from "assets/js/const/SegmentEvents";

export function forgotMyPasswordService(
	options = {
		accountId: null,
		email: "",
		language: "",
	},
	successCallback = () => {},
	failureCallback = () => {}
) {
	const { email, accountId, ...query } = options;
	callAPI(
		{
			url: GATEWAY_PROXY + "/users/forgot-my-password",
			body: { email: email },
			query: { ...chameau(query), account_id: accountId },
		},
		(data) => {
			trackSegmentEvent(SEGMENT_EVENTS.USER_PASSWORD_RESET, data);
			successCallback(data);
		},
		failureCallback
	);
}

export function listUsersService(
	options = DEFAULT_LIST_PARAMS,
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + "/users",
			method: METHODS.get,
			query: {
				...chameau(options),
				account_id: store.getState().global.account.id,
			},
		},
		successCallback,
		failureCallback
	);
}

export function deleteUserService(
	options = {
		id: "",
	},
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + `/users/${options.id}`,
			method: METHODS.delete,
			query: { account_id: store.getState().global.account.id },
		},
		(data) => {
			trackSegmentEvent(SEGMENT_EVENTS.USER_DELETED, data);
			successCallback(data);
		},
		failureCallback
	);
}

export function createUserService(
	options = {
		user: {},
	},
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + "/users",
			body: options.user,
			query: { account_id: store.getState().global.account.id },
		},
		(data) => {
			trackSegmentEvent(SEGMENT_EVENTS.USER_CREATED, data.data);
			successCallback(data);
		},
		failureCallback
	);
}

export function getUserService(
	options = { id: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + `/users/${options.id}`,
			method: METHODS.get,
			query: { account_id: store.getState().global.account.id },
		},
		successCallback,
		failureCallback
	);
}

export function updateUserService(
	options = { id: "", user: {} },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + `/users/${options.id}`,
			method: METHODS.patch,
			body: options.user,
			query: { account_id: store.getState().global.account.id },
		},
		(data) => {
			if (store.getState().global?.user?.id == options.id) {
				updateAppCueData(options.id, {
					...data.data,
				});
			}
			trackSegmentEvent(SEGMENT_EVENTS.USER_UPDATED, data.data);
			successCallback(data);
		},
		failureCallback
	);
}

export function resendUserConfirmationService(
	options = { id: "", email: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + `/users/${options.id}/resend-verification-email`,
			query: { account_id: store.getState().global.account.id },
		},
		(data) => {
			trackSegmentEvent(SEGMENT_EVENTS.USER_CONFIRMATION_SENT, {
				id: options.id,
				email: options.email,
			});
			successCallback(data);
		},
		failureCallback
	);
}

export function confirmUserPasswordService(
	options = { token: "", password: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + "/users/reset-password-confirm",
			body: options,
		},
		(data) => {
			trackSegmentEvent(SEGMENT_EVENTS.USER_PASSWORD_CONFIRM);
			successCallback(data);
		},
		failureCallback
	);
}

export function confirmUserService(
	options = { id: "", confirmation: "", password: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	const { id, ...rest } = options;

	callAPI(
		{
			url: GATEWAY_PROXY + `/users/${id}/confirm`,
			body: rest,
		},
		(data) => {
			trackSegmentEvent(SEGMENT_EVENTS.USER_CONFIRMED, { id: id });
			successCallback(data);
		},
		failureCallback
	);
}
