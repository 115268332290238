import { callAPI, chameau, GATEWAY_PROXY, METHODS } from "utils/apiUtils";
import {
	SEGMENT_EVENTS,
	segmentCondition,
	segmentPayloads,
} from "assets/js/const/SegmentEvents";

export function loginService(
	options = { email: "", password: "", accountId: null },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + "/login",
			body: chameau(options),
		},
		successCallback,
		failureCallback
	);
}

export function impersonateService(
	options = { accountId: null },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + "/impersonate",
			body: options,
		},
		successCallback,
		failureCallback
	);
}

export function imILoggedIn(
	options = {},
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + "/amiloggedin",
			method: METHODS.get,
		},
		successCallback,
		failureCallback
	);
}

export function logoutService(
	options = {},
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + "/logout",
			method: METHODS.get,
			skipRetry: true,
		},
		successCallback,
		failureCallback
	);
}
