import { Translate, I18n } from "react-redux-i18n";
import React from "react";
import { beeTemplate } from "assets/js/scenes/campaigns/scenes/campaignFlow/data/baseTemplates";
import { EDITORS } from "assets/js/const/Campaign";

const startingFromScratchInitialElements = [
	{
		id: "1",
		type: "editorNode",
		data: {
			text: "1",
			initialElement: true,
			type: "helper_trigger",
			selected: true,
		},
		draggable: false,
		position: { x: 350, y: 0 },
	},
];
const TriggerEvents = () => [
	{
		name: <Translate value="app-automation-editor_events_trigger-1_name" />,
		desc: <Translate value="app-automation-editor_events_trigger-1_desc" />,
		type: "trigger",
		tileTitle: "app-automation-editor_events_trigger-1_tileTitle",
		id: "subscribed",
		// defaultValues:{
		// 	listId: store.getState().lists.lists?.data[0]?.id,
		// }
	},
];
const ActionEvents = () => [
	{
		name: <Translate value="app-automation-editor_events_action-1_name" />,
		desc: <Translate value="app-automation-editor_events_action-1_desc" />,
		type: "action",
		tileTitle: "app-automation-editor_events_action-1_tileTitle",
		id: "sendEmail",
		defaultValues: {
			isEmailEdited: false,
			thumbnailUrl: "/assets/images/campaigns/beeTemplate.png",
			email: {
				name: I18n._translate("app-campaigns-flow-template_essentials"),
				content: {
					type: EDITORS.BEE,
					subject: I18n._translate(
						"app-automations-action-default_subjectLine"
					),
					id: "scratchBee1",
					json: beeTemplate(),
				},
			},
			senderId:
				// store?.getState()?.senders?.senders?.data?.filter((sender) => sender.confirmed)
				// 	?.length > 0
				// 	? store.getState().senders?.senders?.data?.filter((sender) => sender.confirmed)[0].id
				// 	:
				null,
			actionName: I18n._translate("app-automations-action-default_name"),
		},
	},
];
const ConditionEvents = () => [
	{
		name: <Translate value="app-automation-editor_events_condition-1_name" />,
		desc: <Translate value="app-automation-editor_events_condition-1_desc" />,
		type: "condition",
		tileTitle: "app-automation-editor_events_condition-1_tileTitle",
		id: "delay",
		defaultValues: {
			delay: "1 Seconds",
		},
	},
	{
		name: <Translate value="app-automation-editor_events_condition-2_name" />,
		desc: <Translate value="app-automation-editor_events_condition-2_desc" />,
		type: "condition",
		tileTitle: "app-automation-editor_events_condition-2_tileTitle",
		id: "ifElse",
		defaultValues: {
			elseCondition: "all",
		},
		configs: {
			noOfLinks: 2,
		},
	},
];
const triggerNewNode = {
	id: "1",
	type: "editorNode",
	data: {
		text: "1",
		initialElement: true,
		type: "trigger",
		selected: true,
	},
	draggable: false,
	position: { x: 350, y: 0 },
};
export {
	startingFromScratchInitialElements,
	TriggerEvents,
	triggerNewNode,
	ConditionEvents,
	ActionEvents,
};
