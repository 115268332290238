export const SET_HEADER_LAYOUT = "SET_HEADER_LAYOUT";

export const setHeaderLayout = (
	layoutHeader,
	layoutDescription,
	layoutTools
) => {
	return {
		type: SET_HEADER_LAYOUT,
		layoutHeader,
		layoutDescription,
		layoutTools,
	};
};
