import store from "assets/js/store";

export const getSelectedAttributesValuesOp = (
	evaluatingSubAutomationName,
	value
) => {
	switch (evaluatingSubAutomationName) {
	case "evaluate_all_condition_on_text":
	case "evaluate_all_not_condition_on_text":
		{
			const startsWithAnyMatch = value.startsWith(".*");
			const endsWtihAnyMatch = value.endsWith(".*");
			if (startsWithAnyMatch && !endsWtihAnyMatch) {
				return "&end";
			}
			if (startsWithAnyMatch && endsWtihAnyMatch) {
				return "&like";
			}
			if (!startsWithAnyMatch && endsWtihAnyMatch) {
				return "&start";
			}
			if (!startsWithAnyMatch && !endsWtihAnyMatch) {
				return "eq";
			}
		}
		break;
	case "evaluate_all_gt_condition_number":
	case "evaluate_all_not_gt_condition_number":
		return "gt";
	case "evaluate_all_lt_condition_number":
	case "evaluate_all_not_lt_condition_number":
		return "lt";

	default:
		break;
	}
};
export const removeRegexChars = (values) => {
	return values.map((value) => {
		let updatedValue = value;
		const startsWithAnyMatch = value.startsWith(".*");
		const endsWtihAnyMatch = value.endsWith(".*");
		if (startsWithAnyMatch || endsWtihAnyMatch) {
			updatedValue = updatedValue.replace(".*", "");
		}
		const isDateTime = value.startsWith("${time.parse(\"");
		if (isDateTime)
			updatedValue = updatedValue
				.replace("${time.parse(\"", "")
				.replace("\")}", "");
		const isInteger = value.startsWith("${int(\"");
		if (isInteger)
			updatedValue = updatedValue.replace("${int(\"", "").replace("\")}", "");
		return updatedValue;
	});
};

export const getContactDetailsEvaluationSubAutomationName = (
	op,
	attribute,
	invert
) => {
	if (invert)
		switch (op) {
		case "eq":
		case "&like":
		case "&start":
		case "&end":
			return "evaluate_all_not_condition_on_text";
		case "ne":
		case "&unlike":
			return "evaluate_all_condition_on_text";
		case "gt":
			if (attribute.type) {
				if (attribute.type == "integer" || attribute.type == "datetime") {
					return "evaluate_all_not_gt_condition_number";
				}
			} else {
				const type = store
					.getState()
					.attributes.data.filter(
						(att) => att.name == attribute.name
					)[0].type;
				if (type == "integer" || type == "datetime") {
					attribute.type = type;
					return "evaluate_all_not_gt_condition_number";
				}
			}
			break;
		case "lt":
			if (attribute.type == "integer" || attribute.type == "datetime") {
				return "evaluate_all_not_lt_condition_number";
			}
		}
	else {
		switch (op) {
		case "eq":
		case "&like":
		case "&start":
		case "&end":
			return "evaluate_all_condition_on_text";
		case "ne":
		case "&unlike":
			return "evaluate_all_not_condition_on_text";
		case "gt":
			if (attribute.type) {
				if (attribute.type == "integer" || attribute.type == "datetime") {
					return "evaluate_all_gt_condition_number";
				}
			} else {
				const type = store
					.getState()
					.attributes.filter((att) => att.name == attribute.name)[0].type;
				if (type == "integer" || type == "datetime") {
					attribute.type = type;
					return "evaluate_all_gt_condition_number";
				}
			}
			break;
		case "lt":
			if (attribute.type == "integer" || attribute.type == "datetime") {
				return "evaluate_all_lt_condition_number";
			}
		}
	}
};
