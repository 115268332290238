const LIST_AUTOMATIONS_SUCCESS = "LIST_TEMPLATES_SUCCESS";
const LIST_AUTOMATIONS_FAILURE = "LIST_TEMPLATES_FAILURE";
const SET_AUTOMATION = "SET_AUTOMATION";
const LIST_AUTOMATION_BLUEPRINTS_SUCCESS = "LIST_AUTOMATION_BLUEPRINTS_SUCCESS";
const UPDATE_AUTOMATION_EVENT = "UPDATE_AUTOMATION_EVENT";
const ADD_AUTOMATION_EVENT = "ADD_AUTOMATION_EVENT";
const RESET_AUTOMATION_EVENTS = "RESET_AUTOMATION_EVENTS";
const CREATE_AUTOMATION_SUCCESS = "CREATE_AUTOMATION_SUCCESS";
const CREATE_AUTOMATION_FAILURE = "CREATE_AUTOMATION_FAILURE";
const GET_AUTOMATION_FAILURE = "GET_AUTOMATION_FAILURE";
const GET_AUTOMATION_SUCCESS = "GET_AUTOMATION_SUCCESS";
const LIST_AUTOMATION_BLUEPRINTS_FAILURE = "LIST_AUTOMATION_BLUEPRINTS_FAILURE";
const UPDATE_ACTIVE_AUTOMATION = "UPDATE_ACTIVE_AUTOMATION";
const UPDATE_ACTIVE_AUTOMATION_SUCCESS = "UPDATE_ACTIVE_AUTOMATION_SUCCESS";
const LIST_AUTOMATION_ACTIONS_SUCCESS = "LIST_AUTOMATION_ACTIONS_SUCCESS";
const GET_ACTION_SUCCESS = "GET_ACTION_SUCCESS";
const DELETE_AUTOMATION_EVENT = "DELETE_AUTOMATION_EVENT";
const UPDATE_ACTION_FAILURE = "UPDATE_ACTION_FAILURE";
const UPDATE_ACTION_SUCCESS = "UPDATE_ACTION_SUCCESS";
const CREATE_ACTION_SUCCESS = "CREATE_ACTION_SUCCESS";
const CREATE_ACTION_FAILURE = "CREATE_ACTION_FAILURE";
const DEACTIVATE_AUTOMATION_SUCCESS = "DEACTIVATE_AUTOMATION_SUCCESS";
const ACTIVATE_AUTOMATION_SUCCESS = "ACTIVATE_AUTOMATION_SUCCESS";
const UPDATE_AUTOMATION_EVENTS = "UPDATE_AUTOMATION_EVENTS";
const DELETE_ACTION_SUCCESS = "DELETE_ACTION_SUCCESS";
const DELETE_ACTION_FAILURE = "DELETE_ACTION_FAILURE";
const UPDATE_AUTOMATION_SELECTED_EVENT = "UPDATE_AUTOMATION_SELECTED_EVENT";
const UPDATE_AUTOMATION_GENERAL_ISSUES = "UPDATE_AUTOMATION_GENERAL_ISSUES";
const SET_EDITOR_MODE = "SET_EDITOR_MODE";
const GET_ACTION_STATS_SUCCESS = "GET_ACTION_STATS_SUCCESS";
const TOGGLE_HIDE_STATS = "TOGGLE_HIDE_STATS";
const UPDATE_AUTOMATION_STATS_SUCCESS = "UPDATE_AUTOMATION_STATS_SUCCESS";
const RENAME_AUTOMATION_SUCCESS = "RENAME_AUTOMATION_SUCCESS";
const SET_SHOW_CONTROL_PANEL = "SET_SHOW_CONTROL_PANEL";
const REDO_AUTOMATION = "REDO_AUTOMATION";
const UNDO_AUTOMATION = "UNDO_AUTOMATION";
const ADD_AUTOMATION_VERSION = "ADD_AUTOMATION_VERSION";
const ADD_SNAPSHOT = "ADD_SNAPSHOT";
const LIST_VISITORS_SUCCESS = "LIST_VISITORS_SUCCESS";
const OPEN_SUB_SCENE = "OPEN_SUB_SCENE";

export {
	CREATE_ACTION_SUCCESS,
	CREATE_ACTION_FAILURE,
	LIST_AUTOMATIONS_FAILURE,
	LIST_AUTOMATIONS_SUCCESS,
	SET_AUTOMATION,
	LIST_AUTOMATION_BLUEPRINTS_SUCCESS,
	ADD_AUTOMATION_EVENT,
	UPDATE_AUTOMATION_EVENT,
	RESET_AUTOMATION_EVENTS,
	CREATE_AUTOMATION_FAILURE,
	CREATE_AUTOMATION_SUCCESS,
	GET_AUTOMATION_FAILURE,
	GET_AUTOMATION_SUCCESS,
	LIST_AUTOMATION_BLUEPRINTS_FAILURE,
	UPDATE_ACTIVE_AUTOMATION,
	UPDATE_ACTIVE_AUTOMATION_SUCCESS,
	LIST_AUTOMATION_ACTIONS_SUCCESS,
	GET_ACTION_SUCCESS,
	DELETE_AUTOMATION_EVENT,
	UPDATE_ACTION_SUCCESS,
	UPDATE_ACTION_FAILURE,
	DEACTIVATE_AUTOMATION_SUCCESS,
	ACTIVATE_AUTOMATION_SUCCESS,
	UPDATE_AUTOMATION_EVENTS,
	DELETE_ACTION_SUCCESS,
	DELETE_ACTION_FAILURE,
	UPDATE_AUTOMATION_SELECTED_EVENT,
	UPDATE_AUTOMATION_GENERAL_ISSUES,
	SET_EDITOR_MODE,
	GET_ACTION_STATS_SUCCESS,
	TOGGLE_HIDE_STATS,
	UPDATE_AUTOMATION_STATS_SUCCESS,
	RENAME_AUTOMATION_SUCCESS,
	SET_SHOW_CONTROL_PANEL,
	UNDO_AUTOMATION,
	REDO_AUTOMATION,
	ADD_AUTOMATION_VERSION,
	ADD_SNAPSHOT,
	LIST_VISITORS_SUCCESS,
	OPEN_SUB_SCENE,
};
