/**
 * This file is mainly to handle the mapping of data from events object to nodes object
 * Events: represents the actual automation events, holding data to from and to different backend endpoints
 * Nodes: represents the actual object that the diagram workflow uses, holding reference to event, and data
 *		  that represents how each node will be drawn , used by the reactflow lib
 */
import _ from "lodash";
import { EVENT_TYPES } from "../../../../../const/Automations";
import { ActionEvents, ConditionEvents } from "./InitialData";
import { isEmailContentValid } from "assets/js/utils/IsValidEmail";
import { EVENTS } from "assets/js/const/Automations";
import { I18n } from "react-redux-i18n";
import {
	isDomainPrivate,
	getDomainFromEmail,
} from "assets/js/scenes/account/scenes/senders/utils";
import store from "assets/js/store";

const uniqueId = (length = 16) => {
	return parseInt(
		Math.ceil(Math.random() * Date.now())
			.toPrecision(length)
			.toString()
			.replace(".", "")
	);
};
const getEventId = () => `event_${+uniqueId()}`;
const getNodeId = () => `node_${+uniqueId()}`;
const getEdgeId = () => `edge_${+uniqueId()}`;

const isNewEvent = (node) => {
	return node.id.includes("event_");
};

const getEndCoachmarkNode = (x, y) => {
	return {
		id: getNodeId(),
		type: "editorNode",
		data: {
			initialElement: false,
			type: "coachmark",
			selected: false,
		},
		draggable: false,
		position: { x: x, y: y },
		className: "coachmarkNode",
	};
};

const getTheParams = (event) => {
	switch (getEventType(event.type, event.baseId)) {
	case EVENT_TYPES.TRIGGER_SUBSCRIBED:
		return {
			// the path from the store
			path: "lists.lists.data",
			// the value that we might need to filter the data with, taking the zero
			//index by default
			value: event.audience.list_id,
			// the property that the param will hold, should be a string
			prop: "name",
		};
	case EVENT_TYPES.CONDITION_DELAY:
		return {
			directValue: event.directValue,
		};

	case EVENT_TYPES.ACTION_SENDEMAIL:
		return {
			directValue: event.actionName,
		};
	}
};
const updateSelectedNode = (event, nodes = []) => {
	let helperNode = null;
	return nodes?.map((node) => {
		if (node.data.selected) {
			if (node.data.type.includes("helper")) {
				helperNode = node;
			}
			return {
				created: node.data.type.includes("helper") && true,
				...node,
				data: {
					...event,
					eventId: event.id,
					selected: true,
				},
			};
		}
		if (helperNode) {
			if (node.source === helperNode.id || node.target == helperNode.id) {
				return {
					...node,
					data: {
						...node.data,
						hideAddBtn: false,
					},
				};
			}
		}

		return { ...node };
	});
};
const constructEventObject = (
	event,
	id = null,
	listId,
	delay = null,
	senderId = null,
	email = null,
	condition = null,
	actionName,
	parentId,
	isEmailEdited,
	thumbnailUrl
) => {
	const options = {};
	//prepare any default values
	if (getEventType(event.type, event.id) === EVENT_TYPES.TRIGGER_SUBSCRIBED) {
		options.audience = {
			list_id: listId,
		};
	}
	if (getEventType(event.type, event.id) === EVENT_TYPES.CONDITION_DELAY) {
		options.directValue = delay;
	}
	if (getEventType(event.type, event.id) === EVENT_TYPES.ACTION_SENDEMAIL) {
		options.email = email ? { ...email, sender: { id: senderId } } : null;
		options.condition = condition;
		options.thumbnailUrl = thumbnailUrl;
		options.isEmailEdited = isEmailEdited;
	}
	return {
		...event,
		actionName: actionName,
		baseId: event.id,
		id: id || getEventId(),
		...options,
	};
};
const getUpdatedEvents = (
	event,
	id = null,
	listId,
	delay = null,
	senderId = null,
	email = null,
	condition = null,
	actionName,
	thumbnailUrl,
	isEmailEdited = true,
	events
) => {
	const newEventData = constructEventObject(
		event,
		id,
		listId,
		delay,
		senderId,
		email,
		condition,
		actionName,
		null,
		isEmailEdited,
		thumbnailUrl
	);
	newEventData.stats = { clickthru: 0, sent: 0, unsubscribe: 0, open: 0 };
	return updateSelectedNode(newEventData, events);
};
const getNodeYValue = (events, nodeIndex) => {
	let nodeHeights = events.map((e, index) => {
		if (nodeIndex > index) {
			if (e.data.baseId === "sendEmail") return 180;
			else return 110;
		} else {
			return 0;
		}
	});
	nodeHeights = nodeHeights.reduce((x, y) => x + y);
	nodeHeights = nodeHeights + nodeIndex * 100;
	return nodeHeights;
};
const getEventType = (type, id) => {
	return `${type.toUpperCase()}_${id?.toUpperCase()}`;
};

// this is called after all the actions and workflow are loaded
const initializeEventsUIData = (events = []) => {
	let updatedEvents = [];

	if (events) {
		let y = 0;
		events.forEach((event, index) => {
			// create the corresponding node
			updatedEvents.push({
				id: event.id,
				type: "editorNode",
				data: {
					...event.data,
					initialElement: index === 0,
					type: event.data.type,
					selected: false,
					tileTitle: event.data.tileTitle,
					//desc:event.data.desc,
					// eventId: event.id,
					// params: getTheParams(event),
				},
				draggable: false,
				position: { x: 350, y: y },
			});
			if (event.data.baseId === "sendEmail") {
				y = y + 310;
			} else y = y + 220;
		});
		if (events.length === 1) {
			// we need to push a helper node
			const newEventId = getNodeId();
			const newEvent = {
				id: newEventId,
				draggable: false,
				type: "editorNode",
				data: {
					selected: true,
					type: "helper",
				},
				position: {
					x: updatedEvents[updatedEvents.length - 1].position.x,
					y: updatedEvents[updatedEvents.length - 1].position.y + 310,
				},
			};
			updatedEvents.push(newEvent);
		}
		const edges = [];
		updatedEvents.forEach((node, index) => {
			if (index == 0) {
				return;
			}
			edges.push({
				id: getEdgeId(),
				source: updatedEvents[index - 1].id,
				target: updatedEvents[index].id,
				type: "custom",
				data: {
					hideAddBtn: false,
				},
			});
			const y = getNodeYValue(updatedEvents, index);
			node.position.y = y;
		});
		updatedEvents.push(...edges);
	}
	return updatedEvents;
};
const convertSecondsToNearestUnit = (timeInSecs) => {
	if (timeInSecs % (60 * 60 * 24 * 7) == 0) {
		return `${timeInSecs / (60 * 60 * 24 * 7)} Weeks`;
	}
	if (timeInSecs % (60 * 60 * 24) == 0) {
		return `${timeInSecs / (60 * 60 * 24)} Days`;
	}
	if (timeInSecs % (60 * 60) == 0) {
		return `${timeInSecs / (60 * 60)} Hours`;
	}
	if (timeInSecs % 60 == 0) {
		return `${timeInSecs / 60} Minutes`;
	}
	return `${timeInSecs} Seconds`;
};
const convertTimeWithUnitToSeconds = (timeWithUnit) => {
	if (typeof timeWithUnit === "string" || timeWithUnit instanceof String) {
		const unit = timeWithUnit.split(" ")[1];
		const value = timeWithUnit.split(" ")[0];
		if (unit === "Weeks") {
			return value * 60 * 60 * 24 * 7;
		}
		if (unit === "Days") {
			return value * 60 * 60 * 24;
		}
		if (unit === "Hours") {
			return value * 60 * 60;
		}
		if (unit === "Minutes") {
			return value * 60;
		}
		return value;
	}
	return timeWithUnit;
};
const mapActionToConditionAndActionEvents = (action) => {
	const events = [];
	if (parseInt(action.delay) > 0 || action.name == "##delay_only_code##")
		//create delay condition event from  the action
		events.push(
			constructEventObject(
				ConditionEvents()[0],
				`${action.id}_delay`,
				null,
				convertSecondsToNearestUnit(parseInt(action.delay)),
				null,
				null,
				null,
				null,
				action.parent_id,
				null,
				null
			)
		);
	if (action.name !== "##delay_only_code##")
		//create action event from the action
		events.push(
			constructEventObject(
				ActionEvents()[0],
				`${action.id}_action`,
				null,
				null,
				action.email_settings?.sender?.id,
				action.email_settings,
				action.condition,
				action.name,
				action.parent_id,
				null,
				action.thumbnail_url
			)
		);
	return events;
};
/**
 * the main purpose of this function is to initalize the parents and acnestors of the events, based on the created ones from the
 * actions we received
 * @param {the events that needs to be asjusted with parent and acnestor values} events
 */
const initalizeParentsAndAncestorsForEvents = (events) => {
	const updatedEvents = events.map((event, index) => {
		if (index == 0) {
			event.parentEventId = null;
			event.ancestorEventId = events[index + 1]?.id;
		} else {
			event.parentEventId = events[index - 1]?.id;
			event.ancestorEventId = events[index + 1]?.id;
		}
		return event;
	});
	return updatedEvents;
};
const sortActionsBasedOnParent = (actions) => {
	const sortedActions = [];
	let parentAction = actions.filter((action) => !action.parent_id)[0];
	sortedActions.push(parentAction);
	actions.forEach((action) => {
		const childAction = actions.filter((action) => {
			if (parentAction) return action.parent_id === parentAction.id;
			else false;
		});
		sortedActions.push(...childAction);
		parentAction = childAction[0];
	});
	return sortedActions;
};
const getDataEventsOnly = (uiEvents) => {
	return uiEvents.filter(
		(event) =>
			event.type === "editorNode" &&
			event.data.type !== "coachmark" &&
			event.data.type !== "trigger" &&
			event.data.type !== "helper"
	);
};
const adjustParentAndAncestorEvents = (uiEvents) => {
	const adjustedEvents = [];
	const events = getDataEventsOnly(uiEvents);
	events.forEach((event, index) => {
		let parentEventId = events[index - 1]?.data?.id || null;
		if (event.data.parentEventId != parentEventId) event.updated = true;
		const ancestorEventId = events[index + 1]?.data?.id || null;
		if (event.data.ancestorEventId != ancestorEventId) event.updated = true;
		adjustedEvents.push({
			...event,
			data: {
				...event.data,
				parentEventId: parentEventId,
				ancestorEventId: ancestorEventId,
			},
		});
	});
	return adjustedEvents;
};

const createEventsTree = (dataset) => {
	const hashTable = Object.create(null);
	dataset.forEach(
		(aData) => (hashTable[aData.id] = { ...aData, childNodes: [] })
	);
	const dataTree = [];
	dataset.forEach((aData) => {
		if (aData.parentEventId)
			hashTable[aData.parentEventId].childNodes.push(hashTable[aData.id]);
		else dataTree.push(hashTable[aData.id]);
	});
	return dataTree;
};
/**
 * The purpose of this function is to update an action: condition to be either none or none, and to accumulate the delay of an action from all previous delays
 * @param {*} tree
 * @param {*} foundFirstAction
 * @param {*} delay
 */
const updateActionsConditionsAndDelays = (
	tree,
	foundFirstAction,
	delay,
	updateDelay
) => {
	if (tree && tree.length > 0) {
		tree.forEach((node) => {
			if (node.type === "action") {
				if (!foundFirstAction) {
					if (node.condition !== "none") {
						node.condition = "none";
						//node.updated = true;
					}
					if (updateDelay) node.updated = true;
					updateActionsConditionsAndDelays(
						node["childNodes"],
						true,
						delay,
						updateDelay
					);
				} else {
					if (node.condition !== "none") {
						node.condition = "none";
						//	node.updated = true;
					}
					if (updateDelay) node.updated = true;
					updateActionsConditionsAndDelays(
						node["childNodes"],
						foundFirstAction,
						delay,
						updateDelay
					);
				}
			} else if (node.type === "condition") {
				node.directValue = parseInt(
					convertTimeWithUnitToSeconds(node.directValue)
				);
				if (node.updated || node.created || updateDelay) node.updated = true;
				if (node.updated || node.created)
					updateActionsConditionsAndDelays(
						node["childNodes"],
						foundFirstAction,
						node.directValue,
						true
					);
				else
					updateActionsConditionsAndDelays(
						node["childNodes"],
						foundFirstAction,
						node.directValue,
						updateDelay
					);
			} else
				updateActionsConditionsAndDelays(
					node["childNodes"],
					foundFirstAction,
					delay,
					updateDelay
				);
		});
	}
};
const getId = (id, type) => {
	if (id) {
		return type == "both"
			? id.includes("action") || id.includes("delay")
				? id.split("_")[0]
				: id
			: id.includes(type)
				? id.split("_")[0]
				: id;
	}
	return null;
};
const getDelayOfPreviousAction = (actions) => {
	return actions.length > 0 ? actions[actions.length - 1].delay : 0;
};
const getActionName = (name) => {
	return name ? name : I18n._translate("app-automations-action-default_name");
};
/**
 * The purpose of this function is to create actions array from the events tree, the actions array will be used for either updating or creating
 * new action
 * The function might be optimized later, but for now we need to keep how it with a simple step by step way to cover different cases
 * It depends on navigating on the events tree, finding different nodes, and deciding either to create/update an action with creating the action
 * details from the event and the next event for delay then send email. It is also considering the fact that there will be more than one
 * child for the same parent that we will have later
 * @param {} tree
 * @param {*} accountId
 * @param {*} automationId
 * @param {*} actions
 */
const createActionsFromEventsTree = (
	tree,
	accountId,
	automationId,
	actions,
	defaultSenderId
) => {
	if (tree && tree.length > 0) {
		tree.forEach((node) => {
			if (node.type === "action") {
				const newAction = {
					name: getActionName(node.actionName),
					parent_id: node.parent_id || null,
					delay: 0,
					id: getId(node.id, "action"),
					email_settings: node.email,
					condition: "none",
					type: "email",
					eventId: [node.id],
				};
				if (
					actions.filter((action) => action.id === newAction.id).length > 0 ||
					node.created
				) {
					newAction.create = true;
					newAction.id = actions.length + 1;
				} else if (node.updated) {
					newAction.update = true;
				}
				node["childNodes"].forEach((child) => {
					child.parent_id = newAction.id;
					if (getId(child.parentEventId, "action") != newAction.id) {
						child.updated = true;
					}
				});
				actions.push(newAction);
				createActionsFromEventsTree(
					node["childNodes"],
					accountId,
					automationId,
					actions,
					defaultSenderId
				);
			} else if (node.type === "condition") {
				if (node.childNodes.length > 0) {
					if (node.childNodes[0].type === "action") {
						const newAction = {
							name: getActionName(node.childNodes[0].actionName),
							parent_id: node.parent_id || null,
							delay: convertTimeWithUnitToSeconds(node.directValue),
							id: node.id.includes("_delay")
								? node.id.split("_")[0]
								: node.childNodes[0].id.includes("_action")
									? node.childNodes[0].id.split("_")[0]
									: null,
							email_settings: node.childNodes[0].email,
							condition: "none",
							type: "email",
							eventId: [node.id, node.childNodes[0].id],
						};

						if (
							actions.filter((action) => action.id === newAction.id).length >
								0 ||
							(node.created && node.childNodes[0].created)
						) {
							newAction.create = true;
							newAction.id = actions.length + 1;
						} else if (
							node.updated ||
							node.childNodes[0].updated ||
							node.created ||
							node.childNodes[0].created
						) {
							newAction.update = true;
						}
						node["childNodes"][0]["childNodes"].forEach((child) => {
							child.parent_id = newAction.id;
							if (getId(child.parentEventId, "both") != newAction.id) {
								child.updated = true;
							}
						});
						actions.push(newAction);
						createActionsFromEventsTree(
							node["childNodes"][0]["childNodes"],
							accountId,
							automationId,
							actions,
							defaultSenderId
						);
					} else if (node.childNodes[0].type === "condition") {
						const newAction = {
							name: "##delay_only_code##",
							parent_id: node.parent_id || null,
							delay: convertTimeWithUnitToSeconds(node.directValue),
							id: getId(node.id, "delay"),
							email_settings: {
								content: {},
								sender: { id: defaultSenderId },
							},
							condition: "none",
							type: "email",
							eventId: [node.id],
						};
						if (
							actions.filter((action) => action.id === newAction.id).length >
								0 ||
							node.created
						) {
							newAction.create = true;
							newAction.id = actions.length + 1;
						} else if (node.updated) {
							newAction.update = true;
						}
						node["childNodes"].forEach((child) => {
							child.parent_id = newAction.id;
							if (getId(child.parentEventId, "delay") != newAction.id) {
								child.updated = true;
							}
						});
						actions.push(newAction);
						createActionsFromEventsTree(
							node["childNodes"],
							accountId,
							automationId,
							actions,
							defaultSenderId
						);
					}
				} else {
					const newAction = {
						name: "##delay_only_code##",
						parent_id: node.parent_id || null,
						delay: convertTimeWithUnitToSeconds(node.directValue),
						id: node.id.includes("_delay") ? node.id.split("_")[0] : null,
						email_settings: {
							content: {},
							sender: { id: defaultSenderId },
						},
						condition: "none",
						type: "email",
						eventId: [node.id],
					};
					if (
						actions.filter((action) => action.id === newAction.id).length > 0 ||
						node.created
					) {
						newAction.create = true;
						newAction.id = actions.length + 1;
					} else if (node.updated) {
						newAction.update = true;
					}
					node["childNodes"].forEach((child) => {
						child.parent_id = newAction.id;
						if (getId(child.parentEventId, "delay") != newAction.id) {
							child.updated = true;
						}
					});
					actions.push(newAction);
					createActionsFromEventsTree(
						node["childNodes"],
						accountId,
						automationId,
						actions,
						defaultSenderId
					);
				}
			}
		});
	}
};
const createActionFromEvent = (
	currentEvent,
	parentEvent,
	ancestorEvent,
	operationType,
	events = [],
	defaultSenderId
) => {
	switch (operationType) {
	case "create": {
		if (currentEvent.type === "action") {
			const returnedArray = [];
			const newAction = {
				name: currentEvent.actionName,
				parent_id: parentEvent.id.includes("subscribed")
					? null
					: parentEvent.beId
						? parentEvent.beId
						: getId(
							parentEvent.id,
							parentEvent.type == "action" ? "action" : "delay"
						  ),
				email_settings: currentEvent.email,
				condition: "none",
				type: "email",
				delay: 0,
				id: currentEvent.id,
				create: true,
			};
			returnedArray.push(newAction);

			if (parentEvent.type === "condition") {
				// we need to update the event's delay
				newAction.delay = convertTimeWithUnitToSeconds(
					parentEvent.directValue
				);
				if (ancestorEvent) {
					if (ancestorEvent.type == "action") {
						const ancestorUpdatedEvent = {
							name: getActionName(ancestorEvent.actionName),
							parent_id: currentEvent.beId
								? currentEvent.beId
								: currentEvent.id,
							email_settings: ancestorEvent.email,
							condition: "none",
							type: "email",
							update: true,
							id: ancestorEvent.beId
								? ancestorEvent.beId
								: getId(ancestorEvent.id, "action"),
						};
						ancestorUpdatedEvent.delay = 0;
						returnedArray.push(ancestorUpdatedEvent);
					}
				}
			}
			return returnedArray;
		}
		if (currentEvent.type === "condition") {
			const returnedArray = [];
			const newAction = {
				name: "##delay_only_code##",
				parent_id: parentEvent.id.includes("subscribed")
					? null
					: getId(
						parentEvent.id,
						parentEvent.type === "action" ? "action" : "delay"
						  ) || null,
				delay: convertTimeWithUnitToSeconds(currentEvent.directValue),
				id: currentEvent.id,
				email_settings: {
					content: {},
					sender: { id: defaultSenderId },
				},
				condition: "none",
				type: "email",
				create: true,
			};
			returnedArray.push(newAction);
			if (ancestorEvent) {
				if (ancestorEvent.type == "condition") {
					const ancestorUpdatedEvent = {
						name: getActionName(ancestorEvent.actionName),
						parent_id: currentEvent.id,
						email_settings: events.filter(
							(event) =>
								event.id === `${getId(ancestorEvent.id, "delay")}_action`
						)[0]?.email || {
							content: {},
							sender: { id: defaultSenderId },
						},
						condition: "none",
						type: "email",
						id: getId(ancestorEvent.id, "delay"),
						delay: convertTimeWithUnitToSeconds(ancestorEvent.directValue),
						update: true,
					};
					returnedArray.push(ancestorUpdatedEvent);
					return returnedArray;
				} else if (ancestorEvent.type === "action") {
					if (parentEvent.type === "condition") {
						const newAction = {
							name: "##delay_only_code##",
							parent_id: parentEvent.id.includes("subscribed")
								? null
								: parentEvent.beId
									? parentEvent.beId
									: getId(parentEvent.parentEventId, "both") || null,
							delay: convertTimeWithUnitToSeconds(parentEvent.directValue),
							id: parentEvent.id,
							email_settings: {
								content: {},
								sender: { id: defaultSenderId },
							},
							condition: "none",
							type: "email",
							create: true,
						};
						const ancestorUpdatedEvent = {
							name: getActionName(ancestorEvent.actionName),
							parent_id: parentEvent.beId ? parentEvent.beId : parentEvent.id,
							email_settings: ancestorEvent.email,
							condition: "none",
							type: "email",
							id: ancestorEvent.beId
								? ancestorEvent.beId
								: getId(ancestorEvent.id, "action"),
							delay: convertTimeWithUnitToSeconds(currentEvent.directValue),
							update: true,
						};
						return [newAction, ancestorUpdatedEvent];
					} else {
						const ancestorUpdatedEvent = {
							name: getActionName(ancestorEvent.actionName),
							parent_id: getId(ancestorEvent.parentEventId, "both"),
							email_settings: ancestorEvent.email,
							condition: "none",
							type: "email",
							id: getId(ancestorEvent.id, "action"),
							delay: convertTimeWithUnitToSeconds(currentEvent.directValue),
							update: true,
						};
						return [ancestorUpdatedEvent];
					}
				}
			} else return returnedArray;
		}
		return;
	}
	case "update": {
		if (currentEvent.type === "action") {
			const newAction = {
				name: getActionName(currentEvent.actionName),
				parent_id:
						getId(
							parentEvent.id,
							parentEvent.type === "action" ? "action" : "delay"
						) || null,
				delay: 0,
				id: getId(currentEvent.id, "action"),
				email_settings: currentEvent.email,
				condition: "none",
				type: "email",
				update: true,
			};
			return [newAction];
		} else if (currentEvent.type === "condition") {
			if (ancestorEvent.type === "action") {
				const newAction = {
					name: getActionName(ancestorEvent.actionName),
					parent_id:
							getId(
								parentEvent.id,
								parentEvent.type === "action" ? "action" : "delay"
							) || null,
					delay: convertTimeWithUnitToSeconds(currentEvent.directValue),
					id: getId(currentEvent.id, "delay"),
					email_settings: ancestorEvent.email,
					condition: "none",
					type: "email",
					update: true,
				};
				return [newAction];
			} else if (ancestorEvent.type === "condition") {
				const newAction = {
					name: "##delay_only_code##",
					parent_id:
							getId(
								parentEvent.id,
								parentEvent.type === "action" ? "action" : "delay"
							) || null,
					delay: convertTimeWithUnitToSeconds(currentEvent.directValue),
					id: getId(currentEvent.id, "delay"),
					email_settings: {
						content: {},
						sender: { id: defaultSenderId },
					},
					condition: "none",
					type: "email",
					update: true,
				};
				return [newAction];
			}
		}
		return;
	}
	}
};
const isActionSubjectFieldValid = (content) => {
	return (
		content.subject !==
			I18n._translate("app-automations-action-default_subjectLine") &&
		content.subject
	);
};
const isActionSenderFieldValid = (sender, actionId) => {
	const sendersList = store.getState().senders.senders?.data;
	const activeAutomation = store.getState().automationsV1.activeAutomation;
	if (sendersList && sendersList.filter((s) => s.id == sender.id).length > 0) {
		const senderEmail = sendersList.filter((s) => s.id == sender.id)[0].email;
		const storedValue = window.localStorage.getItem(
			`${activeAutomation.id}_${senderEmail}`
		);
		if (storedValue == "ignored") return true;
		const domain = getDomainFromEmail(senderEmail);
		const isPrivate = isDomainPrivate(domain);
		return isPrivate;
	}
	return false;
};
const validateActions = (actions) => {
	const listOfInvalidActions = [];
	actions.forEach((action) => {
		const validContent = isEmailContentValid(action.data.email.content);
		const validSubject = isActionSubjectFieldValid(action.data.email.content);
		const validSender = isActionSenderFieldValid(
			action.data.email.sender,
			action.id
		);
		const isEdited = action.data.isEmailEdited !== false;
		if (!validContent || !validSubject || !isEdited || !validSender) {
			const invalidAction = {
				action: action,
				issues: [],
			};
			// the order here is important, as the errors list will have the same order
			!validContent &&
				invalidAction.issues.push({ key: "content", resolved: false });
			!isEdited &&
				invalidAction.issues.push({ key: "notEdited", resolved: false });
			!validSender &&
				invalidAction.issues.push({ key: "sender", resolved: false });
			!validSubject &&
				invalidAction.issues.push({ key: "subject", resolved: false });
			listOfInvalidActions.push(invalidAction);
		}
	});
	return listOfInvalidActions;
};
const isAutomationHasOnlyOneDummyAction = (automationEvents) => {
	if (automationEvents) {
		if (automationEvents.length > 0) {
			if (
				automationEvents.filter((event) => {
					if (event.data) {
						if (event.data.type === EVENTS.ACTION) return true;
					}
					return false;
				}).length > 0
			) {
				return false;
			}
		}
		return true;
	}
};
const isAutomationHasTwoConsectiveDelays = (automationEvents) => {
	return automationEvents.every((event, index) => {
		if (event.data.type === EVENTS.CONDITION) {
			if (automationEvents[index + 1]?.data?.type === EVENTS.ACTION) {
				return true;
			}
			return false;
		}
		return true;
	});
};
const validateAutomation = (automationEvents) => {
	const isAutomationHasTwoConsectiveDelaysValue =
		isAutomationHasTwoConsectiveDelays(automationEvents);
	const isAutomationHasOnlyOneDummyActionValue =
		!isAutomationHasOnlyOneDummyAction(automationEvents);
	if (
		!isAutomationHasOnlyOneDummyActionValue ||
		!isAutomationHasTwoConsectiveDelaysValue
	) {
		const automationErrorObject = {
			id: "workflow",
			notAction: true,
			data: {
				errors: [],
				name: "Action",
			},
		};
		!isAutomationHasOnlyOneDummyActionValue &&
			automationErrorObject.data.errors.push({
				key: "automationHasOnlyOneDummyActionValue",
				resolved: false,
			});
		!isAutomationHasTwoConsectiveDelaysValue &&
			isAutomationHasOnlyOneDummyActionValue &&
			automationErrorObject.data.errors.push({
				key: "automationHasTwoConsectiveDelaysValue",
				resolved: false,
			});
		return automationErrorObject;
	} else return null;
};

export {
	validateActions,
	constructEventObject,
	getEventType,
	getEndCoachmarkNode,
	mapActionToConditionAndActionEvents,
	initalizeParentsAndAncestorsForEvents,
	sortActionsBasedOnParent,
	convertSecondsToNearestUnit,
	convertTimeWithUnitToSeconds,
	getNodeId,
	getEdgeId,
	isNewEvent,
	adjustParentAndAncestorEvents,
	createEventsTree,
	createActionsFromEventsTree,
	updateActionsConditionsAndDelays,
	createActionFromEvent,
	initializeEventsUIData,
	getUpdatedEvents,
	getDataEventsOnly,
	validateAutomation,
	getNodeYValue,
};
