/**
 * This file is responsible for handling the service calls for the account
 */
import { callAPI, GATEWAY_PROXY, METHODS } from "utils/apiUtils";

export function AuthenticateDomainService(
	options = { domain: "", protocol: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + `/domains/${options.domain}/${options.protocol}`,
			method: METHODS.get,
		},
		successCallback,
		failureCallback
	);
}

export function ListDkimService(
	options,
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + "/brands/default/dkim",
			method: METHODS.get,
		},
		successCallback,
		failureCallback
	);
}

export function CreateDkimService(
	options,
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + "/brands/default/dkim",
			method: METHODS.post,
			body: options,
		},
		successCallback,
		failureCallback
	);
}
