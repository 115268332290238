export const SET_CONTACTS = "SET_CONTACTS";
export const RESET_CONTACTS = "RESET_CONTACTS";

export const setContacts = (contacts) => {
	return {
		type: SET_CONTACTS,
		contacts,
	};
};

export const resetContacts = () => {
	return {
		type: RESET_CONTACTS,
	};
};
