import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getBrand } from "assets/js/data/branding/actions";
import CakeStore from "./store";
import { Provider } from "react-redux";
import { bindActionCreators } from "redux";
import Cookies from "universal-cookie";
import TagManager from "cakemail-react-gtm-module";
import { setLocaleWithFallback } from "utils/languages";
import originalBrand from "assets/js/scenes/brand/data/originalBrand";

const BrandWrapper = (props) => {
	return (
		<Provider store={CakeStore}>
			<ConnectedBrand>{props.children}</ConnectedBrand>
		</Provider>
	);
};

BrandWrapper.propTypes = {
	children: PropTypes.any,
};

export default BrandWrapper;

export function setBrandHeadElements(data) {
	var link = document.getElementById("favicon");
	link.href = data.images["favicon"];
	link = document.getElementById("favicon-apple-touch");
	link.href = data.images["favicon-apple-touch"];
	link = document.getElementById("favicon-16");
	link.href = data.images["favicon-16"];
	link = document.getElementById("favicon-32");
	link.href = data.images["favicon-32"];
	link = document.getElementById("favicon-512");
	link.href = data.images["favicon-android-chrome-512"];
	link = document.getElementById("favicon-mstile");
	if (link) link.href = data.images["favicon-mstile"];

	document.title = data.name;
}

const Brand = (props) => {
	useEffect(() => {
		props.getBrand((data) => {
			const cookies = new Cookies();
			cookies.set("app_hostname", window.location.hostname, { path: "/" });

			//Set Original brand data on completely missing brand data
			if (!data.name) {
				data.name = "";
			}
			if (!data?.images) {
				data.images = originalBrand.images;
			}
			if (!data?.url) {
				data.url = originalBrand.url;
			}
			if (!data?.theme) {
				data.theme = originalBrand.theme;
			}
			if (!data?.theme?.base) {
				data.theme.base = originalBrand.theme.base;
			}
			if (!data?.theme?.advance) {
				data.theme.advance = originalBrand.theme.advance;
			}

			setBrandHeadElements(data);

			const localCookie = cookies.get("locale");

			if (data?.client_config?.default_language && !localCookie) {
				setLocaleWithFallback(data?.client_config?.default_language);
			}

			if (data?.client_config?.google_tag_manager_id) {
				let config = {
					gtmId: data.client_config.google_tag_manager_id,
				};

				if (data?.client_config?.google_tag_manager_server) {
					config.server = data.client_config.google_tag_manager_server;
				}

				TagManager.initialize(config);
			}

			if (
				data?.client_config?.segment_config?.enabled &&
				data?.client_config?.segment_config?.id
			) {
				if (data?.client_config?.segment_config?.server) {
					injectSegment(
						data?.client_config?.segment_config?.id,
						data?.client_config?.segment_config?.server
					);
				} else {
					injectSegment(data?.client_config?.segment_config?.id);
				}

				if (!data?.client_config?.segment_config?.disabled_events) {
					data.client_config.segment_config.disabled_events = [];
				}
			}

			if (
				data?.client_config?.profitwell_config?.id &&
				data?.client_config?.profitwell_config?.enabled
			) {
				injectProfitwell(data?.client_config?.profitwell_config?.id);
			}

			// Fallbacks
			if (!data.images["ill-add-contacts"]) {
				data.images["ill-add-contacts"] =
					originalBrand.images["ill-add-contacts"];
			}

			if (!data.images["ill-workflow"]) {
				data.images["ill-workflow"] = originalBrand.images["ill-workflow"];
			}

			// empty states
			if (!data.images["ill-empty-automations"]) {
				data.images["ill-empty-automations"] =
					originalBrand.images["ill-empty-automations"];
			}
			if (!data.images["ill-empty-campaigns-drafts"]) {
				data.images["ill-empty-campaigns-drafts"] =
					originalBrand.images["ill-empty-campaigns-drafts"];
			}
			if (!data.images["ill-empty-campaigns-outbox"]) {
				data.images["ill-empty-campaigns-outbox"] =
					originalBrand.images["ill-empty-campaigns-outbox"];
			}
			if (!data.images["ill-empty-campaigns-sent"]) {
				data.images["ill-empty-campaigns-sent"] =
					originalBrand.images["ill-empty-campaigns-sent"];
			}
			if (!data.images["ill-empty-campaigns-templates"]) {
				data.images["ill-empty-campaigns-templates"] =
					originalBrand.images["ill-empty-campaigns-templates"];
			}
			if (!data.images["ill-empty-contacts"]) {
				data.images["ill-empty-contacts"] =
					originalBrand.images["ill-empty-contacts"];
			}
			if (!data.images["ill-empty-listing"]) {
				data.images["ill-empty-listing"] =
					originalBrand.images["ill-empty-listing"];
			}
			if (!data.images["ill-empty-forms"]) {
				data.images["ill-empty-forms"] =
					originalBrand.images["ill-empty-forms"];
			}

			return data;
		});
	}, []);
	return props.brand ? props.children : null;
};

export const ConnectedBrand = connect(
	(state) => {
		return {
			brand: state.brand,
		};
	},
	(dispatch) =>
		bindActionCreators(
			{
				getBrand,
			},
			dispatch
		)
)(Brand);

const injectSegment = (id, server = "cdn.segment.com") => {
	var gtmScript1 = document.createElement("script");
	gtmScript1.innerHTML =
		"!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error('Segment snippet included twice.');else{analytics.invoked=!0;analytics.methods=['trackSubmit','trackClick','trackLink','trackForm','pageview','identify','reset','group','track','ready','alias','debug','page','once','off','on','addSourceMiddleware','addIntegrationMiddleware','setAnonymousId','addDestinationMiddleware'];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement('script');t.type='text/javascript';t.async=!0;t.src='https://" +
		server +
		"/analytics.js/v1/' + key + '/analytics.min.js';var n=document.getElementsByTagName('script')[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey='YOUR_WRITE_KEY';analytics.SNIPPET_VERSION='4.15.2'; " +
		"analytics.load('" +
		id +
		"'); " +
		"analytics.page({" +
		"'path' : window.location.hash," +
		"'url' : window.location.href ," +
		"'search' : window.location.search," +
		"'name' : 'App loaded'," +
		"}); }}();";
	document.head.appendChild(gtmScript1);
};

const injectProfitwell = (id) => {
	var profitWellScript = document.createElement("script");
	profitWellScript.innerHTML =
		"(function(i,s,o,g,r,a,m){i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)};a=s.createElement(g);m=s.getElementsByTagName(g)[0];a.async=1;a.src=r+'?auth='+ s.getElementById(o+'-js').getAttribute('data-pw-auth');m.parentNode.insertBefore(a,m);})(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js');";
	profitWellScript.id = "profitwell-js";
	profitWellScript.setAttribute("data-pw-auth", id);
	document.head.appendChild(profitWellScript);
};
