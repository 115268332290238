export const PREVIOUS_CONTACT = "SET_PREVIOUS_CONTACT";
export const NEXT_CONTACT = "SET_NEXT_CONTACT";

export const setPreviousContact = (previousContact) => {
	return {
		type: PREVIOUS_CONTACT,
		previousContact,
	};
};

export const setNextContact = (nextContact) => {
	return {
		type: NEXT_CONTACT,
		nextContact,
	};
};
