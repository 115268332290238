export const actionTypes = {
	CHANGE_CURRENT_LANGUAGE: "CHANGE_CURRENT_LANGUAGE",
	SET_LANGUAGES: "SET_LANGUAGES",
};

export const changeCurrentLanguage = (current) => {
	return {
		type: actionTypes.CHANGE_CURRENT_LANGUAGE,
		current,
	};
};

export const setLanguages = (languages) => {
	return {
		type: actionTypes.SET_LANGUAGES,
		languages,
	};
};
