const defaultAutomation = {
	name: "Test automation - welcome-loop-clicked",
	trigger: [
		{
			args: {
				list_id: 8663159,
			},
			name: "Contact.Added",
		},
	],
	main: {
		steps: [],
	},
};

const RepresentableSteps = [
	"TransactionalEmail.Send",
	"Time.Sleep",
	"Condition.Switch",
	"CampaignEmail.Send",
	"Contact.SetCustomAttribute",
	"Contact.Add",
	"Contact.Remove",
	"Time.SleepUntil",
];

export { RepresentableSteps };
