import store from "assets/js/store";
import { DialogHandler, Icons } from "cakemail-ui-components/lib/react";
import { Translate, I18n } from "react-redux-i18n";
import React from "react";
import { openBillingPortal } from "utils/apiUtils";
import { TooltipCoachmark } from "cakemail-ui-components/lib/react";

const LIMIT_FEATURE = {
	LISTS: "lists",
	USERS: "users",
	CAMPAIGN_BLUEPRINTS: "campaign_blueprints",
	AUTOMATION_CONDITIONS: "automation_conditions",
	USE_CAMPAIGN_BLUEPRINTS: "use_campaign_blueprints",
	USE_AUTOMATION_CONDITION: "use_automation_conditions",
	USE_AUTOMATION_CUSTOM_WEBHOOK: "use_automation_customwebhooks",
	USE_HTML_EDITOR: "use_html_editor",
	USE_CONTACT_EXPORT: "use_contact_export",
	USE_CUSTOM_MERGE_TAG: "use_custom_merge_tags",
	USE_LIST_REDIRECTION: "use_list_redirection",
	USE_BEHAVIORAL_SEGMENTATION: "use_behavioral_segmentation",
};

const FREE_PLAN = {
	lists: 1,
	users: 1,
	campaign_blueprints: 0,
	automation_conditions: 0,
	use_campaign_blueprints: false,
	use_automation_conditions: true,
	use_automation_customwebhooks: false,
	use_html_editor: false,
	use_contact_export: false,
	use_list_redirection: false,
	use_custom_merge_tags: false,
	use_behavioral_segmentation: false,
	insert_reseller_logo: true,
};

const GROWTH_PLAN = {
	lists: 3,
	users: 5,
	campaign_blueprints: 1,
	automation_conditions: 1,
	use_campaign_blueprints: true,
	use_automation_conditions: false,
	use_automation_customwebhooks: true,
	use_html_editor: true,
	use_contact_export: true,
	use_custom_merge_tags: true,
	use_list_redirection: true,
	use_behavioral_segmentation: true,
	insert_reseller_logo: false,
};

const PREMIUM_PLAN = {
	lists: -1,
	users: -1,
	campaign_blueprints: -1,
	automation_conditions: -1,
	use_campaign_blueprints: true,
	use_automation_conditions: true,
	use_automation_customwebhooks: true,
	use_html_editor: true,
	use_contact_export: true,
	use_custom_merge_tags: true,
	use_list_redirection: true,
	use_behavioral_segmentation: true,
	insert_reseller_logo: false,
};

function PLAN_TYPE() {
	return {
		free: I18n.t("app-general-plan-type-free"),
		growth: I18n.t("app-general-plan-type-growth"),
		premium: I18n.t("app-general-plan-type-premium"),
	};
}

function LIMIT_DIALOG_TEXT(count) {
	const resellerName = store.getState().brand?.name;
	const planType = getPlanType();

	return {
		[LIMIT_FEATURE.LISTS]: {
			title: I18n.t("app-restriction-list_limit-title", {
				count: count,
				resellerName: resellerName,
				planType: planType || "",
			}),
			text: I18n.t("app-restriction-list_limit-desc"),
			subText: "",
		},
		[LIMIT_FEATURE.USERS]: {
			title: I18n.t("app-restriction-user_limit-title", {
				count: count,
				resellerName: resellerName,
				planType: planType || "",
			}),
			text: I18n.t("app-restriction-user_limit-desc"),
			subText: "",
		},
		[LIMIT_FEATURE.CAMPAIGN_BLUEPRINTS]: {
			title: I18n.t("app-restriction-campaign-blueprint-uses-title", {
				planType: planType || "",
			}),
			text: I18n.t("app-restriction-campaign-blueprint-uses-desc"),
			subText: I18n.t("app-restriction-campaign-blueprint-uses-sub-desc"),
		},
		[LIMIT_FEATURE.AUTOMATION_CONDITIONS]: {
			title: I18n.t("app-restriction-branching-automation-uses-title", {
				planType: planType || "",
				resellerName: resellerName,
			}),
			text: I18n.t("app-restriction-branching-automation-uses-desc"),
			subText: I18n.t("app-restriction-branching-automation-uses-sub-desc"),
		},
		[LIMIT_FEATURE.USE_CAMPAIGN_BLUEPRINTS]: {
			title: I18n.t("app-restriction-campaign-blueprint-feature-title", {
				planType: planType || "",
			}),
			text: I18n.t("app-restriction-campaign-blueprint-feature-desc"),
			subText: I18n.t("app-restriction-campaign-blueprint-feature-sub-desc"),
		},
		[LIMIT_FEATURE.USE_AUTOMATION_CONDITION]: {
			title: I18n.t("app-restriction-branching-automation-feature-title", {
				planType: planType || "",
				resellerName: resellerName,
			}),
			text: I18n.t("app-restriction-branching-automation-feature-desc"),
			subText: I18n.t("app-restriction-branching-automation-feature-sub-desc"),
		},
		[LIMIT_FEATURE.USE_HTML_EDITOR]: {
			title: I18n.t("app-restriction-html-editor-feature-title", {
				count: count,
				resellerName: resellerName,
				planType: planType || "",
			}),
			text: I18n.t("app-restriction-html-editor-feature-desc"),
			subText: I18n.t("app-restriction-html-editor-feature-sub-desc"),
		},
		[LIMIT_FEATURE.USE_CONTACT_EXPORT]: {
			title: I18n.t("app-restriction-contact-export-feature-title", {
				count: count,
				resellerName: resellerName,
				planType: planType || "",
			}),
			text: I18n.t("app-restriction-contact-export-feature-desc"),
			subText: "",
		},
		[LIMIT_FEATURE.USE_CUSTOM_MERGE_TAG]: {
			title: I18n.t("app-restriction-custom-mergetag-feature-title", {
				count: count,
				resellerName: resellerName,
				planType: planType || "",
			}),
			text: I18n.t("app-restriction-custom-mergetag-feature-desc"),
			subText: I18n.t("app-restriction-custom-mergetag-feature-sub-desc"),
		},
		[LIMIT_FEATURE.USE_LIST_REDIRECTION]: {
			title: I18n.t("app-restriction-list-redirection-feature-title", {
				count: count,
				resellerName: resellerName,
				planType: planType || "",
			}),
			text: I18n.t("app-restriction-list-redirection-feature-desc"),
			subText: "",
		},
		[LIMIT_FEATURE.USE_BEHAVIORAL_SEGMENTATION]: {
			title: I18n.t("app-restriction-behavioral-segmentation-feature-title", {
				resellerName: resellerName,
				planType: planType || "",
			}),
			text: I18n.t("app-restriction-list-redirection-feature-desc"),
			subText: "",
		},
		[LIMIT_FEATURE.USE_AUTOMATION_CUSTOM_WEBHOOK]: {
			title: I18n.t("app-restriction-automation-custom-webhook-title", {
				resellerName: resellerName,
				planType: planType || "",
			}),
			text: I18n.t("app-restriction-automation-custom-webhook-desc"),
			subText: "app-restriction-automation-custom-webhook-sub-desc",
		},
	};
}

const usageMap = {
	[LIMIT_FEATURE.LISTS]: "currentLists",
	[LIMIT_FEATURE.USERS]: "currentUsers",
};

//Make this a condition with a return if it returns true
function showLimitFeatureDialog(name, options = {}) {
	let clientLimits = store.getState().global.account?.usage_limits;
	const texts = LIMIT_DIALOG_TEXT(clientLimits[name]);

	const mainFunction = (btnRef) => {
		btnRef.current.startAnimation();
		openBillingPortal(null, btnRef);
	};

	function callback() {
		DialogHandler.hide();
		DialogHandler.show({
			className: "limitFeatureDialog",
			title: options.title ? options.title : texts[name].title,
			content: <p>{options.text ? options.text : texts[name].text}</p>,
			primaryBtnText: options.primaryBtnText || (
				<Translate value="app-menu-account-usage-upgrade_your_plan" />
			),
			primaryBtnOnClick: options.primaryBtnOnClick || mainFunction,
			secondaryBtnText: options.secondaryBtnText || (
				<Translate value="app-general-cancel" />
			),
			secondaryBtnOnClick: options.secondaryBtnOnClick || null,
		});
	}

	return !limitsFeaturesAccess(name, null, callback);
}

function limitsFeaturesAccess(name, comparable, callback = () => {}) {
	let clientLimits = comparable
		? comparable
		: store.getState().global.account?.usage_limits;
	let brandDisabledLimitsFeatures =
		store.getState().brand?.config?.disabledLimitsFeatures || [];

	if (brandDisabledLimitsFeatures.includes(name)) return true;

	const clientUsage = store.getState().global.usage;
	const uses = clientUsage?.[usageMap[name]] || undefined;

	if (isNaN(uses)) {
		if (!clientLimits[name]) callback();
		return clientLimits[name];
	} else {
		const canDoAction = clientLimits[name] === -1 || uses < clientLimits[name];
		if (!canDoAction) callback();
		return canDoAction;
	}
}

export function getPlanType(name = null) {
	const tmp = (
		name ||
		store.getState().global?.customer?.subscription?.plan?.name ||
		"free"
	).toLowerCase();
	if (tmp.includes("free")) return PLAN_TYPE().free;
	else if (tmp.includes("growth")) return PLAN_TYPE().growth;
	else if (tmp.includes("premium")) return PLAN_TYPE().premium;
	else return null;
}

function showLimitFeatureCoachmark(
	names = [],
	tooltipOption = { context: "" },
	onClick = () => {}
) {
	let clientLimits = store.getState().global.account?.usage_limits;

	const planType = PLAN_TYPE();
	const curPlanType = planType?.[getPlanType()];
	const isMainBrand = true; // store.getState().brand?.config?.partner_account_id === 133315;
	const texts = LIMIT_DIALOG_TEXT(clientLimits[names[0]]);

	const content = (
		<div className="featureTable">
			<div className="featureTableRow rowTitle">
				<div>Feature</div>
				{isMainBrand && (
					<>
						<div>{planType.free}</div>
						<div>{planType.growth}</div>
						<div>{planType.premium}</div>
					</>
				)}
			</div>

			{names.map((name) => {
				return (
					<div className="featureTableRow rowContent">
						<div>
							<p>{texts[name].subText}</p>
						</div>
						{isMainBrand && (
							<>
								<div>
									{limitsFeaturesAccess(name, FREE_PLAN) ? (
										<Icons name="glyph-checkmark" className="checkmark" />
									) : (
										<Icons name="glyph-remove" />
									)}
								</div>
								<div>
									{limitsFeaturesAccess(name, GROWTH_PLAN) ? (
										<Icons name="glyph-checkmark" className="checkmark" />
									) : (
										<Icons name="glyph-remove" />
									)}
								</div>
								<div>
									{limitsFeaturesAccess(name, PREMIUM_PLAN) ? (
										<Icons name="glyph-checkmark" className="checkmark" />
									) : (
										<Icons name="glyph-remove" />
									)}
								</div>
							</>
						)}
					</div>
				);
			})}
		</div>
	);

	return (
		<TooltipCoachmark
			state={"show"}
			position="top"
			color="white"
			className={"limitFeatureCoachmark"}
			context={tooltipOption.context}
			isControlled
			arrowPosition={"arrow-right"}
			adjustedXY={{ y: -8, x: 0 }}
			content={
				<>
					<div className="coachmark-title">
						<h4>
							<Translate
								value="app-restriction-feature-coachmark-title"
								planType={curPlanType}
							/>
						</h4>
					</div>
					<div className="coachmark-text-content">{content}</div>
					<div className="coachmark-footer">
						<span
							onClick={(e) => {
								e.preventDefault();
								openBillingPortal(null, null);
							}}
						>
							<a href="#">
								<Translate value="app-menu-account-usage-upgrade_your_plan" />
							</a>
							<Icons name="glyph-share" />
						</span>
						<a
							href="#"
							onClick={(e) => {
								e.preventDefault();
								onClick();
							}}
						>
							<Translate value="app-general-got-it" />
						</a>
					</div>
				</>
			}
		/>
	);
}

export {
	LIMIT_FEATURE,
	showLimitFeatureDialog,
	limitsFeaturesAccess,
	showLimitFeatureCoachmark,
};
