/**
 * This file is responsible for handling the service calls for the templates
 */
import store from "../store";
import {
	callAPI,
	chameau,
	DEFAULT_LIST_PARAMS,
	GATEWAY_PROXY,
	METHODS,
} from "utils/apiUtils";
import {
	SEGMENT_EVENTS,
	trackSegmentEvent,
} from "assets/js/const/SegmentEvents";

export function createTemplateService(
	options = { template: null },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + "/templates",
			body: options.template,
			query: { account_id: store.getState().global.account.id },
		},
		(data) => {
			trackSegmentEvent(SEGMENT_EVENTS.TEMPLATE_CREATED, data);
			successCallback(data);
		},
		failureCallback
	);
}

export function listTemplateService(
	options = DEFAULT_LIST_PARAMS,
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + "/templates",
			method: METHODS.get,
			query: {
				...chameau(options),
				account_id: store.getState().global.account.id,
			},
		},
		successCallback,
		failureCallback
	);
}

export function getTemplateService(
	options = { id: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + `/templates/${options.id}`,
			method: METHODS.get,
			query: { account_id: store.getState().global.account.id },
		},
		successCallback,
		failureCallback
	);
}

export function deleteTemplateService(
	options = { id: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + `/templates/${options.id}`,
			method: METHODS.delete,
			query: { account_id: store.getState().global.account.id },
		},
		(data) => {
			trackSegmentEvent(SEGMENT_EVENTS.TEMPLATE_DELETED, data);
			successCallback(data);
		},
		failureCallback
	);
}

export function patchTemplateService(
	options = { template: {}, id: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + `/templates/${options.id}`,
			method: METHODS.patch,
			body: options.template,
			query: { account_id: store.getState().global.account.id },
		},
		(data) => {
			trackSegmentEvent(SEGMENT_EVENTS.TEMPLATE_UPDATED, data.data);
			successCallback(data);
		},
		failureCallback
	);
}

export function renderTemplateService(
	options = { id: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + `/templates/${options.id}/render`,
			method: METHODS.get,
			query: { account_id: store.getState().global.account.id },
		},
		successCallback,
		failureCallback
	);
}
