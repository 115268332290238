export const USERS = "SET_USERS";
export const SINGLE_USER = "SET_SINGLE_USER";

export const setUsers = (users) => {
	return {
		type: USERS,
		users,
	};
};

export const setSingleUser = (singleUser) => {
	return {
		type: SINGLE_USER,
		singleUser,
	};
};
