import promisePool from "es6-promise-pool";
import {
	deleteContactService,
	getContactsOfSegmentService,
	getContactsOfListService,
	addContactToSuppressionListService,
	unsubscribeContactService,
	getContactService,
} from "assets/js/services/contactService";

export const LISTED_SELECTED_CONTACTS = "SET_SELECTED_CONTACTS";
export const LISTED_UNSELECTED_CONTACTS = "SET_UNSELECTED_CONTACTS";
export const COL_ORDER = "SET_COL_ORDER";
export const SORT_CHANGED = "SET_SORT_CHANGED";
export const RESET_TABLE_GRID_COL_ORDER = "RESET_TABLE_GRID_COL_ORDER";
export const SELECT_ALL_CHANGED = "SELECT_ALL_CHANGED";

export const setSelectedContacts = (selectedContacts) => {
	return {
		type: LISTED_SELECTED_CONTACTS,
		selectedContacts,
	};
};
export const setUnselectedContacts = (unSelectedContacts) => {
	return {
		type: LISTED_UNSELECTED_CONTACTS,
		unSelectedContacts,
	};
};
export const setSelectAll = (selectAllChecked) => {
	return {
		type: SELECT_ALL_CHANGED,
		selectAllChecked,
	};
};
export const setColOrder = (tableColOrder) => {
	return {
		type: COL_ORDER,
		tableColOrder,
	};
};

export const setSortChanged = (sortChange) => {
	return {
		type: SORT_CHANGED,
		sortChange,
	};
};

export const resetColOrder = () => {
	return {
		type: RESET_TABLE_GRID_COL_ORDER,
	};
};
const getAllContacts = (
	segment,
	listId,
	accountId,
	totalContactsCount,
	query,
	successCallback,
	failureCallback,
	getMapFunction,
	actionFunction
) => {
	const allContacts = [];
	const getService = (page, perPage) => {
		return new Promise(function (resolve, reject) {
			if (segment) {
				getContactsOfSegmentService(
					{
						segmentId: segment.id,
						listId: listId,
						page: page,
						perPage: perPage,
						filter: query,
						withCount: true,
					},
					(data) => {
						allContacts.push(...data.data.map(getMapFunction));
						resolve();
					},
					() => {
						reject();
					}
				);
			} else {
				getContactsOfListService(
					{
						listId: listId,
						page: page,
						perPage: perPage,
						filter: query,
						withCount: true,
					},
					(data) => {
						allContacts.push(...data.data.map(getMapFunction));
						resolve();
					},
					() => {
						reject();
					}
				);
			}
		});
	};
	let page = 0;
	const perPage = 100;
	const pageCount = totalContactsCount / perPage;
	var promiseProducer = function () {
		if (page < pageCount) {
			page++;
			return getService(page, perPage);
		} else {
			return null;
		}
	};
	const pool = new promisePool(promiseProducer, 10);

	pool.start().then(() => {
		actionFunction(
			accountId,
			listId,
			allContacts.filter((value) => typeof value !== "undefined"),
			successCallback,
			failureCallback
		);
	});
};
export const deleteAllContacts = (
	accountId,
	segment,
	listId,
	totalContactsCount,
	query,
	unSelectedContacts,
	successCallback,
	failureCallback
) => {
	return function (dispatch) {
		getAllContacts(
			segment,
			listId,
			accountId,
			totalContactsCount,
			query,
			successCallback,
			failureCallback,
			(contact) => {
				if (!unSelectedContacts.includes(contact.id)) {
					return contact.id;
				}
			},
			deleteSetOfContactsWithoutDispatching
		);
	};
};
export const deleteSetOfContacts = (
	accountId,
	selectedListId,
	selectedContactsIds,
	successCallback,
	failureCallback
) => {
	return function (dispatch) {
		deleteSetOfContactsWithoutDispatching(
			accountId,
			selectedListId,
			selectedContactsIds,
			successCallback,
			failureCallback
		);
	};
};
export const deleteSetOfContactsWithoutDispatching = (
	accountId,
	selectedListId,
	selectedContactsIds,
	successCallback,
	failureCallback
) => {
	const deleteService = (contactId) => {
		return new Promise(function (resolve, reject) {
			deleteContactService(
				{ listId: selectedListId, contactId: contactId },
				() => {
					resolve();
				},
				() => {
					reject();
				}
			);
		});
	};

	let index = 0;
	var promiseProducer = function () {
		if (index < selectedContactsIds.length) {
			index++;
			return deleteService(selectedContactsIds[index - 1]);
		} else {
			return null;
		}
	};
	const pool = new promisePool(promiseProducer, 20);

	pool.start().then(
		() => {
			successCallback();
		},
		() => failureCallback()
	);
};

export const suppressAllContacts = (
	accountId,
	segment,
	listId,
	totalContactsCount,
	query,
	unSelectedContacts,
	successCallback,
	failureCallback
) => {
	return function (dispatch) {
		getAllContacts(
			segment,
			listId,
			accountId,
			totalContactsCount,
			query,
			successCallback,
			failureCallback,
			(contact) => {
				if (!unSelectedContacts.includes(contact.id)) {
					return contact.email;
				}
			},
			suppressSetOfContactsWithoutDispatching
		);
	};
};

export const suppressSetOfContacts = (
	accountId,
	selectedContactsEmails,
	successCallback,
	failureCallback
) => {
	return function (dispatch) {
		suppressSetOfContactsWithoutDispatching(
			accountId,
			"",
			selectedContactsEmails,
			successCallback,
			failureCallback
		);
	};
};
export const suppressSetOfContactsWithoutDispatching = (
	accountId,
	listId,
	selectedContactsEmails,
	successCallback,
	failureCallback
) => {
	const suppressService = (email) => {
		return new Promise(function (resolve, reject) {
			addContactToSuppressionListService(
				{ email: email },
				() => {
					resolve();
				},
				() => {
					reject();
				}
			);
		});
	};

	let index = 0;
	var promiseProducer = function () {
		if (index < selectedContactsEmails.length) {
			index++;
			return suppressService(selectedContactsEmails[index - 1]);
		} else {
			return null;
		}
	};
	const pool = new promisePool(promiseProducer, 20);
	pool.start().then(
		() => {
			successCallback();
		},
		() => failureCallback()
	);
};
export const unsubscribeAllContacts = (
	accountId,
	segment,
	listId,
	totalContactsCount,
	query,
	unSelectedContacts,
	successCallback,
	failureCallback
) => {
	return function (dispatch) {
		getAllContacts(
			segment,
			listId,
			accountId,
			totalContactsCount,
			query,
			successCallback,
			failureCallback,
			(contact) => {
				if (
					contact.status != "unsubscribed" &&
					!unSelectedContacts.includes(contact.id)
				)
					return contact.id;
			},
			unsubscribeSetOfContactsWithoutDispatching
		);
	};
};
export const unsubscribeSetOfContacts = (
	accountId,
	selectedListId,
	selectedContactsIds,
	successCallback,
	failureCallback
) => {
	return function (dispatch) {
		unsubscribeSetOfContactsWithoutDispatching(
			accountId,
			selectedListId,
			selectedContactsIds,
			successCallback,
			failureCallback
		);
	};
};
export const unsubscribeSetOfContactsWithoutDispatching = (
	accountId,
	selectedListId,
	selectedContactsIds,
	successCallback,
	failureCallback
) => {
	const unsubscribeService = (contactId) => {
		return new Promise(function (resolve, reject) {
			unsubscribeContactService(
				{ listId: selectedListId, contactId: contactId },
				() => {
					resolve();
				},
				() => {
					reject();
				}
			);
		});
	};
	let index = 0;
	const cleanContactsIds = selectedContactsIds.filter((id) => id !== undefined);
	var promiseProducer = function () {
		if (index < cleanContactsIds.length) {
			index++;
			return unsubscribeService(cleanContactsIds[index - 1]);
		} else {
			return null;
		}
	};
	const pool = new promisePool(promiseProducer, 20);

	pool.start().then(
		() => {
			successCallback();
		},
		() => failureCallback()
	);
};
export const getListOfContactsDetails = (
	accountId,
	selectedListId,
	selectedContactsIds,
	successCallback,
	failureCallback
) => {
	const allContacts = [];
	const getContact = (contactId) => {
		return new Promise(function (resolve, reject) {
			getContactService(
				{ listId: selectedListId, contactId: contactId, accountId: accountId },
				(error, data, response) => {
					allContacts.push(data);
					resolve();
				},
				() => {
					reject();
				}
			);
		});
	};

	let index = 0;
	var promiseProducer = function () {
		if (index < selectedContactsIds.length) {
			index++;
			return getContact(selectedContactsIds[index - 1]);
		} else {
			return null;
		}
	};
	const pool = new promisePool(promiseProducer, 20);

	pool.start().then(
		() => {
			successCallback(allContacts);
		},
		() => failureCallback()
	);
};
