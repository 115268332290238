import { omit } from "lodash";

import {
	SET_AUTOMATION,
	LIST_AUTOMATIONS_SUCCESS,
	LIST_AUTOMATION_BLUEPRINTS_SUCCESS,
	UPDATE_AUTOMATION_EVENT,
	UPDATE_AUTOMATION_EVENTS,
	ADD_AUTOMATION_EVENT,
	RESET_AUTOMATION_EVENTS,
	CREATE_AUTOMATION_SUCCESS,
	GET_AUTOMATION_SUCCESS,
	UPDATE_ACTIVE_AUTOMATION,
	UPDATE_AUTOMATION_GENERAL_ISSUES,
	UPDATE_ACTIVE_AUTOMATION_SUCCESS,
	GET_ACTION_SUCCESS,
	LIST_AUTOMATION_ACTIONS_SUCCESS,
	DELETE_AUTOMATION_EVENT,
	UPDATE_ACTION_SUCCESS,
	DEACTIVATE_AUTOMATION_SUCCESS,
	ACTIVATE_AUTOMATION_SUCCESS,
	DELETE_ACTION_SUCCESS,
	UPDATE_AUTOMATION_SELECTED_EVENT,
	SET_EDITOR_MODE,
	GET_ACTION_STATS_SUCCESS,
	TOGGLE_HIDE_STATS,
	UPDATE_AUTOMATION_STATS_SUCCESS,
} from "./types";
import { TriggerEvents } from "assets/js/scenes/automations/automationsV1/containers/editor/InitialData";
import {
	mapActionToConditionAndActionEvents,
	sortActionsBasedOnParent,
	initalizeParentsAndAncestorsForEvents,
	initializeEventsUIData,
} from "../../../scenes/automations/automationsV1/containers/editor/Mapper";
import { EDITOR_MODE } from "assets/js/const/Automations";
let initialState = () => {
	return {
		automations: null,
		automationBluePrints: null,
		automationEvents: null,
		activeAutomation: null,
		actions: null,
		automationGeneralIssues: null,
		editorMode: EDITOR_MODE.WORKFLOW,
		stats: {},
	};
};

export const reducer = (state = initialState(), action) => {
	switch (action.type) {
	case SET_AUTOMATION:
		return { ...state, automations: action.automations };
	case LIST_AUTOMATIONS_SUCCESS:
		return { ...state, automations: action.data };
	case LIST_AUTOMATION_BLUEPRINTS_SUCCESS:
		return { ...state, automationBluePrints: action.data };
	case UPDATE_AUTOMATION_EVENT: {
		const updatedEvents = state.automationEvents.map((event) => {
			if (event.id === action.updatedEventId) {
				return action.updatedEvent;
			} else return event;
		});
		return { ...state, automationEvents: updatedEvents };
	}
	case UPDATE_AUTOMATION_EVENTS: {
		return { ...state, automationEvents: action.updatedEvents };
	}
	case UPDATE_AUTOMATION_GENERAL_ISSUES: {
		return { ...state, automationGeneralIssues: action.issues };
	}
	case DEACTIVATE_AUTOMATION_SUCCESS: {
		return {
			...state,
			activeAutomation: {
				...state.activeAutomation,
				status: "inactive",
			},
		};
	}
	case UPDATE_AUTOMATION_STATS_SUCCESS: {
		return {
			...state,
			stats: { ...state.stats, ["subscribers"]: action.data },
		};
	}
	case ACTIVATE_AUTOMATION_SUCCESS: {
		return {
			...state,
			activeAutomation: {
				...state.activeAutomation,
				status: "active",
			},
		};
	}
	case ADD_AUTOMATION_EVENT: {
		const updatedEvents = state.automationEvents
			? [...state.automationEvents, action.addedEvent]
			: [action.addedEvent];
		return { ...state, automationEvents: updatedEvents };
	}
	case RESET_AUTOMATION_EVENTS: {
		// on reseting the events we need to reset the active automation as well
		return {
			...state,
			automationEvents: null,
			activeAutomation: null,
			actions: null,
			editorMode: EDITOR_MODE.WORKFLOW,
			automationGeneralIssues: null,
			stats: {},
		};
	}
	case DELETE_AUTOMATION_EVENT: {
		return {
			...state,
			automationEvents: [...state.automationEvents].splice(
				state.automationEvents.findIndex(
					(event) => event.id === action.deletedEventId
				),
				1
			),
		};
	}
	case UPDATE_AUTOMATION_SELECTED_EVENT: {
		return {
			...state,
			automationEvents: state.automationEvents.map((node) => {
				if (node.id === action.id) {
					return {
						...node,
						data: {
							...node.data,
							selected: true,
						},
					};
				} else {
					return {
						...node,
						data: {
							...node.data,
							selected: false,
						},
					};
				}
			}),
		};
	}
	case GET_AUTOMATION_SUCCESS: {
		// in case of getting the automation, we will need to create/extract the trigger event from the
		// automation object retrieved
		return {
			...state,
			activeAutomation: {
				id: action.data.data.id,
				name: action.data.data.name,
				status: action.data.data.status,
				listId: action.data.data.audience.list_id,
				activated_on: action.data.data.activated_on,
			},
			stats: {
				...state.stats,
				subscribers: action.data.data.audience.contactsCount,
			},
		};
	}
	case UPDATE_ACTIVE_AUTOMATION_SUCCESS:
		// we need to map the triggerEvent to the automation object
		return {
			...state.activeAutomation,
			audience: {
				list_id: action.data.audience.list_id,
			},
		};
		//[...state.automationEvents, triggerEvent]}
	case LIST_AUTOMATION_ACTIONS_SUCCESS: {
		let mappedEvents = null;
		if (action.actions.data.length > 0) {
			mappedEvents = [];
			const sortedActions = sortActionsBasedOnParent(action.actions.data);
			sortedActions.forEach((data) => {
				const mappedEventForCurrentAction =
						mapActionToConditionAndActionEvents(data);
				mappedEvents.push(...mappedEventForCurrentAction);
			});
			mappedEvents = initalizeParentsAndAncestorsForEvents(mappedEvents);
		}
		const triggerEvent = {
			audience: action.automation.data.audience,
			baseId: "subscribed",
			id: `event_${+new Date()}`,
			...TriggerEvents()[0],
		};
		const updatedEvents = [];
		if (state.automationEvents) updatedEvents.push(...state.automationEvents);
		if (mappedEvents) updatedEvents.push(...mappedEvents);
		const updatedEventsWithUIData = initializeEventsUIData(
			[triggerEvent, ...updatedEvents].map((event) => {
				return {
					id: event.id,
					data: event,
				};
			})
		);
		return {
			...state,
			actions: action.actions.data,
			automationEvents: updatedEventsWithUIData,
		};
	}
	case GET_ACTION_SUCCESS: {
		const updatedEvents = state.automationEvents.map((event) => {
			if (event.id === `${action.action.data.id}_action`) {
				return {
					...event,
					data: {
						...event.data,
						email: { ...action.action.data.email_settings },
					},
				};
			}
			return { ...event };
		});
		return {
			...state,
			automationEvents: updatedEvents,
		};
	}
	case GET_ACTION_STATS_SUCCESS: {
		return {
			...state,
			stats: {
				...state.stats,
				[action.actionStats.id]: action.actionStats.data,
			},
		};
	}
	case UPDATE_ACTION_SUCCESS: {
		const updatedActions = [...state.actions];
		updatedActions.splice(
			updatedActions.findIndex(
				(automationAction) => automationAction.id === action.oldActionId
			),
			1
		);
		updatedActions.push(action.updatedAction);
		return { ...state, actions: updatedActions };
	}
	case DELETE_ACTION_SUCCESS: {
		const updatedActions = [...state.actions];
		updatedActions.splice(
			updatedActions.findIndex(
				(automationAction) => automationAction.id === action.oldActionId
			),
			1
		);
		return { ...state, actions: updatedActions };
	}
	case SET_EDITOR_MODE: {
		return { ...state, editorMode: action.editorMode };
	}
	default:
		const rest = omit(state, Object.keys(initialState()));
		return {
			...state,
		};
	}
};
