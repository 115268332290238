import {
	SENDERS_SORT_VALUE as SET_SENDERS_SORT_VALUE,
	SINGLE_SENDER as SET_SINGLE_SENDER,
	SENDERS_SEARCH_VALUE,
} from "./actions";

let initialState = () => {
	return {
		sendersSortValue: "-confirmed",
		sendersSearchValue: "",
		singleSender: null,
	};
};

export const reducer = (state = initialState(), action) => {
	switch (action.type) {
	case SET_SENDERS_SORT_VALUE:
		return { ...state, sendersSortValue: action.sendersSortValue };
		break;
	case SENDERS_SEARCH_VALUE:
		return { ...state, sendersSearchValue: action.search };
		break;
	case SET_SINGLE_SENDER:
		return { ...state, singleSender: action.singleSender };
		break;
	default:
		return state;
		break;
	}
};
