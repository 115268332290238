import React from "react";
import { Translate, I18n } from "react-redux-i18n";
import { isValidUrl } from "utils/urlUtils";
import * as checkPasswordStrength from "./PasswordValidator";

const PASSWORD_REQUIREMENTS = ["lowercase", "uppercase", "number", "symbol"];

export function isValidEmail(email, csvValidation) {
	if (csvValidation) {
		if (
			/^"*(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))"*$/.test(
				email
			)
		) {
			return true;
		}
	} else {
		if (
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
				email
			)
		) {
			return true;
		}
	}
	return false;
}

export function getInitialInputValues(inputName, initial) {
	return {
		[inputName]: initial ? initial : "",
		[inputName + "_valid"]: "",
		[inputName + "_message"]: "",
	};
}

export function validatePhone(phone, inputName) {
	let error = false;
	let message = <Translate value={"app-general-input-good"} />;
	let valid = "valid";

	if (phone !== "") {
		if (
			phone !== "" &&
			!/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(phone)
		) {
			error = true;
			valid = "error";
			message = (
				<Translate
					value={"app-general-input-" + inputName + "-message-invalid"}
				/>
			);
		}
	} else {
		valid = "";
		message = "";
	}

	return {
		[inputName]: phone,
		[inputName + "_valid"]: valid,
		[inputName + "_message"]: message,
		error: error,
	};
}

export function validateUrl(url, inputName) {
	let error = false;
	let message = <Translate value={"app-general-input-good"} />;
	let valid = "valid";

	if (url) {
		url = url.trim();

		if (url.match(/http[s]{0,1}:\/\//) === null) {
			url = "https://" + url;
		}

		if (!isValidUrl(url)) {
			error = true;
			message = (
				<Translate
					value={"app-general-input-" + inputName + "-message-invalid"}
				/>
			);
			valid = "error";
		}
	} else {
		valid = "";
		message = "";
	}

	return {
		[inputName]: url,
		[inputName + "_valid"]: valid,
		[inputName + "_message"]: message,
		error: error,
	};
}

export function validateEmail(email, validateEmptyOnly = false) {
	let error = false;
	let isEmpty = true;
	let isValid = false;

	if (email) {
		isEmpty = email.length === 0;
		isValid = isValidEmail(email);
	}

	let message = <Translate value={"app-general-input-good"} />;
	let valid = "valid";

	if (isEmpty) {
		message = I18n._translate("app-auth_validators_email_empty");
		valid = "empty";
		error = true;
	} else if (!validateEmptyOnly && !isValid) {
		message = I18n._translate("app-auth_validators_email_invalid");
		valid = "error";
		error = true;
	}

	return {
		valid: valid,
		message: message,
		error: error,
		value : email
	};
}

export function validatePassword(password, validateEmptyOnly = false) {
	let error = false;
	let isEmpty = true;
	let isMinReq = false;
	if (password) {
		isEmpty = password.length === 0;
	}

	let message = <Translate value={"app-general-input-good"} />;
	let valid = "valid";

	if (isEmpty) {
		message = I18n._translate("app-auth_validators_pwd_empty");
		valid = "empty";
		error = true;
	} else if (!validateEmptyOnly) {
		let passCheck = checkPasswordStrength(password);
		let difference = [];
		let passContains = new Set(passCheck.contains.map((item) => item.message));
		difference = [...difference, ...PASSWORD_REQUIREMENTS].filter(
			(requires) => !passContains.has(requires)
		);
		valid = "empty";
		error = true;

		if (
			password.length > 14 ||
			(password.length > 7 && difference.length === 0)
		) {
			error = false;
			valid = "valid";
		}

		if (error) {
			message = (
				<>
					<ul className="listHelper">
						{PASSWORD_REQUIREMENTS.map((item, index) => {
							return (
								<li
									key={index}
									className={difference.includes(item) ? "" : "included"}
								>
									{I18n._translate("app-auth_validators_pwd_" + item)}
								</li>
							);
						})}
						<li className={passCheck.length > 7 ? "included" : ""}>
							{I18n._translate("app-auth_validators_pwd_size")}
						</li>
						<li className={passCheck.length > 14 ? "included" : ""}>
							{I18n._translate("app-auth_validators_pwd_big-size")}
						</li>
					</ul>
				</>
			);
		}
	}

	return {
		valid: valid,
		message: message,
		error: error,
	};
}

export function validateGenericInput(input, inputName) {
	let error = false;

	let message = <Translate value={"app-general-input-good"} />;
	let valid = "valid";

	if (!isValidString(input)) {
		message = (
			<Translate value={"app-general-input-" + inputName + "-message"} />
		);
		valid = "empty";
		error = true;
	}

	return {
		[inputName]: input,
		[inputName + "_valid"]: valid,
		[inputName + "_message"]: message,
		error: error,
	};
}
export const isValidString = (string) => {
	if (string) return !/^ *$/.test(string);
	else return false;
};
/**
 * checks if the account address has missing data or not
 * @param {account data that needs to have address key} accountData
 * returns true if the account is missing data and false if the account data is proper
 */
export const checkIfAccountHasMissingInfo = (accountData) => {
	return (
		!accountData.address.address1 ||
		!accountData.address.city ||
		!accountData.address.country ||
		!accountData.address.postal_code
	);
};
export const validateSendTestEmails = (string) => {
	let emails = string
		.replace(/(\r\n|\n|\r )/gm, "")
		.trim()
		.split(",");
	let errors = [];
	let valid = "valid";

	if (string === "") {
		valid = "empty";
	}
	if (emails.length > 5) {
		valid = "error";
	} else {
		emails.forEach((email, index) => {
			const cleanedEmail = email.trim();
			if (!isValidEmail(cleanedEmail) && email !== "") {
				valid = "error";
				errors.push(cleanedEmail);
			}
		});
	}
	return {
		errors: errors.toString(),
		valid: valid,
	};
};
