/**
 * This file is responsible for handling the service calls for the account
 */
import store from "../store";
import {
	callAPI,
	chameau,
	createDefaultResource,
	DEFAULT_LIST_PARAMS,
	GATEWAY_PROXY,
	METHODS,
} from "utils/apiUtils";
import { DialogHandler } from "cakemail-ui-components/lib/react";
import { I18n, Translate } from "react-redux-i18n";
import React from "react";
import {
	SEGMENT_EVENTS,
	trackSegmentEvent,
} from "assets/js/const/SegmentEvents";
import fetchRoute from "utils/routes";
import { LIMIT_FEATURE, limitsFeaturesAccess } from "utils/LimitsAndFeatures";
import { deleteCacheKey, LOCAL_STORAGE_DATA } from "utils/localStorageUtil";

export function createListService(
	options = {},
	successCallback = () => {},
	failureCallback = () => {}
) {
	if (!limitsFeaturesAccess(LIMIT_FEATURE.LISTS)) {
		console.error("List limit reached");
		failureCallback(null, { detail: [{ msg: "List limit reached" }] }, 400);
		return;
	}

	const accountId = store.getState()?.global?.account?.id;
	if (!options.redirections) {
		options.redirections = {
			after_opt_in:
				window.location.origin +
				"/#" +
				fetchRoute("listSubscription") +
				"?a=" +
				accountId,
			after_double_opt_in:
				window.location.origin +
				"/#" +
				fetchRoute("listSubscription") +
				"?a=" +
				accountId,
		};
	}

	if (!options.name) {
		options.name = I18n.t("app-general-list-untitled");
	}

	callAPI(
		{
			url: GATEWAY_PROXY + "/lists",
			body: options,
			query: { account_id: accountId },
		},
		(data) => {
			trackSegmentEvent(SEGMENT_EVENTS.LIST_CREATED, data.data);
			successCallback(data);
		},
		failureCallback
	);
}

export function patchListService(
	options = { id: "", list: {} },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + `/lists/${options.id}`,
			body: options.list,
			method: METHODS.patch,
			query: { account_id: store.getState().global.account.id },
		},
		(data) => {
			trackSegmentEvent(SEGMENT_EVENTS.LIST_UPDATED, data.data);
			successCallback(data);
		},
		failureCallback
	);
}

export function listListsService(
	options = DEFAULT_LIST_PARAMS,
	successCallback = () => {},
	failureCallback = () => {}
) {
	if (!options?.perPage) options.perPage = 1000;
	if (!options?.sort) options.sort = "-created_on";

	callAPI(
		{
			url: GATEWAY_PROXY + "/lists",
			method: METHODS.get,
			query: {
				...chameau(options),
				account_id: store.getState().global.account.id,
			},
		},
		(data) => {
			//If no list exists when a bland fetch is made, create one
			if (!options?.filter && !options.page && data.data.length === 0) {
				createDefaultResource()
					.then((result) => {
						data.data.push(result.list);
						if (!isNaN(data?.pagination?.count)) {
							data.pagination.count++;
						}
						successCallback(data);
					})
					.catch(() => {
						successCallback(data);
					});
			} else {
				successCallback(data);
			}
		},
		failureCallback
	);
}

export function acceptListPolicyService(
	options = { id: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + `/lists/${options.id}/accept-policy`,
			query: { account_id: store.getState().global.account.id },
		},
		successCallback,
		(msg, response) => {
			DialogHandler.show({
				type: "error",
				title: <Translate value="app-general-error-list-policy" />,
			});
			failureCallback(msg, response);
		}
	);
}

export function getListService(
	options = { id: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + `/lists/${options.id}`,
			method: METHODS.get,
			query: { account_id: store.getState().global.account.id },
		},
		successCallback,
		failureCallback
	);
}

export function deleteListService(
	options = { id: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + `/lists/${options.id}`,
			method: METHODS.delete,
			query: { account_id: store.getState().global.account.id },
		},
		(data) => {
			deleteCacheKey(LOCAL_STORAGE_DATA.LISTS_CONTACTS_COUNT, options.listId);
			trackSegmentEvent(SEGMENT_EVENTS.LIST_DELETED, data);
			successCallback(data);
		},
		failureCallback
	);
}
