import fetchRoute from "utils/routes";
import React from "react";
import { Spinner } from "cakemail-ui-components/lib/react";

export function isValidUrl(url) {
	if (
		/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/.test(
			url
		)
	) {
		return true;
	}
	return false;
}

export function removeLegacyParameterByName(key) {
	const origin = window.location.origin;
	let params = new URLSearchParams(window.location.search);
	if (params.get(key)) {
		params.delete(key);
		params = params.toString();
		params = params ? "?" + params : "";

		const finalUrl = origin + "/" + params + window.location.hash;

		let obj = { Title: document.title, Url: finalUrl };

		history.pushState(obj, obj.Title, obj.Url);
	}
}

export function addLegacyParameter(name, value) {
	const origin = window.location.origin;
	let params = new URLSearchParams(window.location.search);
	if (params.get(name)) {
		params.set(name, value);
	} else {
		params.append(name, value);
	}
	params = params.toString();
	params = params ? "?" + params : "";

	const finalUrl = origin + "/" + params + window.location.hash;

	let obj = { Title: document.title, Url: finalUrl };

	history.pushState(obj, obj.Title, obj.Url);
}

export function setBackToFlow(url = null, newFlow = false) {
	if (newFlow && url) {
		const id = new Date().getTime();
		window.localStorage.setItem("flow_" + id, JSON.stringify([url]));
		addLegacyParameter("flowId", id);
		return;
	}

	const flowId = new URLSearchParams(window.location.search).get("flowId");
	let flow = window.localStorage.getItem("flow_" + flowId);

	try {
		flow = JSON.parse(flow);
	} catch (error) {
		removeBackToFlow(flowId);
	}

	if (flow && url) {
		flow.push(url);
		window.localStorage.setItem("flow_" + flowId, JSON.stringify(flow));
	} else {
		removeBackToFlow(flowId);
	}
}

export function goBackToFlow(fallbackRoute = null, history = null) {
	const flowId = new URLSearchParams(window.location.search).get("flowId");
	const finalFallback = fallbackRoute ? fallbackRoute : fetchRoute("dashboard");

	if (!flowId) {
		window.location = window.location.search + "#" + finalFallback;
		return;
	}

	let flow = window.localStorage.getItem("flow_" + flowId);

	try {
		flow = JSON.parse(flow);
	} catch (error) {
		removeBackToFlow(flowId);
	}

	if (flow?.length > 0) {
		if (history) {
			history.push(flow.pop());
		} else {
			window.location = window.location.search + "#" + flow.pop();
		}

		if (flow.length === 0) {
			removeBackToFlow(flowId);
		} else {
			window.localStorage.setItem("flow_" + flowId, JSON.stringify(flow));
		}
	} else {
		if (history) {
			history.push(finalFallback);
		} else {
			window.location = window.location.search + "#" + finalFallback;
		}
	}
}

export function removeBackToFlow(flowId = null) {
	const currentFlowId = new URLSearchParams(window.location.search).get(
		"flowId"
	);
	if (currentFlowId && flowId == null) {
		window.localStorage.removeItem("flow_" + currentFlowId);
	} else {
		window.localStorage.removeItem("flow_" + flowId);
	}

	removeLegacyParameterByName("flowId");
}

export function clearBackToFlows() {
	let keys = Object.keys(window.localStorage);
	keys.forEach((key) => {
		if (/^flow_[0-9]+$/.test(key)) {
			window.localStorage.removeItem(key);
		}
	});
	removeLegacyParameterByName("flowId");
}

export const pagePeeker = {
	init: (memoizeImg) => {
		Object.keys(memoizeImg.current).forEach((key) => {
			const src = memoizeImg.current[key].src;
			if (memoizeImg.current[key].ready) {
				let el = document.getElementById(key);
				if (el && el.src !== src) el.src = src;
			} else {
				pagePeeker.fetchPagePeekerUrl(src, key, false, memoizeImg);
			}
		});
	},
	fetchPagePeekerUrl : (link, id, preFetch = false, memoizeImg)=>{
		if (preFetch && !memoizeImg.current[id].hasFetched) {
			fetch(link).then(() => {
				if (memoizeImg.current[id]) {
					memoizeImg.current[id] = {
						...memoizeImg.current[id],
						hasFetched: true,
					};
				}
			});
		}

		fetch(link.replace("thumbs", "thumbs_ready"))
			.then((res) => res.json())
			.then((res) => {
				if (res.IsReady) {
					if (memoizeImg.current[id]) {
						memoizeImg.current[id] = {
							...memoizeImg.current[id],
							ready: true,
						};
					}

					let el = document.getElementById(id);
					if (el && el.src !== link) el.src = link;

					let parent = el.closest(".thumbnail.pagePeekerThumbnail.loading");
					if (parent) parent.classList.remove("loading");

					let spinner = document.getElementById(
						"spinner-link-img-" + id.substring(id.lastIndexOf("-") + 1)
					);
					if (spinner) spinner.classList.add("hidden");
				} else {
					setTimeout(() => {
						this.fetchPagePeekerUrl(link, id, true, memoizeImg);
					}, 2000);
				}
			})
			.catch(() => {
				/*Just to handle failure*/
			});
	},
	addImg: (index, url = null) => {
		if (url) {
			return (
				<div className="thumbnail pagePeekerThumbnail loading">
					<a href={url} rel="noreferrer" target="_blank">
						<img id={`link-img-${index}`} src={""} />
					</a>
					<Spinner
						id={`spinner-link-img-${index}`}
						radius={20}
						strokeWidth={2}
						spinnerSpeed={1}
					/>
				</div>
			);
		}
		return (
			<div className="thumbnail pagePeekerThumbnail loading">
				<img id={`link-img-${index}`} src={""} />
				<Spinner
					id={`spinner-link-img-${index}`}
					radius={20}
					strokeWidth={2}
					spinnerSpeed={1}
				/>
			</div>
		);
	},
	applyMemoize: (memoizeImg, index, url) => {
		if (!memoizeImg.current[`link-img-${index}`]) {
			memoizeImg.current[`link-img-${index}`] = {
				ready: false,
				src:
					"https://api.pagepeeker.com/v2/thumbs.php?size=l&wait=61&url=" + url,
			};
		}
	},
};
