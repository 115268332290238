import React, { Component } from "react";
import {
	Button,
	Modal,
	HeadingBox,
	Logo,
} from "cakemail-ui-components/lib/react";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { getUrlsBasedOnLanguage } from "utils/languages";
import PropTypes from "prop-types";

class UiWrapper extends Component {
	constructor(props) {
		super(props);
		this.openAccordion = this.openAccordion.bind(this);
		this.state = { hasError: false, info: "", opened: false };
	}

	componentDidCatch(error, info) {
		// Display fallback UI
		//console.error(error,info);
		this.setState({ hasError: true, info: info, error: error });
	}

	openAccordion() {
		this.setState({
			opened: !this.state.opened,
		});
	}

	render() {
		if (this.state.hasError) {
			let title =
				this.state.error.title ||
				I18n._translate("app-general-critical-error-PH-broken");
			let text =
				this.state.error.text ||
				I18n._translate("app-general-critical-error-TXT");

			let buttonText;
			if (this.state.error.pageName) {
				buttonText =
					this.state.error.buttonText ||
					I18n._translate("app-general-critical-error-B", {
						page: this.state.error.pageName,
					});
			} else {
				buttonText =
					this.state.error.buttonText ||
					I18n._translate("app-general-error-account-profile-B");
			}

			let primaryAction =
				this.state.error.primaryAction ||
				(() => {
					location.reload();
				});
			let linkText =
				this.state.error.linkText ||
				I18n._translate("app-general-critical-error-TXT-report");
			let secondaryAction =
				this.state.error.secondaryAction ||
				(() => {
					window.open(this.props.helpUrl, "_blank");
				});
			let errorCode = this.state.error.errorCode;

			let stack =
				typeof this.state?.error?.stack === "string"
					? this.state?.error?.stack
					: "";
			stack = stack.split("\n");

			let textStack = stack.map((item, index) => {
				return <p key={index}>{item.trim(" ")}</p>;
			});

			// You can render any custom fallback UI
			return (
				<Modal
					hideCloseButton
					hideSubHeader
					className="errorDialog"
					topBarLeft={<Logo className="logo" url={this.props.logo} />}
				>
					<div>
						<HeadingBox title={title} text={text} />

						{/* <img src="/assets/images/errorMan.svg" /> */}

						<Button
							text={buttonText}
							colorTheme="teal"
							shapeStyle="fill"
							radius="round"
							size="medium"
							onClick={() => {
								primaryAction();
								location.reload();
							}}
						/>

						{this.props.helpUrl && (
							<Button
								text={linkText}
								onClick={secondaryAction}
								className="buttonLink"
								colorTheme="grey"
								shapeStyle="stroke"
								radius="round"
								size="medium"
							/>
						)}

						{errorCode && <p className="small">{errorCode}</p>}
					</div>
				</Modal>
			);
		}
		return this.props.children;
	}
}

UiWrapper.propTypes = {
	helpUrl: PropTypes.string,
	logo: PropTypes.string,
};

export default connect((state) => {
	return {
		helpUrl: getUrlsBasedOnLanguage(
			state.brand?.url?.help_url,
			"help_url",
			state.global.language
		),
	};
})(UiWrapper);
