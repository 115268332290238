export const LOADING_CONTACTS = "SET_LOADING_CONTACTS";
export const LISTED_CONTACTS_COUNT = "SET_LISTED_CONTACTS_COUNT";
export const LISTED_CONTACTS_QUERY = "SET_LISTED_CONTACTS_QUERY";
export const LISTED_CONTACTS_SMART_QUERY = "SET_LISTED_CONTACTS_SMART_QUERY";
export const LISTED_CONTACTS_SEARCH_QUERY = "SET_LISTED_CONTACTS_SEARCH_QUERY";
export const LISTED_CONTACTS_SORTING = "SET_LISTED_CONTACTS_SORTING";
export const LISTED_CONTACTS_PER_PAGE = "SET_LISTED_CONTACTS_PER_PAGE";
export const LISTED_CONTACTS_PAGINATION = "SET_LISTED_CONTACTS_PAGINATION";
export const RESET_LISTING = "RESET_LISTING";
export const SELECTED_SEGMENT = "SET_SELECTED_SEGMENT";
export const SELECTED_SUBSCRIPTION = "SET_SELECTED_SUBSCRIPTION";
export const SELECTED_SEGMENTS = "SET_SELECTED_SEGMENTS";
export const TOTAL_CONTACTS_COUNT_BY_SEGMENT_OR_SUBSCRIPTION =
	"SET_TOTAL_CONTACTS_COUNT_BY_SEGMENT_OR_SUBSCRIPTION";
export const SMART_FILTER_CHANGE = "SET_SMART_FILTER_CHANGE";

export const setLoadingContacts = (loadingContacts) => {
	return {
		type: LOADING_CONTACTS,
		loadingContacts,
	};
};

export const setListedContactsTotal = (currentContactsCount) => {
	return {
		type: LISTED_CONTACTS_COUNT,
		currentContactsCount,
	};
};

export const setListedContactsQuery = (query) => {
	return {
		type: LISTED_CONTACTS_QUERY,
		query,
	};
};

export const setListedContactsSmartQuery = (smartQuery) => {
	return {
		type: LISTED_CONTACTS_SMART_QUERY,
		smartQuery,
	};
};

export const setListedContactsSearchQuery = (searchQuery) => {
	return {
		type: LISTED_CONTACTS_SEARCH_QUERY,
		searchQuery,
	};
};

export const setListedContactsSorting = (sorting) => {
	return {
		type: LISTED_CONTACTS_SORTING,
		sorting,
	};
};

export const setListedContactsPerPage = (perPage) => {
	return {
		type: LISTED_CONTACTS_PER_PAGE,
		perPage,
	};
};

export const setListedContactsPagination = (page) => {
	return {
		type: LISTED_CONTACTS_PAGINATION,
		page,
	};
};

export const resetListing = () => {
	return {
		type: RESET_LISTING,
	};
};

export const setSelectedSegment = (segment) => {
	return {
		type: SELECTED_SEGMENT,
		segment,
	};
};

export const setSelectedSubscription = (subscription) => {
	return {
		type: SELECTED_SUBSCRIPTION,
		subscription,
	};
};

export const setSelectedSegments = (segments) => {
	return {
		type: SELECTED_SEGMENTS,
		segments,
	};
};

export const setTotalContactsCountBySegmentOrSubscription = (
	totalContactsCountBySegmentOrSubscription
) => {
	return {
		type: TOTAL_CONTACTS_COUNT_BY_SEGMENT_OR_SUBSCRIPTION,
		totalContactsCountBySegmentOrSubscription,
	};
};

export const setSmartFilterChange = (smartFilterChange) => {
	return {
		type: SMART_FILTER_CHANGE,
		smartFilterChange,
	};
};
