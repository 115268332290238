export const SET_SINGLE_FORM = "SET_SINGLE_FORM";
export const SET_LOADING_FORM_GROUP = "SET_LOADING_FORM_GROUP";
export const SET_FORM_ATTRIBUTES = "SET_FORM_ATTRIBUTES";

export const setSingleForm = (singleForm) => {
	return {
		type: SET_SINGLE_FORM,
		singleForm,
	};
};

export const setLoadingFormGroup = (loading) => {
	return {
		type: SET_LOADING_FORM_GROUP,
		loading,
	};
};

export const setFormAttribute = (formAttributes) => {
	return {
		type: SET_FORM_ATTRIBUTES,
		formAttributes,
	};
};
