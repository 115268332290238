const PublicTranslations = {
	"en-US": {
		"app-atuh-create_btn": "Sign up",
		"app-auth-sign_btn": "Sign in",
		"app-automation-editor-stats-filter-value_today": "today",
		"app-campaigns-editors_all": "Editors",
		"app-campaigns-editors_bee": "Email designer",
		"app-campaigns-editors_html": "HTML editor",
		"app-campaigns-flow-step-search-starting_point_zero_desc":
			"Sorry we couldn't find what you’re looking for.",
		"app-campaigns-flow-step-search-starting_point_zero-bluePrints":
			"No themes found",
		"app-campaigns-flow-step-template-campaigns_search": "Search",
		"app-campaigns-flow-step-template-campaigns_themes": "templates",
		"app-campaigns-flow-step-template-heading-title": "Browse email templates",
		"app-campaigns-flow-step-template-selection-type_scratch":
			"Start from scratch",
		"app-campaigns-flow-step-template-selection-type_themes":
			"Professional templates",
		"app-campaigns-flow-step-template-templates_filter-clear": "clear filters",
		"app-campaigns-flow-step-template-templates_filter-label": "Filter by",
		"app-campaigns-industry_all": "Industry",
		"app-campaigns-industry_automotive": "Automotive",
		"app-campaigns-industry_business-services": "Business Services",
		"app-campaigns-industry_computer-internet": "Computer & Internet",
		"app-campaigns-industry_cosmetics": "Cosmetics",
		"app-campaigns-industry_education": "Education",
		"app-campaigns-industry_electronics": "Electronics",
		"app-campaigns-industry_fashion": "Fashion",
		"app-campaigns-industry_financial-money": "Financial & Money",
		"app-campaigns-industry_food-beverage": "Food & Beverage",
		"app-campaigns-industry_health-wellness": "Health & Wellness",
		"app-campaigns-industry_hr": "HR",
		"app-campaigns-industry_manufacturing": "Manufacturing",
		"app-campaigns-industry_marketing-and-design": "Marketing & Design",
		"app-campaigns-industry_media-entertainment": "Media & Entertainment",
		"app-campaigns-industry_news-blog-and-magazines": "News, Blog & Magazines",
		"app-campaigns-industry_non-profit": "Non Profit",
		"app-campaigns-industry_others": "Others",
		"app-campaigns-industry_pets-and-animal-care": "Pets and Animal Care",
		"app-campaigns-industry_real-estate": "Real Estate",
		"app-campaigns-industry_small-business": "Small Business",
		"app-campaigns-industry_sports": "Sports",
		"app-campaigns-industry_transportation-storage": "Transportation & Storage",
		"app-campaigns-industry_travel-leisure": "Travel & Leisure",
		"app-campaigns-seasonal_all": "Seasonal",
		"app-campaigns-seasonal_april-fools-day": "April Fool's Day",
		"app-campaigns-seasonal_back-to-school": "Back to School",
		"app-campaigns-seasonal_black-friday": "Black Friday",
		"app-campaigns-seasonal_black-history-month": "Black History Month",
		"app-campaigns-seasonal_book-lovers-day": "Book Lover's Day",
		"app-campaigns-seasonal_breast-cancer": "Breast Cancer",
		"app-campaigns-seasonal_chinese-new-year": "Chinese New Year",
		"app-campaigns-seasonal_christmas": "Christmas",
		"app-campaigns-seasonal_cinco-de-mayo": "Cinco De Mayo",
		"app-campaigns-seasonal_cyber-monday": "Cyber Monday",
		"app-campaigns-seasonal_earth-day": "Earth Day",
		"app-campaigns-seasonal_easter": "Easter",
		"app-campaigns-seasonal_fashion-week": "Fashion Week",
		"app-campaigns-seasonal_fathers-day": "Father's Day",
		"app-campaigns-seasonal_first-day-of-fall": "First Day of Fall",
		"app-campaigns-seasonal_first-day-of-winter": "First Day of Winter",
		"app-campaigns-seasonal_get-to-know-your-customer":
			"Get To Know Your Customer Day",
		"app-campaigns-seasonal_giving-tuesday": "Giving Tuesday",
		"app-campaigns-seasonal_halloween": "Halloween",
		"app-campaigns-seasonal_hanukkah": "Hanukkah",
		"app-campaigns-seasonal_holiday": "Holiday",
		"app-campaigns-seasonal_independence-day": "Independence Day",
		"app-campaigns-seasonal_international-coffee-day":
			"International Coffee Day",
		"app-campaigns-seasonal_international-womens-day":
			"International Women's Day",
		"app-campaigns-seasonal_labor-day": "Labor Day",
		"app-campaigns-seasonal_lgbtq-pride-month": "LGBTQ+ Pride Month",
		"app-campaigns-seasonal_mardi-gras": "Mardi Gras",
		"app-campaigns-seasonal_memorial-day": "Memorial Day",
		"app-campaigns-seasonal_mothers-day": "Mother's Day",
		"app-campaigns-seasonal_new-year": "New Year",
		"app-campaigns-seasonal_single-day": "Singles Day",
		"app-campaigns-seasonal_small-business-saturday": "Small Business Saturday",
		"app-campaigns-seasonal_spring": "Spring",
		"app-campaigns-seasonal_st-patricks-day": "St. Patrick's Day",
		"app-campaigns-seasonal_super-bowl": "Super Bowl",
		"app-campaigns-seasonal_thanksgiving": "Thanksgiving",
		"app-campaigns-seasonal_valentines-day": "Valentine's Day",
		"app-campaigns-seasonal_video-games-day": "Video Games Day",
		"app-campaigns-seasonal_waitangi": "Waitangi",
		"app-campaigns-seasonal_womens-equality-day": "Women's Equality Day",
		"app-campaigns-seasonal_world-emoji-day": "World Emoji Day",
		"app-campaigns-seasonal_world-meditation-day": "World Meditation Day",
		"app-campaigns-template-meta-preview_desc":
			"Preview email template '%{name}'",
		"app-campaigns-template-preview_btn": "Preview",
		"app-campaigns-template-preview_closeBtn": "Close preview",
		"app-campaigns-template-preview_header": "Preview email template",
		"app-campaigns-template-preview_selectBtn": "Select template",
		"app-campaigns-template-select_btn": "Select",
		"app-campaigns-usage_all": "Usage",
		"app-campaigns-usage_apologize-email": "Apologize",
		"app-campaigns-usage_apology": "Apology",
		"app-campaigns-usage_cv-resumes": "Cv & Resumes",
		"app-campaigns-usage_delivery": "Delivery",
		"app-campaigns-usage_download": "Download",
		"app-campaigns-usage_events": "Events",
		"app-campaigns-usage_mystery-email": "Mystery",
		"app-campaigns-usage_newsletter": "Newsletter",
		"app-campaigns-usage_personal-note": "Personal Note",
		"app-campaigns-usage_product-launch": "Product Launch",
		"app-campaigns-usage_product-promotion": "Product Promotion",
		"app-campaigns-usage_seasonal-promotion": "Seasonal Promotion",
		"app-campaigns-usage_service-promotion": "Service Promotion",
		"app-campaigns-usage_tutorial": "Tutorial",
		"app-campaigns-usage_typography": "Typography",
		"app-contact-all-PA-of": "of",
		"app-contact-all-PA-per_page": " per page",
		"app-contacts_status-noFilter": "Don't filter",
		"app-general_or": "or",
		"app-general_returnHome": "Return to homepage",
		"app-general-done": "Done",
		"app-general-error-code-1006":
			"Too many login attempts. To protect the account, login attempts are blocked for 24 hours. To recover your account quicker, please contact support",
		"app-general-pagination-of": "of",
		"app-general-showing": "Showing",
		"app-public_saveWork": "to save your work",
		"app-public-mobile-template-list-title": "Browse email templates",
		"app-public-templates-helmet-cat-desc":
			"Choose from %{count} %{name} email templates available at %{resellerName}. Explore from hundreds of great newsletter templates that we have to offer!",
		"app-public-templates-helmet-cat-title":
			"%{count} %{name} email templates | %{resellerName}",
		"app-public-templates-helmet-main-desc":
			"700+ Free newsletter templates offered to you by %{resellerName}. All our templates will allow you to create beautiful emails!",
		"app-public-templates-helmet-main-title":
			"700+ Free Email & Newsletter Templates | %{resellerName}",
		"app-public-templates-helmet-single-title":
			"%{name} email template | %{resellerName}",
	},
	"en-ES": {
		"app-atuh-create_btn": "Regístrate",
		"app-auth-sign_btn": "Registrarse",
		"app-automation-editor-stats-filter-value_today": "hoy dia",
		"app-campaigns-editors_all": "Editores",
		"app-campaigns-editors_bee": "diseñador de correo electrónico",
		"app-campaigns-editors_html": "editor HTML",
		"app-campaigns-flow-step-search-starting_point_zero_desc":
			"Lo sentimos, no pudimos encontrar lo que estás buscando.",
		"app-campaigns-flow-step-search-starting_point_zero-bluePrints":
			"No se encontraron temas",
		"app-campaigns-flow-step-template-campaigns_search": "Búsqueda",
		"app-campaigns-flow-step-template-campaigns_themes": "plantillas",
		"app-campaigns-flow-step-template-heading-title":
			"Seleccione un punto de partida",
		"app-campaigns-flow-step-template-selection-type_scratch":
			"Empezar desde el principio",
		"app-campaigns-flow-step-template-selection-type_themes":
			"Plantillas profesionales",
		"app-campaigns-flow-step-template-templates_filter-clear":
			"retirar filtros",
		"app-campaigns-flow-step-template-templates_filter-label": "Filtrado por",
		"app-campaigns-industry_all": "Industria",
		"app-campaigns-industry_automotive": "Automotor",
		"app-campaigns-industry_business-services": "Servicios de negocios",
		"app-campaigns-industry_computer-internet": "Informática e Internet",
		"app-campaigns-industry_cosmetics": "Productos cosméticos",
		"app-campaigns-industry_education": "Educación",
		"app-campaigns-industry_electronics": "Electrónica",
		"app-campaigns-industry_fashion": "Moda",
		"app-campaigns-industry_financial-money": "Financiero y Dinero",
		"app-campaigns-industry_food-beverage": "Alimentaria y de bebidas",
		"app-campaigns-industry_health-wellness": "Salud y bienestar",
		"app-campaigns-industry_hr": "Recursos Humanos",
		"app-campaigns-industry_manufacturing": "Fabricación",
		"app-campaigns-industry_marketing-and-design": "Márketing y Diseño",
		"app-campaigns-industry_media-entertainment": "Medios y entretenimiento",
		"app-campaigns-industry_news-blog-and-magazines":
			"Noticias, blogs y revistas",
		"app-campaigns-industry_non-profit": "Sin fines de lucro",
		"app-campaigns-industry_others": "Otros",
		"app-campaigns-industry_pets-and-animal-care":
			"Mascotas y cuidado de animales",
		"app-campaigns-industry_real-estate": "Bienes raíces",
		"app-campaigns-industry_small-business": "Pequeños negocios",
		"app-campaigns-industry_sports": "Deportes",
		"app-campaigns-industry_transportation-storage":
			"Transporte y Almacenamiento",
		"app-campaigns-industry_travel-leisure": "Viajes y Ocio",
		"app-campaigns-seasonal_all": "De temporada",
		"app-campaigns-seasonal_april-fools-day": "Día de los Inocentes",
		"app-campaigns-seasonal_back-to-school": "Regreso a clases",
		"app-campaigns-seasonal_black-friday": "viernes negro",
		"app-campaigns-seasonal_black-history-month": "Mes de la Historia Negra",
		"app-campaigns-seasonal_book-lovers-day":
			"Día de los amantes de los libros",
		"app-campaigns-seasonal_breast-cancer": "Cáncer de mama",
		"app-campaigns-seasonal_chinese-new-year": "Año Nuevo Chino",
		"app-campaigns-seasonal_christmas": "Navidad",
		"app-campaigns-seasonal_cinco-de-mayo": "Cinco de Mayo",
		"app-campaigns-seasonal_cyber-monday": "Cyber Monday",
		"app-campaigns-seasonal_earth-day": "Día de la Tierra",
		"app-campaigns-seasonal_easter": "Pascua",
		"app-campaigns-seasonal_fashion-week": "Semana de la Moda",
		"app-campaigns-seasonal_fathers-day": "Dia del padre",
		"app-campaigns-seasonal_first-day-of-fall": "Primer día de otoño",
		"app-campaigns-seasonal_first-day-of-winter": "Primer día de invierno",
		"app-campaigns-seasonal_get-to-know-your-customer":
			"Día de conocer a sus clientes",
		"app-campaigns-seasonal_giving-tuesday": "Un dia para dar",
		"app-campaigns-seasonal_halloween": "Halloween",
		"app-campaigns-seasonal_hanukkah": "Jánuca",
		"app-campaigns-seasonal_holiday": "Días de fiesta",
		"app-campaigns-seasonal_independence-day": "Día de la Independencia",
		"app-campaigns-seasonal_international-coffee-day":
			"Día Internacional del Café",
		"app-campaigns-seasonal_international-womens-day":
			"Día Internacional de la Mujer",
		"app-campaigns-seasonal_labor-day": "Dia del Trabajo",
		"app-campaigns-seasonal_lgbtq-pride-month": "Mes del Orgullo LGBTQ+",
		"app-campaigns-seasonal_mardi-gras": "Mardi Gras",
		"app-campaigns-seasonal_memorial-day": "Día de los Caídos",
		"app-campaigns-seasonal_mothers-day": "Día de la Madre",
		"app-campaigns-seasonal_new-year": "Año Nuevo",
		"app-campaigns-seasonal_single-day": "Día de los solteros",
		"app-campaigns-seasonal_small-business-saturday":
			"Sábado de pequeñas empresas",
		"app-campaigns-seasonal_spring": "Primavera",
		"app-campaigns-seasonal_st-patricks-day": "Día de San Patricio",
		"app-campaigns-seasonal_super-bowl": "Super Tazón",
		"app-campaigns-seasonal_thanksgiving": "Acción de gracias",
		"app-campaigns-seasonal_valentines-day": "Día de San Valentín",
		"app-campaigns-seasonal_video-games-day": "Dia Gamer",
		"app-campaigns-seasonal_waitangi": "Waitangi",
		"app-campaigns-seasonal_womens-equality-day":
			"Día de la Igualdad de la Mujer",
		"app-campaigns-seasonal_world-emoji-day": "Día Mundial del Emoji",
		"app-campaigns-seasonal_world-meditation-day":
			"Día Mundial de la Meditación",
		"app-campaigns-template-meta-preview_desc":
			"Vista previa de la plantilla de correo electrónico '%{name}'",
		"app-campaigns-template-preview_btn": "Vista previa",
		"app-campaigns-template-preview_closeBtn": "Cerrar vista previa",
		"app-campaigns-template-preview_header":
			"Vista previa de la plantilla de correo electrónico",
		"app-campaigns-template-preview_selectBtn": "Seleccionar plantilla",
		"app-campaigns-template-select_btn": "Seleccione",
		"app-campaigns-usage_all": "Uso",
		"app-campaigns-usage_apologize-email": "Disculparse",
		"app-campaigns-usage_apology": "Disculpa",
		"app-campaigns-usage_cv-resumes": "CV y currículums",
		"app-campaigns-usage_delivery": "Entrega",
		"app-campaigns-usage_download": "Descargar",
		"app-campaigns-usage_events": "Eventos",
		"app-campaigns-usage_mystery-email": "Misterio",
		"app-campaigns-usage_newsletter": "Boletin informativo",
		"app-campaigns-usage_personal-note": "Nota personal",
		"app-campaigns-usage_product-launch": "Lanzamiento del producto",
		"app-campaigns-usage_product-promotion": "Promoción del producto",
		"app-campaigns-usage_seasonal-promotion": "Promoción de temporada",
		"app-campaigns-usage_service-promotion": "Promoción de servicios",
		"app-campaigns-usage_tutorial": "Tutorial",
		"app-campaigns-usage_typography": "Tipografía",
		"app-contact-all-PA-of": "de",
		"app-contact-all-PA-per_page": " por página",
		"app-contacts_status-noFilter": "no filtrar",
		"app-general_or": "o",
		"app-general_returnHome": "Regresar a la página principal",
		"app-general-done": "Hecho",
		"app-general-error-code-1006":
			"Demasiados intentos de inicio de sesión. Para proteger la cuenta, los intentos de inicio de sesión se han bloqueado durante 24 horas. Para recuperar su cuenta más rápido, comuníquese con asistencia.",
		"app-general-pagination-of": "de",
		"app-general-showing": "Demostración",
		"app-public_saveWork": "para guardar tu trabajo",
		"app-public-mobile-template-list-title":
			"Explorar plantillas de correo electrónico",
		"app-public-templates-helmet-cat-desc":
			"Elija entre %{count} %{name} plantillas de correo electrónico disponibles en %{resellerName}. ¡Explore entre cientos de excelentes plantillas de boletines que tenemos para ofrecer!",
		"app-public-templates-helmet-cat-title":
			"%{count} %{name} plantillas de correo electrónico | %{resellerName}",
		"app-public-templates-helmet-main-desc":
			"Más de 750 plantillas de boletines gratuitos que le ofrece %{resellerName}. ¡Todas nuestras plantillas te permitirán crear hermosos correos electrónicos!",
		"app-public-templates-helmet-main-title":
			"Más de 750 plantillas gratuitas para correos electrónicos y boletines | %{resellerName}",
		"app-public-templates-helmet-single-title":
			"%{name} plantilla de correo electrónico | %{resellerName}",
	},
	"es-US": {
		"app-atuh-create_btn": "Regístrate",
		"app-auth-sign_btn": "Registrarse",
		"app-automation-editor-stats-filter-value_today": "hoy dia",
		"app-campaigns-editors_all": "Editores",
		"app-campaigns-editors_bee": "diseñador de correo electrónico",
		"app-campaigns-editors_html": "editor HTML",
		"app-campaigns-flow-step-search-starting_point_zero_desc":
			"Lo sentimos, no pudimos encontrar lo que estás buscando.",
		"app-campaigns-flow-step-search-starting_point_zero-bluePrints":
			"No se encontraron temas",
		"app-campaigns-flow-step-template-campaigns_search": "Búsqueda",
		"app-campaigns-flow-step-template-campaigns_themes": "plantillas ",
		"app-campaigns-flow-step-template-heading-title":
			"Explorar plantillas de correo electrónico",
		"app-campaigns-flow-step-template-selection-type_scratch":
			"Empezar desde el principio",
		"app-campaigns-flow-step-template-selection-type_themes":
			"Plantillas profesionales",
		"app-campaigns-flow-step-template-templates_filter-clear":
			"retirar filtros",
		"app-campaigns-flow-step-template-templates_filter-label": "Filtrado por",
		"app-campaigns-industry_all": "Industria",
		"app-campaigns-industry_automotive": "Automotor",
		"app-campaigns-industry_business-services": "Servicios de negocios",
		"app-campaigns-industry_computer-internet": "Informática e Internet",
		"app-campaigns-industry_cosmetics": "Productos cosméticos",
		"app-campaigns-industry_education": "Educación",
		"app-campaigns-industry_electronics": "Electrónica",
		"app-campaigns-industry_fashion": "Moda",
		"app-campaigns-industry_financial-money": "Financias y Dinero",
		"app-campaigns-industry_food-beverage": "Alimentaria y de bebidas",
		"app-campaigns-industry_health-wellness": "Salud y bienestar",
		"app-campaigns-industry_hr": "Recursos Humanos",
		"app-campaigns-industry_manufacturing": "Fabricación",
		"app-campaigns-industry_marketing-and-design": "Márketing y Diseño",
		"app-campaigns-industry_media-entertainment": "Medios y entretenimiento",
		"app-campaigns-industry_news-blog-and-magazines":
			"Noticias, blogs y revistas",
		"app-campaigns-industry_non-profit": "Sin fines de lucro",
		"app-campaigns-industry_others": "Otros",
		"app-campaigns-industry_pets-and-animal-care":
			"Mascotas y cuidado de animales",
		"app-campaigns-industry_real-estate": "Bienes raíces",
		"app-campaigns-industry_small-business": "Pequeños negocios",
		"app-campaigns-industry_sports": "Deportes",
		"app-campaigns-industry_transportation-storage":
			"Transporte y Almacenamiento",
		"app-campaigns-industry_travel-leisure": "Viajes y Ocio",
		"app-campaigns-seasonal_all": "De temporada",
		"app-campaigns-seasonal_april-fools-day": "Día de los Inocentes",
		"app-campaigns-seasonal_back-to-school": "Regreso a clases",
		"app-campaigns-seasonal_black-friday": "viernes negro",
		"app-campaigns-seasonal_black-history-month": "Mes de la Historia Negra",
		"app-campaigns-seasonal_book-lovers-day":
			"Día de los amantes de los libros",
		"app-campaigns-seasonal_breast-cancer": "Cáncer de mama",
		"app-campaigns-seasonal_chinese-new-year": "Año Nuevo Chino",
		"app-campaigns-seasonal_christmas": "Navidad",
		"app-campaigns-seasonal_cinco-de-mayo": "Cinco de Mayo",
		"app-campaigns-seasonal_cyber-monday": "Lunes cibernético",
		"app-campaigns-seasonal_earth-day": "Día de la Tierra",
		"app-campaigns-seasonal_easter": "Pascua",
		"app-campaigns-seasonal_fashion-week": "Semana de la Moda",
		"app-campaigns-seasonal_fathers-day": "Dia del padre",
		"app-campaigns-seasonal_first-day-of-fall": "Primer día de otoño",
		"app-campaigns-seasonal_first-day-of-winter": "Primer día de invierno",
		"app-campaigns-seasonal_get-to-know-your-customer":
			"Día de conocer a sus clientes",
		"app-campaigns-seasonal_giving-tuesday": "Un dia para dar",
		"app-campaigns-seasonal_halloween": "Halloween",
		"app-campaigns-seasonal_hanukkah": "Jánuca",
		"app-campaigns-seasonal_holiday": "Días de fiesta",
		"app-campaigns-seasonal_independence-day": "Día de la Independencia",
		"app-campaigns-seasonal_international-coffee-day":
			"Día Internacional del Café",
		"app-campaigns-seasonal_international-womens-day":
			"Día Internacional de la Mujer",
		"app-campaigns-seasonal_labor-day": "Dia del Trabajo",
		"app-campaigns-seasonal_lgbtq-pride-month": "Mes del Orgullo LGBTQ+",
		"app-campaigns-seasonal_mardi-gras": "Mardi Gras",
		"app-campaigns-seasonal_memorial-day": "Día de los Caídos",
		"app-campaigns-seasonal_mothers-day": "Día de la Madre",
		"app-campaigns-seasonal_new-year": "Año Nuevo",
		"app-campaigns-seasonal_single-day": "Día de los solteros",
		"app-campaigns-seasonal_small-business-saturday":
			"Sábado de pequeñas empresas",
		"app-campaigns-seasonal_spring": "Primavera",
		"app-campaigns-seasonal_st-patricks-day": "Día de San Patricio",
		"app-campaigns-seasonal_super-bowl": "Super Tazón",
		"app-campaigns-seasonal_thanksgiving": "Acción de gracias",
		"app-campaigns-seasonal_valentines-day": "Día de San Valentín",
		"app-campaigns-seasonal_video-games-day": "Dia Gamer",
		"app-campaigns-seasonal_waitangi": "Waitangi",
		"app-campaigns-seasonal_womens-equality-day":
			"Día de la Igualdad de la Mujer",
		"app-campaigns-seasonal_world-emoji-day": "Día Mundial del Emoji",
		"app-campaigns-seasonal_world-meditation-day":
			"Día Mundial de la Meditación",
		"app-campaigns-template-meta-preview_desc":
			"Vista previa de la plantilla de correo electrónico '%{name}'",
		"app-campaigns-template-preview_btn": "Vista previa",
		"app-campaigns-template-preview_closeBtn": "Cerrar vista previa",
		"app-campaigns-template-preview_header":
			"Vista previa de la plantilla de correo electrónico",
		"app-campaigns-template-preview_selectBtn": "Seleccionar plantilla",
		"app-campaigns-template-select_btn": "Seleccione",
		"app-campaigns-usage_all": "Uso",
		"app-campaigns-usage_apologize-email": "Disculparse",
		"app-campaigns-usage_apology": "Disculpa",
		"app-campaigns-usage_cv-resumes": "CV y currículums",
		"app-campaigns-usage_delivery": "Entrega",
		"app-campaigns-usage_download": "Descargar",
		"app-campaigns-usage_events": "Eventos",
		"app-campaigns-usage_mystery-email": "Misterio",
		"app-campaigns-usage_newsletter": "Boletin informativo",
		"app-campaigns-usage_personal-note": "Nota personal",
		"app-campaigns-usage_product-launch": "Lanzamiento del producto",
		"app-campaigns-usage_product-promotion": "Promoción del producto",
		"app-campaigns-usage_seasonal-promotion": "Promoción de temporada",
		"app-campaigns-usage_service-promotion": "Promoción de servicios",
		"app-campaigns-usage_tutorial": "Tutorial",
		"app-campaigns-usage_typography": "Tipografía",
		"app-contact-all-PA-of": "de",
		"app-contact-all-PA-per_page": " por página",
		"app-contacts_status-noFilter": "no filtrar",
		"app-general_or": "o",
		"app-general_returnHome": "Regresar a la página principal",
		"app-general-done": "Hecho",
		"app-general-error-code-1006":
			"Demasiados intentos de inicio de sesión. Para proteger la cuenta, los intentos de inicio de sesión se han bloqueado durante 24 horas. Para recuperar su cuenta más rápido, comuníquese con asistencia.",
		"app-general-pagination-of": "de",
		"app-general-showing": "Demostración",
		"app-public_saveWork": "para guardar tu trabajo",
		"app-public-mobile-template-list-title":
			"Explorar plantillas de correo electrónico",
		"app-public-templates-helmet-cat-desc":
			"Elija entre %{count} %{name} plantillas de correo electrónico disponibles en %{resellerName}. ¡Explore entre cientos de excelentes plantillas de boletines que tenemos para ofrecer!",
		"app-public-templates-helmet-cat-title":
			"%{count} %{name} plantillas de correo electrónico | %{resellerName}",
		"app-public-templates-helmet-main-desc":
			"Más de 750 plantillas de boletines gratuitos que le ofrece %{resellerName}. ¡Todas nuestras plantillas le permitirán crear hermosos correos electrónicos!",
		"app-public-templates-helmet-main-title":
			"Más de 750 plantillas gratuitas para correos electrónicos y boletines | %{resellerName}",
		"app-public-templates-helmet-single-title":
			"%{name} plantilla de correo electrónico | %{resellerName}",
	},
	"fr-CA": {
		"app-atuh-create_btn": "S'inscrire",
		"app-auth-sign_btn": "S'identifier",
		"app-automation-editor-stats-filter-value_today": "aujourd'hui",
		"app-campaigns-editors_all": "Éditeurs",
		"app-campaigns-editors_bee": "Designer",
		"app-campaigns-editors_html": "Éditeur HTML",
		"app-campaigns-flow-step-search-starting_point_zero_desc":
			"Désolés. Nous n'avons pas pu trouver ce que vous cherchez.",
		"app-campaigns-flow-step-search-starting_point_zero-bluePrints":
			"Aucun thème trouvé",
		"app-campaigns-flow-step-template-campaigns_search": "Recherchez parmi ",
		"app-campaigns-flow-step-template-campaigns_themes": " gabarits",
		"app-campaigns-flow-step-template-heading-title":
			"Naviguer à travers les gabarits",
		"app-campaigns-flow-step-template-selection-type_scratch": "De zéro",
		"app-campaigns-flow-step-template-selection-type_themes":
			"Gabarits professionnels",
		"app-campaigns-flow-step-template-templates_filter-clear":
			"Réinitialiser les filtres",
		"app-campaigns-flow-step-template-templates_filter-label": "Filtre par",
		"app-campaigns-industry_all": "Industrie",
		"app-campaigns-industry_automotive": "Automobile",
		"app-campaigns-industry_business-services": "Services aux entreprises",
		"app-campaigns-industry_computer-internet": "Informatique et Internet",
		"app-campaigns-industry_cosmetics": "Cosmétiques",
		"app-campaigns-industry_education": "Éducation",
		"app-campaigns-industry_electronics": "Électronique",
		"app-campaigns-industry_fashion": "Mode",
		"app-campaigns-industry_financial-money": "Finances & Argent",
		"app-campaigns-industry_food-beverage": "Nourriture & Breuvages",
		"app-campaigns-industry_health-wellness": "Santé & Bien-être",
		"app-campaigns-industry_hr": "Ressources humaines",
		"app-campaigns-industry_manufacturing": "Fabrication",
		"app-campaigns-industry_marketing-and-design": "Marketing & Design",
		"app-campaigns-industry_media-entertainment": "Médias & Divertissement",
		"app-campaigns-industry_news-blog-and-magazines":
			"Nouvelles, Blogues & Magazines",
		"app-campaigns-industry_non-profit": "Organisation à but non lucratif",
		"app-campaigns-industry_others": "Autres",
		"app-campaigns-industry_pets-and-animal-care":
			"Animaux et soins aux animaux",
		"app-campaigns-industry_real-estate": "Immobilier",
		"app-campaigns-industry_small-business": "Petites entreprises",
		"app-campaigns-industry_sports": "Sports",
		"app-campaigns-industry_transportation-storage": "Transport & Storage",
		"app-campaigns-industry_travel-leisure": "Voyages & loisirs",
		"app-campaigns-seasonal_all": "Saisonnier",
		"app-campaigns-seasonal_april-fools-day": "1er avril",
		"app-campaigns-seasonal_back-to-school": "Retour à l'école",
		"app-campaigns-seasonal_black-friday": "Vendredi fou",
		"app-campaigns-seasonal_black-history-month":
			"Mois de l'histoire des Noirs",
		"app-campaigns-seasonal_book-lovers-day": "Jour des lecteurs",
		"app-campaigns-seasonal_breast-cancer": "Cancer du sein",
		"app-campaigns-seasonal_chinese-new-year": "Nouvel An chinois",
		"app-campaigns-seasonal_christmas": "Noël",
		"app-campaigns-seasonal_cinco-de-mayo": "Cinco De Mayo",
		"app-campaigns-seasonal_cyber-monday": "Cyber lundi",
		"app-campaigns-seasonal_earth-day": "Earth Day",
		"app-campaigns-seasonal_easter": "Pâques",
		"app-campaigns-seasonal_fashion-week": "Semaine de la mode",
		"app-campaigns-seasonal_fathers-day": "Fête des pères",
		"app-campaigns-seasonal_first-day-of-fall": "Premier jour de l'automne",
		"app-campaigns-seasonal_first-day-of-winter": "Premier jour de l'hiver",
		"app-campaigns-seasonal_get-to-know-your-customer":
			"Journée \"Apprenez à connaître votre client\"",
		"app-campaigns-seasonal_giving-tuesday": "Giving Tuesday",
		"app-campaigns-seasonal_halloween": "Halloween",
		"app-campaigns-seasonal_hanukkah": "Hanukkah",
		"app-campaigns-seasonal_holiday": "Fête",
		"app-campaigns-seasonal_independence-day": "Jour de l'Indépendance",
		"app-campaigns-seasonal_international-coffee-day":
			"Jour international du café",
		"app-campaigns-seasonal_international-womens-day":
			"Journée internationale des femmes",
		"app-campaigns-seasonal_labor-day": "Labor Day",
		"app-campaigns-seasonal_lgbtq-pride-month": "Mois de la fierté LGBTQ+",
		"app-campaigns-seasonal_mardi-gras": "Mardi Gras",
		"app-campaigns-seasonal_memorial-day": "Jour du souvenir",
		"app-campaigns-seasonal_mothers-day": "Fête des mères",
		"app-campaigns-seasonal_new-year": "New Year",
		"app-campaigns-seasonal_single-day": "Jour des célibataires",
		"app-campaigns-seasonal_small-business-saturday": "Samedi des PME",
		"app-campaigns-seasonal_spring": "Spring",
		"app-campaigns-seasonal_st-patricks-day": "St. Patrick",
		"app-campaigns-seasonal_super-bowl": "Super Bowl",
		"app-campaigns-seasonal_thanksgiving": "Thanksgiving",
		"app-campaigns-seasonal_valentines-day": "St-Valentin",
		"app-campaigns-seasonal_video-games-day": "Jour du jeu vidéo",
		"app-campaigns-seasonal_waitangi": "Waitangi",
		"app-campaigns-seasonal_womens-equality-day": "Jour des droits des femmes",
		"app-campaigns-seasonal_world-emoji-day": "Journée mondiale de l'Emoji",
		"app-campaigns-seasonal_world-meditation-day":
			"Journée mondiale de la méditation",
		"app-campaigns-template-meta-preview_desc":
			"Prévisualisation du gabarit de courriel '%{name}'",
		"app-campaigns-template-preview_btn": "Aperçu",
		"app-campaigns-template-preview_closeBtn": "Fermer l'aperçu",
		"app-campaigns-template-preview_header":
			"Prévisualisation du gabarit de courriel",
		"app-campaigns-template-preview_selectBtn": "Sélectionner ce gabarit",
		"app-campaigns-template-select_btn": "Sélectionnez",
		"app-campaigns-usage_all": "Usage",
		"app-campaigns-usage_apologize-email": "S'excuser",
		"app-campaigns-usage_apology": "Excuses",
		"app-campaigns-usage_cv-resumes": "Cv & Résumés",
		"app-campaigns-usage_delivery": "Livraison",
		"app-campaigns-usage_download": "Téléchargement",
		"app-campaigns-usage_events": "Événements",
		"app-campaigns-usage_mystery-email": "Mystère",
		"app-campaigns-usage_newsletter": "Infolettre",
		"app-campaigns-usage_personal-note": "Note personnelle",
		"app-campaigns-usage_product-launch": "Lancement de produit",
		"app-campaigns-usage_product-promotion": "Promotion de produit",
		"app-campaigns-usage_seasonal-promotion": "Promotion saisonnière",
		"app-campaigns-usage_service-promotion": "Promotion de service",
		"app-campaigns-usage_tutorial": "Tutoriel",
		"app-campaigns-usage_typography": "Typographie",
		"app-contact-all-PA-of": "de",
		"app-contact-all-PA-per_page": "par page",
		"app-contacts_status-noFilter": "Ne pas filtrer",
		"app-general_or": "ou",
		"app-general_returnHome": "Retourner à la page d'accueil",
		"app-general-done": "Fait",
		"app-general-error-code-1006":
			"Trop de tentatives de connexion. Pour protéger le compte, les tentatives de connexion sont bloquées pendant 24 heures. Pour récupérer votre compte plus rapidement, veuillez contacter le support",
		"app-general-pagination-of": "de",
		"app-general-showing": "Affichage de",
		"app-public_saveWork": "pour sauvegarder votre travail",
		"app-public-mobile-template-list-title": "Parcourir les modèles",
		"app-public-templates-helmet-cat-desc":
			"Choisissez parmi %{count} modèles %{name} d’e-mails disponibles sur %{resellerName}. Explorez des centaines de modèles d’infolettres que nous avons à vous proposer!",
		"app-public-templates-helmet-cat-title":
			"%{count} %{name} gabarits de courriels | %{resellerName}",
		"app-public-templates-helmet-main-desc":
			"700+ gabarits d'infolettres gratuits vous sont proposés par %{resellerName}. Tous nos gabarits vous permettront de créer de magnifiques courriels !",
		"app-public-templates-helmet-main-title":
			"700+ Modèles gratuits de courriels et de bulletins d'information | %{resellerName}",
		"app-public-templates-helmet-single-title":
			"%{name} gabarit de courriel | %{resellerName}",
	},
	"nl-NL": {
		"app-atuh-create_btn": "Inschrijven",
		"app-auth-sign_btn": "Inloggen",
		"app-automation-editor-stats-filter-value_today": "vandaag",
		"app-campaigns-editors_all": "EDITORS",
		"app-campaigns-editors_bee": "E-MAILONTWERPER",
		"app-campaigns-editors_html": "HTML-editor",
		"app-campaigns-flow-step-search-starting_point_zero_desc":
			"Sorry dat we niet konden vinden wat je zoekt. ",
		"app-campaigns-flow-step-search-starting_point_zero-bluePrints":
			"Geen thema's gevonden ",
		"app-campaigns-flow-step-template-campaigns_search": "Zoeken ",
		"app-campaigns-flow-step-template-campaigns_themes": "Sjablonen",
		"app-campaigns-flow-step-template-heading-title": "Selecteer een startpunt",
		"app-campaigns-flow-step-template-selection-type_scratch":
			"Begin helemaal opnieuw",
		"app-campaigns-flow-step-template-selection-type_themes":
			"Professionele sjablonen",
		"app-campaigns-flow-step-template-templates_filter-clear":
			"Filters resetten",
		"app-campaigns-flow-step-template-templates_filter-label": "FILTEREN OP",
		"app-campaigns-industry_all": "INDUSTRIE",
		"app-campaigns-industry_automotive": "Auto's",
		"app-campaigns-industry_business-services": "Bedrijfsdiensten",
		"app-campaigns-industry_computer-internet": "Computer & internet ",
		"app-campaigns-industry_cosmetics": "Cosmetica",
		"app-campaigns-industry_education": "Opleiding",
		"app-campaigns-industry_electronics": "Elektronica",
		"app-campaigns-industry_fashion": "Mode",
		"app-campaigns-industry_financial-money": "Financieel & Geld",
		"app-campaigns-industry_food-beverage": "Eten & Drinken",
		"app-campaigns-industry_health-wellness": "gezondheid",
		"app-campaigns-industry_hr": "HR",
		"app-campaigns-industry_manufacturing": "productie",
		"app-campaigns-industry_marketing-and-design": "Marketing en ontwerp",
		"app-campaigns-industry_media-entertainment": "Media en entertainment",
		"app-campaigns-industry_news-blog-and-magazines":
			"Nieuws, blogs en tijdschriften",
		"app-campaigns-industry_non-profit": "Non-profit",
		"app-campaigns-industry_others": "anderen",
		"app-campaigns-industry_pets-and-animal-care":
			"Huisdieren en dierenverzorging",
		"app-campaigns-industry_real-estate": "Onroerend goed",
		"app-campaigns-industry_small-business": "Detailhandel",
		"app-campaigns-industry_sports": "Sport",
		"app-campaigns-industry_transportation-storage": "Transport en opslag",
		"app-campaigns-industry_travel-leisure": "Reizen en vrije tijd",
		"app-campaigns-seasonal_all": "SEIZOEN",
		"app-campaigns-seasonal_april-fools-day": "1 april",
		"app-campaigns-seasonal_back-to-school": "Terug naar school",
		"app-campaigns-seasonal_black-friday": "Black Friday",
		"app-campaigns-seasonal_black-history-month": "Zwarte-geschiedenismaand ",
		"app-campaigns-seasonal_book-lovers-day": "Dag van de Boekliefhebber",
		"app-campaigns-seasonal_breast-cancer": "Borstkanker",
		"app-campaigns-seasonal_chinese-new-year": "Chinees Nieuwjaar",
		"app-campaigns-seasonal_christmas": "Kerstmis",
		"app-campaigns-seasonal_cinco-de-mayo": "Cinco De Mayo",
		"app-campaigns-seasonal_cyber-monday": "Cyber maandag",
		"app-campaigns-seasonal_earth-day": "dag van de Aarde",
		"app-campaigns-seasonal_easter": "Pasen",
		"app-campaigns-seasonal_fashion-week": "Mode week ",
		"app-campaigns-seasonal_fathers-day": "Vaderdag",
		"app-campaigns-seasonal_first-day-of-fall": "Eerste dag van de herfst",
		"app-campaigns-seasonal_first-day-of-winter": "Eerste dag van de winter",
		"app-campaigns-seasonal_get-to-know-your-customer":
			"Leer uw klant kennen dag",
		"app-campaigns-seasonal_giving-tuesday": "Giving Tuesday",
		"app-campaigns-seasonal_halloween": "Halloween",
		"app-campaigns-seasonal_hanukkah": "Chanoeka",
		"app-campaigns-seasonal_holiday": "Vakantie",
		"app-campaigns-seasonal_independence-day": "Onafhankelijkheidsdag",
		"app-campaigns-seasonal_international-coffee-day":
			"Internationale Koffiedag",
		"app-campaigns-seasonal_international-womens-day":
			"internationale Vrouwendag",
		"app-campaigns-seasonal_labor-day": "Dag van de Arbeid",
		"app-campaigns-seasonal_lgbtq-pride-month": "LGBTQ+ Pride-maand",
		"app-campaigns-seasonal_mardi-gras": "Mardi Gras",
		"app-campaigns-seasonal_memorial-day": "herdenkingsdag",
		"app-campaigns-seasonal_mothers-day": "Moederdag",
		"app-campaigns-seasonal_new-year": "Nieuwjaar",
		"app-campaigns-seasonal_single-day": "Vrijgezellen dag",
		"app-campaigns-seasonal_small-business-saturday":
			"Kleine bedrijven zaterdag",
		"app-campaigns-seasonal_spring": "Voorjaar",
		"app-campaigns-seasonal_st-patricks-day": "St. Patrick's Day",
		"app-campaigns-seasonal_super-bowl": "Super Bowl",
		"app-campaigns-seasonal_thanksgiving": "Thanksgiving",
		"app-campaigns-seasonal_valentines-day": "Valentijnsdag",
		"app-campaigns-seasonal_video-games-day": "Dag van videogames",
		"app-campaigns-seasonal_waitangi": "Waitangi",
		"app-campaigns-seasonal_womens-equality-day":
			"Dag van de gelijkheid van vrouwen",
		"app-campaigns-seasonal_world-emoji-day": "Wereld Emoji Dag",
		"app-campaigns-seasonal_world-meditation-day": "Wereld Meditatie Dag",
		"app-campaigns-template-meta-preview_desc":
			"Voorbeeld van e-mailsjabloon '%{name}'",
		"app-campaigns-template-preview_btn": "Voorbeeld",
		"app-campaigns-template-preview_closeBtn": "Voorbeeld sluiten",
		"app-campaigns-template-preview_header": "Voorbeeld van e-mailsjabloon",
		"app-campaigns-template-preview_selectBtn": "Selecteer sjabloon",
		"app-campaigns-template-select_btn": "Selecteer",
		"app-campaigns-usage_all": "GEBRUIK",
		"app-campaigns-usage_apologize-email": "Verontschuldigen",
		"app-campaigns-usage_apology": "Verontschuldiging",
		"app-campaigns-usage_cv-resumes": "Cv & CV",
		"app-campaigns-usage_delivery": "Levering",
		"app-campaigns-usage_download": "Downloaden",
		"app-campaigns-usage_events": "Evenementen",
		"app-campaigns-usage_mystery-email": "Mysterie",
		"app-campaigns-usage_newsletter": "Nieuwsbrief",
		"app-campaigns-usage_personal-note": "Persoonlijke notitie",
		"app-campaigns-usage_product-launch": "Product lancering",
		"app-campaigns-usage_product-promotion": "Product Promotie",
		"app-campaigns-usage_seasonal-promotion": "Seizoensgebonden promotie",
		"app-campaigns-usage_service-promotion": "Servicepromotie",
		"app-campaigns-usage_tutorial": "zelfstudie",
		"app-campaigns-usage_typography": "Typografie",
		"app-contact-all-PA-of": "van",
		"app-contact-all-PA-per_page": " per pagina",
		"app-contacts_status-noFilter": "Niet filteren",
		"app-general_or": "of",
		"app-general_returnHome": "Keer terug naar de start pagina",
		"app-general-done": "Gedaan",
		"app-general-error-code-1006":
			"Te veel inlogpogingen. Om het account te beschermen, worden inlogpogingen 24 uur geblokkeerd. Neem contact op met de ondersteuning om uw account sneller te herstellen",
		"app-general-pagination-of": "van",
		"app-general-showing": "Tonen",
		"app-public_saveWork": "om je werk op te slaan",
		"app-public-mobile-template-list-title": "Blader door e-mailsjablonen",
		"app-public-templates-helmet-cat-desc":
			"Kies uit %{count} %{name} e-mailsjablonen die beschikbaar zijn bij %{resellerName}. Ontdek honderden geweldige nieuwsbriefsjablonen die we te bieden hebben!",
		"app-public-templates-helmet-cat-title":
			"%{count} %{name} e-mailsjablonen | %{resellerName}",
		"app-public-templates-helmet-main-desc":
			"700+ gratis nieuwsbriefsjablonen aangeboden door %{resellerName}. Met al onze sjablonen kunt u prachtige e-mails maken!",
		"app-public-templates-helmet-main-title":
			"700+ gratis e-mail- en nieuwsbriefsjablonen | %{resellerName}",
		"app-public-templates-helmet-single-title":
			"%{name} e-mailsjabloon | %{resellerName}",
	},
	"pt-BR": {
		"app-atuh-create_btn": "Inscrever-se",
		"app-auth-sign_btn": "Entrar",
		"app-automation-editor-stats-filter-value_today": "hoje",
		"app-campaigns-editors_all": "Editores",
		"app-campaigns-editors_bee": "Designer de e-mail",
		"app-campaigns-editors_html": "Editor de HTML",
		"app-campaigns-flow-step-search-starting_point_zero_desc":
			"Desculpe, não conseguimos encontrar o que você está procurando.",
		"app-campaigns-flow-step-search-starting_point_zero-bluePrints":
			"Nenhum tema encontrado",
		"app-campaigns-flow-step-template-campaigns_search": "Procurar",
		"app-campaigns-flow-step-template-campaigns_themes": "modelos",
		"app-campaigns-flow-step-template-heading-title":
			"Procurar modelos de e-mail",
		"app-campaigns-flow-step-template-selection-type_scratch":
			"Começar do zero",
		"app-campaigns-flow-step-template-selection-type_themes":
			"Modelos profissionais",
		"app-campaigns-flow-step-template-templates_filter-clear": "limpar filtros",
		"app-campaigns-flow-step-template-templates_filter-label": "Filtrar por",
		"app-campaigns-industry_all": "Indústria",
		"app-campaigns-industry_automotive": "Automotivo",
		"app-campaigns-industry_business-services":
			"Serviços prestados às empresas",
		"app-campaigns-industry_computer-internet": "Computador e Internet",
		"app-campaigns-industry_cosmetics": "Cosméticos",
		"app-campaigns-industry_education": "Educação",
		"app-campaigns-industry_electronics": "Eletrônicos",
		"app-campaigns-industry_fashion": "Moda",
		"app-campaigns-industry_financial-money": "Financeiro e Dinheiro",
		"app-campaigns-industry_food-beverage": "Comida & Bebida",
		"app-campaigns-industry_health-wellness": "Saúde e bem estar",
		"app-campaigns-industry_hr": "RH",
		"app-campaigns-industry_manufacturing": "Fabricação",
		"app-campaigns-industry_marketing-and-design": "Marketing e design",
		"app-campaigns-industry_media-entertainment": "Mídia e entretenimento",
		"app-campaigns-industry_news-blog-and-magazines":
			"Notícias, blogs e revistas",
		"app-campaigns-industry_non-profit": "Sem fins lucrativos",
		"app-campaigns-industry_others": "Outros",
		"app-campaigns-industry_pets-and-animal-care":
			"Animais de estimação e cuidados com animais",
		"app-campaigns-industry_real-estate": "Imobiliária",
		"app-campaigns-industry_small-business": "Pequenos negócios",
		"app-campaigns-industry_sports": "Esportes",
		"app-campaigns-industry_transportation-storage":
			"Transporte e armazenamento",
		"app-campaigns-industry_travel-leisure": "Viagens e Lazer",
		"app-campaigns-seasonal_all": "Sazonal",
		"app-campaigns-seasonal_april-fools-day": "Dia da Mentira",
		"app-campaigns-seasonal_back-to-school": "De volta à escola",
		"app-campaigns-seasonal_black-friday": "Sexta-feira preta",
		"app-campaigns-seasonal_black-history-month": "Mês da história negra",
		"app-campaigns-seasonal_book-lovers-day": "Dia do Amante do Livro",
		"app-campaigns-seasonal_breast-cancer": "Câncer de mama",
		"app-campaigns-seasonal_chinese-new-year": "ano Novo Chinês",
		"app-campaigns-seasonal_christmas": "Natal",
		"app-campaigns-seasonal_cinco-de-mayo": "Cinco de maio",
		"app-campaigns-seasonal_cyber-monday": "Cyber segunda-feira",
		"app-campaigns-seasonal_earth-day": "Dia da Terra",
		"app-campaigns-seasonal_easter": "Páscoa",
		"app-campaigns-seasonal_fashion-week": "Semana da Moda",
		"app-campaigns-seasonal_fathers-day": "Dia dos Pais",
		"app-campaigns-seasonal_first-day-of-fall": "Primeiro dia de outono",
		"app-campaigns-seasonal_first-day-of-winter": "Primeiro dia de inverno",
		"app-campaigns-seasonal_get-to-know-your-customer":
			"Conheça o seu dia do cliente",
		"app-campaigns-seasonal_giving-tuesday": "Dando terça-feira",
		"app-campaigns-seasonal_halloween": "dia das Bruxas",
		"app-campaigns-seasonal_hanukkah": "Hanukkah",
		"app-campaigns-seasonal_holiday": "Feriado",
		"app-campaigns-seasonal_independence-day": "Dia da Independência",
		"app-campaigns-seasonal_international-coffee-day":
			"Dia Internacional do Café",
		"app-campaigns-seasonal_international-womens-day":
			"Dia Internacional da Mulher",
		"app-campaigns-seasonal_labor-day": "Dia de trabalho",
		"app-campaigns-seasonal_lgbtq-pride-month": "Mês do Orgulho LGBTQ+",
		"app-campaigns-seasonal_mardi-gras": "Carnaval",
		"app-campaigns-seasonal_memorial-day": "dia Memorial",
		"app-campaigns-seasonal_mothers-day": "Dia das Mães",
		"app-campaigns-seasonal_new-year": "Ano Novo",
		"app-campaigns-seasonal_single-day": "Dia dos Solteiros",
		"app-campaigns-seasonal_small-business-saturday":
			"Sábado para pequenas empresas",
		"app-campaigns-seasonal_spring": "Mola",
		"app-campaigns-seasonal_st-patricks-day": "Dia de São Patricio",
		"app-campaigns-seasonal_super-bowl": "Super Bowl",
		"app-campaigns-seasonal_thanksgiving": "Ação de graças",
		"app-campaigns-seasonal_valentines-day": "Dia dos namorados",
		"app-campaigns-seasonal_video-games-day": "Dia dos videogames",
		"app-campaigns-seasonal_waitangi": "Waitangi",
		"app-campaigns-seasonal_womens-equality-day":
			"Dia da Igualdade das Mulheres",
		"app-campaigns-seasonal_world-emoji-day": "Dia Mundial do Emoji",
		"app-campaigns-seasonal_world-meditation-day": "Dia Mundial da Meditação",
		"app-campaigns-template-meta-preview_desc":
			"Visualizar modelo de e-mail '%{name}'",
		"app-campaigns-template-preview_btn": "Visualizar",
		"app-campaigns-template-preview_closeBtn": "Fechar visualização",
		"app-campaigns-template-preview_header": "Visualizar modelo de e-mail",
		"app-campaigns-template-preview_selectBtn": "Selecionar modelo",
		"app-campaigns-template-select_btn": "Selecionar",
		"app-campaigns-usage_all": "Uso",
		"app-campaigns-usage_apologize-email": "Pedir desculpas",
		"app-campaigns-usage_apology": "Desculpa",
		"app-campaigns-usage_cv-resumes": "Currículo e Currículos",
		"app-campaigns-usage_delivery": "Entrega",
		"app-campaigns-usage_download": "Download",
		"app-campaigns-usage_events": "Eventos",
		"app-campaigns-usage_mystery-email": "Mistério",
		"app-campaigns-usage_newsletter": "Boletim de Notícias",
		"app-campaigns-usage_personal-note": "Nota pessoal",
		"app-campaigns-usage_product-launch": "Lançamento do produto",
		"app-campaigns-usage_product-promotion": "Promoção do produto",
		"app-campaigns-usage_seasonal-promotion": "Promoção sazonal",
		"app-campaigns-usage_service-promotion": "Promoção de serviço",
		"app-campaigns-usage_tutorial": "Tutorial",
		"app-campaigns-usage_typography": "Tipografia",
		"app-contact-all-PA-of": "de",
		"app-contact-all-PA-per_page": " por página",
		"app-contacts_status-noFilter": "Não filtre",
		"app-general_or": "ou",
		"app-general_returnHome": "Retornar a página inicial",
		"app-general-done": "Feito",
		"app-general-error-code-1006":
			"Muitas tentativas de login. Para proteger a conta, as tentativas de login são bloqueadas por 24 horas. Para recuperar sua conta mais rapidamente, entre em contato com o suporte",
		"app-general-pagination-of": "de",
		"app-general-showing": "Mostrando",
		"app-public_saveWork": "para salvar seu trabalho",
		"app-public-mobile-template-list-title": "Procurar modelos de e-mail",
		"app-public-templates-helmet-cat-desc":
			"Escolha entre %{count} %{name} modelos de e-mail disponíveis em %{resellerName}. Explore centenas de excelentes modelos de newsletter que temos para oferecer!",
		"app-public-templates-helmet-cat-title":
			"%{count} %{name} modelos de e-mail | %{resellerName}",
		"app-public-templates-helmet-main-desc":
			"Mais de 750 modelos de newsletter gratuitos oferecidos a você por %{resellerName}. Todos os nossos templates permitirão que você crie lindos emails!",
		"app-public-templates-helmet-main-title":
			"700+ Modelos Gratuitos de E-mail e Boletim Informativo | %{resellerName}",
		"app-public-templates-helmet-single-title":
			"%{name} modelo de e-mail | %{resellerName}",
	},
};
export default PublicTranslations;
