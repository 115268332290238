export const SET_LOADING_FORMS = "SET_LOADING_FORMS";
export const SET_LISTED_FORMS_PER_PAGE = "SET_LISTED_FORMS_PER_PAGE";
export const SET_LISTED_FORMS_PAGINATION = "SET_LISTED_FORMS_PAGINATION";

export const setLoadingForms = (loadingForms) => {
	return {
		type: SET_LOADING_FORMS,
		loadingForms,
	};
};

export const setListedFormsPerPage = (perPage) => {
	return {
		type: SET_LISTED_FORMS_PER_PAGE,
		perPage,
	};
};

export const setListedFormsPagination = (page) => {
	return {
		type: SET_LISTED_FORMS_PAGINATION,
		page,
	};
};
