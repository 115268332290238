import moment, { now } from "moment";
import { I18n, Translate } from "react-redux-i18n";
import { DialogHandler } from "cakemail-ui-components/lib/react";
import React from "react";

export function getCalendarFormat(date, showTime = true) {
	const format = showTime
		? `[${I18n.t("app-general-date-prefix")}] LL [${I18n.t(
			"app-general-date-at"
		  )}] h:mm A`
		: `[${I18n.t("app-general-date-prefix")}] LL`;
	return moment(
		moment.unix(date).isValid() ? moment.unix(date) : date
	).calendar(now(), {
		nextWeek: format,
		lastWeek: format,
		sameElse: format,
	});
}

/*
@params props : object -> shape, {timezone, date, dateOption, selection}
*/
export function getInitialDateTimeObjectPicker(props = {}) {
	const { timezone, date, dateOption, selection } = props;
	let time = null;
	let pm = false;

	if (dateOption) {
		time = moment(moment.unix(date));
	} else {
		time = moment();
	}

	let hour = time.hour();
	let minute = time.minute();
	const tz = timezone ? timezone : moment.tz.guess();

	if (selection === "soon") {
		hour = "12";
		minute = "00";
		pm = true;
	} else {
		if (hour > 12) {
			hour = hour - 12;
			pm = true;
		} else if (hour === 0) {
			hour = "12";
		} else if (hour === 12) {
			pm = true;
		} else if (hour < 10) {
			hour = "0" + hour;
		}

		if (minute < 10) {
			minute = "0" + minute;
		}
	}

	return {
		date: time.toDate(),
		hour: hour,
		minute: minute,
		timezone: tz,
		pm: pm,
	};
}

/*
@param fullTimeObject : object returned from getInitialDateTimeObjectPicker
 */
export function getFinalDateTimeFromPicker(fullTimeObject) {
	let hours = fullTimeObject.hour;

	if (fullTimeObject.hour == "12" && !fullTimeObject.pm) {
		hours = "00";
	} else if (fullTimeObject.pm && fullTimeObject.hour != "12") {
		hours = parseInt(fullTimeObject.hour) + 12;
	}

	let finalDate = moment(fullTimeObject.date).format("YYYY-MM-DD");
	let finalDateTime = moment(
		finalDate + " " + hours + ":" + fullTimeObject.minute
	);

	return finalDateTime.clone().tz(fullTimeObject.timezone).unix();
}

export function isDatePassed(date) {
	const correctedDate = isNaN(date) ? date : moment.unix(date);
	return moment(correctedDate).diff(moment(), "days", true) < 0;
}

export function passedDateDialog(onClick = () => {}, secondaryClick = null) {
	let options = {
		title: <Translate value="app-date-section-passed-title" />,
		type: "warning",
		content: (
			<p>
				<Translate value="app-date-section-passed-desc" />
			</p>
		),
		primaryBtnText: <Translate value="app-date-section-passed-btn-primary" />,
		primaryBtnOnClick: () => {
			onClick();
			DialogHandler.hide();
		},
		secondaryBtnText: (
			<Translate value="app-date-section-passed-btn-secondary" />
		),
	};

	if (secondaryClick) {
		options.secondaryBtnOnClick = () => {
			secondaryClick();
			DialogHandler.hide();
		};
	}
	DialogHandler.show(options);
}
/**
 * The puprose of this function is to check if a certain date/month lies between two date/months value in a circular way
 * @param {date that represents the start time} startDate
 * @param {date that represents the end time} endDate
 * @param {the date to be compared} currentDate
 */
export const checkIfValueLiesInRange = (startDate, endDate, currentDate) => {
	var result = [];
	if (endDate < startDate) {
		startDate.setFullYear(currentDate.getFullYear() - 1);
		endDate.setFullYear(currentDate.getFullYear());
	}
	while (startDate <= endDate) {
		result.push(startDate.getMonth() + 1);
		startDate.setMonth(startDate.getMonth() + 1);
	}
	if (
		result.filter((month) => month === currentDate.getMonth() + 1).length > 0
	) {
		if (currentDate.getMonth() + 1 === result[0]) {
			if (currentDate.getDate() >= startDate.getDate()) {
				return true;
			} else {
				return false;
			}
		}
		if (currentDate.getMonth() + 1 === result[result.length - 1]) {
			if (currentDate.getDate() <= endDate.getDate()) {
				return true;
			} else {
				return false;
			}
		}
		return true;
	} else return false;
};
/***
 * function that formats date to be in the following format : "dddd, MMM D" with capitalized day and month names
 */
export function formatDateWithCapDM(date) {
	try {
		const formattedValue = moment(date).local().format("dddd, MMM D");

		const arrMom = formattedValue.split(", ");

		const formattedDate =
			formattedValue[0].toUpperCase() +
			arrMom[0].substr(1) +
			", " +
			arrMom[1][0].toUpperCase() +
			arrMom[1].substr(1);
		return formattedDate;
	} catch (e) {
		return date;
	}
}

export const getDateOfFirstDayOfPreviousMonth = () => {
	const date = new Date();
	const firstDayPrevMonth = new Date(
		date.getFullYear(),
		date.getMonth() - 1,
		1
	);
	return firstDayPrevMonth;
};
export const getDateOfLastDayOfPreviousMonth = () => {
	const date = new Date();
	const lastDayPrevMonth = new Date(
		date.getFullYear(),
		date.getMonth(),
		0,
		23,
		59,
		59,
		999
	);
	return lastDayPrevMonth;
};
export const getDateOfFirstDayOfPreviousYear = () => {
	const date = new Date();
	const firstDayPrevYear = new Date(date.getFullYear() - 1, 0, 1);
	return firstDayPrevYear;
};
export const getDateOfLastDayOfPreviousYear = () => {
	const date = new Date();
	const lastDayPrevYear = new Date(date.getFullYear(), 0, 0, 23, 59, 59, 999);
	return lastDayPrevYear;
};
export const getDateOfFirstDayOfPreviousWeek = () => {
	const today = new Date();
	const first = today.getDate() - today.getDay() - 7 + 1;
	const firstDay = new Date(today.setDate(first));
	firstDay.setHours(0, 0, 0, 0);
	return firstDay;
};
export const getDateOfLastDayOfPreviousWeek = () => {
	const today = new Date();
	const first = today.getDate() - today.getDay();
	const last = first + 6 - 7 + 1;
	const lastDay = new Date(today.setDate(last));
	lastDay.setHours(23, 59, 59, 999);
	return lastDay;
};
export const getDateOfBeginingYesterday = () => {
	const today = new Date();
	const day = today.getDate() - 1;
	const yesterday = new Date(today.setDate(day));
	yesterday.setHours(0, 0, 0, 0);
	return yesterday;
};
export const getDateOfEndYesterday = () => {
	const today = new Date();
	const day = today.getDate() - 1;
	const yesterday = new Date(today.setDate(day));
	yesterday.setHours(23, 59, 59, 999);
	return yesterday;
};
export const getDateOfBeginingToday = () => {
	const today = new Date();
	today.setHours(0, 0, 0, 0);
	return today;
};
export const getDateOfEndToday = () => {
	const today = new Date();
	today.setHours(23, 59, 59, 999);
	return today;
};
/*
ALWAYS USE CALENDAR FORMAT HERE !


for campaigns :
Countdown specs
• 2+ days = Sending <date> at <time> to <list name>
• 1+ day = Sending tomorrow at <time> to <list name>
• -1 day = Sending in <#> hours to <list name>
• -1 hour = Sending in <#> minutes to <list name>• -60 = Sending in less than a minute to <list name>

Live campaign label countdown specs
• 2+ days = Live: <#> days
• -2 days = Live: <#> hours
• -1 hour = Live: <#> minutes
• -60 seconds = Live: <#> seconds
• 1 second = Live: 1 second

 */
