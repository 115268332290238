import { I18n, Translate } from "react-redux-i18n";

function fetchRoute(route, params) {
	//I18n._translate("app-general-critical-error-PH-broken" )

	const routes = {
		//AUTH public paths
		signUp: "/auth/signup",
		signUpDirect: "/auth/signup/direct",
		signIn: "/auth/signin",
		forgotPassword: "/auth/reset-password",
		passwordConfirmation: "/auth/confirm-password/:token([0-9a-zA-Z%]+)",
		senderConfirmation: "/auth/sender-confirmation/:token([0-9a-zA-Z%]+)",
		accountCreated: "/auth/account-created",
		accountConfirmation:
			"/auth/account-confirmation/:id([0-9a-zA-Z%]+)/:token([0-9a-zA-Z%]+)",
		userConfirmation:
			"/auth/user-confirmation/:id([0-9a-zA-Z%]+)/:token([0-9a-zA-Z-_%]+)",
		accountSetup: "/auth/setup",
		errorFallback: "/blockeduser",

		//CAMPAIGNS
		campaignCreate: "/campaigns/create",
		campaignCreateId: "/campaigns/:id([0-9a-zA-Z%]+)/create",
		campaignDrafts: "/campaigns/drafts",
		campaignOutbox: "/campaigns/outbox",
		campaigns: "/campaigns",
		campaignSent: "/campaigns/sent",
		campaignEdit: "/campaigns/:id([0-9a-zA-Z%]+)/edit",
		campaignStats: "/campaigns/:id([0-9a-zA-Z%]+)/stats",
		campaignStatsPerformance: "/campaigns/:id([0-9a-zA-Z%]+)/stats/performance",
		campaignStatsActivity: "/campaigns/:id([0-9a-zA-Z%]+)/stats/activity",
		campaignTemplates: "/campaigns/templates",
		// campaignTemplatesUsage: "/campaigns/create",
		campaignTemplateEdit: "/campaigns/templates/:id([0-9a-zA-Z%]+)/edit",
		campaignTemplatePreview: "/campaigns/templates/:id([0-9a-zA-Z%]+)",

		//LISTS
		lists: "/lists",
		singleList: "/lists/:listId([0-9a-zA-Z%]+)",
		listManagement: "/lists/:listId([0-9a-zA-Z%]+)/manage",
		listManagementCreate: "/lists/:listId([0-9a-zA-Z%]+)/manage/new",
		addSingleContact: "/lists/:listId([0-9a-zA-Z%]+)/contacts/create",
		contactsOfList: "/lists/:listId([0-9a-zA-Z%]+)/contacts",

		//CONTACTS
		contacts: "/contacts",
		importContacts: "/contacts/import",
		addContacts: "/contacts/import/add-contacts",

		//SEGMENTS
		segmentCreate: "/contacts/segments/create",
		segmentEdit:
			"/contacts/segments/:listId([0-9a-zA-Z%]+)/:segmentId([0-9a-zA-Z%]+)",

		//DASHBOARD
		dashboard: "/dashboard",

		//ACCOUNT
		profile: "/account",

		//SENDERS
		senders: "/account/senders",
		singleSender: "/account/senders/:id([0-9a-zA-Z%]+)",
		deleteSingleSender: "/account/senders/:id([0-9a-zA-Z%]+)/delete",
		sendersCreate: "/account/senders/create",
		domains: "/account/senders/domains",
		domainsSelect: "/account/senders/domains/select",
		domainAuthenticate: "/account/senders/domains/:id(.+)/authenticate",

		users: "/account/users",
		userCreate: "/account/users/create",
		userDelete: "/account/users/:id([0-9a-zA-Z%]+)/delete",
		userSingle: "/account/users/:id([0-9a-zA-Z%]+)",

		accountActivities: "/account/activity",
		accountExports: "/account/exports",

		//FORMS
		forms: "/forms",
		formsCreate: "/forms/create",
		formPolicy: "/forms/:id([0-9a-zA-Z%]+)/policy",

		//AUTOMATION
		automation: "/automations",
		automationCreate: "/automations/create",
		automationEdit: "/automations/:id([0-9a-zA-Z%]+)/edit",
		automationSetup: "/automations/setup",
		contactsListing: "/automations/:id([0-9a-zA-Z%]+)/edit/contacts",

		//BRAND
		brand: "/brand",

		//LIST CONFIRMATIONS - public
		listRedirections: "/public/list",
		listSubscription: "/public/list/subscribe",
		listConfirmation: "/public/list/confirm",
		listUnsubscribe: "/public/list/unsubscribe",

		//ONBOARDING - browser router - public
		public: "/public",
		templatesListing: "/public/templates",
		emailCreation: "/select/public/templates/:id([0-9a-zA-Z%]+)/create",
		templatePreview: "/public/templates/:id([0-9a-zA-Z%]+)",
	};

	if (!Object.keys(routes).includes(route)) {
		console.error(`Route : ${route} doesn't exist`);
		return null;
	}
	return replaceParams(routes[route], params);
}

export default fetchRoute;

function replaceParams(route, params = null) {
	if (!params) return route;
	Object.keys(params).forEach((param, index) => {
		if (!params[param]) {
			console.error(
				`Route param : ${param}, doesn't exist on route : ${route} `
			);
		} else {
			route = route.replace(new RegExp(`:${param}\\((.)+?\\)`), params[param]);
		}
	});

	return route;
}
