import { applyMiddleware, combineReducers, createStore } from "redux";
import promise from "redux-promise-middleware";
import { reducer as CampaignReducer } from "./scenes/campaigns/reducer";
import { reducer as ContactsReducer } from "./scenes/contacts/reducer";
import { reducer as FormsReducer } from "./scenes/forms/reducer";
import { reducer as LayoutReducer } from "./data/layout/reducer";
import { reducer as GlobalsReducer } from "./reducer";
import { reducer as AccountReducer } from "./scenes/account/reducer";
import { reducer as SegmentsReducer } from "./scenes/data/segments/reducer";
import { reducer as ContactsListingReducer } from "./scenes/data/contacts/listing/reducer";
import { reducer as ContactAttributesReducer } from "./scenes/data/contacts/attributes/reducer";
import { reducer as SendersReducer } from "./scenes/data/senders/reducer";
import { reducer as SubscriptionsReducer } from "./scenes/data/subscriptions/reducer";
import { reducer as ListedFormsReducer } from "./scenes/data/forms/reducer";
import { reducer as DeliveriesReducer } from "./scenes/data/deliveries/reducer";
import { reducer as DeliverablesReducer } from "./scenes/data/deliverables/reducer";
import { reducer as TemplatesReducer } from "./data/templates/reducer";
import { reducer as AutomationReducer } from "./data/automations/reducer";
import { reducer as ContactsSegmentationReducer } from "assets/js/scenes/automations/components/Contacts/Filters/data/reducer";
import { reducer as AutomationV1Reducer } from "./data/automations/automationV1/reducer";
import { reducer as BrandReducer } from "./data/branding/reducer";
import { reducer as ListsReducer } from "./scenes/lists/reducer";
import { reducer as OverlaysReducer } from "assets/js/data/overlays/reducer";
import { reducer as localBrandReducer } from "./scenes/brand/reducer";
import thunk from "redux-thunk";
import { i18nReducer } from "react-redux-i18n";

const appReducer = combineReducers({
	campaign: CampaignReducer,
	contacts: ContactsReducer,
	forms: FormsReducer,
	global: GlobalsReducer,
	i18n: i18nReducer,
	layout: LayoutReducer,
	account: AccountReducer,
	segments: SegmentsReducer,
	automations: AutomationReducer,
	contactsSegmentation: ContactsSegmentationReducer,
	automationsV1: AutomationV1Reducer,
	listedContacts: ContactsListingReducer,
	attributes: ContactAttributesReducer,
	senders: SendersReducer,
	subscriptions: SubscriptionsReducer,
	listedForms: ListedFormsReducer,
	deliveries: DeliveriesReducer,
	deliverables: DeliverablesReducer,
	templates: TemplatesReducer,
	brand: BrandReducer,
	lists: ListsReducer,
	localBrand: localBrandReducer,
	overlays: OverlaysReducer,
});

function rootReducer(state = [], action) {
	if (action.type === "RESET_STORE") {
		state = {
			i18n: state.i18n,
			global: {
				language: state.global.language,
				authenticated: false,
				accountReady: false,
			},
			brand: state.brand,
		};
	}

	return appReducer(state, action);
}
const store = createStore(rootReducer, {}, applyMiddleware(promise, thunk));

// if public route, we will need to initialize the locizer as fast as possible once the store is created
// if (window.location.href.includes("public")) {
// 	locizer.init({
// 		fallbackLng: "en-US",
// 		referenceLng: "en-US",
// 		loadIfTranslatedOver: -1,
// 		projectId: process.env.LOCIZE_PORJECT_ID,
// 		version: process.env.LOCIZE_PORJECT_VERSION,
// 		order: ["querystring"],
// 		lookupQuerystring: "lng",
// 	});

// const url = new URLSearchParams(window.location.search);
// const urlLocale = url.get("locale");
// let cookies = new Cookies();
// const cookieLocale = cookies.get("locale");

// let finalLocale = "en_US";
// if (urlLocale) {
// 	finalLocale = urlLocale;
// } else if (cookieLocale) {
// 	finalLocale = cookieLocale;
// }
// let translations = {};
// translations[finalLocale]  = PublicTranslations[finalLocale ? finalLocale.replace("_", "-") : "en-US"];
// loadTranslations(translations)
// setLocaleWithFallback(finalLocale)
//syncTranslationWithStore(store);
//store.dispatch(loadTranslations(translations));
//store.dispatch(setLocaleWithFallback(finalLocale));

// locizer.load(
// 	"public",
// 	finalLocale ? finalLocale.replace("_", "-") : "en-US",
// 	(err, messages, locale) => {
//console.log(messages)
// console.log(PublicTranslations[finalLocale? finalLocale.replace("_", "-") : "en-US"])
// let translations = {};
// translations[finalLocale] = 	PublicTranslations[finalLocale ? finalLocale.replace("_", "-") : "en-US"];
// syncTranslationWithStore(store);
// store.dispatch(loadTranslations(translations));
// store.dispatch(setLocaleWithFallback(finalLocale));
// 	}
// );
//}

export default store;
