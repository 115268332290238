import {
	SUBSCRIPTIONS as SET_SUBSCRIPTIONS,
	SUBSCRIPTIONS_COUNT as SET_SUBSCRIPTIONS_COUNT,
	LISTED_SUBSCRIPTIONS_PER_PAGE as SET_LISTED_SUBSCRIPTIONS_PER_PAGE,
	LISTED_SUBSCRIPTIONS_PAGINATION as SET_LISTED_SUBSCRIPTIONS_PAGINATION,
} from "./actions";
import React from "react";

let initialState = () => {
	return {
		subscriptions: null,
		subscriptionsCount: 0,
		perPage: 25,
		page: 1,
	};
};

export const reducer = (state = initialState(), action) => {
	switch (action.type) {
	case SET_SUBSCRIPTIONS:
		return { ...state, subscriptions: action.subscriptions };
		break;
	case SET_SUBSCRIPTIONS_COUNT:
		return { ...state, subscriptionsCount: action.subscriptionsCount };
		break;
	case SET_LISTED_SUBSCRIPTIONS_PER_PAGE:
		return { ...state, perPage: action.perPage, page: initialState().page };
		break;
	case SET_LISTED_SUBSCRIPTIONS_PAGINATION:
		return { ...state, page: action.page };
	default:
		return state;
		break;
	}
};
