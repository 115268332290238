import { getAllPreviousSendActions } from "assets/js/scenes/automations/containers/editor/utils/utils";

/**
 * A variable is represented as ${VAR_NAME} , the function gets the VAR_NAME
 * @param {*} varValue
 */
const getValudOfVariable = (varValue) => {
	if (varValue) {
		let actualValue = varValue.replace(/\}$/, "");
		actualValue = actualValue.replace(/\$\{/gi, "");
		return actualValue;
	}
	return null;
};
/**
 * this function gets the id of the send email action [trigger] that a stats points to example : output would return the trigger
 * that was send as a param to get stats step
 */
const getRepresentableStepForTheCondition = (automation, logStepId) => {
	return getValudOfVariable(
		automation.main.steps.filter((step) => step.id == logStepId)[0].args.params
			.trigger_id
	).replace(/_output$/, "");
};
/**
 * this function extracts the needed values from the BE var expression, example : "var": "int(output.clicked)"
 * the function extracts the name of the counted logs, which is number of clicks, the send email action  [trigger] that the stats point to
 * @param {*} automation
 * @param {*} varValue
 */
const getValuesFromVar = (automation, varValue) => {
	let actualValue = varValue.replace(/\)$/, "");
	let attribute = "";
	let endsWith = "";
	if (actualValue.endsWith(".c")) {
		attribute = "clicks";
		endsWith = ".c";
	}
	if (actualValue.endsWith(".o")) {
		attribute = "opens";
		endsWith = ".o";
	}
	if (actualValue.endsWith(".s")) {
		attribute = "sent";
		endsWith = ".s";
	}

	let valueWithoutAttribute = actualValue.replace(endsWith, "");
	valueWithoutAttribute = valueWithoutAttribute.replace(/_output$/, "");
	return {
		attribute: attribute,
		value: getRepresentableStepForTheCondition(
			automation,
			valueWithoutAttribute
		),
	};
};
/**
 * this function converts a condition from a BE switch condition to FE condition object
 * @param {} automation
 * @param {*} condition
 */
const getSingleCondition = (automation, condition) => {
	if (
		Object.keys(condition)[0] == "ge" &&
		Object.values(condition)[0].value == 1
	) {
		return {
			...getValuesFromVar(automation, Object.values(condition)[0].var),
			op: "specific",
		};
	}
	if (
		Object.keys(condition)[0] == "eq" &&
		Object.values(condition)[0].value == 0
	) {
		const valuesFromVar = getValuesFromVar(
			automation,
			Object.values(condition)[0].var
		);
		valuesFromVar.attribute = `no_${valuesFromVar.attribute}`;
		return { ...valuesFromVar, op: "specific" };
	}
};
const getListOfConditions = (automation, condition) => {
	const operator = Object.keys(condition)[0];

	const condOptions =
		condition.length > 0
			? getSingleCondition(automation, condition[operator][0])
			: { attribute: "opens" };
	return {
		...condOptions,
		op: operator,
		value: condition[operator].length == 5 ? "5" : "all",
	};
};
const isObject = (value) => {
	return !!(value && typeof value === "object" && !Array.isArray(value));
};
const traverseObjectToVarKey = (obj, keyToMatch) => {
	if (isObject(obj)) {
		const entries = Object.entries(obj);
		for (let i = 0; i < entries.length; i += 1) {
			const [objectKey, objectValue] = entries[i];
			if (objectKey === keyToMatch) {
				return obj;
			}
			if (isObject(objectValue)) {
				const child = traverseObjectToVarKey(objectValue, keyToMatch);

				if (child !== null) {
					return child;
				}
			}
		}
	}
	return null;
};
/**
 * this function converts a condition from a switch condition to FE condition object
 * @param {} automation
 * @param {*} condition
 */
const getCondition = (automation, condition) => {
	const varObject = traverseObjectToVarKey(condition, "var");
	// check the subautomation that the varObject evaluates
	const evaluatingStep = automation.main.steps.filter(
		(step) => step.name == varObject.var.split("_output")[0]
	)[0];
	const evaluatingSubAutomationName = evaluatingStep.args.sub_automation;
	const actionType = evaluatingStep.args.params.type;
	const triggerIds = evaluatingStep.args.params.trigger_ids;
	// if triggerIds is string -> specific
	if (evaluatingSubAutomationName == "sub_get_stats") {
		return getSingleCondition(automation, condition);
	} else {
		const condition = {
			...getEvaluatedActionFromSwitch(
				actionType,
				evaluatingSubAutomationName,
				!varObject.value
			),
			value: triggerIds.length == 5 ? "5" : "all",
		};
		return condition;
	}
};
/**
 * Convert the condition of the frontend to switch object
 */
const getSingleSwitch = (condition, value, forcedName, addSuffix = true) => {
	const switchCondition = {};
	let op = "";
	let varValue = "";
	if (condition.attribute.includes("no_")) {
		op = "eq";
		switchCondition.eq = { value: 0 };
	} else {
		op = "ge";
		switchCondition.ge = { value: 1 };
	}
	if (condition.attribute.endsWith("clicks")) {
		varValue = forcedName ? forcedName : condition.value + value;

		varValue = addSuffix ? varValue + "_output.c" : varValue + "_output";
	} else if (condition.attribute.endsWith("opens")) {
		varValue = forcedName ? forcedName : condition.value + value;
		varValue = addSuffix ? varValue + "_output.o" : varValue + "_output";
	} else if (condition.attribute.endsWith("sent")) {
		varValue = forcedName ? forcedName : condition.value + value;
		varValue = addSuffix ? varValue + "_output.s" : varValue + "_output";
	}
	switchCondition[op].var = varValue;
	return switchCondition;
};
/**
 *
 * @param {} subAutomationName
 * @param {*} outputVar : subautomations return outputs in the form of object with keys and values, or a single value, in case of object outputVar
 * is the required key
 */
const constructOutputCallerVar = (subAutomationName, outputVar) => {
	let result = subAutomationName;
	result = outputVar ? result + outputVar : result;
	return `${result}_output`;
};
/**
 *
 * @param {*} op
 * @param {*} varName
 * @param {*} value
 */
const constructSwitchConditionJson = (
	op,
	varName,
	value,
	invertTheCondition
) => {
	const condition = { [op]: { var: varName, value: value } };
	if (invertTheCondition) {
		return { not: condition };
	}
	return condition;
};
const getPreviousSetOfEmails = (condition, eventId) => {
	const previousEmails = getAllPreviousSendActions(eventId);
	return previousEmails.map((email) => email.id);
};
// const getListOfSwitches = (condition, value)=>{
// 	// get all the previous emails
// 	const previousSetOfEmails = getPreviousSetOfEmails(condition, value);
// 	if(condition.value=="all")
// 		return previousSetOfEmails.map(email => getSingleSwitch({...condition, value:email}, value));
// 	else {
// 		const getOnlyLast5Email = previousSetOfEmails.slice(0, 5).map(email => getSingleSwitch({...condition, value:email}, value));
// 		return getOnlyLast5Email;
// 	}
// };
const getListOfSwitches = (requiredSubAutomationType) => {
	switch (requiredSubAutomationType) {
	case "MultipleTriggers": {
	}
	}
};
const getSwitch = (condition, value) => {
	if (condition.op == "specific") {
		return getSingleSwitch(condition, value);
	} else {
		return {
			[condition.op]: getListOfSwitches("MultipleTriggers", condition, value),
		};
	}
};
const getConditionsValues = (condition, eventId) => {
	let conditionValues = [];
	if (condition.op == "specific") {
		conditionValues.push(condition.value);
	} else {
		conditionValues = getPreviousSetOfEmails(condition, eventId);
	}
	return conditionValues;
};
const mapCampaignActivitiesToBECorresponders = (type) => {
	switch (type) {
	case "clicks":
	case "no_clicks":
		return "clickthru";
	case "opens":
	case "no_opens":
		return "opened";
	case "sent":
	case "no_sent":
		return "sent";
	}
};
const mapBEActivitiesToCampaignCorresponders = (type) => {
	switch (type) {
	case "clickthru":
		return "clicks";
	case "opened":
		return "opens";
	case "sent":
		return "sent";
	}
};
const getEvaluationSubAutomationName = (type, invert) => {
	if (invert)
		switch (type) {
		case "clicks":
		case "opens":
		case "sent":
			return "all_not_action_emails";
		case "no_clicks":
		case "no_opens":
		case "no_sent":
			return "all_action_emails";
		}
	else {
		switch (type) {
		case "clicks":
		case "opens":
		case "sent":
			return "all_action_emails";
		case "no_clicks":
		case "no_opens":
		case "no_sent":
			return "all_not_action_emails";
		}
	}
};
const getEvaluatedActionFromSwitch = (type, subAutomationName, inverted) => {
	if (inverted)
		switch (subAutomationName) {
		case "all_not_action_emails": {
			return {
				op: "or",
				attribute: mapBEActivitiesToCampaignCorresponders(type),
			};
		}
		case "all_action_emails": {
			return {
				op: "or",
				attribute: `no_${mapBEActivitiesToCampaignCorresponders(type)}`,
			};
		}
		}
	else {
		switch (subAutomationName) {
		case "all_action_emails": {
			return {
				op: "and",
				attribute: mapBEActivitiesToCampaignCorresponders(type),
			};
		}
		case "all_not_action_emails": {
			return {
				op: "and",
				attribute: `no_${mapBEActivitiesToCampaignCorresponders(type)}`,
			};
		}
		}
	}
};
export {
	getCondition,
	getValudOfVariable,
	getValuesFromVar,
	getRepresentableStepForTheCondition,
	getSwitch,
	getPreviousSetOfEmails,
	getConditionsValues,
	mapCampaignActivitiesToBECorresponders,
	getSingleSwitch,
	getEvaluationSubAutomationName,
	constructOutputCallerVar,
	constructSwitchConditionJson,
};
