import {
	SINGLE_CONTACT as SET_SINGLE_CONTACT,
	SINGLE_CONTACT_VIEW as SET_SINGLE_CONTACT_VIEW,
	SINGLE_CONTACT_EVENTS as SET_SINGLE_CONTACT_EVENTS,
} from "./actions";
import { reducer as BrowserReducer } from "./data/browser/reducer";
import * as _ from "lodash";

let initialState = () => {
	return {
		contact: null,
		contactEvents: null,
		contactInactiveSubscriptions: null,
		view: "details",
	};
};

export const reducer = (state = initialState(), action) => {
	switch (action.type) {
	case SET_SINGLE_CONTACT:
		return { ...state, contact: action.contact };
	case SET_SINGLE_CONTACT_EVENTS:
		return { ...state, contactEvents: action.events };
	case SET_SINGLE_CONTACT_VIEW:
		return { ...state, view: action.view };
	default:
		const rest = _.omit(state, Object.keys(initialState()));
		return {
			...state,
			browser: BrowserReducer(rest.browser, action),
		};
		break;
	}
};
