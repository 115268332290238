import { reducer as ListedFormsReducer } from "../../../data/forms/reducer";
import _ from "lodash";
import {
	SET_LOADING_FORMS,
	SET_LISTED_FORMS_PER_PAGE,
	SET_LISTED_FORMS_PAGINATION,
} from "./actions";

export let initialState = () => {
	return {
		loadingForms: false,
		sorting: ["name"],
		perPage: 25,
		page: 1,
	};
};

export const reducer = (state = initialState(), action) => {
	switch (action.type) {
	case SET_LOADING_FORMS:
		return { ...state, loadingForms: action.loadingForms };

	case SET_LISTED_FORMS_PER_PAGE:
		return { ...state, perPage: action.perPage, page: initialState().page };

	case SET_LISTED_FORMS_PAGINATION:
		return { ...state, page: action.page };

	default:
		const rest = _.omit(state, Object.keys(initialState()));
		return {
			...state,
			listedForms: ListedFormsReducer(rest.listedForms, action),
		};
	}
};
