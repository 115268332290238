import { CUSTOM as SET_CUSTOM, VISIBLE as SET_VISIBLE } from "./actions";

export let initialState = () => {
	return {
		isError: false,
		customHeader: null,
		customMessage: null,
		isVisible: true,
	};
};

export const reducer = (state = initialState(), action) => {
	switch (action.type) {
	case SET_CUSTOM:
		return {
			...state,
			customHeader: action.header,
			customSubHeader: action.subHeader,
			customText: action.text,
			isError: action.isError,
			isVisible: true,
		};
		break;
	case SET_VISIBLE:
		return { ...state, isVisible: action.isVisible };
		break;
	default:
		return state;
		break;
	}
};
