import { SELECTED as SELECTED_RELATIONSHIP } from "./actions";
import React from "react";
import { Translate } from "react-redux-i18n";

export let initialState = () => {
	return [
		{
			name: "from-signup-form",
			label: (
				<Translate value="app-imports-permission-helper-DO-used_signup_form" />
			),
			selected: false,
			icon: "/assets/images/import/relation_icon_1.svg",
			description: (
				<Translate
					value="app-imports-permission-helper-TXT-submited_email_by_form"
					dangerousHTML
				/>
			),
			permissionType: "express",
			permissionExpiry: (
				<Translate value="app-imports-permission-helper-TXT-expires_unsubscribes" />
			),
			tooltip: <Translate value="app-contact-helper_question" dangerousHTML />,
			dateTitle: null,
			dateSubTitle: null,
			singleDescription: (
				<Translate value="app-contacts-add-contact-form-FO-used_signup_form" />
			),
			gdpr: false,
		},
		{
			name: "active-clients",
			label: (
				<Translate value="app-imports-permission-helper-DO-active_clients" />
			),
			selected: false,
			icon: "/assets/images/import/relation_icon_2.png",
			description: (
				<Translate
					value="app-imports-permission-helper-TXT-all_active_clients"
					dangerousHTML
				/>
			),
			permissionType: "implied",
			permissionExpiry: (
				<Translate value="app-imports-permission-helper-TXT-expires_two_years" />
			),
			tooltip: <Translate value="app-contact-helper_question" dangerousHTML />,
			dateTitle: (
				<Translate value="app-import-relationship-date-TXT-last_purchase" />
			),
			dateSubTitle: (
				<Translate
					value="app-import-relationship-date-TXT-was_on_or_after"
					dangerousHTML
				/>
			),
			singleDescription: (
				<Translate value="app-contacts-add-contact-form-FO-active_clients" />
			),
			gdpr: true,
		},
		{
			name: "inactive-clients",
			label: (
				<Translate value="app-imports-permission-helper-DO-inactive_clients" />
			),
			selected: false,
			icon: "/assets/images/import/relation_icon_3.png",
			description: (
				<Translate
					value="app-import-relationship-inactive-TXT-inactive_contacts"
					dangerousHTML
				/>
			),
			permissionType: "implied",
			permissionExpiry: (
				<Translate value="app-imports-permission-helper-TXT-expires_two_years" />
			),
			tooltip: <Translate value="app-contact-helper_question" dangerousHTML />,
			dateTitle: (
				<Translate value="app-import-relationship-date-TXT-last_purchase" />
			),
			dateSubTitle: (
				<Translate
					value="app-import-relationship-date-TXT-was_on_or_after"
					dangerousHTML
				/>
			),
			singleDescription: (
				<Translate value="app-contacts-add-contact-form-FO-inactive_clients" />
			),
			gdpr: true,
		},
		{
			name: "asked-for-info",
			label: (
				<Translate value="app-imports-permission-helper-DO-asked_information" />
			),
			selected: false,
			icon: "/assets/images/import/relation_icon_4.png",
			description: (
				<Translate
					value="app-import-relationship-information-request-TXT-contacts_request"
					dangerousHTML
				/>
			),
			permissionType: "inquiry",
			permissionExpiry: (
				<Translate value="app-import-relationship-information-request-TXT-expiration_information" />
			),
			tooltip: <Translate value="app-contact-helper_question" dangerousHTML />,
			dateTitle: (
				<Translate value="app-import-relationship-date-TXT-information_requested" />
			),
			dateSubTitle: (
				<Translate
					value="app-import-relationship-date-TXT-info_requested_on_or_after"
					dangerousHTML
				/>
			),
			singleDescription: (
				<Translate value="app-contacts-add-contact-form-FO-asked_information" />
			),
			gdpr: true,
		},
		{
			name: "business-card",
			label: (
				<Translate value="app-imports-permission-helper-DO-business_card" />
			),
			selected: false,
			icon: "/assets/images/import/relation_icon_5.png",
			description: (
				<Translate
					value="app-imports-permission-helper-TXT-email_relationship"
					dangerousHTML
				/>
			),
			permissionType: "implied",
			permissionExpiry: (
				<Translate value="app-imports-permission-helper-TXT-expires_2_years_business_card" />
			),
			tooltip: <Translate value="app-contact-helper_question" dangerousHTML />,
			dateTitle: (
				<Translate value="app-import-relationship-business-PH-when_did_you_receive" />
			),
			dateSubTitle: (
				<Translate
					value="app-import-relationship-business-TXT-provided"
					dangerousHTML
				/>
			),
			singleDescription: (
				<Translate value="app-contacts-add-contact-form-FO-business_card" />
			),
			gdpr: true,
		},
		{
			name: "association",
			label: (
				<Translate value="app-imports-permission-helper-DO-same_association" />
			),
			selected: false,
			icon: "/assets/images/import/relation_icon_6.png",
			description: (
				<Translate
					value="app-import-relationship-association-TXT-association_i_belong"
					dangerousHTML
				/>
			),
			permissionType: "implied",
			permissionExpiry: (
				<Translate value="app-import-relationship-TXT-expires_two_years" />
			),
			tooltip: <Translate value="app-contact-helper_question" dangerousHTML />,
			dateTitle: (
				<Translate value="app-import-relationship-date-TXT-association_joined" />
			),
			dateSubTitle: (
				<Translate
					value="app-import-relationship-date-TXT-association_joined_on_or_after"
					dangerousHTML
				/>
			),
			singleDescription: (
				<Translate value="app-contacts-add-contact-form-FO-same_association" />
			),
			gdpr: true,
		},
		{
			name: "employees",
			label: <Translate value="app-imports-permission-helper-DO-employees" />,
			selected: false,
			icon: "/assets/images/import/relation_icon_7.png",
			description: (
				<Translate
					value="app-import-relationship-employees-TXT-employees_list"
					dangerousHTML
				/>
			),
			permissionType: "implied",
			permissionExpiry: (
				<Translate value="app-import-relationship-employees-TXT-expires" />
			),
			tooltip: <Translate value="app-contact-helper_question" dangerousHTML />,
			dateTitle: (
				<Translate value="app-import-relationship-date-T-are_my_employees" />
			),
			dateSubTitle: (
				<Translate
					value="app-import-relationship-date-TXT-are_my_employees"
					dangerousHTML
				/>
			),
			singleDescription: (
				<Translate value="app-contacts-add-contact-form-FO-employees" />
			),
			gdpr: true,
		},
		{
			name: "partners",
			label: <Translate value="app-imports-permission-helper-DO-partners" />,
			selected: false,
			icon: "/assets/images/import/relation_icon_8.png",
			description: (
				<Translate
					value="app-import-relationship-partners-TXT-partners"
					dangerousHTML
				/>
			),
			permissionType: "implied",
			permissionExpiry: (
				<Translate value="app-import-relationship-TXT-expires_two_years" />
			),
			tooltip: <Translate value="app-contact-helper_question" dangerousHTML />,
			dateTitle: (
				<Translate value="app-import-relationship-date-T-are_my_partners" />
			),
			dateSubTitle: (
				<Translate
					value="app-import-relationship-date-TXT-are_my_partners"
					dangerousHTML
				/>
			),
			singleDescription: (
				<Translate value="app-contacts-add-contact-form-FO-partners" />
			),
			gdpr: true,
		},
		{
			name: "from-public-listing",
			label: <Translate value="app-imports-permission-helper-DO-public_list" />,
			selected: false,
			icon: "/assets/images/import/relation_icon_9.png",
			description: (
				<Translate
					value="app-import-relationship-public-TXT-public_contatc_list"
					dangerousHTML
				/>
			),
			permissionType: "no-permission",
			permissionExpiry: (
				<Translate value="app-import-relationship-purchased-TXT-cant_email_contacts" />
			),
			dateTitle: null,
			dateSubTitle: null,
			singleDescription: (
				<Translate value="app-contacts-add-contact-form-FO-public_list" />
			),
			gdpr: false,
		},
		{
			name: "from-certified-listing",
			label: (
				<Translate value="app-imports-permission-helper-DO-purchased_list" />
			),
			selected: false,
			icon: "/assets/images/import/relation_icon_10.png",
			description: (
				<Translate
					value="app-import-relationship-purchased-TXT-using_purchased_list"
					dangerousHTML
				/>
			),
			permissionType: "no-permission",
			permissionExpiry: (
				<Translate value="app-import-relationship-purchased-TXT-cant_email_contacts" />
			),
			dateTitle: null,
			dateSubTitle: null,
			singleDescription: (
				<Translate value="app-contacts-add-contact-form-FO-purchased_list" />
			),
			gdpr: false,
		},
		{
			name: "from-contest",
			label: <Translate value="app-imports-permission-helper-DO-contest" />,
			selected: false,
			icon: "/assets/images/import/relation_icon_11.png",
			description: (
				<Translate
					value="app-imports-permission-helper-TXT-entered_contest"
					dangerousHTML
				/>
			),
			permissionType: "express",
			permissionExpiry: (
				<Translate value="app-imports-permission-helper-TXT-expires_unsubscribes" />
			),
			tooltip: <Translate value="app-contact-helper_question" dangerousHTML />,
			singleDescription: (
				<Translate value="app-contacts-add-contact-form-FO-contest" />
			),
			gdpr: true,
		},
		{
			name: "match-multiple-options",
			label: (
				<Translate value="app-imports-permission-helper-DO-match_two_more_options" />
			),
			selected: false,
			icon: "/assets/images/import/relation_icon_12.png",
			description: (
				<Translate
					value="app-import-relationship-multiple-TXT-organize"
					dangerousHTML
				/>
			),
			permissionType: "multiple",
			permissionExpiry: "",
			dateTitle: null,
			dateSubTitle: null,
			gdpr: false,
		},
		{
			name: "no-match",
			label: (
				<Translate value="app-imports-permission-helper-DO-match_no_options" />
			),
			selected: false,
			description: (
				<Translate
					value="app-import-relationship-zendesk-TXT-dont_worry"
					dangerousHTML
				/>
			),
			permissionType: "n/a",
			permissionExpiry: "Requires a relation",
			dateTitle: null,
			dateSubTitle: null,
			singleDescription: (
				<Translate value="app-contacts-add-contact-form-FO-match_no_options" />
			),
			gdpr: false,
		},
	];
};

const relationship = (state = {}, action) => {
	switch (action.type) {
	case SELECTED_RELATIONSHIP:
		if (state.name !== action.name) {
			return { ...state, selected: false };
		}

		return { ...state, selected: true };

	default:
		return state;
	}
};

export const reducer = (state = initialState(), action) => {
	switch (action.type) {
	case SELECTED_RELATIONSHIP:
		return state.map((r) => relationship(r, action));
	default:
		return state;
	}
};
