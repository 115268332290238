import {
	DELIVERABLES as SET_DELIVERABLES,
	DRAFT_DELIVERABLES as SET_DRAFT_DELIVERABLES,
	LISTED_DELIVERABLES_PER_PAGE as SET_LISTED_DELIVERABLES_PER_PAGE,
	LISTED_DELIVERABLES_PAGINATION as SET_LISTED_DELIVERABLES_PAGINATION,
} from "./actions";

let initialState = () => {
	return {
		deliverables: null,
		draftDeliverables: null,
		perPage: 25,
		page: 1,
	};
};

export const reducer = (state = initialState(), action) => {
	switch (action.type) {
	case SET_DELIVERABLES:
		return { ...state, deliverables: action.deliverables };
		break;
	case SET_DRAFT_DELIVERABLES:
		return { ...state, draftDeliverables: action.draftDeliverables };
		break;
	case SET_LISTED_DELIVERABLES_PER_PAGE:
		return { ...state, perPage: action.perPage, page: initialState().page };
		break;
	case SET_LISTED_DELIVERABLES_PAGINATION:
		return { ...state, page: action.page };
	default:
		return state;
		break;
	}
};
