import moment from "moment";
import store from "../store";
import { getCampaignStatsService } from "assets/js/services/reportService";
import promisePool from "es6-promise-pool";
import { getListService } from "assets/js/services/listService";
import { getSegmentService } from "assets/js/services/segmentService";
import { getCampaignService } from "assets/js/services/campaignService";
import deepcopy from "deepcopy";
import { getContactsOfListService } from 'assets/js/services/contactService'

export const LOCAL_STORAGE_DATA = {
	LISTS: "Lists",
	CAMPAIGN: "Campaigns",
	SEGMENT: "Segments",
};

export function getListsCache() {
	return getCacheItems(LOCAL_STORAGE_DATA.LISTS);
}
export function setListsCache(memo = null, id = null) {
	setCachedItems(LOCAL_STORAGE_DATA.LISTS, memo, { id: id });
}

export function getCampaignCache() {
	return getCacheItems(LOCAL_STORAGE_DATA.CAMPAIGN);
}
export function setCampaignCache(memo = null, id = null) {
	setCachedItems(LOCAL_STORAGE_DATA.CAMPAIGN, memo, { id: id });
}

export function getSegmentsCache() {
	return getCacheItems(LOCAL_STORAGE_DATA.SEGMENT);
}
export function setSegmentsCache(memo = null, id = null) {
	setCachedItems(LOCAL_STORAGE_DATA.SEGMENT, memo, { id: id });
}

function getCacheItems(key) {
	const accountId = store.getState().global.account.id;
	let data = window.localStorage.getItem(accountId + key);
	if (data) {
		try {
			let cachedItems = JSON.parse(data);
			if (cachedItems?.expires) {
				if (moment(cachedItems?.expires).isBefore()) {
					return {};
				}
			} else {
				const asArray = Object.entries(cachedItems);
				const filtered = asArray.filter((item) => {
					return !(item[1].expires && moment(item?.[1]?.expires).isBefore());
				});
				cachedItems = Object.fromEntries(filtered);
			}

			return cachedItems;
		} catch (e) {
			console.error("Error with localStorage", e);
			return {};
		}
	}
	return {};
}

function setCachedItems(key, memo, options = { globalExpire: null, id: null }) {
	const accountId = store.getState().global.account.id;

	if (!memo) memo = {};

	if (options.globalExpire) {
		memo.expires = moment().add(
			setCacheExpirery(options.globalExpire ? options.globalExpire : 60),
			"minutes"
		);
	}

	try {
		window.localStorage.setItem(accountId + key, JSON.stringify(memo));
		return memo;
	} catch (e) {
		console.error("Error with localStorage", e);
	}
}

export function setCacheExpirery(baseOffset = 10, baseRandOffset = 10) {
	return Math.random() * baseRandOffset + baseOffset;
}

export function deleteCacheKey(key, id = null) {
	const accountId = store.getState().global.account.id;
	let cachedItems = window.localStorage.getItem(accountId + key);
	if (!cachedItems) return;

	if (id === null) {
		window.localStorage.removeItem(key);
	} else {
		try {
			cachedItems = JSON.parse(cachedItems);
			delete cachedItems[id];
			window.localStorage.setItem(accountId + key, JSON.stringify(cachedItems));
			// eslint-disable-next-line no-empty
		} catch (e) {}
	}
}

/*
 * @param data : initial loop data to go though
 * @param initMemo : initial memo data if any
 * @param cacheFunction : One of the caching function from this file
 * @param dataCallback : give you back the current "memo" in the param for external use
 * @param promiseFunction : your promise function that prepares the data to cache, give you the (data, current memo, setCache memo function,resolve function for the promise)
 * 													At the end of the function, you should always use the setCache function to update the cache, and if you want progressive updates on component,
 * 													use a setState with the new memoData that you built. Check the functions in this file for examples
 *
 */
export function promisePoolCaching(
	data,
	initMemo = {},
	cacheFunction = () => {},
	dataCallback = null,
	promiseFunction = () => {}
) {
	const currentData = data;
	const maxCount = currentData.length;
	let currentIndex = 0;
	let tmpMemo = { ...initMemo };

	// eslint-disable-next-line no-inner-declarations
	function getReport(promiseData) {
		currentIndex++;
		return new Promise((resolve) => {
			promiseFunction(
				promiseData,
				tmpMemo,
				(newMemo) => {
					tmpMemo = {
						...tmpMemo,
						...newMemo,
					};
				},
				resolve
			);
		});
	}

	// eslint-disable-next-line no-inner-declarations
	function promisePoolProducer() {
		if (currentIndex < maxCount) {
			return getReport(currentData[currentIndex]);
		} else {
			return null;
		}
	}

	let es6Pool = new promisePool(promisePoolProducer, 10);
	es6Pool.start().then(() => {
		cacheFunction(tmpMemo);
		if (dataCallback) {
			dataCallback(tmpMemo);
		}
	});
}

export function campaignCachePromisePool(campaigns, setState) {
	const filteredCampaignIds = campaigns.reduce((prev, current) => {
		const foundCampaign = prev.find((item) => item.id === current.id);
		if (!foundCampaign) {
			return prev.concat([current]);
		} else {
			return prev;
		}
	}, []);

	promisePoolCaching(
		filteredCampaignIds,
		getCampaignCache(),
		setCampaignCache,
		setState,
		(promiseData, tmpMemo, setMemoFunction, resolve) => {
			if (
				tmpMemo["" + promiseData.id] == null ||
				tmpMemo["" + promiseData.id]?.name == null
			) {
				getCampaignService(
					{
						id: promiseData.id,
					},
					(data) => {
						tmpMemo = {
							[promiseData.id]: {
								...tmpMemo["" + promiseData.id],
								name: data.data.name,
							},
						};

						setMemoFunction(tmpMemo);
						resolve();
					},
					() => {
						resolve();
					}
				);
			} else {
				resolve();
			}
		}
	);
}

export function listCachePromisePool(lists, setState) {
	const filteredListId = lists.reduce((prev, current) => {
		const foundList = prev.find((item) => item.id === current.id);
		if (!foundList) {
			return prev.concat([current]);
		} else {
			return prev;
		}
	}, []);

	promisePoolCaching(
		filteredListId,
		getListsCache(),
		setListsCache,
		setState,
		(promiseData, tmpMemo, setMemoFunction, resolve) => {
			if (
				promiseData.id &&
				tmpMemo["" + promiseData.id]?.name == null
			) {
				getListService(
					{
						id: promiseData.id,
					},
					(data) => {
						tmpMemo = {
							[promiseData.id]: {
								...tmpMemo["" + promiseData.id],
								name: data.data.name,
							},
						};

						setMemoFunction(tmpMemo);
						resolve();
					},
					() => {
						resolve();
					}
				);
			} else {
				resolve();
			}
		}
	);
}

export function segmentCachePromisePool(segments, setState) {
	const filteredSegmentId = segments.reduce((prev, current) => {
		const foundSegment = prev.find((item) => item.id === current.id);
		if (!foundSegment) {
			return prev.concat([current]);
		} else {
			return prev;
		}
	}, []);

	promisePoolCaching(
		filteredSegmentId,
		getSegmentsCache(),
		setSegmentsCache,
		setState,
		(promiseData, tmpMemo, setMemoFunction, resolve) => {
			if (
				promiseData.id &&
				tmpMemo["" + promiseData.id]?.name == null
			) {
				getSegmentService(
					{
						segmentId: promiseData.id,
						listId: promiseData?.audience.list_id,
					},
					(data) => {
						tmpMemo = {
							[promiseData.id]: {
								...tmpMemo["" + promiseData.id],
								name: data.data.name,
							},
						};

						setMemoFunction(tmpMemo);
						resolve();
					},
					() => {
						resolve();
					}
				);
			} else {
				resolve();
			}
		}
	);
}

export function campaignReportCachePromisePool(campaigns, setState) {
	promisePoolCaching(
		campaigns,
		getCampaignCache(),
		setCampaignCache,
		setState,
		(promiseData, tmpMemo, setMemoFunction, resolve) => {
			if (
				promiseData.status !== "archived" &&
				tmpMemo["" + promiseData.id]?.report == null
			) {
				getCampaignStatsService(
					{
						id: promiseData.id,
					},
					(data) => {
						let hourDiff = moment().diff(
							moment(moment.unix(promiseData.scheduled_for)),
							"hours"
						);
						let expires = null;

						if (hourDiff < 48) {
							expires = moment().add(setCacheExpirery(1, 4), "minutes");
						} else {
							let daysDiff = moment().diff(
								moment(moment.unix(promiseData.scheduled_for)),
								"days"
							);
							expires = moment().add(
								Math.random() * 10 + daysDiff,
								"minutes"
							);
						}

						tmpMemo = {
							[promiseData.id]: {
								name: promiseData.name,
								report: {
									open_rate: data.data.open_rate,
									click_rate: data.data.click_rate,
									unsubscribe_rate: data.data.unsubscribe_rate,
									bounce_rate: data.data.bounce_rate,
									sent_emails: data.data.sent_emails,
								},
								expires: expires,
							},
						};
						//Permits progressive update
						setState(tmpMemo);
						//SetCache
						setMemoFunction(tmpMemo);
						resolve();
					},
					() => {
						resolve();
					}
				);
			} else {
				resolve();
			}
		}
	);
}

export function contactListCountCachePromisePool(lists, setState) {
	promisePoolCaching(
		lists,
		getListsCache(),
		setListsCache,
		setState,
		(promiseData, tmpMemo, setMemoFunction, resolve) => {
			if (promiseData?.id &&
				tmpMemo["" + promiseData.id]?.contactsCount == null) {
				getContactsOfListService(
					{
						listId: promiseData.id,
						perPage: 1,
						withCount: true,
					},
					(data) => {
						tmpMemo = {
							[promiseData.id]: {
								...tmpMemo["" + promiseData.id],
								contactsCount: data.pagination.count,
								name : promiseData?.name
							}
						};
						setState(tmpMemo);
						setMemoFunction(tmpMemo);
						resolve();
					},
					() => {
						resolve();
					}
				);
			} else {
				resolve();
			}
		}
	);
}

export function fetchCampaignExtraInfo(campaigns, setState, cacheCampaignStats = true) {
	if(cacheCampaignStats)
		campaignCachePromisePool(campaigns, setState);

	const transformedCampaignObjForList = campaigns.map((campaign) => {
		return {
			...campaign,
			id: campaign.audience?.list_id,
		};
	});
	listCachePromisePool(transformedCampaignObjForList, setState);

	const transformedCampaignObjForSegment = campaigns.map((campaign) => {
		return {
			...campaign,
			id: campaign.audience?.segment_id,
		};
	});

	segmentCachePromisePool(transformedCampaignObjForSegment, setState);
}
