/**
 * This file is to map if else condition , from and to BE structure, it should contain at least two functions, one that maps
 * from BE to FE structure, and the other one that maps from FE to BE structure
 */
import { ConditionEvents } from "assets/js/scenes/automations/containers/editor/EventsConfigs";
import {
	getEdge,
	getEndCoachmarkNode,
	getStatsStepName,
	getNextTarget,
	getNextRepresentableElement,
	getAllPreviousSendActions,
} from "./utils";
import {
	getCondition,
	getSwitch,
	getSingleSwitch,
	getConditionsValues,
	mapCampaignActivitiesToBECorresponders,
	constructOutputCallerVar,
	constructSwitchConditionJson,
	getEvaluationSubAutomationName,
} from "./ifElseUtils";

// need a function to extract the conditions from the switch block

const parseBEIfElseToFE = (automation, mainStep, subStep, targetId) => {
	const events = [];
	const operator = Object.keys(mainStep.args.switch[0].condition)[0];
	const conditionsDef = () => {
		return mainStep.args.switch[0].condition[operator].map((condition) => {
			return getCondition(automation, condition);
		});
	};
	events.push({
		id: mainStep.id,
		position: {
			x: 0,
			y: 0,
		},
		data: {
			name: ConditionEvents()[1].name,
			desc: ConditionEvents()[1].desc,
			type: "condition",
			tileTitle: "app-automation-editor_events_condition-2_tileTitle",
			id: mainStep.id,
			baseId: "ifElse",
			directValue: "all",
			initialElement: false,
			conditions: {
				operator: operator,
				conditions: conditionsDef(),
			},
		},
		type: "editorNode",
	});
	const links = [];
	if (mainStep.args.switch[0].next == "cleanup_and_exit") {
		const endCoachmark = getEndCoachmarkNode(0, 0);
		events.push(endCoachmark);
		links.push(
			getEdge("branchingEdge", mainStep.id, endCoachmark.id, {
				branchLabel: "Yes",
			})
		);
	} else
		links.push(
			getEdge(
				"branchingEdge",
				mainStep.id,
				getNextRepresentableElement(mainStep.args.switch[0], automation),
				{
					branchLabel: "Yes",
				}
			)
		);
	if (targetId) {
		links.push(
			getEdge("branchingEdge", mainStep.id, targetId, { branchLabel: "No" })
		);
	} else {
		const endCoachmark = getEndCoachmarkNode(0, 0);
		events.push(endCoachmark);
		links.push(
			getEdge("branchingEdge", mainStep.id, endCoachmark.id, {
				branchLabel: "No",
			})
		);
	}

	return {
		events: events,
		links: links,
	};
};
const parseFEIfElseToBE = (FEEvent, connectedEdges, events) => {
	const mainSteps = [];
	const subAutomationsNames = [];
	const switchConditions = [];
	const prepareSingleMainStep = (condition, index) => {
		let conditionValues = [];
		conditionValues = getConditionsValues(condition, FEEvent.id);
		conditionValues.forEach((conditionValue) => {
			// condition value here is the emails step id
			const stepName = getStatsStepName(FEEvent.id, index);
			if (mainSteps.filter((step) => step.name == stepName).length == 0)
				mainSteps.push({
					name: stepName,
					action: "SubAutomation.Call",
					pausable: true,
					args: {
						sub_automation: "sub_get_stats",
						params: {
							trigger_id: "${" + conditionValue + "_output}",
						},
					},
				});
		});
	};
	/**
	 *
	 * @param {*} type  : one of [clickthru, opened,sent]
	 * * @param {*} triggerIds  : ids of email address that will be evaluated, should be an array
	 */
	const prepareLoopMainStep = (type, triggerIds, index, subAutomationName) => {
		return {
			name: `${FEEvent.id}${index}`,
			action: "SubAutomation.Call",
			pausable: true,
			args: {
				params: {
					trigger_ids: triggerIds.map((id) => "${" + id + "_output}"),
					type: type,
				},
				sub_automation: subAutomationName,
			},
		};
	};
	FEEvent.data.conditions.conditions.forEach((condition, index) => {
		switch (condition.op) {
		case "specific": {
			// get the switch object and push it to the corresponding array
			const currentSwitch = getSingleSwitch(
				condition,
				FEEvent.id,
				`${FEEvent.id}${index}`
			);
			switchConditions.push(currentSwitch);
			// the below function will create the main step and push it to the corresponding array
			prepareSingleMainStep(condition, index);
			subAutomationsNames.push("sub_get_stats");
			break;
		}
		case "and": {
			const subautomationName = getEvaluationSubAutomationName(
				condition.attribute
			);
				// get the switch object and push it to the corresponding array
			const conditionVar = constructOutputCallerVar(`${FEEvent.id}${index}`);
			const currentSwitch = constructSwitchConditionJson(
				"eq",
				conditionVar,
				true
			);
			switchConditions.push(currentSwitch);
			// the below function will create the main step and push it to the corresponding array
			let allPreviousSendActionIds = getAllPreviousSendActions(
				FEEvent.id
			).map((e) => e.id);
			if (condition.value == "5")
				allPreviousSendActionIds = allPreviousSendActionIds.slice(0, 5);

			mainSteps.push(
				prepareLoopMainStep(
					mapCampaignActivitiesToBECorresponders(condition.attribute),
					allPreviousSendActionIds,
					index,
					subautomationName
				)
			);
			subAutomationsNames.push(subautomationName);
			break;
		}
		case "or": {
			const subautomationName = getEvaluationSubAutomationName(
				condition.attribute,
				true
			);
				// get the switch object and push it to the corresponding array
			const conditionVar = constructOutputCallerVar(`${FEEvent.id}${index}`);
			const currentSwitch = constructSwitchConditionJson(
				"eq",
				conditionVar,
				false
			);
			switchConditions.push(currentSwitch);
			// the below function will create the main step and push it to the corresponding array
			let allPreviousSendActionIds = getAllPreviousSendActions(
				FEEvent.id
			).map((e) => e.id);
			if (condition.value == "5")
				allPreviousSendActionIds = allPreviousSendActionIds.slice(0, 5);

			mainSteps.push(
				prepareLoopMainStep(
					mapCampaignActivitiesToBECorresponders(condition.attribute),
					allPreviousSendActionIds,
					index,
					subautomationName
				)
			);
			subAutomationsNames.push(subautomationName);

			break;
		}

		default:
			break;
		}
	});

	// adjust the next for each step
	mainSteps.map((step, index) => {
		if (index == mainSteps.length - 1) step.next = FEEvent.id;
		else step.next = mainSteps[index + 1].name;
		return step;
	});
	return {
		main: [
			...mainSteps,
			{
				action: "Condition.Switch",
				pausable: true,
				name: FEEvent.id,
				args: {
					switch: [
						{
							condition: {
								[FEEvent.data.conditions.operator]: switchConditions,
							},
							next: getNextTarget(
								connectedEdges.filter((edge) => edge.data.branchLabel == "Yes"),
								events
							),
						},
					],
				},
				next: getNextTarget(
					connectedEdges.filter((edge) => edge.data.branchLabel == "No", events)
				),
			},
		],
		reusableSubAutomations: subAutomationsNames,
	};
};

export { parseBEIfElseToFE, parseFEIfElseToBE };
