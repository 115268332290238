import { getEdge } from "assets/js/scenes/automations/containers/editor/utils/utils";

const addManualAddContactsTrigger = (nextSubAutomation) => {
	const id = `m_${+new Date()}`;
	const triggerEvent = {
		id: id,
		type: "editorNode",
		data: {
			audience: "123123",
			baseId: "manual",
			id: id,
			initialElement: true,
			type: "trigger",
		},
		position: {
			x: 252,
			y: 0,
		},
	};
	const firstTargetId = nextSubAutomation.id;
	const links = getEdge("branchingEdge", triggerEvent.id, firstTargetId);

	return {
		events: [triggerEvent],
		links: [links],
	};
};
export { addManualAddContactsTrigger };
