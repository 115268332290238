import {
	PREVIOUS_CONTACT as SET_PREVIOUS_CONTACT,
	NEXT_CONTACT as SET_NEXT_CONTACT,
} from "./actions";
import React from "react";

let initialState = () => {
	return {
		previousContact: null,
		nextContact: null,
	};
};

export const reducer = (state = initialState(), action) => {
	switch (action.type) {
	case SET_PREVIOUS_CONTACT:
		return {
			...state,
			previousContact: action.previousContact,
		};
		break;
	case SET_NEXT_CONTACT:
		return {
			...state,
			nextContact: action.nextContact,
		};
		break;
	default:
		return state;
	}
};
