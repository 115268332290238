import {
	LISTED_CONTACTS_COUNT as SET_LISTED_CONTACTS_COUNT,
	LISTED_CONTACTS_PAGINATION as SET_LISTED_CONTACTS_PAGINATION,
	LISTED_CONTACTS_PER_PAGE as SET_LISTED_CONTACTS_PER_PAGE,
	LISTED_CONTACTS_QUERY as SET_LISTED_CONTACTS_QUERY,
	LISTED_CONTACTS_SMART_QUERY as SET_LISTED_CONTACTS_SMART_QUERY,
	LISTED_CONTACTS_SEARCH_QUERY as SET_LISTED_CONTACTS_SEARCH_QUERY,
	LISTED_CONTACTS_SORTING as SET_LISTED_CONTACTS_SORTING,
	LOADING_CONTACTS as SET_LOADING_CONTACTS,
	SELECTED_SEGMENT as SET_SELECTED_SEGMENT,
	SELECTED_SEGMENTS as SET_SELECTED_SEGMENTS,
	SELECTED_SUBSCRIPTION as SET_SELECTED_SUBSCRIPTION,
	RESET_LISTING,
	TOTAL_CONTACTS_COUNT_BY_SEGMENT_OR_SUBSCRIPTION as SET_TOTAL_CONTACTS_COUNT_BY_SEGMENT_OR_SUBSCRIPTION,
	SMART_FILTER_CHANGE as SET_SMART_FILTER_CHANGE,
} from "./actions";
import React from "react";

export let initialState = () => {
	return {
		loadingContacts: false,
		query: null,
		sorting: ["email"],
		perPage: 50,
		page: 1,
		currentContactsCount: 0,
		segment: null,
		smartQuery: null,
		searchQuery: "status==active",
		selectedSegments: null,
		subscription: null,
		totalContactsCountBySegmentOrSubscription: 0,
		smartFilterChange: false,
	};
};

export const reducer = (state = initialState(), action) => {
	let newQuery;
	switch (action.type) {
	case SET_LOADING_CONTACTS:
		return { ...state, loadingContacts: action.loadingContacts };
		break;
	case SET_LISTED_CONTACTS_COUNT:
		return { ...state, currentContactsCount: action.currentContactsCount };
		break;
	case SET_LISTED_CONTACTS_QUERY:
		return { ...state, query: action.query };
		break;
	case SET_LISTED_CONTACTS_SMART_QUERY:
		newQuery = state.searchQuery;
		return { ...state, smartQuery: action.smartQuery, query: newQuery };

		break;
	case SET_LISTED_CONTACTS_SEARCH_QUERY:
		newQuery = action.searchQuery;
		return { ...state, searchQuery: action.searchQuery, query: newQuery };

		break;
	case SET_LISTED_CONTACTS_SORTING:
		return { ...state, sorting: action.sorting };
		break;
	case SET_LISTED_CONTACTS_PER_PAGE:
		return { ...state, perPage: action.perPage, page: initialState().page };
		break;
	case SET_LISTED_CONTACTS_PAGINATION:
		return { ...state, page: action.page };
		break;
	case RESET_LISTING:
		return { ...initialState() };
		break;
	case SET_SELECTED_SEGMENT:
		return { ...state, segment: action.segment, query: null };
		break;
	case SET_SELECTED_SUBSCRIPTION:
		return { ...state, subscription: action.subscription, query: null };
		break;
	case SET_SELECTED_SEGMENTS:
		return { ...state, selectedSegments: action.segments, query: null };
		break;
	case SET_TOTAL_CONTACTS_COUNT_BY_SEGMENT_OR_SUBSCRIPTION:
		return {
			...state,
			totalContactsCountBySegmentOrSubscription:
					action.totalContactsCountBySegmentOrSubscription,
		};
		break;
	case SET_SMART_FILTER_CHANGE:
		return { ...state, smartFilterChange: action.smartFilterChange };
		break;
	default:
		return state;
		break;
	}
};
