export const SINGLE_CONTACT = "SET_SINGLE_CONTACT";
export const SINGLE_CONTACT_VIEW = "SET_SINGLE_CONTACT_VIEW";
export const SINGLE_CONTACT_EVENTS = "SET_SINGLE_CONTACT_EVENTS";

export const setSingleContact = (contact) => {
	return {
		type: SINGLE_CONTACT,
		contact,
	};
};

export const setSingleContactView = (view) => {
	return {
		type: SINGLE_CONTACT_VIEW,
		view,
	};
};

export const setSingleContactEvents = (events) => {
	return {
		type: SINGLE_CONTACT_EVENTS,
		events,
	};
};
