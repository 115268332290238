import { omit } from "lodash";

import {
	SET_SINGLE_LIST,
	SET_SINGLE_LIST_ATTRIBUTES,
	RESET_SINGLE_LIST,
} from "./actions";
import { reducer as ContactsReducer } from "./scenes/contacts/reducer";

let initialState = () => {
	return {
		list: null,
		listAttributes: null,
	};
};

export const reducer = (state = initialState(), action) => {
	switch (action.type) {
	case SET_SINGLE_LIST:
		return { ...state, list: action.list };
		break;
	case SET_SINGLE_LIST_ATTRIBUTES:
		return { ...state, listAttributes: action.listAttributes };
		break;
	case RESET_SINGLE_LIST:
		return initialState;
		break;
	default:
		const rest = omit(state, Object.keys(initialState()));
		return {
			...state,
			contacts: ContactsReducer(rest.contacts, action),
		};
	}
};
