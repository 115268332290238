import {
	LIST_TEMPLATES_SUCCESS,
	DELETE_TEMPLATE_SUCCESS,
	UPDATE_TEMPLATE_SUCCESS,
	GET_TEMPLATE_SUCCESS,
	SET_BLUEPRINTS,
} from "assets/js/data/templates/types";
let updatedTemplates = null;
const INITIAL_STATE = {
	templates: null,
	activeTemplate: null,
};

export const reducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
	case LIST_TEMPLATES_SUCCESS:
		return {
			...state,
			templates: action.templates,
		};
	case DELETE_TEMPLATE_SUCCESS: {
		updatedTemplates = state.templates.data.filter(
			(template) => template.id !== action.templateId
		);
		const newTemplates = {
			...state.templates,
			data: updatedTemplates,
			pagination: {
				...state.templates.pagination,
				count: state.templates.pagination.count - 1,
			},
		};
		return {
			...state,
			templates: newTemplates,
		};
	}

	case UPDATE_TEMPLATE_SUCCESS:
		// get the updated values for the templates, renaming the specific template
		updatedTemplates = {
			...state.templates,
			data: state.templates.data.map((temp) => {
				if (temp.id === action.template.id) return action.template;
				else return temp;
			}),
		};
		return {
			...state,
			templates: updatedTemplates,
		};
	case GET_TEMPLATE_SUCCESS: {
		return {
			...state,
			activeTemplate: action.template,
		};
	}
	case SET_BLUEPRINTS: {
		return {
			...state,
			blueprints: action.blueprints,
		};
	}
	default:
		return state;
	}
};
