import { render } from "react-dom";
import React from "react";
import { ApiClient } from "cakemail-js-gateway-api";
import UIMain from "assets/js/UIMain";
import UiWrapper from "./assets/js/UiWrapper";
import * as Sentry from "@sentry/react";
import { TrackJS } from "trackjs";
import "cakemail-ui-components/lib/dist/sass/cakebootstrap_main.scss";
import "./assets/js/reactBoot.scss";
import BrandWrapper from "assets/js/BrandWrapper";
import partnerStackAndRedirect from 'utils/partnerStack'

if(!window.location.href.includes("public")){
	const fonts =  require("./assets/js/fonts.scss");
}

const amplitude = require("amplitude-js");
amplitude.getInstance().init("7087b825b8aca41083da869a2cce4edf");
try {
	window.version = VERSION;
} catch (error) {
	console.warn(
		"an error occurred on fetching the version, error details : ",
		error
	);
}

if (top !== self) top.location.replace(self.location.href);

try {
	Typekit.load({ async: true });
} catch (e) {}

if (process.env.ENV !== "dev") {
	TrackJS.install({
		token: process.env.TRACK_JS_TOKEN,
		application: process.env.TRACK_JS_APPLICATION,
		version: VERSION
	});
	Sentry.init({ dsn: process.env.SENTRY_DSN, environment: process.env.ENV });
}

const cmCakemailClient = new ApiClient();

cmCakemailClient.basePath = process.env.GATEWAY_URL;

if (partnerStackAndRedirect()) {
	render(
		"",
		document.getElementById("root")
	);
} else {
	render(
		<BrandWrapper>
			<UiWrapper>
				<UIMain cakemailApiClient={cmCakemailClient} />
			</UiWrapper>
		</BrandWrapper>,
		document.getElementById("root")
	);
}


