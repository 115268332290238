/**
 * This file is responsible for handling the service calls for the campaign
 */
import store from "../store";
import {
	callAPI,
	chameau,
	DEFAULT_LIST_PARAMS,
	GATEWAY_PROXY,
	METHODS,
} from "utils/apiUtils";
import { getTemplateService } from "./TemplateService";
import { EDITORS } from "assets/js/const/Campaign";
import {
	SEGMENT_EVENTS,
	trackSegmentEvent,
} from "assets/js/const/SegmentEvents";
import {
	deleteUserMetaDataService,
	getUserMetaDataService,
} from "assets/js/services/hiddenService";
import { updateAppCueData } from "assets/js/scenes/OnBoardingFlow/utils";
import { GOAL_NAME } from "assets/js/const/Goals";
import { goThroughCampaignAndDeleteBlueprintMeta, updateCampaignBlueprintMeta } from 'utils/metaDataUtils'

export function listCampaignsService(
	options = DEFAULT_LIST_PARAMS,
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + "/campaigns",
			method: METHODS.get,
			query: {
				...chameau(options),
				account_id: store.getState().global.account.id,
			},
		},(data)=>{
			if (options.filter.includes("status==delivered")) {
				goThroughCampaignAndDeleteBlueprintMeta(data.data);
			}

			successCallback(data)	;
		},
		failureCallback
	);
}

export function createCampaignService(
	options = { campaign: {} },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + "/campaigns",
			method: METHODS.post,
			body: options.campaign,
			query: { account_id: store.getState().global.account.id },
		},
		(data) => {
			const user = store.getState().global.user;
			updateAppCueData(user.id, { newCampaignIsCreated: true });
			trackSegmentEvent(SEGMENT_EVENTS.CAMPAIGN_CREATED, data.data);
			successCallback(data);
		},
		failureCallback
	);
}

export function patchCampaignService(
	options = { campaign: {}, campaignId: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + `/campaigns/${options.campaignId}`,
			method: METHODS.patch,
			body: options.campaign,
			query: { account_id: store.getState().global.account.id },
		},
		successCallback,
		failureCallback
	);
}

export function getCampaignService(
	options = { id: null },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + `/campaigns/${options.id}`,
			method: METHODS.get,
			query: { account_id: store.getState().global.account.id },
		},
		successCallback,
		failureCallback
	);
}

export function sendTestEmailService(
	options = { campaignId: "", email: "", accountId: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	const { campaignId, email, ...restOptions } = options;
	callAPI(
		{
			url: GATEWAY_PROXY + `/campaigns/${campaignId}/send-test`,
			method: METHODS.post,
			body: { email: email },
			query: { ...chameau(restOptions) },
		},
		successCallback,
		failureCallback
	);
}

export function renderCampaignService(
	options = { campaignId: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + `/campaigns/${options.campaignId}/render`,
			method: METHODS.get,
			query: { account_id: store.getState().global.account.id },
		},
		successCallback,
		failureCallback
	);
}

export function unscheduleCampaignService(
	options = { campaignId: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + `/campaigns/${options.campaignId}/unschedule`,
			method: METHODS.post,
			query: { account_id: store.getState().global.account.id },
		},
		(data) => {
			trackSegmentEvent(SEGMENT_EVENTS.CAMPAIGN_UNSCHEDULED, data);
			successCallback(data);
		},
		failureCallback
	);
}

export function scheduleCampaignService(
	options = {
		campaignId: "",
		scheduleCampaign: { date: null, html_empty: false },
	},
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + `/campaigns/${options.campaignId}/schedule`,
			body: options.scheduleCampaign,
			method: METHODS.post,
			query: { account_id: store.getState().global.account.id },
		},
		(data) => {
			const user = store.getState().global.user;
			getUserMetaDataService(
				{
					id: user.id,
					name: GOAL_NAME.CAMPAIGN,
				},
				(data) => {
					if (data.data.id === options.campaignId) {
						deleteUserMetaDataService({
							id: user.id,
							name: GOAL_NAME.CAMPAIGN,
						});
					}
				}
			);
			updateAppCueData(user.id, { campaignIsScheduled: true });
			successCallback(data);
		},
		failureCallback
	);
}

export function suspendCampaignService(
	options = { campaignId: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + `/campaigns/${options.campaignId}/suspend`,
			method: METHODS.post,
			query: { account_id: store.getState().global.account.id },
		},
		(data) => {
			trackSegmentEvent(SEGMENT_EVENTS.CAMPAIGN_SUSPENDED, data);
			successCallback(data);
		},
		failureCallback
	);
}

export function resumeCampaignService(
	options = { campaignId: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + `/campaigns/${options.campaignId}/resume`,
			method: METHODS.post,
			query: { account_id: store.getState().global.account.id },
		},
		(data) => {
			trackSegmentEvent(SEGMENT_EVENTS.CAMPAIGN_RESUMED, data);
			successCallback(data);
		},
		failureCallback
	);
}

export function cancelCampaignService(
	options = { campaignId: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + `/campaigns/${options.campaignId}/cancel`,
			method: METHODS.post,
			query: { account_id: store.getState().global.account.id },
		},
		(data) => {
			trackSegmentEvent(SEGMENT_EVENTS.CAMPAIGN_CANCELED, data);
			successCallback(data);
		},
		failureCallback
	);
}

export function deleteCampaignService(
	options = { campaignId: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + `/campaigns/${options.campaignId}`,
			method: METHODS.delete,
			query: {
				...chameau(options.restOptions),
				account_id: store.getState().global.account.id,
			},
		},
		(data) => {
			updateCampaignBlueprintMeta(options.campaignId);
			trackSegmentEvent(SEGMENT_EVENTS.CAMPAIGN_DELETED, data);
			const user = store.getState().global.user;
			getUserMetaDataService(
				{
					id: user.id,
					name: GOAL_NAME.CAMPAIGN,
				},
				(data) => {
					if (data.data.id === options.campaignId) {
						deleteUserMetaDataService({
							id: user.id,
							name: GOAL_NAME.CAMPAIGN,
						});
					}
				}
			);
			successCallback(data);
		},
		failureCallback
	);
}

export function listCampaignLinksService(
	options = { campaignId: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	const { campaignId, ...restOptions } = options;
	callAPI(
		{
			url: GATEWAY_PROXY + `/campaigns/${campaignId}/links`,
			method: METHODS.get,
			query: {
				...chameau(restOptions),
				account_id: store.getState().global.account.id,
			},
		},
		successCallback,
		failureCallback
	);
}

export function getLinkService(
	options = { id: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	callAPI(
		{
			url: GATEWAY_PROXY + `/links/${options.id}`,
			method: METHODS.get,
			query: { account_id: store.getState().global.account.id },
		},
		successCallback,
		failureCallback
	);
}

export function createCampaignFromTemplateByTemplateIdService(
	options = { campaignName: "", templateId: "" },
	successCallback = () => {},
	failureCallback = () => {}
) {
	getTemplateService(
		{
			id: options.templateId,
		},
		(data) => {
			const fetchedTemplate = data.data;
			const contentType =
				fetchedTemplate.content.type === EDITORS.BEE ? "json" : EDITORS.HTML;
			let request = {
				name: options.campaignName,
				content: {
					[contentType]: fetchedTemplate.content[contentType],
					type: contentType === "json" ? EDITORS.BEE : EDITORS.HTML,
				},
			};
			createCampaignService(
				{ campaign: request },
				successCallback,
				failureCallback
			);
		},
		failureCallback
	);
}
