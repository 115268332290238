/**
 * This file is to map if else condition , from and to BE structure, it should contain at least two functions, one that maps
 * from BE to FE structure, and the other one that maps from FE to BE structure
 */
import { ConditionEvents } from "assets/js/scenes/automations/containers/editor/EventsConfigs";
import {
	getEdge,
	getEndCoachmarkNode,
	getStatsStepName,
	getNextTarget,
	getShadowsForStep,
} from "assets/js/scenes/automations/containers/editor/utils/utils";
import {
	getCondition,
	getSingleSwitch,
	getConditionsValues,
	mapCampaignActivitiesToBECorresponders,
	constructOutputCallerVar,
	constructSwitchConditionJson,
	getEvaluationSubAutomationName,
	getNextOfBranch,
} from "./utils/generalUtils";
import { getContactDetailsEvaluationSubAutomationName } from "./utils/contactDetailsUtils";
import deepcopy from "deepcopy";

// need a function to extract the conditions from the switch block

const parseBEIfElseToFE = (automation, mainStep, subStep, targetId) => {
	const events = [];
	const operator = Object.keys(subStep.args.switch[0].condition)[0];
	const conditionsDef = () => {
		return subStep.args.switch[0].condition[operator].map(
			(condition, index) => {
				return getCondition(subStep, automation, condition, index);
			}
		);
	};
	const defs = conditionsDef();
	events.push({
		id: mainStep.name,
		position: {
			x: 0,
			y: 0,
		},
		data: {
			name: ConditionEvents()[1].name,
			desc: ConditionEvents()[1].desc,
			type: "condition",
			tileTitle: "app-automation-editor_events_condition-2_tileTitle",
			id: mainStep.name,
			baseId: "ifElse",
			directValue: "all",
			initialElement: false,
			conditions: {
				operator: operator,
				conditions: defs,
			},
			shadows: getShadowsForStep(automation, mainStep.name),
		},
		type: "editorNode",
	});
	const links = [];

	if (
		subStep.args.switch[0].next == "end" ||
		getNextOfBranch(subStep, automation) == "end"
	) {
		const endCoachmark = getEndCoachmarkNode(0, 0);
		events.push(endCoachmark);
		links.push(
			getEdge("branchingEdge", mainStep.name, endCoachmark.id, {
				branchLabel: "Yes",
			})
		);
	} else
		links.push(
			getEdge(
				"branchingEdge",
				mainStep.name,
				getNextOfBranch(subStep, automation),
				{
					branchLabel: "Yes",
				}
			)
		);
	if (targetId && targetId != "end") {
		links.push(
			getEdge("branchingEdge", mainStep.name, targetId, { branchLabel: "No" })
		);
	} else {
		const endCoachmark = getEndCoachmarkNode(0, 0);
		events.push(endCoachmark);
		links.push(
			getEdge("branchingEdge", mainStep.name, endCoachmark.id, {
				branchLabel: "No",
			})
		);
	}

	return {
		events: events,
		links: links,
	};
};
const parseFEIfElseToBE = (FEEvent, connectedEdges, events) => {
	const mainSteps = [];
	const subAutomationsNames = [];
	const switchConditions = [];
	const prepareSingleMainStep = (condition, index) => {
		let conditionValues = [];
		conditionValues = getConditionsValues(condition, FEEvent.id);
		conditionValues.forEach((conditionValue) => {
			// condition value here is the emails step id
			const stepName = getStatsStepName(FEEvent.id, index);
			if (mainSteps.filter((step) => step.name == stepName).length == 0)
				mainSteps.push({
					name: stepName,
					action: "SubAutomation.Call",
					args: {
						sub_automation: "sub_get_stats",
						params: {
							trigger_id: "${input.data.step_trigger." + conditionValue + "}",
						},
					},
				});
		});
	};
	/**
	 *
	 * @param {*} type  : one of [clickthru, opened,sent]
	 * * @param {*} triggerIds  : ids of email address that will be evaluated, should be an array
	 */
	const prepareLoopMainStep = (type, triggerIds, index, subAutomationName) => {
		return {
			name: `${FEEvent.id}${index}`,
			action: "SubAutomation.Call",
			args: {
				params: {
					trigger_ids: triggerIds,
					type: type,
				},
				sub_automation: subAutomationName,
			},
		};
	};
	const handleCampaignActivityCondition = (actualCondition, index) => {
		const condition = deepcopy(actualCondition);
		if (condition.campaignsOption == "specific") {
			// get the switch object and push it to the corresponding array
			const currentSwitch = getSingleSwitch(
				condition,
				FEEvent.id,
				`${FEEvent.id}${index}`
			);
			switchConditions.push(currentSwitch);
			// the below function will create the main step and push it to the corresponding array
			prepareSingleMainStep(condition, index);
			subAutomationsNames.push("sub_get_stats");
		} else {
			switch (condition.op) {
			case "and": {
				const subautomationName = getEvaluationSubAutomationName(
					condition.value
				);
					// get the switch object and push it to the corresponding array
				const conditionVar = constructOutputCallerVar(
					`${FEEvent.id}${index}`
				);
				const currentSwitch = constructSwitchConditionJson(
					"eq",
					conditionVar,
					true
				);
				switchConditions.push(currentSwitch);
				// need to add the "get_last_5" sub_automation at the top of the list to make sure it's accessible to all subautomations
				// when needed
				if (
					condition.campaignsOption == "last5" &&
						mainSteps.filter((name) => name == "get_last_5").length == 0
				) {
					mainSteps.unshift({
						action: "SubAutomation.Call",
						args: {
							sub_automation: "get_last_5_of_list",
						},
						name: "get_last_5",
					});
					subAutomationsNames.push("get_last_5_of_list");
				}
				// we need to pass down the name of the sub_automation step that calculates the 5 last emails
				mainSteps.push(
					prepareLoopMainStep(
						mapCampaignActivitiesToBECorresponders(condition.value),
						//input.data.outputs is the parameter holding all the outputs
						condition.value == "5"
							? "${get_last_5_output}"
							: "${input.data.outputs}",
						index,
						subautomationName
					)
				);
				subAutomationsNames.push(subautomationName);
				break;
			}
			case "or": {
				const subautomationName = getEvaluationSubAutomationName(
					condition.value,
					true
				);
					// get the switch object and push it to the corresponding array
				const conditionVar = constructOutputCallerVar(
					`${FEEvent.id}${index}`
				);
				const currentSwitch = constructSwitchConditionJson(
					"eq",
					conditionVar,
					false
				);
				switchConditions.push(currentSwitch);
				// need to add the "get_last_5" sub_automation at the top of the list to make sure it's accessible to all subautomations
				// when needed
				if (
					condition.campaignsOption == "last5" &&
						mainSteps.filter((name) => name == "get_last_5").length == 0
				) {
					mainSteps.unshift({
						action: "SubAutomation.Call",
						args: {
							sub_automation: "get_last_5_of_list",
						},
						name: "get_last_5",
					});
					subAutomationsNames.push("get_last_5_of_list");
				}
				// the below function will create the main step and push it to the corresponding array
				mainSteps.push(
					prepareLoopMainStep(
						mapCampaignActivitiesToBECorresponders(condition.value),
						condition.campaignsOption == "last5"
							? "${get_last_5_output}"
							: "${input.data.outputs}",
						index,
						subautomationName
					)
				);
				subAutomationsNames.push(subautomationName);

				break;
			}

			default:
				break;
			}
		}
	};
	const handleContactDetailsCondition = (condition, index) => {
		if (condition.selectedAttributes.length > 0) {
			switch (condition.op) {
			case "or":
				{
					// get the switch object and push it to the corresponding array
					const currentSwitch = constructSwitchConditionJson(
						"eq",
						`$\{${FEEvent.id}${index}_output}`,
						false
					);
					switchConditions.push(currentSwitch);
					mainSteps.push({
						action: "SubAutomation.Call",
						name: `${FEEvent.id}${index}loop`,
						args: {
							sub_automation: "get_value_list_key",
							params: {
								listItems: "${input.data.contact.custom_attributes}",
								key: condition.selectedAttributes[0].name,
							},
						},
					});
					const evaluationSubAutomationName =
							getContactDetailsEvaluationSubAutomationName(
								condition.selectedAttributesValuesOp,
								condition.selectedAttributes[0],
								true
							);
					mainSteps.push({
						action: "SubAutomation.Call",
						name: `${FEEvent.id}${index}`,
						args: {
							sub_automation: evaluationSubAutomationName,
							params: {
								attrVal:
										condition.selectedAttributes[0].type == "datetime"
											? `\${time.parse(text.replace_all(${FEEvent.id}${index}loop_output," ","T")+"Z")}`
											: condition.selectedAttributes[0].type == "integer"
												? `\${int(${FEEvent.id}${index}loop_output)}`
												: `\${${FEEvent.id}${index}loop_output}`,
								values:
										condition.selectedAttributes[0].type == "datetime"
											? condition.selectedAttributesValues.map(
												(val) => `\${time.parse("${val}")}`
											  )
											: condition.selectedAttributes[0].type == "integer"
												? condition.selectedAttributesValues.map(
													(val) => `\${int("${val}")}`
											  )
												: condition.selectedAttributesValues,
							},
						},
					});
					subAutomationsNames.push(evaluationSubAutomationName);
					//subAutomationsNames.push("evaluate_all_condition_on_text");
					subAutomationsNames.push("get_map_list_value");
				}
				break;
			case "and": {
				// get the switch object and push it to the corresponding array
				const currentSwitch = constructSwitchConditionJson(
					"eq",
					`$\{${FEEvent.id}${index}_output}`,
					true
				);
				switchConditions.push(currentSwitch);
				mainSteps.push({
					action: "SubAutomation.Call",
					name: `${FEEvent.id}loop`,
					args: {
						sub_automation: "get_value_list_key",
						params: {
							listItems: "${input.data.contact.custom_attributes}",
							key: condition.selectedAttributes[0].name,
						},
					},
				});
				const evaluationSubAutomationName =
						getContactDetailsEvaluationSubAutomationName(
							condition.selectedAttributesValuesOp,
							condition.selectedAttributes[0],
							false
						);

				mainSteps.push({
					action: "SubAutomation.Call",
					name: `${FEEvent.id}${index}`,
					args: {
						sub_automation: evaluationSubAutomationName,

						//"all_contact_value_text",
						params: {
							attrVal: `\${${FEEvent.id}loop_output}`,
							values: condition.selectedAttributesValues,
						},
					},
				});
				subAutomationsNames.push(evaluationSubAutomationName);
				//subAutomationsNames.push("evaluate_all_condition_on_text");
				subAutomationsNames.push("get_map_list_value");
			}
			}
		}
	};
	FEEvent.data.conditions.conditions.forEach((condition, index) => {
		switch (condition.type) {
		case "ContactDetails":
			handleContactDetailsCondition(condition, index);
			break;
		case "CampaignActivity":
			handleCampaignActivityCondition(condition, index);
			break;
		default:
			break;
		}
	});
	let shadows = [];
	let inTos =[];
	if (FEEvent.data.shadows) shadows = [...FEEvent.data.shadows];
	inTos = events.filter(e=>e.data.baseId=="loop").filter(e=>e.data.goTo==FEEvent.id);
	if(inTos.length>0) inTos=inTos.map(e=>`${e.id}_loop`);

	return {
		main: [
			{
				steps: [
					{
						name: `${FEEvent.id}`,
						action: "SubAutomation.Call",
						args: {
							sub_automation: FEEvent.id,
						},
						next: "end",
						shadows: shadows,
						inTos:inTos
					},
				],
			},
		],
		sub: [
			{
				name: FEEvent.id,
				params: [],
				steps: [
					...mainSteps,
					{
						action: "Condition.Switch",
						name: FEEvent.id,
						args: {
							switch: [
								{
									condition: {
										[FEEvent.data.conditions.operator]: switchConditions,
									},
									next: getNextTarget(
										connectedEdges.filter(
											(edge) => edge.data.branchLabel == "Yes"
										),
										events
									),
								},
							],
						},
						next: getNextTarget(
							connectedEdges.filter(
								(edge) => edge.data.branchLabel == "No",
								events
							)
						),
					},
				],
			},
		],
		reusableSubAutomations: subAutomationsNames,
	};
};

export { parseBEIfElseToFE, parseFEIfElseToBE };
