import { SET_ATTRIBUTES_SELECTIONS, ADD_ATTRIBUTE_SELECTION } from "./types";
let initialState = () => {
	return {
		selections: [],
	};
};
export const reducer = (state = initialState(), action) => {
	switch (action.type) {
	case SET_ATTRIBUTES_SELECTIONS:
		return { ...state, selections: action.data };
		break;
	case ADD_ATTRIBUTE_SELECTION:
		return { ...state, selections: [action.data] };
		break;
	default:
		return state;
		break;
	}
};
