export const IMPORT_ID = "SET_IMPORT_ID";
export const IMPORTING = "SET_IS_IMPORTING";
export const RESET = "RESET_IMPORT";
export const RESET_CONFIG = "RESET_IMPORT_CONFIG";

export const setImportId = (id) => {
	return {
		type: IMPORT_ID,
		id,
	};
};

export const setIsImporting = (isImporting) => {
	return {
		type: IMPORTING,
		isImporting,
	};
};

export const resetImport = () => {
	return {
		type: RESET,
	};
};

export const resetImportConfig = () => {
	return {
		type: RESET_CONFIG,
	};
};
