import { omit } from "lodash";
import { reducer as CampaignReducer } from "./scenes/campaignStats/reducer";

import {
	OUTBOX_FILTER_VALUE as SET_OUTBOX_FILTER_VALUE,
	OUTBOX_SORT_VALUE as SET_OUTBOX_SORT_VALUE,
	SENT_SORT_VALUE as SET_SENT_SORT_VALUE,
	DRAFT_SORT_VALUE as SET_DRAFT_SORT_VALUE,
	DRAFT_FILTER_VALUE,
	SENT_FILTER_VALUE,
	RESET_CAMPAIGN,
} from "./actions";

let initialState = () => {
	return {
		outboxFilterValue: null,
		outboxSortValue: "-scheduled_for",
		sentSortValue: "-scheduled_for",
		sentFilterValue: null,
		draftSortValue: "-created_on",
		draftFilterValue: null,
	};
};

export const reducer = (state = initialState(), action) => {
	switch (action.type) {
	case DRAFT_FILTER_VALUE:
		return { ...state, draftFilterValue: action.draftFilterValue };
		break;
	case SET_OUTBOX_FILTER_VALUE:
		return { ...state, outboxFilterValue: action.outboxFilterValue };
		break;
	case SENT_FILTER_VALUE:
		return { ...state, sentFilterValue: action.sentFilterValue };
		break;
	case SET_OUTBOX_SORT_VALUE:
		return { ...state, outboxSortValue: action.outboxSortValue };
		break;
	case SET_SENT_SORT_VALUE:
		return { ...state, sentSortValue: action.sentSortValue };
		break;
	case SET_DRAFT_SORT_VALUE:
		return { ...state, draftSortValue: action.draftSortValue };
		break;
	case RESET_CAMPAIGN:
		return initialState();
		break;
	default:
		const rest = omit(state, Object.keys(initialState()));
		return {
			...state,
			campaignStats: CampaignReducer(rest.campaignStats, action),
		};
	}
};
