import { GET_BRAND_SUCCESS, GET_BRAND_FAILURE } from "./types";
import { getBrandService } from "assets/js/services/brandingService";

function getBrand(successCallback = () => {}, failureCallback = () => {}) {
	return function (dispatch) {
		getBrandService(
			(data) => {
				let cleanedData = successCallback(data);
				dispatch(getBrandSuccess(cleanedData));
			},
			(msg) => {
				dispatch(getBrandFailure());
				failureCallback(msg);
			}
		);
	};
}

export function getBrandSuccess(brand) {
	return { type: GET_BRAND_SUCCESS, brand };
}

function getBrandFailure() {
	return {
		type: GET_BRAND_FAILURE,
	};
}

export { getBrand };
