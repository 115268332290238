import React, { lazy, Suspense } from "react";
import { Switch } from "react-router";
import { Redirect, Route, withRouter } from "react-router-dom";
import fetchRoute from "utils/routes";
import { Translate } from "react-redux-i18n";
import {
	Button,
	DialogHandler,
	Logo,
	Icons,
} from "cakemail-ui-components/lib/react";
import { CAMPAIGN_SOURCE } from "assets/js/const/Campaign";
import Proptypes from "prop-types";
import { connect } from "react-redux";
import { loadTranslations, syncTranslationWithStore } from "react-redux-i18n";
import { getLocaleWithFallback, setLocaleWithFallback } from "utils/languages";
import PublicTranslations from "../../translations/public";
import Cookies from "universal-cookie";
import store from "../../store";

const EmailTemplateSelection = lazy(() =>
	import("assets/js/components/Email/TemplateSelection/EmailTemplateSelection")
);
const TemplatePreviewContainer = lazy(() =>
	import("assets/js/scenes/OnBoardingFlow/containers/TemplatePreviewContainer")
);

const OnboardingFlow = (props) => {
	React.useEffect(() => {
		const url = new URLSearchParams(window.location.search);
		const urlLocale = url.get("locale");
		let cookies = new Cookies();
		const cookieLocale = cookies.get("locale");
		let finalLocale = "en_US";
		if (urlLocale) {
			finalLocale = urlLocale;
		} else if (cookieLocale) {
			finalLocale = cookieLocale;
		}
		finalLocale = getLocaleWithFallback(finalLocale);

		let translations = {};
		translations[finalLocale] =
			PublicTranslations[finalLocale ? finalLocale.replace("_", "-") : "en-US"];
		syncTranslationWithStore(store);
		store.dispatch(loadTranslations(translations));
		store.dispatch(setLocaleWithFallback(finalLocale));
	}, []);
	return (
		<div className="publicContent">
			<Switch>
				{props.authenticated === true &&
					props.location.pathname.includes(fetchRoute("templatesListing")) &&
					!props.location.pathname.includes("create") && (
					<Redirect to={`#${fetchRoute("campaignCreate")}`} />
				)}
				{!props.authenticated &&
					props.location.pathname.includes("/public/campaign") && (
					<Redirect
						to={`#${fetchRoute("signIn")}?continue=${
							props.location.pathname
						}`}
					/>
				)}

				<Route
					exact
					path={fetchRoute("templatePreview")}
					render={() => {
						return (
							<Suspense fallback={<div></div>}>
								{" "}
								<TemplatePreviewContainer isPublicPath isNew={false} />
							</Suspense>
						);
					}}
				/>
				<Route
					path={fetchRoute("templatesListing")}
					render={() => (
						<Suspense fallback={<div></div>}>
							<EmailTemplateSelection
								isPublicPath
								location={props.location}
								handlePreview={(template) =>
									props.history.push({
										pathname: fetchRoute("templatePreview", {
											id: template.id,
										}),
									})
								}
								history={props.history}
								topBarLeft={[
									<Logo
										url={props.logo}
										width={98}
										height={19}
										alt={"Cakemail logo"}
									/>,
									<div className="separator"></div>,
									<h1>
										<Translate value="app-campaigns-flow-step-template-heading-title" />
									</h1>,
								]}
								topBarRight={
									<>
										<a href={`#${fetchRoute("signUp")}`}>
											<Translate value="app-atuh-create_btn" />
										</a>{" "}
										<Translate value="app-general_or" />
										<a href={`#${fetchRoute("signIn")}`}>
											<Translate value="app-auth-sign_btn" />
										</a>{" "}
										<Translate value="app-public_saveWork" />
										<Button
											colorTheme="teal"
											shapeStyle="stroke"
											onClick={() => {
												window.location = "https://cakemail.com";
											}}
										>
											<Translate value="app-general_returnHome" />
										</Button>
										<span className="mobileView">
											<Icons name="glyph-search" isAction />
										</span>
									</>
								}
								validTabs={[
									CAMPAIGN_SOURCE.BLUE_PRINTS,
									CAMPAIGN_SOURCE.SCRATCH,
								]}
								allowHTMLTemplates={false}
								handleTemplateSelection={(e, meuSelection, id) => {
									const isChangingStartingPoint = props.location?.search
										? new URLSearchParams(props.location.search).get(
											"changingStartingPoint"
										  )
										: null;
									if (isChangingStartingPoint) {
										DialogHandler.show({
											type: "warning",
											title: (
												<Translate value="app-campaigns-flow-step-template-dialog_warning_title" />
											),
											content: (
												<p>
													<Translate value="app-campaigns-flow-step-template-dialog_warning" />
												</p>
											),
											primaryBtnText: (
												<Translate value="app-campaigns-flow-step-template-dialog_primaryBtn" />
											),
											primaryBtnOnClick: () => {
												DialogHandler.hide();
												props.history.push(
													fetchRoute("emailCreation", { id: id.split(",")[1] })
												);
											},
											secondaryBtnText: (
												<Translate value="app-campaigns-flow-step-template-dialog_secBtn" />
											),
										});
									} else {
										props.history.push(
											fetchRoute("emailCreation", { id: id.split(",")[1] })
										);
									}
								}}
								showLanguageDropdown={true}
							/>
						</Suspense>
					)}
				/>
			</Switch>
		</div>
	);
};

OnboardingFlow.propTypes = {
	logo: Proptypes.string,
	history: Proptypes.object,
	location: Proptypes.object,
	fetchAccount: Proptypes.func,
	authenticated: Proptypes.bool,
};

OnboardingFlow.defaultProps = {};

export default withRouter(
	connect((state) => {
		return {
			logo: state.brand?.images["logo"],
		};
	}, null)(OnboardingFlow)
);
