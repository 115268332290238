// editor types
const EDITORS = {
	BEE: "bee",
	HTML: "html",
};

const CAMPAIGN_STEPS = {
	LOADING: "Loading",
	TEMPLATE_SELECTION: "TemplateSelection",
	HTML_EDITING: "HtmlEditing",
	EMAIL_DESIGNER_EDITING: "EmailDesignerEditing",
	CAMPAIGN_OVERVIEW: "CampaignOverview",
	COUCH: "Couch",
	CANCEL: "Cancel",
	VALIDATE: "Validate",
};
const CAMPAIGN_STATUS = {
	SCHEDULED: "scheduled",
	DELIVERED: "delivered",
	INCOMPLETE: "incomplete",
};
const CAMPAIGN_SOURCE = {
	SENTS: "sents",
	TEMPLATES: "templates",
	SCRATCH: "scratch",
	BLUE_PRINTS: "bluePrints",
	PRO_BLUE_PRINTS: "proBluePrints",
	FREE_BLUE_PRINTS: "FreeBluePrints",
};

export { EDITORS, CAMPAIGN_STEPS, CAMPAIGN_STATUS, CAMPAIGN_SOURCE };
