import { DialogHandler, Dropdown } from "cakemail-ui-components/lib/react";
import { Translate, I18n } from "react-redux-i18n";
import { sendZendeskRequestService } from "assets/js/services/hiddenService";
import React from "react";
import { isPartner, openBillingPortal } from "utils/apiUtils";
import store from "assets/js/store";

/**
 * @param type enum["upgrade", "downgrade", "support"]
 * @param message string
 **/
export function showZendeskSupportDialog(type, message = "") {
	if (isPartner(true)) {
		return;
	}

	let title = <Translate value="app-plan-upgrade-dialog-title" />;
	let text = <Translate value="app-plan-upgrade-dialog-text" />;

	if (type === "downgrade") {
		title = <Translate value="app-plan-dialog-stripe-downgrade-title" />;
		text = <Translate value="app-plan-dialog-stripe-downgrade-text" />;
	}
	DialogHandler.hide();
	DialogHandler.show({
		title: title,
		content: <p>{text}</p>,
		primaryBtnText: <Translate value="app-plan-upgrade-zendesk-send" />,
		primaryBtnOnClick: () => {
			sendZendeskRequestService(
				{
					content: {
						type: type,
						message: message,
					},
				},
				() => {
					DialogHandler.show({
						type: "success",
						title: <Translate value="app-general-request-submited" />,
					});
				},
				(msg) => {
					let message = "app-plan-upgrade-dialog-error";
					if (typeof msg === "string") {
						message = msg;
					}

					DialogHandler.show({
						type: "error",
						title: <Translate value={message} />,
					});
				}
			);
			DialogHandler.hide();
		},
		secondaryBtnText: <Translate value="app-general-cancel" />,
	});
}

export function showStripeCurrencySelection(priceId) {
	DialogHandler.hide();
	let selectedCurrency = "USD";

	let currencies = store.getState().brand?.config?.supported_currencies;

	if (!currencies) {
		currencies = ["USD"];
	}
	selectedCurrency = currencies[0];

	DialogHandler.show({
		className: "stripeCurrencyDialog",
		title: <Translate value="app-plan-dialog-stripe-title" />,
		content: (
			<div className="stripeCurrencyDialog">
				<p>
					<Translate value="app-plan-dialog-stripe-text" dangerousHTML />
				</p>

				<Dropdown
					type="custom"
					label={I18n.t("app-plan-currency-select-label")}
					placeholder={I18n.t("app-plan-currency-select-label")}
					options={currencies.map((currency) => {
						return {
							label: currency,
							value: currency,
							selected: currency === selectedCurrency,
						};
					})}
					onChange={(e, input, option) => {
						selectedCurrency = option.value;
					}}
				/>
			</div>
		),
		primaryBtnText: <Translate value="app-plan-currency-select-btn" />,
		primaryBtnOnClick: () => {
			openBillingPortal(selectedCurrency, null, priceId, true);
			DialogHandler.hide();
		},
		secondaryBtnText: <Translate value="app-general-cancel" />,
	});
}
