export const LOCAL_BRAND = "SET_LOCAL_BRAND";
export const UNPUBLISHED_BRAND = "SET_UNPUBLISHED_BRAND";
export const UPDATE_LOCAL_BRAND = "UPDATE_LOCAL_BRAND";

export const setLocalBrand = (path, data) => {
	return {
		type: LOCAL_BRAND,
		path,
		data,
	};
};

export const setUnpublishedData = (data) => {
	return {
		type: UNPUBLISHED_BRAND,
		data,
	};
};

export const updateLocalBrand = (
	path,
	localData,
	unpublishedData = null,
	toDelete = false
) => {
	return {
		type: UPDATE_LOCAL_BRAND,
		path,
		localData,
		unpublishedData,
		toDelete,
	};
};
