import Cookies from "universal-cookie";
import REDIRECTS_ALLOWED from "assets/js/const/Redirects";

export default function partnerStackAndRedirect() {
	const windowHistoryParam = new URLSearchParams(window?.location?.search);

	/*
	 * Partnerstack prepackaging
	 * Partnerstack is filling the query params after react routes, but the normal script doesnt recognizes it
	 */
	const gsxid = windowHistoryParam.get("gsxid");
	const gspk = windowHistoryParam.get("gspk");

	if (gsxid && gspk) {
		const localCookie = new Cookies();
		try {
			localCookie.set("gsxid", gsxid, { path: "/" });
			localCookie.set("growSumoPartnerKey", window.atob(gspk), { path: "/" });
		} catch (e) {}
	}

	const redirect = windowHistoryParam.get("redirect");
	if (redirect) {
		//for (const link of REDIRECTS_ALLOWED) {
		//if (redirect.includes(link)) {
		window.location = redirect;
		return true;
		//}
		//}
	}

	return false;
}
