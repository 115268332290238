export const DRAFT_FILTER_VALUE = "SET_DRAFT_FILTER_VALUE";
export const OUTBOX_FILTER_VALUE = "SET_OUTBOX_FILTER_VALUE";
export const SENT_FILTER_VALUE = "SET_SENT_FILTER_VALUE";
export const OUTBOX_SORT_VALUE = "SET_OUTBOX_SORT_VALUE";
export const SENT_SORT_VALUE = "SET_SENT_SORT_VALUE";
export const DRAFT_SORT_VALUE = "SET_DRAFT_SORT_VALUE";
export const RESET_CAMPAIGN = "RESET_CAMPAIGN";

export const setDraftFilterValue = (draftFilterValue) => {
	return {
		type: DRAFT_FILTER_VALUE,
		draftFilterValue,
	};
};

export const setOutboxFilterValue = (outboxFilterValue) => {
	return {
		type: OUTBOX_FILTER_VALUE,
		outboxFilterValue,
	};
};

export const setSentFilterValue = (sentFilterValue) => {
	return {
		type: SENT_FILTER_VALUE,
		sentFilterValue,
	};
};

export const setOutboxSortValue = (outboxSortValue) => {
	return {
		type: OUTBOX_SORT_VALUE,
		outboxSortValue,
	};
};

export const setSentSortValue = (sentSortValue) => {
	return {
		type: SENT_SORT_VALUE,
		sentSortValue,
	};
};

export const setDraftSortValue = (draftSortValue) => {
	return {
		type: DRAFT_SORT_VALUE,
		draftSortValue,
	};
};

export const resetCampaignStore = () => {
	return {
		type: RESET_CAMPAIGN,
	};
};
