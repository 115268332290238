import { omit } from "lodash";

import { SET_CONTACTS, RESET_CONTACTS } from "./actions";

let initialState = () => {
	return {
		contacts: null,
	};
};

export const reducer = (state = initialState(), action) => {
	switch (action.type) {
	case SET_CONTACTS:
		return { ...state, contacts: action.list };
		break;
	case RESET_CONTACTS:
		return initialState;
		break;
	default:
		const rest = omit(state, Object.keys(initialState()));
		return {
			...state,
		};
	}
};
