import { reducer as ImportReducer } from "./scenes/import/reducer";
import { reducer as FilterTableReducer } from "./scenes/listing/reducer";
import { reducer as SingleContactReducer } from "./scenes/singleContact/reducer";
import { reducer as ListingReducer } from "./data/listing/reducer";
import { reducer as RelationshipReducer } from "./data/relationships/reducer";
import { reducer as SubscriptionsReducer } from "../data/subscriptions/reducer";
import { reducer as SystemSegmentReducer } from "./data/systemSegments/reducer";
import { reducer as ContactsListingReducer } from "../data/contacts/listing/reducer";
import { reducer as SegmentEditorReducer } from "./scenes/segments/reducer";
import _ from "lodash";
import {
	GRAND_TOTAL_CONTATCS_COUNT as SET_GRAND_TOTAL_CONTACTS_COUNT,
	SINGLE_CONTACT_LISTING_INDEX as SET_SINGLE_CONTACT_LISTING_INDEX,
	SINGLE_CONTACT_LISTING_INDEX_CONTACT_ID as SET_SINGLE_CONTACT_LISTING_INDEX_CONTACT_ID,
	SELECTED_LIST as SET_SELECTED_LIST,
	SINGLE_LIST,
} from "./actions";
import { LIST_CONTACTS_SUCCESS, LIST_CONTACTS_FAILURE } from "./types";

let initialState = () => {
	return {
		singleContactListingIndex: null,
		grandTotalContactsCount: 0,
		selectedList: null,
		contacts: null,
		singleList: null,
	};
};

const findContactIndexById = (contactId, listedContacts) => {
	if (listedContacts === null) {
		return null;
	}
	let found = listedContacts.data.filter((contact) => {
		return contactId === contact.id;
	});

	if (found.length > 0) {
		return listedContacts.data.indexOf(found.shift());
	} else {
		return null;
	}
};

export const reducer = (state = initialState(), action) => {
	switch (action.type) {
	case SET_SINGLE_CONTACT_LISTING_INDEX_CONTACT_ID:
		return {
			...state,
			singleContactListingIndex: findContactIndexById(
				action.contactId,
				state.listedContacts
			),
		};
		break;
	case SET_SINGLE_CONTACT_LISTING_INDEX:
		return { ...state, singleContactListingIndex: action.index };
		break;
	case SET_GRAND_TOTAL_CONTACTS_COUNT:
		return {
			...state,
			grandTotalContactsCount: action.grandTotalContactsCount,
		};
		break;
	case SET_SELECTED_LIST:
		return { ...state, selectedList: action.list };
		break;
	case LIST_CONTACTS_SUCCESS: {
		const updatedContacts = state.contacts ? [...state.contacts] : [];
		updatedContacts.push(action.data);
		return { ...state, contacts: updatedContacts };
	}
	case LIST_CONTACTS_FAILURE:
		return { ...state, contacts: null };
	case SINGLE_LIST:
		return { ...state, singleList: action.singleList };
	default:
		const rest = _.omit(state, Object.keys(initialState()));
		return {
			...state,
			importation: ImportReducer(rest.importation, action),
			list: ListingReducer(rest.list, action),
			filterTable: FilterTableReducer(rest.filterTable, action),
			relationships: RelationshipReducer(rest.relationships, action),
			single: SingleContactReducer(rest.single, action),
			subscriptions: SubscriptionsReducer(rest.subscriptions, action),
			systemSegments: SystemSegmentReducer(rest.systemSegments, action),
			listedContacts: ContactsListingReducer(rest.listedContacts, action),
			segmentEditor: SegmentEditorReducer(rest.segmentEditor, action),
		};
		break;
	}
};
