const AUTOMATION_SOURCE = {
	TEMPLATES: "automation_templates",
	SCRATCH: "automation_scratch",
	SAVED_AUTOMATIONS: "automation_savedAutomations",
};
const AUTOMATION_TYPE = "automation";
const AUTOMATION_CREATION_STEPS = {
	NAME: "name",
	LIST_SELECTION: "listSelection",
	MARKETING_GOAL: "marketingGoal",
};
const EVENT_TYPES = {
	TRIGGER_SUBSCRIBED: "TRIGGER_SUBSCRIBED",
	CONDITION_DELAY: "CONDITION_DELAY",
	ACTION_SENDEMAIL: "ACTION_SENDEMAIL",
	CONDITION_IFELSE: "CONDITION_IFELSE",
	CONDITION_SPLIT50: "CONDITION_SPLIT50",
	ACTION_UPDATECONTACT: "ACTION_UPDATECONTACT",
	ACTION_ADDCONTACT: "ACTION_ADDCONTACT",
	ACTION_DELETECONTACT: "ACTION_DELETECONTACT",
	ACTION_LOOP:"ACTION_LOOP"
};
const EVENTS = {
	ACTION: "action",
	TRIGGER: "trigger",
	CONDITION: "condition",
};

const EDITOR_MODE = {
	WORKFLOW: "WORKFLOW",
	VALIDATION: "VALIDATION",
	MANAGE_CONTACTS: "MANAGE_CONTACTS",
	NUMBERING: "NUMBERING"
};
export {
	AUTOMATION_SOURCE,
	AUTOMATION_TYPE,
	AUTOMATION_CREATION_STEPS,
	EVENT_TYPES,
	EVENTS,
	EDITOR_MODE,
};
