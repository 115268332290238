import {
	SENDERS as SET_SENDERS,
	DOMAINS as SET_DOMAINS,
	LISTED_SENDERS_PER_PAGE as SET_LISTED_SENDERS_PER_PAGE,
	LISTED_SENDERS_PAGINATION as SET_LISTED_SENDERS_PAGINATION,
	SET_UNBLOCKED_DOMAINS,
	SET_DKIM_KEYS,
} from "./types";

let initialState = () => {
	return {
		senders: null,
		perPage: 25,
		page: 1,
		domains: {},
		unBlockedDomains: [],
		dkimKeys: null,
	};
};

export const reducer = (state = initialState(), action) => {
	switch (action.type) {
	case SET_SENDERS:
		return { ...state, senders: action.senders };
	case SET_DOMAINS:
		return { ...state, domains: { ...action.domains } };
	case SET_LISTED_SENDERS_PER_PAGE:
		return { ...state, perPage: action.perPage, page: initialState().page };
	case SET_LISTED_SENDERS_PAGINATION:
		return { ...state, page: action.page };
	case SET_UNBLOCKED_DOMAINS:
		return { ...state, unBlockedDomains: action.unBlockedDomains };
	case SET_DKIM_KEYS:
		return { ...state, dkimKeys: action.dkimKeys };
	default:
		return state;
	}
};
