import { SYSTEM_SEGMENTS as SET_SYSTEM_SEGMENTS } from "./actions";
import React from "react";

let initialState = null;

export const reducer = (state = initialState, action) => {
	switch (action.type) {
	case SET_SYSTEM_SEGMENTS:
		return action.systemSegments;
		break;
	default:
		return state;
		break;
	}
};
