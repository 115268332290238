const LIST = "LIST";
const LIST_TEMPLATES_SUCCESS = "LIST_TEMPLATES_SUCCESS";
const LIST_TEMPLATES_FAILURE = "LIST_TEMPLATES_FAILURE";
const DELETE_TEMPLATE_SUCCESS = "DELETE_TEMPLATE_SUCCESS";
const UPDATE_TEMPLATE_SUCCESS = "UPDATE_TEMPLATE_SUCCESS";
const GET_TEMPLATE_FAILURE = "GET_TEMPLATE_FAILURE";
const GET_TEMPLATE_SUCCESS = "GET_TEMPLATE_SUCCESS";
const SET_BLUEPRINTS = "SET_BLUEPRINTS";

export {
	LIST,
	LIST_TEMPLATES_SUCCESS,
	LIST_TEMPLATES_FAILURE,
	UPDATE_TEMPLATE_SUCCESS,
	DELETE_TEMPLATE_SUCCESS,
	GET_TEMPLATE_SUCCESS,
	GET_TEMPLATE_FAILURE,
	SET_BLUEPRINTS,
};
