import React, { Component } from "react";
import { I18n } from "react-redux-i18n";

export const PERMISSION_DATE = "SET_PERMISSION_DATE";
export const PREVIEW_DATA = "SET_PREVIEW_DATA";
export const NEW_CONTACT_ATTRIBUTE = "ADD_NEW_CONTACT_ATTRIBUTES";
export const SELECTED_EMAIL_TYPES = "SET_SELECTED_EMAIL_TYPES";
export const TICKET_ID = "SET_TICKET_ID";
export const CONTACT_ATTRIBUTES = "SET_CONTACT_ATTRIBUTES";

export const columnNameDefaultValue = "app-imports-attributes-FP-name_column";
export const columnContentDefaultText =
	"app-imports-attributes-FP-paste_your_info";

const textIsDefaultGridText = (text) => {
	return text === I18n.t(columnContentDefaultText);
};

export const cleanEmptyData = (data) => {
	let cleanedData = [];

	//Go Through rows
	for (let i = 0; i < data.length; i++) {
		//Go trough cols untils finds a non-empty cell. Add that row, to cleaned array.
		for (let k = 0; k < data[i].length; k++) {
			if (data[i][k].length > 0 && !textIsDefaultGridText(data[i][k])) {
				cleanedData.push(data[i].slice());
				break;
			}
		}
	}

	if (cleanedData.length > 0) {
		//Go Through columns
		let columnIndexToCheck = cleanedData[0].length - 1;
		while (columnIndexToCheck !== 0) {
			let columnIsEmpty = true;
			for (let i = 0; i < cleanedData.length; i++) {
				//Go trough rows untils. If no non-empty cells are found, go through every row and delete that for index for each column.
				if (
					cleanedData[i][columnIndexToCheck].length > 0 &&
					!textIsDefaultGridText(cleanedData[i][columnIndexToCheck])
				) {
					columnIndexToCheck--;
					columnIsEmpty = false;
					break;
				}
			}

			if (!columnIsEmpty) {
				continue;
			}

			//Did not break, delete same col for each row.
			for (let k = 0; k < cleanedData.length; k++) {
				cleanedData[k].splice(columnIndexToCheck, 1);
			}

			columnIndexToCheck--;
		}
	}

	//Clean first 2 rows for placeholder data
	for (let i = 0; i < cleanedData.length; i++) {
		for (let k = 0; k < cleanedData[i].length; k++) {
			if (
				cleanedData[i][k].length > 0 &&
				textIsDefaultGridText(cleanedData[i][k])
			) {
				cleanedData[i][k] = "";
			}
		}
	}
	return cleanedData;
};

export const setPermissionDate = (date) => {
	return {
		type: PERMISSION_DATE,
		date,
	};
};

export const setPreviewData = (previewData, toClean = true) => {
	if (toClean) {
		previewData = cleanEmptyData(previewData);
	}

	return {
		type: PREVIEW_DATA,
		previewData,
	};
};

export const setEmailTypes = (selectedEmailTypes) => {
	return {
		type: SELECTED_EMAIL_TYPES,
		selectedEmailTypes,
	};
};

export const setTicketId = (id) => {
	return {
		type: TICKET_ID,
		id,
	};
};

export const setNewContactAttribute = (newContactAttributes) => {
	return {
		type: NEW_CONTACT_ATTRIBUTE,
		newContactAttributes,
	};
};
