/**
 * This file is to map send email action , from and to BE structure, it should contain at least two functions, one that maps
 * from BE to FE structure, and the other one that maps from FE to BE structure
 */
import { ActionEvents } from "assets/js/scenes/automations/containers/editor/EventsConfigs";
import { getEdge, getEndCoachmarkNode, getNextTarget } from "./utils";

const parseBESendEmailToFE = (mainStep, subStep, targetId) => {
	const events = [];
	events.push({
		id: mainStep.id,
		type: "editorNode",
		position: {
			x: 0,
			y: 0,
		},
		data: {
			name: ActionEvents()[0].name,
			desc: ActionEvents()[0].desc,
			type: "action",
			tileTitle: "app-automation-editor_events_action-1_tileTitle",
			id: mainStep.id,
			actionName: mainStep.description,
			baseId: "sendEmail",
			email: {
				content: {
					id: subStep.args.content.id,
					subject: subStep.args.subject,
					html: null,
					text: null,
					json: {},
					type: "bee",
				},
				sender: {
					id: subStep.args.sender?.id,
				},
				thumbnailUrl: subStep.args.thumbnail,
				initialElement: false,
			},
		},
	});
	const links = [];
	if (targetId) {
		links.push(getEdge("custom", mainStep.id, targetId));
	} else {
		const endCoachmark = getEndCoachmarkNode(0, 0);
		events.push(endCoachmark);
		links.push(getEdge("custom", mainStep.id, endCoachmark.id));
	}
	return {
		events: events,
		links: links,
	};
};
const parseFESendEmailToBE = (FEEvent, connectedEdges, events) => {
	return {
		main: [
			{
				name: FEEvent.id,
				description: FEEvent.data.actionName,
				action: "CampaignEmail.Send",
				pausable: true,
				args: {
					content: {
						id: FEEvent.data.email.content.id,
					},
					sender: {
						id: FEEvent.data.email.sender.id,
					},
					subject: FEEvent.data.email.content.subject,
				},
				next: getNextTarget(connectedEdges, events),
			},
		],
	};
};
export { parseBESendEmailToFE, parseFESendEmailToBE };
