import ExtendableError from "./ExtendableError";

class CriticalError extends ExtendableError {
	constructor(args) {
		super(args);

		this._title = args.title;
		this._text = args.text;
		this._buttonText = args.buttonText;
		this._primaryAction = args.primaryAction;
		this._linkText = args.linkText;
		this._secondaryAction = args.secondaryAction;
		this._errorCode = args.errorCode;
		this._contact = args.contact;
		this._pageName = args.pageName;

		Object.defineProperty(this, "title", {
			configurable: true,
			enumerable: true,
			get: () => {
				return this._title;
			},
			set: (title) => {
				this._title = title;
			},
		});

		Object.defineProperty(this, "text", {
			configurable: true,
			enumerable: true,
			get: () => {
				return this._text;
			},
			set: (text) => {
				this._text = text;
			},
		});

		Object.defineProperty(this, "buttonText", {
			configurable: true,
			enumerable: true,
			get: () => {
				return this._buttonText;
			},
			set: (buttonText) => {
				this._buttonText = buttonText;
			},
		});

		Object.defineProperty(this, "primaryAction", {
			configurable: true,
			enumerable: true,
			get: () => {
				return this._primaryAction;
			},
			set: (newAction) => {
				this._primaryAction = newAction;
			},
		});

		Object.defineProperty(this, "linkText", {
			configurable: true,
			enumerable: true,
			get: () => {
				return this._linkText;
			},
			set: (linkText) => {
				this._linkText = linkText;
			},
		});

		Object.defineProperty(this, "secondaryAction", {
			configurable: true,
			enumerable: true,
			get: () => {
				return this._secondaryAction;
			},
			set: (secondaryAction) => {
				this._secondaryAction = secondaryAction;
			},
		});

		Object.defineProperty(this, "errorCode", {
			configurable: true,
			enumerable: true,
			get: () => {
				return this._errorCode;
			},
			set: (errorCode) => {
				this._errorCode = errorCode;
			},
		});

		Object.defineProperty(this, "contact", {
			configurable: true,
			enumerable: true,
			get: () => {
				return this._contact;
			},
			set: (contact) => {
				this._contact = contact;
			},
		});

		Object.defineProperty(this, "pageName", {
			configurable: true,
			enumerable: true,
			get: () => {
				return this._pageName;
			},
			set: (pageName) => {
				this._pageName = pageName;
			},
		});
	}
}

export default CriticalError;
