export const IMPORTATION_CONFIG = "SET_IMPORTATION_CONFIG";
export const IMPORTATION_FILE = "SET_IMPORTATION_FILE";
export const IMPORTATION_DELIMITER = "SET_IMPORTATION_DELIMITER";

export const setImportationConfig = (importationConfig) => {
	return {
		type: IMPORTATION_CONFIG,
		importationConfig,
	};
};

export const setImportationFile = (file) => {
	return {
		type: IMPORTATION_FILE,
		file,
	};
};

export const setImportationDelimiter = (delimiter) => {
	return {
		type: IMPORTATION_DELIMITER,
		delimiter,
	};
};
