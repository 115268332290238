import {
	LIST_CONTACTS_SUCCESS,
	LIST_CONTACTS_FAILURE,
} from "assets/js/scenes/contacts/types";
import { getContactsOfListService } from "assets/js/services/contactService";

export const GRAND_TOTAL_CONTATCS_COUNT = "SET_GRAND_TOTAL_CONTATCS_COUNT";
export const SINGLE_CONTACT_LISTING_INDEX = "SET_SINGLE_CONTACT_LISTING_INDEX";
export const SINGLE_CONTACT_LISTING_INDEX_CONTACT_ID =
	"SET_SINGLE_CONTACT_LISTING_INDEX_BY_CONTACT_ID";
export const SELECTED_LIST = "SET_SELECTED_LIST";
export const SINGLE_LIST = "SET_SINGLE_LIST";

const setGrandTotalContactsCount = (grandTotalContactsCount) => {
	return {
		type: GRAND_TOTAL_CONTATCS_COUNT,
		grandTotalContactsCount,
	};
};

const setSingleContactListingIndex = (index) => {
	return {
		type: SINGLE_CONTACT_LISTING_INDEX,
		index,
	};
};

const setSingleContactListingIndexByContactId = (contactId) => {
	return {
		type: SINGLE_CONTACT_LISTING_INDEX_CONTACT_ID,
		contactId,
	};
};

const setSelectedList = (list) => {
	return {
		type: SELECTED_LIST,
		list,
	};
};

const listContactsOfList = (
	listId,
	params = {},
	successCallback,
	failureCallback
) => {
	return function (dispatch) {
		getContactsOfListService(
			{ listId: listId, withCount: true, ...params },
			(data) => {
				dispatch(listContactsSuccess(data, listId));
				if (successCallback) successCallback(data);
			},
			() => {
				dispatch(listContactsFailure());
				if (failureCallback) failureCallback();
			}
		);
	};
};

const listContactsOfListWithExclusions = (
	listId,
	options,
	excludedContacts,
	successCallback,
	failureCallback
) => {
	return function (dispatch) {
		getContactsOfListService(
			{ listId: listId, withCount: true, ...options },
			(error, data, response) => {
				const updatedContacts = data;
				if (excludedContacts) {
					let data = updatedContacts.data.filter(
						(el) => !excludedContacts.map((c) => c.contactId).includes(el.id)
					);
					updatedContacts.data = data;
				}
				dispatch(listContactsSuccess(updatedContacts, listId));
				if (successCallback) successCallback(error, updatedContacts, response);
			},
			() => {
				dispatch(listContactsFailure());
				if (failureCallback) failureCallback();
			}
		);
	};
};
function listContactsSuccess(data, listId) {
	return {
		type: LIST_CONTACTS_SUCCESS,
		data: {
			contacts: data,
			listId: listId,
		},
	};
}

function listContactsFailure(data) {
	return {
		type: LIST_CONTACTS_FAILURE,
	};
}

function setSingleList(singleList) {
	return {
		type: SINGLE_LIST,
		singleList,
	};
}

export {
	listContactsOfList,
	listContactsSuccess,
	listContactsFailure,
	setSelectedList,
	setSingleContactListingIndexByContactId,
	setGrandTotalContactsCount,
	setSingleContactListingIndex,
	setSingleList,
};
