import { actionTypes } from "./actions";

export let initialState = () => {
	return {
		currentDateRange: "7d",
		currentLocation: "city",
		subscribers: {
			data: null,
			loading: false,
			language: null,
			dateRange: null,
		},
		confirmedSubscribers: {
			data: null,
			loading: false,
			language: null,
			dateRange: null,
		},
		subscriberSources: {
			data: null,
			loading: false,
			language: null,
			dateRange: null,
		},
		subscriberInterests: {
			data: null,
			loading: false,
			language: null,
			dateRange: null,
		},
		subscriberLanguages: {
			data: null,
			loading: false,
			language: null,
			dateRange: null,
		},
		subscriberMap: {
			data: null,
			loading: false,
			language: null,
			dateRange: null,
			location: null,
		},
	};
};

export const reducer = (state = initialState(), action) => {
	switch (action.type) {
	case actionTypes.CHANGE_OVERVIEW_DATA_RANGE:
		return { ...state, currentDateRange: action.currentDateRange };

	case actionTypes.CHANGE_OVERVIEW_LOCATION:
		return { ...state, currentLocation: action.currentLocation };

	case actionTypes.SET_SUBSCRIBERS:
		const subscribers = {
			...state.subscribers,
			data: action.data,
			language: action.language,
			dateRange: action.dateRange,
		};
		return { ...state, subscribers };

	case actionTypes.SET_CONFIRMED_SUBSCRIBERS:
		const confirmedSubscribers = {
			...state.confirmedSubscribers,
			data: action.data,
			language: action.language,
			dateRange: action.dateRange,
		};
		return { ...state, confirmedSubscribers };

	case actionTypes.SET_SUBSCRIBER_SOURCES:
		const subscriberSources = {
			...state.subscriberSources,
			data: action.data,
			language: action.language,
			dateRange: action.dateRange,
		};
		return { ...state, subscriberSources };

	case actionTypes.SET_SUBSCRIBER_INTERESTS:
		const subscriberInterests = {
			...state.subscriberInterests,
			data: action.data,
			language: action.language,
			dateRange: action.dateRange,
		};
		return { ...state, subscriberInterests };

	case actionTypes.SET_SUBSCRIBER_LANGUAGES:
		const subscriberLanguages = {
			...state.subscriberLanguages,
			data: action.data,
			language: action.language,
			dateRange: action.dateRange,
		};
		return { ...state, subscriberLanguages };

	case actionTypes.SET_SUBSCRIBER_MAP:
		const subscriberMap = {
			...state.subscriberMap,
			data: action.data,
			language: action.language,
			dateRange: action.dateRange,
			location: action.location,
		};
		return { ...state, subscriberMap };

	case actionTypes.SET_SUBSCRIBERS_LOADING:
		return {
			...state,
			subscribers: {
				...state.subscribers,
				loading: action.loading,
			},
		};

	case actionTypes.SET_CONFIRMED_SUBSCRIBERS_LOADING:
		return {
			...state,
			confirmedSubscribers: {
				...state.confirmedSubscribers,
				loading: action.loading,
			},
		};

	case actionTypes.SET_SUBSCRIBER_SOURCES_LOADING:
		return {
			...state,
			subscriberSources: {
				...state.subscriberSources,
				loading: action.loading,
			},
		};

	case actionTypes.SET_SUBSCRIBER_INTERESTS_LOADING:
		return {
			...state,
			subscriberInterests: {
				...state.subscriberInterests,
				loading: action.loading,
			},
		};

	case actionTypes.SET_SUBSCRIBER_LANGUAGES_LOADING:
		return {
			...state,
			subscriberLanguages: {
				...state.subscriberLanguages,
				loading: action.loading,
			},
		};

	case actionTypes.SET_SUBSCRIBER_MAP_LOADING:
		return {
			...state,
			subscriberMap: {
				...state.subscriberMap,
				loading: action.loading,
			},
		};

	default:
		return state;
	}
};
