export const SENDERS_SORT_VALUE = "SET_SENDERS_SORT_VALUE";
export const SENDERS_SEARCH_VALUE = "SET_SENDERS_SEARCH_VALUE";
export const SINGLE_SENDER = "SET_SINGLE_SENDER";

export const setSendersSortValue = (sendersSortValue) => {
	return {
		type: SENDERS_SORT_VALUE,
		sendersSortValue,
	};
};

export const setSendersSearchValue = (search) => {
	return {
		type: SENDERS_SEARCH_VALUE,
		search,
	};
};

export const setSingleSender = (singleSender) => {
	return {
		type: SINGLE_SENDER,
		singleSender,
	};
};
