import { omit } from "lodash";
import {
	SET_CAMPAIGNS,
	SET_CAMPAIGNS_LINKS,
	UPDATE_FILTER,
	SET_FILTERS,
} from "./types";

let initialState = () => {
	return {
		campaigns: { data: [] },
	};
};

export const reducer = (state = initialState(), action) => {
	switch (action.type) {
	case SET_CAMPAIGNS: {
		return { ...state, campaigns: action.data };
	}
	case SET_CAMPAIGNS_LINKS: {
		return { ...state, campaignsLinks: action.data };
	}
	case SET_FILTERS: {
		return { ...state, filters: action.data };
	}
	case UPDATE_FILTER: {
		const updatedFilters = state.filters.map((f) => {
			if (f.id == action.filter.id) {
				return action.filter;
			} else return f;
		});
		return { ...state, filters: updatedFilters };
	}
	default:
		const rest = omit(state, Object.keys(initialState()));
		return {
			...state,
		};
	}
};
