import { getBrandSuccess } from "assets/js/data/branding/actions";
import CakeStore from "assets/js/store";
import fetchRoute from "utils/routes";
import {
	TaskBarHandler,
	Button,
	Icons,
	DialogHandler,
} from "cakemail-ui-components/lib/react";
import React from "react";
import { Translate, I18n } from "react-redux-i18n";
import BRAND from "assets/js/scenes/brand/const";
import { setBrandHeadElements } from "assets/js/BrandWrapper";
import { setBrandPreview } from "assets/js/actions";

/*
	findDataInBrand will find and return either the parent object by reference or the final path object
 */
export function findDataInBrand(path, stateSchema, returnSchema = false) {
	let fullPath = path.split(".");

	for (let i = 0; i < fullPath.length - 1; i++) {
		let elem = fullPath[i];
		if (!stateSchema[elem]) stateSchema[elem] = {};
		stateSchema = stateSchema[elem];
	}

	if (returnSchema) {
		return stateSchema;
	}
	return stateSchema[fullPath[fullPath.length - 1]];
}

/*
save actual brand to localstorage for when we close the preview
load back the back if possible, if not, refresh the page on the brand page.

Apply local brand to current brand
go through all css theme and apply them to root
 */
export function applyLocalBrandToPreview(
	brand,
	modifiedBrand,
	unpublishedBrand,
	history,
	info = {},
	previewCallback = () => {}
) {
	/*
	TODO to make it modular, the publish / preview will need a callback from the main container
		And use this function.
	*/

	const accountId = CakeStore.getState().global.account.id;

	window.localStorage.setItem(
		accountId + "_" + BRAND.CURRENT_BRAND,
		JSON.stringify(brand)
	);
	window.localStorage.setItem(
		accountId + "_" + BRAND.MODIFIED_BRAND,
		JSON.stringify(modifiedBrand)
	);
	window.localStorage.setItem(
		accountId + "_" + BRAND.UNPUBLISHED_CHANGES,
		JSON.stringify(unpublishedBrand)
	);

	setBrandHeadElements(modifiedBrand);
	CakeStore.dispatch(getBrandSuccess(modifiedBrand));
	previewCallback(true);
	setStylesFromBrand(modifiedBrand);

	TaskBarHandler.show({
		tasks: [
			{
				className: "brandTaskBar",
				content: (
					<div>
						<div>
							<Icons name="glyph-show" />
							<p>
								<Translate
									value="app-brand-preview-bar-unpublished"
									count={info.count}
								/>
							</p>
						</div>

						<Button
							text={
								<Translate value="app-campaigns-template-preview_closeBtn" />
							}
							colorTheme="grey"
							shapeStyle="fill"
							radius="square"
							size="small"
							onClick={() => {
								history.push(fetchRoute("brand"));
								previewCallback(false);
								TaskBarHandler.hide();
							}}
						/>
					</div>
				),
			},
		],
	});

	history.push(fetchRoute("dashboard"));
}

export function setStylesFromBrand(modifiedBrand) {
	//TODO recursive loop inside theme object instead ?
	for (let key in modifiedBrand.theme.base) {
		if (modifiedBrand.theme.base.hasOwnProperty(key)) {
			document.documentElement.style.setProperty(
				`--${key}`,
				modifiedBrand.theme.base[key]
			);
		}
	}
}

export function showResetDialog(title, desc, callback = () => {}) {
	DialogHandler.show({
		title: title,
		content: <p>{desc}</p>,
		primaryBtnText: <Translate value="app-general-restore-default" />,
		primaryBtnOnClick: callback,
		secondaryBtnText: <Translate value="app-general-cancel" />,
	});
}

export function removeLocalBrandStorage() {
	const accountId = CakeStore.getState().global.account.id;

	window.localStorage.removeItem(accountId + "_" + BRAND.CURRENT_BRAND);
	window.localStorage.removeItem(accountId + "_" + BRAND.MODIFIED_BRAND);
	window.localStorage.removeItem(accountId + "_" + BRAND.UNPUBLISHED_CHANGES);
}

/*
	deleteUnpublishedDataFromPath will remove by reference what is found in the path
 */
export function deleteUnpublishedDataFromPath(path, data) {
	for (const key in data) {
		if (key.includes(path)) {
			delete data[key];
		}
	}
}

/*
	addUnpublishedDataFromReset adds by reference whats is in the path add all the original values
 */
export function addUnpublishedDataFromReset(
	path,
	unpublishedData,
	localBrandSchemaObj,
	currentBrandData,
	originalBrandData,
	filteredKey = null
) {
	// logic contradition solution, where theres hidden element that are published but not counted
	const excludedInPublisheCount = [
		"favicon-apple-touch",
		"favicon-32",
		"favicon-16",
		"favicon",
	];
	for (let key in localBrandSchemaObj) {
		let currentValue = currentBrandData[key];
		let actualOriginalValue = originalBrandData[key];
		let finalKey = key;
		if (typeof currentValue === "object" && originalBrandData?.[key]) {
			if (currentBrandData[key]?.en && originalBrandData[key]?.en) {
				currentValue = currentBrandData[key].en;
				actualOriginalValue = originalBrandData[key].en;
				finalKey += ".en";
			} else {
				let objectKey = Object.keys(originalBrandData[key])[0];
				currentValue = currentBrandData[key][objectKey];
				actualOriginalValue = originalBrandData[key][objectKey];
				finalKey += "." + objectKey;
			}
		}

		if (actualOriginalValue != null) {
			if (filteredKey) {
				if (key.includes(filteredKey)) {
					if (
						currentValue !== actualOriginalValue &&
						!excludedInPublisheCount.includes(key)
					) {
						unpublishedData[path + "." + finalKey] = {
							value: actualOriginalValue,
							valid: "valid",
						};
					}
					localBrandSchemaObj[key] = originalBrandData[key];
				}
			} else {
				if (currentValue !== actualOriginalValue) {
					unpublishedData[path + "." + finalKey] = {
						value: actualOriginalValue,
						valid: "valid",
					};
				}
			}
		}
	}
}

export function resizeImgData(canvas, file, width, height = null) {
	let ctx = canvas.getContext("2d");
	let nHeight = height ? height : width;
	ctx.drawImage(file, 0, 0);
	canvas.width = width;
	canvas.height = nHeight;
	ctx.drawImage(file, 0, 0, width, nHeight);

	return canvas.toDataURL("image/png");
}
