import { omit } from "lodash";
import { layoutedEvents } from "assets/js/scenes/automations/containers/editor/utils/utils";

import {
	SET_AUTOMATION,
	LIST_AUTOMATIONS_SUCCESS,
	LIST_AUTOMATION_BLUEPRINTS_SUCCESS,
	UPDATE_AUTOMATION_EVENT,
	UPDATE_AUTOMATION_EVENTS,
	ADD_AUTOMATION_EVENT,
	RESET_AUTOMATION_EVENTS,
	CREATE_AUTOMATION_SUCCESS,
	GET_AUTOMATION_SUCCESS,
	LIST_VISITORS_SUCCESS,
	RENAME_AUTOMATION_SUCCESS,
	UPDATE_AUTOMATION_GENERAL_ISSUES,
	UPDATE_ACTIVE_AUTOMATION_SUCCESS,
	GET_ACTION_SUCCESS,
	LIST_AUTOMATION_ACTIONS_SUCCESS,
	OPEN_SUB_SCENE,
	DELETE_AUTOMATION_EVENT,
	UPDATE_ACTION_SUCCESS,
	DEACTIVATE_AUTOMATION_SUCCESS,
	ACTIVATE_AUTOMATION_SUCCESS,
	DELETE_ACTION_SUCCESS,
	UPDATE_AUTOMATION_SELECTED_EVENT,
	SET_EDITOR_MODE,
	GET_ACTION_STATS_SUCCESS,
	TOGGLE_HIDE_STATS,
	UPDATE_AUTOMATION_STATS_SUCCESS,
	SET_SHOW_CONTROL_PANEL,
	UNDO_AUTOMATION,
	REDO_AUTOMATION,
	ADD_AUTOMATION_VERSION,
	ADD_SNAPSHOT,
} from "./types";
import { EDITOR_MODE } from "assets/js/const/Automations";
import { deleteAllHelperNodes } from "assets/js/scenes/automations/containers/editor/utils/utils";

let initialState = () => {
	return {
		automations: null,
		automationBluePrints: null,
		automationEvents: null,
		activeAutomation: null,
		actions: null,
		automationGeneralIssues: null,
		editorMode: EDITOR_MODE.WORKFLOW,
		stats: {},
		unsafeChangesTracker: { added: [], deleted: [], updated: [] },
		inProgressAutomations: [],
		showControlPanel: "expanded",
		selectedEventId: null,
	};
};

export const reducer = (state = initialState(), action) => {
	switch (action.type) {
	case SET_AUTOMATION:
		return { ...state, automations: action.automations };
	case LIST_AUTOMATIONS_SUCCESS:
		return { ...state, automations: action.data };
	case LIST_AUTOMATION_BLUEPRINTS_SUCCESS:
		return { ...state, automationBluePrints: action.data };
	case UPDATE_AUTOMATION_EVENT: {
		const updatedEvents = state.automationEvents.map((event) => {
			if (event.id === action.updatedEventId) {
				return action.updatedEvent;
			} else return event;
		});
		if (action.addSnapshot) {
			state.automationEventsSnapshots.past.push({
				tags: action.snapShotTags,
				selectedEventId: state.selectedEventId,
				snapshot: layoutedEvents(
					deleteAllHelperNodes(state.automationEvents)
				),
			});
		}
		return { ...state, automationEvents: updatedEvents };
	}

	case UPDATE_AUTOMATION_EVENTS: {
		if (action.addSnapshot) {
			state.automationEventsSnapshots.past.push({
				tags: action.snapShotTags,
				selectedEventId: state.selectedEventId,
				snapshot: layoutedEvents(
					deleteAllHelperNodes(state.automationEvents)
				),
			});
		}
		return { ...state, automationEvents: [...action.updatedEvents] };
	}
	case ADD_SNAPSHOT: {
		return {
			...state,
			automationEventsSnapshots: {
				...state.automationEventsSnapshots,
				past: [
					...state.automationEventsSnapshots.past,
					{
						tags: action.snapShotTags,
						snapshot: layoutedEvents(deleteAllHelperNodes(action.events)),
						selectedEventId: state.selectedEventId,
					},
				],
			},
		};
	}
	case UPDATE_AUTOMATION_GENERAL_ISSUES: {
		return { ...state, automationGeneralIssues: action.issues };
	}
	case DEACTIVATE_AUTOMATION_SUCCESS: {
		return {
			...state,
			activeAutomation: {
				...state.activeAutomation,
				status: action.status,
				paused: action.paused,
				committed_on: action.data.data.committed_on,
				committedRevision: action.data.data.committed_revision,
			},
		};
	}
	case UPDATE_AUTOMATION_STATS_SUCCESS: {
		return {
			...state,
			stats: { ...state.stats, ["subscribers"]: action.data },
		};
	}
	case ACTIVATE_AUTOMATION_SUCCESS: {
		// since the activation request takes long time to be completed by BE, we are pushing to a new state array to be inprogress, and need to
		// remove it when the request is completed
		state.inProgressAutomations.splice(
			state.inProgressAutomations.indexOf(action.data.data.id),
			1
		);
		let updatedAutomations = null;
		if (state.automations?.data) {
			const automation = state.automations.data.filter(
				(auto) => auto.id == action.data.data.id
			)[0];
			automation.status = action.data.data.status;
			automation.paused = action.data.data.paused;
			updatedAutomations = { ...state.automations };
		}
		return {
			...state,
			activeAutomation: {
				...state.activeAutomation,
				status: "active",
				paused: action.data.data.paused,
				committed_on: action.data.data.committed_on,
				committedRevision: action.data.data.committed_revision,
			},
			automations: updatedAutomations,
		};
	}
	case ADD_AUTOMATION_EVENT: {
		const updatedEvents = state.automationEvents
			? [...state.automationEvents, action.addedEvent]
			: [action.addedEvent];
		return { ...state, automationEvents: updatedEvents };
	}
	case RESET_AUTOMATION_EVENTS: {
		// on reseting the events we need to reset the active automation as well
		return {
			...state,
			automationEvents: null,
			activeAutomation: null,
			actions: null,
			editorMode: EDITOR_MODE.WORKFLOW,
			automationGeneralIssues: null,
			stats: {},
			selectedEventId: null,
		};
	}
	case DELETE_AUTOMATION_EVENT: {
		return {
			...state,
			automationEvents: [...state.automationEvents].splice(
				state.automationEvents.findIndex(
					(event) => event.id === action.deletedEventId
				),
				1
			),
		};
	}
	case UPDATE_AUTOMATION_SELECTED_EVENT: {
		return {
			...state,
			selectedEventId: action.id,
		};
	}
	case CREATE_AUTOMATION_SUCCESS: {
		return {
			...state,
			activeAutomation: {
				id: action.data.id,
				name: action.data.name,
				status: action.data.status,
				paused: action.data.paused,
				committed_on: action.data.data.committed_on,
				committedRevision: action.data.data.committed_revision,
				customevent_id: action.data.data.trigger.filter(
					(trigger) => trigger.name == "Custom.Event"
				)[0].args.customevent_id,
			},
			automationEvents: [],
			// stats: {
			// 	...state.stats,
			// 	subscribers: action.data.data.audience.contactsCount,
			// },
		};
	}
	case GET_AUTOMATION_SUCCESS: {
		return {
			...state,
			activeAutomation: {
				id: action.data.data.id,
				name: action.data.data.name,
				status: action.data.data.status,
				paused: action.data.data.paused,
				committed_on: action.data.data.committed_on,
				committedRevision: action.data.data.committed_revision,
				customevent_id: action.data.data.trigger.filter(
					(trigger) => trigger.name == "Custom.Event"
				)[0]?.args?.customevent_id,
				listId: action.data.data.list_id,
			},
			automationEvents: action.updatedEvents,
			automationEventsSnapshots: { past: [], future: [] },
		};
	}
	case ADD_AUTOMATION_VERSION: {
		return {
			...state,
			automationEventsSnapshots: {
				...state.automationEventsSnapshots,
				past: action.version,
			},
		};
	}
	case UNDO_AUTOMATION: {
		const past = state.automationEventsSnapshots.past;
		const previous = past[past.length - 1];
		const newPast = past.slice(0, past.length - 1);
		state.activeAutomation.update = Date.now();
		return {
			...state,

			automationEventsSnapshots: {
				past: newPast,
				future: [
					{
						snapshot: state.automationEvents,
						selectedEventId: state.selectedEventId,
					},
					...state.automationEventsSnapshots.future,
				],
			},
			selectedEventId: previous.selectedEventId,
			automationEvents: [...previous.snapshot],
		};
	}
	case REDO_AUTOMATION: {
		const future = state.automationEventsSnapshots.future;
		const past = state.automationEventsSnapshots.past;
		const next = future[0];
		const newFuture = future.slice(1);
		state.activeAutomation.update = Date.now();
		return {
			...state,
			automationEventsSnapshots: {
				past: [
					...past,
					{
						snapshot: state.automationEvents,
						selectedEventId: state.selectedEventId,
					},
				],
				future: newFuture,
			},
			selectedEventId: next.selectedEventId,
			automationEvents: next.snapshot,
		};
	}
	case UPDATE_ACTIVE_AUTOMATION_SUCCESS:
		// we need to map the triggerEvent to the automation object
		return {
			...state.activeAutomation,
			audience: {
				list_id: action.data.audience.list_id,
			},
		};
		//[...state.automationEvents, triggerEvent]}
		// case LIST_AUTOMATION_ACTIONS_SUCCESS: {
		// 	const events = mapBEStepsToFEEvents(automation3);
		// 	return {
		// 		...state,
		// 		actions: action.actions.data,
		// 		automationEvents: events,
		// 	};
		// }

	case GET_ACTION_STATS_SUCCESS: {
		return {
			...state,
			stats: {
				...state.stats,
				[action.actionStats.id]: action.actionStats.data,
			},
		};
	}
	case RENAME_AUTOMATION_SUCCESS: {
		return {
			...state,
			activeAutomation: {
				...state.activeAutomation,
				name: action.newName,
			},
		};
	}

	case SET_EDITOR_MODE: {
		return { ...state, editorMode: action.editorMode };
	}
	case SET_SHOW_CONTROL_PANEL: {
		return { ...state, showControlPanel: action.showControlPanel };
	}
	case LIST_VISITORS_SUCCESS: {
		return { ...state, automationVisitors: action.data };
	}
	case OPEN_SUB_SCENE: {
		return {
			...state,
			subScene: { scene: action.scene, configs: action.configs },
		};
	}

	default:
		const rest = omit(state, Object.keys(initialState()));
		return {
			...state,
		};
	}
};
