import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

class ErrorThrower extends Component {
	constructor(props) {
		super(props);
	}

	componentDidUpdate(nextProps) {
		if (nextProps.critialError) {
			throw nextProps.critialError;
		}
	}

	render() {
		return this.props.children;
	}
}

export default withRouter(
	connect((state) => {
		return {
			critialError: state.global.criticalError,
		};
	}, null)(ErrorThrower)
);
