/**
 * This file is mainly to handle the mapping of data from events object to nodes object
 * Events: represents the actual automation events, holding data to from and to different backend endpoints
 * Nodes: represents the actual object that the diagram workflow uses, holding reference to event, and data
 *		  that represents how each node will be drawn , used by the reactflow lib
 */
import _ from "lodash";
import { parseBESendEmailToFE, parseFESendEmailToBE } from "./SendEmailMapper";
import { parseBEDelayToFE, parseFEDelayToBE, getSubDelay } from "./DelayMapper";
import { parseBEIfElseToFE, parseFEIfElseToBE } from "./IfElseMapper";
import {
	GET_STATS,
	EVALUATE_ALL_CONDITION_ON_EMAILS,
	EVALUATE_ALL_NOT_CONDITION_ON_EMAILS,
	MAPPED,
} from "assets/js/scenes/automations/scenes/AutomationEditor/containers/Conditions/IfElse/SubAutomationsDefinitions";
import { EVENT_TYPES } from "assets/js/const/Automations";

import {
	parseBETriggerToFE,
	parseFETriggerToBE,
} from "../../scenes/AutomationEditor/containers/Triggers/SubscribeTrigger/Mapper";
import {
	getEdge,
	getEventType,
	getEndCoachmarkNode,
	convertTimeWithUnitToSeconds,
	convertSecondsToNearestUnit,
	getNextRepresentableElement,
	helperNode,
} from "./utils";
import { getConnectedEdges, isNode, isEdge } from "react-flow-renderer";

const mapBEStepToFEEvent = (automation, mainStep, subStep, target) => {
	switch (subStep.action) {
	case "CampaignEmail.Send":
		return parseBESendEmailToFE(mainStep, subStep, target);
	case "Time.Sleep":
		return parseBEDelayToFE(mainStep, subStep, target);
	case "Condition.Switch":
		return parseBEIfElseToFE(automation, mainStep, subStep, target);
	}
};
const mapFEEventToBEStep = (event, connectedEdges, events) => {
	const eventType = getEventType(event.data.type, event.data.baseId);
	switch (eventType) {
	case EVENT_TYPES.TRIGGER_SUBSCRIBED:
		return parseFETriggerToBE(event, events);
	case EVENT_TYPES.ACTION_SENDEMAIL:
		return parseFESendEmailToBE(event, connectedEdges, events);
	case EVENT_TYPES.CONDITION_DELAY:
		return parseFEDelayToBE(event, connectedEdges, events);
	case EVENT_TYPES.CONDITION_IFELSE:
		return parseFEIfElseToBE(event, connectedEdges, events);
	}
};

const getSubAutomationForAMainStep = (automation, step) => {
	return automation.sub_automations.filter(
		(sub) => sub.name == step.args.sub_automation
	)[0];
};
const dummyFunctionToAddId = (automation) => {
	automation.main.steps.forEach((step) => (step.id = step.name));
};
const mapBEStepsToFEEvents = (automation) => {
	dummyFunctionToAddId(automation);
	let events = [];
	let links = [];
	let nextStepToTrigger = null;
	// new automation
	if (automation.main.steps.length == 0) {
		nextStepToTrigger = helperNode();
		const coachmark = getEndCoachmarkNode(0, 0);
		const link = getEdge("custom", nextStepToTrigger.id, coachmark.id);
		events.push(coachmark);
		events.push(nextStepToTrigger);
		links.push(link);
	} else
		nextStepToTrigger = {
			id: getNextRepresentableElement(
				{ next: automation.main.steps[1].id },
				automation
			),
		};
	const triggerObject = parseBETriggerToFE(
		automation.trigger[0].args.list_id,
		nextStepToTrigger
	);
	links = [...links, ...triggerObject.links];
	events = [...events, ...triggerObject.events];
	automation.main.steps.forEach((step) => {
		if (step.action == "SubAutomation.Call") {
			const subAutomationSteps = getSubAutomationForAMainStep(
				automation,
				step
			).steps;
			subAutomationSteps.forEach((subStep) => {
				const targetId = getNextRepresentableElement(step, automation);
				const eventObject = mapBEStepToFEEvent(
					automation,
					step,
					subStep,
					targetId
				);
				if (eventObject) {
					events = [...events, ...eventObject.events];
					links = [...links, ...eventObject.links];
				}
			});
		} else {
			const targetId = getNextRepresentableElement(step, automation);
			const eventObject = mapBEStepToFEEvent(automation, step, step, targetId);
			if (eventObject) {
				events = [...events, ...eventObject.events];
				links = [...links, ...eventObject.links];
			}
		}
	});
	events = events.filter((element) => {
		return element !== undefined;
	});
	links = links.filter((element) => {
		return element !== undefined;
	});
	return [...events, ...links];
};
const addReusableSubAutomations = (nodes, automation) => {
	if (nodes.filter((node) => node.data.baseId == "delay").length > 0) {
		automation.sub_automations.push(getSubDelay());
	}
	if (nodes.filter((node) => node.data.baseId == "ifElse").length > 0) {
		//	automation.sub_automations.push(EVALUATE_ALL_CONDITION_ON_EMAILS);
		//	automation.sub_automations.push(GET_STATS);
		//	automation.sub_automations.push(EVALUATE_ALL_NOT_CONDITION_ON_EMAILS);
	}
};
const adjustFirstEventAfterTrigger = (events) => {
	const firstElementAfterTriggerId = events.filter(
		(e) =>
			isEdge(e) &&
			events.filter((event) => event.data.type == "trigger")[0].id === e.source
	)[0].target;
	const index = events.findIndex(
		(ele) => ele.id === firstElementAfterTriggerId
	);
	const firstElementAfterTrigger = events[index];
	events.splice(index, 1);
	events.splice(0, 0, firstElementAfterTrigger);
};
const mapFEEventsToBEEvents = (events, activeAutomation) => {
	const automation = {
		name: activeAutomation.name,
		list_id: "",
		trigger: [],
		main: { steps: [] },
		sub_automations: [],
	};
	adjustFirstEventAfterTrigger(events);
	const nodes = events.filter((event) => isNode(event));
	const edges = events.filter((event) => isEdge(event));

	//First step: add all the reusable sub automations that will be required
	addReusableSubAutomations(nodes, automation);

	//Second step: convert each event to main and subautomation, if it's subautomation is not required
	nodes.forEach((event) => {
		const connectedEdges = getConnectedEdges(nodes, edges).filter(
			(edge) => edge.source == event.id
		);
		if (event.data.type == "trigger") {
			automation.list_id = event.data.audience;
			automation.trigger.push(mapFEEventToBEStep(event, null, events));
		} else {
			const mappedObject = mapFEEventToBEStep(event, connectedEdges, events);
			if (mappedObject?.main) {
				mappedObject.main.forEach((newStep) => {
					if (
						automation.main.steps.filter((step) => step.name == newStep.name)
							.length == 0
					) {
						automation.main.steps = [...automation.main.steps, newStep];
					}
				});
			}

			if (mappedObject?.sub)
				automation.sub_automations = [
					...automation.sub_automations,
					...mappedObject.sub,
				];
			// this is required to add the reusable subautomations
			if (mappedObject?.reusableSubAutomations) {
				mappedObject?.reusableSubAutomations.forEach((sub) => {
					if (
						automation.sub_automations.filter(
							(subAutomation) => subAutomation.name == sub
						).length == 0
					) {
						automation.sub_automations.push(MAPPED[sub]);
					}
				});
			}
		}
	});
	return automation;
};

export {
	convertSecondsToNearestUnit,
	convertTimeWithUnitToSeconds,
	mapBEStepsToFEEvents,
	mapFEEventsToBEEvents,
};
