import CriticalError from "../CriticalError";
import { openStripeBillingPortal } from "assets/js/services/hiddenService";
import {
	DialogHandler,
	FullPageLoaderHandler,
	NotificationHandler,
	OverlayHandler,
	TaskBarHandler,
} from "cakemail-ui-components/lib/react";
import { I18n, Translate } from "react-redux-i18n";
import React from "react";
import moment from "moment";
import store from "../store";
import { setUsageData } from "assets/js/data/usage/actions";
import { getAccountStatsService } from "assets/js/services/accountService";
import {
	checkLimitNotification,
	openLimitDialog,
} from "utils/accountNotification";
import fetchRoute from "./routes";
import {
	SEGMENT_EVENTS,
	trackSegmentEvent,
} from "assets/js/const/SegmentEvents";
import {
	acceptListPolicyService,
	createListService,
} from "assets/js/services/listService";
import { listSendersService } from "assets/js/services/SenderService";
import {
	showStripeCurrencySelection,
	showZendeskSupportDialog,
} from "assets/js/components/generalDialogs/simpleDialogs";
import { isValidEmail } from "utils/validators";
import { listUsersService } from "assets/js/services/userService";
import CakeStore from "assets/js/store";
import { logoutService } from "assets/js/services/authService";
import { clearBackToFlows } from "utils/urlUtils";
import { clearGlobalItems } from "utils/componentUtils";
import { setAccountReady, setAuthenticated } from "assets/js/actions";

//TODO DELETE
export function validateResponse(
	response,
	successCallback = null,
	errorCallback = null
) {
	if (
		response != null &&
		(response.statusCode >= 200 || response.status >= 200) &&
		(response.statusCode < 400 || response.status < 400)
	) {
		if (successCallback) {
			successCallback(response);
		}
	} else {
		if (errorCallback) {
			handleCustomApiFailure(response, errorCallback);
		}
	}
}

//TODO TO DELETE
export function getApiResponse(
	error,
	data,
	response,
	pageName = null,
	history = null,
	path = null,
	retry = false,
	successCallback = null,
	errorCallback = null
) {
	let results = {};
	//If there's a callback, no need to return data, because the callback will handle the response
	if (
		response != null &&
		response.statusCode >= 200 &&
		response.statusCode < 400
	) {
		if (successCallback) {
			successCallback(response, data);
		}

		results.data = data;
		results.response = response;
	} else {
		if (errorCallback) {
			errorCallback(response, error);
		}

		if (retry === true) {
			results.error = "";
		} else if (pageName != null && history != null && path != null) {
			let error = new CriticalError({
				pageName: pageName,
				primaryAction: () => {
					history.push(path);
				},
			});
			results.error = error;
		} else {
			results.error = error;
		}
	}
	return results;
}

export const APP_URL = window.location.origin;
export const GATEWAY_PROXY = APP_URL + "/api";

export const handleCustomApiFailure = (
	response,
	failureCallback = () => {},
	errorCode = null
) => {
	const details =
		response?.body?.detail || response?.detail || response?.body?.error?.detail;
	let usage = null;
	let limits = null;
	if (details?.[0] && details[0]?.code) {
		const code = errorCode ? errorCode : details[0].code;
		switch (code) {
		case 4:
			if (
				!window.location.href.includes(fetchRoute("public")) &&
					!window.location.href.includes("/auth")
			) {
				globalLogOut();
				window.location = window.location.search + "#" + fetchRoute("signIn");
			}
			break;
			//Rate limit exceeded
		case 429:
			failureCallback("app-general-error-code-429", response, code);
			break;
			//603 => Signup Disabled
		case 603:
			failureCallback("app-general-error-code-603", response, code);
			break;
			//1000 => Not enough permissions
		case 1000:
			DialogHandler.show({
				type: "error",
				title: <Translate value="app-general-error-code-1000" />,
			});
			break;
			//1001 => User pending confirmation
		case 1001:
			failureCallback(null, response, code);
			break;
			//1002 => Invalid credentials
		case 1002:
			failureCallback("app-auth-sign_error_incorrect", response, code);
			break;
			//1003 => Invalid token
		case 1003:
			failureCallback("app-general-error-code-1003", response, code);
			break;
			//1501 => Form policy not compatible with list current trigger configuration
		case 1501:
			DialogHandler.show({
				type: "warning",
				title: <Translate value="app-forms-policies-legacy-issue-title" />,
				content: (
					<p>
						<Translate value="app-forms-policies-legacy-issue-text" />
					</p>
				),
				primaryBtnText: <Translate value="app-general-close" />,
			});
			failureCallback(null, response, code);
			break;
			//2010, password change needs to be different form previous one
		case 2010:
			failureCallback("app-general-error-code-2010", response, code);
			break;
			//9100 => Account has been suspended or is already suspended
			//1007 => User account is suspended
		case 1007:
		case 9100:
			trackSegmentEvent(SEGMENT_EVENTS.ACCOUNT_SUSPENDED);
			window.location =
					window.location.search + "#" + fetchRoute("errorFallback");
			break;
			//1006 => User account is locked out
		case 1006:
			failureCallback("app-general-error-code-1006", response, code);
			break;
		case 9101:
			// Action is blocked due to low ehawk score
			trackSegmentEvent(SEGMENT_EVENTS.ACTION_BLOCKED);
			failureCallback("app-general_action-blocked", response, code);
			break;
		case 8008:
		case 8006:
			// No senders, or sender not confirmed
			failureCallback("app-general_no-sender", response, code);
			break;
		case 9000:
			// error creating the account
			failureCallback("app-general-error-account-creation", response, code);
			break;
		default:
			failureCallback(null, response, code);
			break;
		}
	} else {
		const message = typeof details === "string" ? details : details?.[0]?.msg;

		switch (message) {
		case "Not authenticated":
			if (
				!window.location.href.includes(fetchRoute("public")) &&
					!window.location.href.includes("/auth")
			) {
				globalLogOut();
				window.location = window.location.search + "#" + fetchRoute("signIn");
			}
			break;
		case "Account address information is required":
			failureCallback("app-general-error-address-missing", response, 422);
			break;
		case "Metadata not found":
		case "Campaign id not found":
			failureCallback(null, response, 404);
			break;
		case "Metadata point already exists":
			failureCallback(null, response, 422);
			break;
		case "Contact limit exceeded":
			usage = store.getState().global.usage;
			limits = {
				contactLimit:
						store.getState().global.account.usage_limits.maximum_contacts,
				emailsMonthlyLimits:
						store.getState().global.account.usage_limits.per_month,
			};

			openLimitDialog(usage, limits);

			failureCallback(null, response, 400, { handled: true });
			break;
		case "Campaign exceeds the per-month emails limit":
		case "Campaign exceeds the per-campaign emails limit":
			usage = store.getState().global.usage;
			limits = {
				contactLimit:
						store.getState().global.account.usage_limits.maximum_contacts,
				emailsMonthlyLimits:
						store.getState().global.account.usage_limits.per_month,
			};

			openLimitDialog(
				usage,
				limits,
				<Translate
					value="app-billing-limit-sent-usage-campaign-desc"
					dangerousHTML
				/>,
				[
					{
						desc: (
							<Translate value="app-billing-limit-sent-usage-campaign-usage" />
						),
						info: usage.emails,
					},
					{
						desc: (
							<Translate value="app-billing-limit-sent-usage-campaign-limit" />
						),
						info: limits.emailsMonthlyLimits,
					},
				],
				<Translate
					value="app-billing-limit-sent-usage-campaign-title"
					usage={Math.floor(usage.emailUsage)}
				/>
			);

			failureCallback(null, response, 400, { handled: true });
			break;
		default:
			//Not exact message
			if (message) {
				if (message.includes("is already taken!")) {
					failureCallback("app-atuh-create_error_general", response);
				} else if (message.includes("does not have a subscription.")) {
					failureCallback("no subscription", response, null);
				} else if (message.includes("is a read-only attribute")) {
					failureCallback(
						"app-list-attribute-error-system-field",
						response,
						null
					);
				} else {
					failureCallback(null, response);
				}
			} else {
				failureCallback(null, response);
			}
			break;
		}
	}
};

export function globalLogOut(redirection = false) {
	if (!redirection) {
		window.location = window.location.search + "#" + fetchRoute("signIn");
	}

	store.dispatch({ type: "RESET_STORE" });
	store.dispatch(setAuthenticated(false));
	store.dispatch(setAccountReady(false));

	const user = store.getState().global.user;

	trackSegmentEvent(SEGMENT_EVENTS.SIGN_OUT, user);

	clearGlobalItems();
}

export function getAccountUsage(
	isUpdate = true,
	accountId,
	prevLimits = null,
	callback = () => {}
) {
	const storeUsage = store.getState().global.usage;
	const account = store.getState().global.account;

	getAccountStatsService(
		null,
		(data) => {
			let curUsage = { ...storeUsage };

			curUsage.emails = data.data.sent_emails;
			curUsage.emailUsage = data.data.emails_usage;
			curUsage.contacts = data.data.active_contacts;
			curUsage.contactUsage = data.data.contacts_usage;
			curUsage.currentLists = data.data.current_lists;
			curUsage.currentUsers = -1;

			//If the fetch is not an update, so a fresh page load, the oldUsage is the currentUsage
			let oldUsage = null;

			if (!isUpdate) {
				callback({ limits: account.usage_limits, usage: curUsage });

				oldUsage = curUsage;
				if (prevLimits) {
					prevLimits = prevLimits.split(",");
					const prevContacts = parseInt(prevLimits[0]);
					const prevEmail = parseInt(prevLimits[1]);

					if (
						prevContacts > account.usage_limits.maximum_contacts ||
						prevEmail > account.usage_limits.per_month
					) {
						trackSegmentEvent(SEGMENT_EVENTS.PLAN_DOWNGRADED, {
							from: {
								contactLimit: prevContacts,
								emailLimit: prevEmail,
							},
							to: {
								contactLimit: account.usage_limits.maximum_contacts,
								emailLimit: account.usage_limits.per_month,
							},
						});
					} else if (
						prevContacts < account.usage_limits.maximum_contacts ||
						prevEmail < account.usage_limits.per_month
					) {
						trackSegmentEvent(SEGMENT_EVENTS.PLAN_UPGRADED, {
							from: {
								contactLimit: prevContacts,
								emailLimit: prevEmail,
							},
							to: {
								contactLimit: account.usage_limits.maximum_contacts,
								emailLimit: account.usage_limits.per_month,
							},
						});
					}
				}
			} else {
				oldUsage = { ...storeUsage };
			}

			//Add extra layer of usage like this until stats call has all that is needed
			listUsersService(
				{ withCount: true, perPage: 1 },
				(data) => {
					curUsage.currentUsers = data.pagination.count;
					store.dispatch(setUsageData({ ...curUsage }));
				},
				() => {
					store.dispatch(setUsageData({ ...curUsage }));
				}
			);

			checkLimitNotification(oldUsage, curUsage, isUpdate);
		},
		() => {
			callback({});
		}
	);
}

export function openBillingPortal(
	currency = null,
	btnRef = null,
	priceId = null,
	billingPortal = false
) {
	if (isPartner(true, { btnRef: btnRef })) {
		return;
	}

	const account = store.getState().global.account;
	const stripeAccess = store.getState().brand?.config?.stripe_portal_access === true;
	let message =
		"User wants to upgrade, but the portal failed to open. \n";
	const billingPlanURL = store.getState().brand?.config?.billing_plan_url;

	if (stripeAccess){
		let url =
			window.location.origin +
			`/?prev_limits=${account.usage_limits.maximum_contacts},${account.usage_limits.per_month}#/`;

		if(billingPortal){
			openStripeBillingPortal(
				{
					returnToUrl: url,
					currency: currency,
					parentAccountId: store.getState().brand?.config?.partner_account_id,
					price: priceId,
				},
				(result) => {
					window.location.replace(result?.data?.portal_url);
					if (btnRef?.current) {
						btnRef.current.reset();
					}
					setTimeout(() => {
						FullPageLoaderHandler.hide();
					}, 2000);
				},
				(customErrorMessage) => {
					DialogHandler.hide();
					if (customErrorMessage === "no subscription") {
						showStripeCurrencySelection(priceId);
					} else {
						if (currency) {
							message += `User wanted to use ${currency} as the preferred currency`;
						}
						showZendeskSupportDialog("upgrade", message);
					}

					FullPageLoaderHandler.hide();
					if (btnRef?.current) {
						btnRef.current.reset();
					}
				}
			);
		} else {
			if (billingPlanURL){
				window.location = (billingPlanURL);
			} else {
				showZendeskSupportDialog("upgrade", message);
				FullPageLoaderHandler.hide();
			}
		}

	} else {
		showZendeskSupportDialog("upgrade", message);
		FullPageLoaderHandler.hide();
	}
}

export function getBrandId() {
	const id = store.getState().brand?.config?.brand_id;
	return id ? id : "default";
}

export const METHODS = {
	post: "POST",
	put: "PUT",
	delete: "DELETE",
	get: "GET",
	patch: "PATCH",
};

export const DEFAULT_LIST_PARAMS = {
	page: 1,
	perPage: 25,
	withCount: false,
	filter: null,
	sort: null,
};

export function callAPI(
	{
		url = "",
		method = METHODS.post,
		body = null,
		query = null,
		headers = null,
		skipRetry = false,
	},
	successCallback = () => {},
	failureCallback = () => {}
) {
	const options = prepareMicroServiceCall(
		method || METHODS.post,
		body,
		headers
	);

	let queryExtention = "";
	const initQueryChar = url.includes("?") ? "&" : "?";

	for (const key in query) {
		if (![null, undefined].includes(query[key]))
			queryExtention += `&${key}=${encodeURIComponent(query[key])}`;
	}

	if (queryExtention) {
		queryExtention = initQueryChar + queryExtention.slice(1);
	}

	url = url + queryExtention;

	function validateResponse(response, result) {
		if (response != null && response.status >= 200 && response.status < 400) {
			if (successCallback) {
				successCallback(result);
			}
		} else {
			handleCustomApiFailure({ ...response, body: result }, failureCallback);
		}
	}

	function retry(tries = 0, skipRetry) {
		fetch(url, options)
			.then((response) => {
				let data = null;
				if (response.headers.get("content-type").includes("application/json")) {
					data = response.json();
				} else {
					data = response.text();
				}

				data.then((result) => {
					if (
						!skipRetry &&
						[404, 429, 500, 502, 503, 504].includes(response.status) &&
						tries < 2
					) {
						retry((tries += 1));
					} else {
						validateResponse(response, result);
					}
				});
			})
			.catch((error) => {
				if (!skipRetry && tries < 2) {
					retry((tries += 1));
				} else {
					handleCustomApiFailure(error, failureCallback);
				}
			});
	}
	retry(0, skipRetry);
}

export function prepareMicroServiceCall(
	method = METHODS.post,
	data = null,
	headers = null
) {
	const _headers = new Headers();
	_headers.set("content-type", "application/json");
	if (headers) {
		for (const headerKey in headers) {
			_headers.set(headerKey, headers[headerKey]);
		}
	}

	let options = {
		method: method,
		redirect: "follow",
		headers: _headers,
		credentials: "same-origin",
	};

	if (data) {
		if (_headers.get("content-type") === "application/json") {
			options.body = JSON.stringify(data);
		} else {
			options.body = data;
		}
	}

	return options;
}

export const defaultRequestOptions = {
	page: 1,
	perPage: 25,
	search: null,
	sort: "-created_on",
	filters: [],
};

export const createDefaultResource = () => {
	const user = store.getState().global.user;
	const account = store.getState().global.account;

	return new Promise((resolve, reject) => {
		listSendersService(
			{
				accountId: account.id,
			},
			(data) => {
				if (data?.data?.length > 0) {
					resolve({ sender: data.data[0] });
				} else {
					reject();
				}
			},
			() => {
				reject();
			}
		);
	}).then((result) => {
		return new Promise((resolve, reject) => {
			createListService(
				{
					default_sender: { id: result.sender.id },
					name: isValidEmail(account.name)
						? I18n._translate("app-general-lists_default")
						: I18n._translate("app-general-lists_default_named", {
							name: account.name,
						  }),
					language: user.language,
				},
				(data) => {
					acceptListPolicyService({
						id: data.data.id,
					});

					resolve({ ...result, list: data.data });
				},
				() => {
					reject();
				}
			);
		});
	});
};

export const getAmplitudeMonthlyStats = (account, callback) => {
	let last30Days = moment().subtract(1, "months").unix();
	let last7Days = moment().subtract(1, "weeks").unix();
	let last24Hours = moment().subtract(1, "days").unix();

	function getWantedStats(data) {
		let finalResults = {};
		if (data?.month) {
			const monthOpen =
				data.month.open_rate > 0
					? (data.month.sent_emails * data.month.open_rate) / 100
					: 0;
			const monthClick =
				monthOpen > 0 ? (data.month.click_rate * monthOpen) / 100 : 0;
			finalResults.month = {
				sents: data.month.sent_emails,
				opens: parseFloat(monthOpen.toFixed(2)),
				click: parseFloat(monthClick.toFixed(2)),
			};
		}
		if (data?.week) {
			const weekOpen =
				data.week.open_rate > 0
					? (data.week.sent_emails * data.week.open_rate) / 100
					: 0;
			const weekClick =
				weekOpen > 0 ? (data.week.click_rate * weekOpen) / 100 : 0;
			finalResults.week = {
				sents: data.week.sent_emails,
				opens: parseFloat(weekOpen.toFixed(2)),
				click: parseFloat(weekClick.toFixed(2)),
			};
		}
		if (data?.day) {
			const dayOpen =
				data.day.open_rate > 0
					? (data.day.sent_emails * data.day.open_rate) / 100
					: 0;
			const dayClick = dayOpen > 0 ? (data.day.click_rate * dayOpen) / 100 : 0;
			finalResults.day = {
				sents: data.day.sent_emails,
				opens: parseFloat(dayOpen.toFixed(2)),
				click: parseFloat(dayClick.toFixed(2)),
			};
		}

		return finalResults;
	}

	return new Promise((resolve, reject) => {
		getAccountStatsService(
			{ startTime: last30Days },
			(data) => {
				resolve({ month: data.data });
			},
			() => {
				reject();
			}
		);
	})
		.then((result) => {
			return new Promise((resolve, reject) => {
				getAccountStatsService(
					{ startTime: last7Days },
					(data) => {
						resolve({ ...result, week: data.data });
					},
					() => {
						reject(result);
					}
				);
			});
		})
		.then((result) => {
			return new Promise((resolve, reject) => {
				getAccountStatsService(
					{ startTime: last24Hours },
					(data) => {
						resolve({ ...result, day: data.data });
					},
					() => {
						reject(result);
					}
				);
			});
		})
		.then((result) => {
			callback(getWantedStats(result));
		})
		.catch((result) => {
			callback(getWantedStats(result));
		});
};

export function isPartner(checkPreview = false, options = {}) {
	const previewingBrand = store.getState()?.global?.previewingBrand;
	if (checkPreview && previewingBrand) {
		setTimeout(() => {
			DialogHandler.show({
				type: "warning",
				title: <Translate value={"app-reseller-action-blocked"} />,
			});

			if (options?.btnRef?.current) {
				options?.btnRef?.current.reset();
			}
			if (options?.hideDialog) {
				DialogHandler.hide();
			}
			if (options?.hideFullPage) {
				FullPageLoaderHandler.hide();
			}
		}, 500);
		return store.getState()?.global?.account?.partner && previewingBrand;
	}
	return store.getState()?.global?.account?.partner && !previewingBrand;
}

export const handlePaginationSorting = (data, perPage, page, sort) => {
	const sortedData = [...data];
	switch (sort) {
	case "created_on":
	case "+created_on":
		sortedData.sort((a, b) => Number(a.created_on) - Number(b.created_on));
		break;
	case "-created_on":
		sortedData.sort((a, b) => Number(b.created_on) - Number(a.created_on));
		break;
	case "+name": {
		sortedData.sort(function (a, b) {
			var nameA = a.name.toLowerCase(),
				nameB = b.name.toLowerCase();
			if (nameA < nameB) return -1;
			if (nameA > nameB) return 1;
			return 0;
		});
		break;
	}
	default:
		break;
	}
	var from = (page - 1) * Number(perPage);
	var to = from + Number(perPage);
	return sortedData.slice(from, to);
};

export function chameau(options = {}) {
	const camelToSnakeCase = (str) =>
		str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
	const out = {};
	for (const key in options) {
		out[camelToSnakeCase(key)] = options[key];
	}
	return out;
}
