import { getAllPreviousSendActions } from "assets/js/scenes/automations/containers/editor/utils/utils";
import { convertPlanToFlatArrayOfActions } from "assets/js/scenes/automations/containers/editor/utils/utils";
import { getMatchedConditionToEmitStep } from "../../../../../../containers/editor/Mapper";
import {
	removeRegexChars,
	getSelectedAttributesValuesOp,
} from "./contactDetailsUtils";
/**
 * A variable is represented as ${VAR_NAME} , the function gets the VAR_NAME
 * @param {*} varValue
 */
const getValudOfVariable = (varValue) => {
	let actualValue = varValue.replace(/\}$/, "");
	actualValue = actualValue.replace(/\$\{/gi, "");
	return actualValue;
};
/**
 * this function gets the id of the send email action [trigger] that a stats points to example : output would return the trigger
 * that was send as a param to get stats step
 */

const getRepresentableStepForTheCondition = (
	subStepName,
	automation,
	logStepId
) => {
	//const nexStepId=convertPlanToFlatArrayOfActions(automation).filter(s=>s.stepNumber==logStepId)[0].id;
	//automation.sub_automation.filter(s=>s.name==subStepName)[0].steps.filter(s=>s.id==logStepId)[0].args.params)
	return getValudOfVariable(
		automation.sub_automations
			.filter((s) => s.name == subStepName)[0]
			.steps.filter((s) => s.name == logStepId)[0].args.params.trigger_id
	)
		.replace(/_output$/, "")
		.replace("input.data.step_trigger.", "");
};
/**
 * this function extracts the needed values from the BE var expression, example : "var": "int(output.clicked)"
 * the function extracts the name of the counted logs, which is number of clicks, the send email action  [trigger] that the stats point to
 * @param {*} automation
 * @param {*} varValue
 */

const getValuesFromVar = (subStepName, automation, varValue) => {
	let actualValue = varValue.replace("}", "");
	actualValue = actualValue.replace("${", "");
	let attribute = "";
	let endsWith = "";
	if (actualValue.endsWith(".c")) {
		attribute = "clicked";
		endsWith = ".c";
	}
	if (actualValue.endsWith(".o")) {
		attribute = "opens";
		endsWith = ".o";
	}
	if (actualValue.endsWith(".s")) {
		attribute = "sent";
		endsWith = ".s";
	}

	let valueWithoutAttribute = actualValue.replace(endsWith, "");
	valueWithoutAttribute = valueWithoutAttribute.replace(/_output$/, "");
	valueWithoutAttribute = valueWithoutAttribute.replace(
		/input.data.step_trigger.$/,
		""
	);
	return {
		value: attribute,
		selectedCamps: [
			getRepresentableStepForTheCondition(
				subStepName,
				automation,
				valueWithoutAttribute
			),
		],
	};
};
/**
 * this function converts a condition from a BE switch condition to FE condition object
 * @param {} automation
 * @param {*} condition
 */
const getSingleCondition = (subStepName, automation, condition) => {
	if (
		Object.keys(condition)[0] == "ge" &&
		Object.values(condition)[0].value == 1
	) {
		return {
			...getValuesFromVar(
				subStepName,
				automation,
				Object.values(condition)[0].var
			),
			campaignsOption: "specific",
			type: "CampaignActivity",
		};
	}
	if (
		Object.keys(condition)[0] == "eq" &&
		Object.values(condition)[0].value == 0
	) {
		const valuesFromVar = getValuesFromVar(
			subStepName,
			automation,
			Object.values(condition)[0].var
		);
		valuesFromVar.value = `not${valuesFromVar.value}`;
		return {
			...valuesFromVar,
			campaignsOption: "specific",
			type: "CampaignActivity",
		};
	}
};
const getListOfConditions = (automation, condition) => {
	const operator = Object.keys(condition)[0];

	const condOptions =
		condition.length > 0
			? getSingleCondition(automation, condition[operator][0])
			: { attribute: "opens" };
	return {
		...condOptions,
		op: operator,
		campaignsOption: condition[operator].length == 5 ? "last5" : "all",
	};
};
const isObject = (value) => {
	return !!(value && typeof value === "object" && !Array.isArray(value));
};
const getStepRandomId = () => {};
const traverseObjectToVarKey = (obj, keyToMatch) => {
	if (isObject(obj)) {
		const entries = Object.entries(obj);
		for (let i = 0; i < entries.length; i += 1) {
			const [objectKey, objectValue] = entries[i];
			if (objectKey === keyToMatch) {
				return obj;
			}
			if (isObject(objectValue)) {
				const child = traverseObjectToVarKey(objectValue, keyToMatch);

				if (child !== null) {
					return child;
				}
			}
		}
	}
	return null;
};

/**
 * this function converts a condition from a switch condition to FE condition object
 * @param {} automation
 * @param {*} condition
 */
const getCondition = (subSteps, automation, condition, index) => {
	const varObject = traverseObjectToVarKey(condition, "var");
	// check the subautomation that the varObject evaluates
	const evaluatingStep = automation.sub_automations
		.filter((sub) => sub.name == subSteps.name)[0]
		.steps.filter(
			(step) => `$\{${step.name}` == varObject.var.split("_output")[0]
		)[0];
	const evaluatingSubAutomationName = evaluatingStep.args.sub_automation;
	const actionType = evaluatingStep.args.params.type;
	const triggerIds = evaluatingStep.args.params.trigger_ids
		? evaluatingStep.args.params.trigger_ids
		: evaluatingStep.args.params.trigger_id;
	const randomConditionId = `${Date.now()}${index}`;
	let selectedAttributesValuesOp = null;
	switch (evaluatingSubAutomationName) {
	// if evaluatingSubAutomationName is sub_get_stats -> specific
	case "sub_get_stats":
		return {
			...getSingleCondition(subSteps.name, automation, condition),
			type: "CampaignActivity",
			id: randomConditionId,
		};
	case "evaluate_all_condition_on_text":
	case "evaluate_all_not_condition_on_text":
	case "evaluate_all_gt_condition_number":
	case "evaluate_all_not_gt_condition_number":
	case "evaluate_all_lt_condition_number":
	case "evaluate_all_not_lt_condition_number": {
		const evaluatingStepZero = automation.sub_automations
			.filter((sub) => sub.name == subSteps.name)[0]
			.steps.filter((step) => step.name == `${evaluatingStep.name}loop`)[0];
		const attrVal = evaluatingStepZero.args.params.key;
		const values = evaluatingStep.args.params.values;
		const condition = {
			// the below function will return op, and attribute values
			...getEvaluatedActionFromContactDetailsSwitch(
				evaluatingSubAutomationName,
				!varObject.value
			),
			selectedAttributesValuesOp: values[0]
				? getSelectedAttributesValuesOp(
					evaluatingSubAutomationName,
					values[0]
					  )
				: null,
			selectedAttributesValues: removeRegexChars(values),
			selectedAttributes: [{ name: attrVal }],
		};
		condition.type = "ContactDetails";
		condition.value = "info";
		condition.id = randomConditionId;
		return condition;
	}
	default: {
		const condition = {
			...getEvaluatedActionFromSwitch(
				actionType,
				evaluatingSubAutomationName,
				!varObject.value
			),
			campaignsOption: triggerIds == "${get_last_5_output}" ? "last5" : "all",
			type: "CampaignActivity",
		};
		return { ...condition, id: randomConditionId };
	}
	}
};
/**
 * Convert the condition of the frontend to switch object
 */
const getSingleSwitch = (condition, value, forcedName, addSuffix = true) => {
	const switchCondition = {};
	let op = "";
	let varValue = "";
	if (condition.value.includes("not")) {
		op = "eq";
		switchCondition.eq = { value: 0 };
	} else {
		op = "ge";
		switchCondition.ge = { value: 1 };
	}
	if (condition.value.endsWith("clicked")) {
		varValue = forcedName ? forcedName : condition.value + value;

		varValue = addSuffix ? varValue + "_output.c" : varValue + "_output";
	} else if (condition.value.endsWith("open")) {
		varValue = forcedName ? forcedName : condition.value + value;
		varValue = addSuffix ? varValue + "_output.o" : varValue + "_output";
	} else if (condition.value.endsWith("sent")) {
		varValue = forcedName ? forcedName : condition.value + value;
		varValue = addSuffix ? varValue + "_output.s" : varValue + "_output";
	}
	switchCondition[op].var = `$\{${varValue}}`;
	return switchCondition;
};
/**
 *
 * @param {} subAutomationName
 * @param {*} outputVar : subautomations return outputs in the form of object with keys and values, or a single value, in case of object outputVar
 * is the required key
 */
const constructOutputCallerVar = (subAutomationName, outputVar) => {
	let result = subAutomationName;
	result = outputVar ? result + outputVar : result;
	return `$\{${result}_output}`;
};
/**
 *
 * @param {*} op
 * @param {*} varName
 * @param {*} value
 */
const constructSwitchConditionJson = (
	op,
	varName,
	value,
	invertTheCondition
) => {
	const condition = { [op]: { var: varName, value: value } };
	if (invertTheCondition) {
		return { not: condition };
	}
	return condition;
};
const getPreviousSetOfEmails = (condition, eventId) => {
	const previousEmails = getAllPreviousSendActions(eventId);
	return previousEmails.map((email) => email.id);
};
// const getListOfSwitches = (condition, value)=>{
// 	// get all the previous emails
// 	const previousSetOfEmails = getPreviousSetOfEmails(condition, value);
// 	if(condition.value=="all")
// 		return previousSetOfEmails.map(email => getSingleSwitch({...condition, value:email}, value));
// 	else {
// 		const getOnlyLast5Email = previousSetOfEmails.slice(0, 5).map(email => getSingleSwitch({...condition, value:email}, value));
// 		return getOnlyLast5Email;
// 	}
// };
const getListOfSwitches = (requiredSubAutomationType) => {
	switch (requiredSubAutomationType) {
	case "MultipleTriggers": {
	}
	}
};
const getSwitch = (condition, value) => {
	if (condition.op == "specific") {
		return getSingleSwitch(condition, value);
	} else {
		return {
			[condition.op]: getListOfSwitches("MultipleTriggers", condition, value),
		};
	}
};
const getConditionsValues = (condition, eventId) => {
	let conditionValues = [];
	if (condition.op == "specific") {
		conditionValues.push(condition.selectedCamps[0]);
	} else {
		conditionValues = getPreviousSetOfEmails(condition, eventId);
	}
	return conditionValues;
};
const mapCampaignActivitiesToBECorresponders = (type) => {
	switch (type) {
	case "clicked":
	case "notclicked":
		return "clickthru";
	case "open":
	case "notopen":
		return "opened";
	case "sent":
	case "notsent":
		return "sent";
	}
};
const mapBEActivitiesToCampaignCorresponders = (type) => {
	switch (type) {
	case "clickthru":
		return "clicked";
	case "opened":
		return "open";
	case "sent":
		return "sent";
	}
};
const mapBEContactToContactCorresponders = (type) => {
	const value = getValudOfVariable(type);
	return value.split("input.data.contact.")[1];
};
const getEvaluationSubAutomationName = (type, invert) => {
	if (invert)
		switch (type) {
		case "clicked":
		case "open":
		case "sent":
			return "all_not_action_emails";
		case "notclicked":
		case "notopen":
		case "notsent":
			return "all_action_emails";
		}
	else {
		switch (type) {
		case "clicked":
		case "open":
		case "sent":
			return "all_action_emails";
		case "notclicked":
		case "notopen":
		case "notsent":
			return "all_not_action_emails";
		}
	}
};
/**
 * For campaign activity filters
 * @param {*} type
 * @param {*} subAutomationName
 * @param {*} inverted
 */
const getEvaluatedActionFromSwitch = (type, subAutomationName, inverted) => {
	if (inverted)
		switch (subAutomationName) {
		case "all_not_action_emails": {
			return {
				op: "or",
				value: mapBEActivitiesToCampaignCorresponders(type),
			};
		}
		case "all_action_emails": {
			return {
				op: "or",
				value: `not${mapBEActivitiesToCampaignCorresponders(type)}`,
			};
		}
		}
	else {
		switch (subAutomationName) {
		case "all_action_emails": {
			return {
				op: "and",
				value: mapBEActivitiesToCampaignCorresponders(type),
			};
		}
		case "all_not_action_emails": {
			return {
				op: "and",
				value: `not${mapBEActivitiesToCampaignCorresponders(type)}`,
			};
		}
		}
	}
};

/**
 * For contact details filters
 * @param {*} type
 * @param {*} subAutomationName
 * @param {*} inverted
 */
const getEvaluatedActionFromContactDetailsSwitch = (
	subAutomationName,
	inverted
) => {
	if (inverted)
		return {
			op: "or",
		};
	else {
		return {
			op: "and",
		};
	}
};

/**
 * Function to get the Yes next of a branch
 * @param {*} subStep
 * @param {*} automation
 */
const getNextOfBranch = (subStep, automation) => {
	if (subStep.args.switch[0].steps) {
		if (
			subStep.args.switch[0].steps[0].args.sub_automation == "emit_next_step"
		) {
			const automationMainSteps = convertPlanToFlatArrayOfActions(automation);
			const nextStepNumber = subStep.args.switch[0].steps[0].args.params.step;
			const matchedConditionToEmitStep = nextStepNumber
				? getMatchedConditionToEmitStep(
					automationMainSteps,
					subStep.args.switch[0].steps[0]
				  )
				: null;
			const targetId = matchedConditionToEmitStep
				? matchedConditionToEmitStep.id
				: null;
			return targetId;
		}
	} else {
		return "end";
	}
};
export {
	getCondition,
	getValudOfVariable,
	getValuesFromVar,
	getRepresentableStepForTheCondition,
	getSwitch,
	getPreviousSetOfEmails,
	getConditionsValues,
	mapCampaignActivitiesToBECorresponders,
	getSingleSwitch,
	getEvaluationSubAutomationName,
	constructOutputCallerVar,
	constructSwitchConditionJson,
	getNextOfBranch,
};
