const LIST_SENDERS_SUCCESS = "LIST_SENDERS_SUCCESS";
const SENDERS = "SET_SENDERS";
const DOMAINS = "SET_DOMAINS";
const SENDERS_COUNT = "SET_SENDERS_COUNT";
const LISTED_SENDERS_PER_PAGE = "SET_LISTED_SENDERS_PER_PAGE";
const LISTED_SENDERS_PAGINATION = "SET_LISTED_SENDERS_PAGINATION";
const SET_UNBLOCKED_DOMAINS = "SET_UNBLOCKED_DOMAINS";
const SET_DKIM_KEYS = "SET_DKIM_KEYS";

export {
	LIST_SENDERS_SUCCESS,
	LISTED_SENDERS_PAGINATION,
	LISTED_SENDERS_PER_PAGE,
	SENDERS_COUNT,
	SENDERS,
	DOMAINS,
	SET_UNBLOCKED_DOMAINS,
	SET_DKIM_KEYS,
};
